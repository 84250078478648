import { Button, HSpacer, Modal, ProgressIndicator, VSpacer } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

const steps = 5;
export const ProgressIndicatorDemo = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [circleStep, setCircleStep] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const handleNext = () => {
    if (activeStep < steps) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep > 1 ? activeStep - 1 : activeStep);
  };

  const handleCircleNext = () => {
    setCircleStep(circleStep < steps ? circleStep + 1 : circleStep);
  };

  const handleCircleBack = () => {
    setCircleStep(circleStep >= 0 ? circleStep - 1 : circleStep);
  };

  return (
    <Stack spacing={1}>
      <Button onClick={() => setShowModal(true)} testID="show-modal">
        Show Modal with Progress Indicator
      </Button>
      <DemoBlock label="Primary Linear" sx={{ padding: 5 }}>
        <ProgressIndicator
          activeStep={activeStep}
          steps={steps}
          testID="test"
          type="primary"
        />
      </DemoBlock>
      <DemoBlock label="Secondary Linear" sx={{ padding: 5 }}>
        <ProgressIndicator
          activeStep={activeStep}
          steps={steps}
          testID="test"
          type="secondary"
        />
      </DemoBlock>
      <Stack direction="row" justifyContent="space-between">
        <Button
          disabled={activeStep < 2}
          onClick={handleBack}
          testID="test"
        >
          Back
        </Button>
        <Button
          disabled={activeStep === steps}
          onClick={handleNext}
          testID="test"
        >
          Next
        </Button>
      </Stack>
      <DemoBlock
        label="Circular"
        sx={{
          padding: 5,
        }}
      >
        <ProgressIndicator
          activeStep={circleStep}
          steps={steps}
          testID="test"
          type="circle"
        />
        <VSpacer size="3" />
        <Stack direction="row">
          <Button
            disabled={circleStep === 0}
            onClick={handleCircleBack}
            size="tiny" testID="test"
          >
            Back
          </Button>
          <HSpacer size="3" />
          <Button
            disabled={circleStep === steps}
            onClick={handleCircleNext}
            size="tiny"
            testID="test"
          >
            Next
          </Button>
        </Stack>
      </DemoBlock>
      <VSpacer size="6" />
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          open
          testID="test"
          title="Progress Stepper Modal Example"
        >
          <DemoBlock label="Primary Linear">
            <ProgressIndicator
              activeStep={activeStep}
              steps={steps}
              testID="test"
              type="primary"
            />
          </DemoBlock>
          <DemoBlock label="Secondary Linear">
            <ProgressIndicator
              activeStep={activeStep}
              steps={steps}
              testID="test"
              type="secondary"
            />
          </DemoBlock>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              disabled={activeStep < 2}
              onClick={handleBack}
              testID="test"
            >
              Back
            </Button>
            <Button
              disabled={activeStep === steps - 1}
              onClick={handleNext}
              testID="test"
            >
              Next
            </Button>
          </Stack>
        </Modal>
      )}
    </Stack>
  );
};
