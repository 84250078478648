import { SpacerSize } from '@/themes/variant-interfaces/Size';
import { BannerStatus } from '@/themes/variant-interfaces/Status';
import {
  CheckCircleOutlined,
  ErrorOutline,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Box, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { HSpacer, VSpacer } from '../Spacer';
import { Text } from '../Text/Text';

interface BannerStatusObject {
  color: string,
  icon: ReactNode,
}

interface IconProps {
  'data-testid': string,
  fontSize: 'inherit' | 'large' | 'medium' | 'small',
  sx: SxProps,
}

export interface GlobalBannerProps {
  action?: ReactNode,
  bottomSpacerSize?: SpacerSize,
  description?: string,
  hideIcon?: boolean,
  message: string,
  onClose?: () => void,
  open?: boolean,
  position?: CSSProperties['position'],
  status?: BannerStatus,
  testID: string,
}

export const GlobalBanner = ({
  action,
  bottomSpacerSize,
  description,
  hideIcon,
  message,
  onClose,
  open = true,
  position = 'sticky',
  status = 'success',
  testID,
}: GlobalBannerProps) => {
  const theme = useTheme();
  const iconTestId = `${testID}-icon`;
  const props: IconProps = {
    'data-testid': iconTestId,
    fontSize: 'small',
    sx: { color: theme.palette[status].contrastText },
  };
  const statusMapping: Record<BannerStatus, BannerStatusObject> = {
    error: {
      icon: hideIcon ? null : <ErrorOutline {...props} />,
      color: 'error.main',
    },
    info: {
      icon: hideIcon ? null : <InfoOutlined {...props} />,
      color: 'info.main',
    },
    success: {
      icon: hideIcon ? null : <CheckCircleOutlined {...props} />,
      color: 'success.main',
    },
    warning: {
      icon: hideIcon ? null : <WarningAmberOutlined {...props} />,
      color: 'warning.main',
    },
  };

  const boxStyle: SxProps<Theme> = {
    backgroundColor: statusMapping[status].color,
    minHeight: '48px',
    position,
    width: '100%',
    ...(open ? { opacity: 1, display: 'block' } : { opacity: 0, display: 'none' }),
  };

  return (
    <>
      <Box sx={boxStyle}>
        <Stack
          alignItems="flex-start"
          direction="row"
          height="inherit"
          justifyContent="space-between"
        >
          <Stack alignSelf="center" direction="row" marginLeft="16px" py="12px">
            {statusMapping[status].icon}
            <HSpacer size="3" />
            <Stack>
              <Text
                category="title-medium"
                sx={{ color: theme.palette[status].contrastText }}
                testID={`${testID}-message`}
              >
                {message}
              </Text>
              {!!description && (
                <Text
                  category="body-small"
                  sx={{ color: theme.palette[status].contrastText }}
                  testID={`${testID}-description`}
                >
                  {description}
                </Text>
              )}
            </Stack>
          </Stack>
          <Stack alignItems="center" direction="row" m="4px">
            {action}
            {!!onClose && (
              <IconButton
                onClick={onClose}
                size="large"
                sx={{ color: theme.palette[status].contrastText }}
                testID={`${testID}-close-button`}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
      {!!bottomSpacerSize && (
        <VSpacer size={bottomSpacerSize}/>
      )}
    </>
  );
};
