import { HSpacer, Text } from '@/components/DesignSystem';
import { ApiProductRecommendation } from '@api/interfaces';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { ViewedBadge } from '@/components/shared/ViewedBadge';

interface ProductRecommendationDetailsProps {
  recommendation?: ApiProductRecommendation,
  showDescription?: boolean,
}

const ProductRecommendationDetails = ({
  recommendation,
  showDescription = false,
}: ProductRecommendationDetailsProps) => {
  const numberOfProducts = (
    (recommendation?.productRecommendationData?.length ?? 0)
    + (recommendation?.bundles?.length ?? 0)
  );
  const showViewedBadge = !!recommendation?.recommendedFarmer
    ?.some(({ viewedAt }) => !!viewedAt);
  return (
    <Stack spacing={1.2}>
      <Stack alignItems="center" direction="row">
        <Text category='h6' fontWeight="medium">
          Recommendation #{recommendation?.publicId}
        </Text>
        {showViewedBadge && (
          <>
            <HSpacer size="3" />
            <ViewedBadge
              testID={`recommendation-${recommendation?.publicId}-viewed-indicator`}
            />
          </>
        )}
      </Stack>
      <Stack flexDirection="row">
        <Text>
          Salesperson Name:
        </Text>
        <HSpacer size="2" />
        <Text category="body-medium" color="primary">
          {recommendation?.salespersonDetails?.businessName ?? '-'}
        </Text>
      </Stack>
      <Stack flexDirection="row">
        <Text>
          Products: {numberOfProducts}&nbsp;&nbsp;
          Farmers: {recommendation?.recommendationFarmer?.length}
        </Text>
      </Stack>
      <Stack flexDirection="row">
        <Text>
          Created On:
        </Text>
        <HSpacer size="2" />
        <Text color="success">
          {DateTime.fromJSDate(recommendation!.createdAt)
            .toFormat('MMM dd, yyyy')}
        </Text> &nbsp;&nbsp;
        <Text>
          Expires On:
        </Text>
        <HSpacer size="2" />
        <Text color="warning">
          {DateTime.fromJSDate(recommendation!.expiration)
            .toFormat('MMM dd, yyyy')}
        </Text>
      </Stack>
      {showDescription && (
        <Stack flexDirection="row">
          <Text>
            Description:
          </Text>
          <HSpacer size="2" />
          <Text>
            {recommendation?.note}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductRecommendationDetails;
