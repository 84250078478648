import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { ManufacturerEndpoint } from '@api/endpoints/ManufacturerEndpoint';

export class ManufacturerApi {
  static create (body: ManufacturerEndpoint.Create.Request) {
    return Client('manufacturers', { body, method: 'POST' });
  }

  static list (
    filters: ManufacturerEndpoint.List.Query = {},
  ): Promise<ManufacturerEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`manufacturers?${query}`);
  }

  static update (id: string, body: ManufacturerEndpoint.Update.Request) {
    return Client(`manufacturers/${id}`, { body, method: 'PATCH' });
  }

  static getManufacturer (id: string): Promise<ManufacturerEndpoint.List.Response> {
    return Client(`manufacturers/${id}`, { method: 'GET' });
  }

  static getManufacturerName (filters: string,
  ): Promise<ManufacturerEndpoint.List.Response> {
    return Client(`manufacturers?search=${filters}`);
  }
}
