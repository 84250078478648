import { Box, IconButton, Stack } from '@mui/material';
import { HSpacer, ProgressStepper, Text, VSpacer } from '@/components/DesignSystem';
import { NavigateNextOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import {
  useMediaQuery,
} from '@/hooks/useMediaQuery';
import { CarouselSlides } from './CarouselSlides';
import { useState } from 'react';

type CarouselProps = {
  buttons: JSX.Element,
  slides: CarouselSlides,
}
export const Carousel = ({ slides, buttons }: CarouselProps) => {
  const { isMobile } = useMediaQuery();
  const [currentSlide, setCurrentSlide] = useState(0);

  const slide = slides[currentSlide];
  const SlideComponent = slide.component;

  return (
    <Stack>
      <SlideComponent />
      <Box position='relative'>
        <Stack
          bottom={isMobile ? '8vw' : '10vh'}
          left={0}
          margin='auto'
          maxWidth={isMobile ? '100vw' : '600px'}
          paddingX={isMobile ? '8px' : '20px' }
          position='absolute'
          right={0}
        >
          <Stack paddingX={isMobile ? '10px' : undefined }>
            {!!slide.header && (
              <>
                <Text category='h2' fontWeight={500} textAlign='center'>
                  {slide.header}
                </Text>
                <VSpacer size='6'/>
              </>
            )}
            <Stack paddingX={isMobile ? '8px' : '40px'}>
              <Text category='body-large' textAlign='center'>
                {slide.description}
              </Text>
              <VSpacer size='6'/>
              <Stack direction='row' justifyContent='space-between'>
                {currentSlide > 0 && (
                  <IconButton
                    onClick={() => {
                      setCurrentSlide(
                        currentSlide > 0 ? currentSlide - 1 : 0,
                      );
                    }}
                    sx={{ border: 1 }}
                  >
                    <NavigateBeforeOutlined />
                  </IconButton>
                )}
                {currentSlide === 0 && (
                  <HSpacer size='9' />
                )}
                <ProgressStepper
                  activeStep={currentSlide}
                  steps={slides.length}
                  testID='slide-stepper'
                />
                <IconButton
                  onClick={() => {
                    setCurrentSlide(
                      currentSlide < slides.length - 1 ? currentSlide + 1 : 0,
                    );
                  }}
                  sx={{ border: 1 }}
                >
                  <NavigateNextOutlined />
                </IconButton>
              </Stack>
              <VSpacer size='8' />
              {buttons}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
