import { QueryKeys } from '@/constants/QueryKeys';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { ProductApi } from '@/utilities/api/ProductApi';
import { Box, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getProductImageUrl } from '@/utilities/Product';
import { SavedProduct } from './ProductPage';
import {
  Button,
  CalculateQuantityModal,
  HSpacer,
  NumericInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { PricingRequestCartEndpoint } from '@api/endpoints';
import { PackageType, parseEnums, ProductUom, ProductUomEnums } from '@shared/enums';
import { ProductUomSelect } from '@/components/shared/Select/ProductUomSelect';
import { PackageTypeSelect } from '@/components/shared/Select/PackageTypeSelect';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Calculate from '@mui/icons-material/Calculate';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { FriendlyUoM } from '@/constants/FriendlyUoM';

const styles: SXStyles = {
  image: {
    borderRadius: '16px',
    maxHeight: '112px',
    maxWidth: '112px',
    objectFit: 'contain',
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    wordBreak: 'break-word',
  },
} as const;

interface ProductModalProps {
  cartProductId?: string,
  isAlternate?: boolean,
  isCompanion?: boolean,
  onClose: () => void,
  open: boolean,
  parentProductName?: string,
  productId: string,
}

export const ProductModal = ({
  cartProductId,
  isAlternate = false,
  isCompanion = false,
  onClose,
  open,
  parentProductName,
  productId,
}: ProductModalProps) => {
  const { isMobile } = useMediaQuery();
  const {
    createShoppingCart,
    shoppingCart,
    setShowCart,
    updateShoppingCart,
  } = useShoppingCart();
  const [showCalculateQuantityModal, setShowCalculateQuantityModal] = useState(false);

  const { data: product, isFetching } = useQuery(
    [QueryKeys.GET_PRODUCT_DETAILS, productId],
    async () => {
      return (await ProductApi.get(productId));
    },
  );

  const manufacturerName = product?.manufacturer?.name ?? null;
  const imageUrl = getProductImageUrl(product?.image);

  const [orderedProduct, setOrderedProduct] = useState<SavedProduct>({
    image: product?.image,
    name: product?.name || '',
    productId: product?.id || '',
  });
  const { user } = useAuthentication();

  useEffect(() => {
    if (product) {
      setOrderedProduct({
        ...orderedProduct,
        image: product?.image,
        name: product.name ?? '',
        productId: product.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (cartProductId) {
      const cartProduct = shoppingCart?.find(({ id }) => id === cartProductId);
      if (cartProduct) {
        setOrderedProduct({
          ...orderedProduct,
          package: cartProduct.package as PackageType,
          quantity: cartProduct.quantity,
          uom: cartProduct.uom as ProductUom,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProductId, shoppingCart]);

  const isValid = !!orderedProduct.quantity && !!orderedProduct.uom;
  const isProductAdded = shoppingCart.some(({ productId: cartProductId }) => (
    cartProductId === productId
  ));
  const showQuantityUom = isProductAdded && !!orderedProduct.quantity && !!orderedProduct.uom;
  const isEditingCartProduct = !!cartProductId;
  const editMode = !isProductAdded || isEditingCartProduct;

  const handleUpdateCart = async () => {
    if (isValid) {
      if (isEditingCartProduct) {
        await updateShoppingCart({
          shoppingCartId: cartProductId,
          updateRequest: {
            ...orderedProduct as PricingRequestCartEndpoint.UpdatePricingRequestCart.Request,
            isAlternate,
            isCompanion,
            parentProductName,
            package: orderedProduct.package ?? undefined,
          },
        });
        onClose();
      } else {
        await createShoppingCart({
          createRequest: {
            ...orderedProduct,
            isAlternate,
            isCompanion,
            parentProductName,
          } as PricingRequestCartEndpoint.Create.Request,
          image: orderedProduct.image,
          user,
        });
      }
    }
  };

  const getAllowedUoms = () => {
    return (product?.purchaseUom ?? []).map((uom: string) => (
      parseEnums(ProductUomEnums, uom)
    ));
  };

  const productImage = (
    <Box
      alt={`Image of ${product?.name}`}
      component="img"
      src={imageUrl}
      sx={styles.image}
    />
  );

  const productInputs = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <NumericInput
          label="Quantity"
          maxValue={9999999.99}
          onChangeNumber={(quantity) =>
            setOrderedProduct({ ...orderedProduct, quantity })
          }
          testID="product-quantity-input"
          value={orderedProduct?.quantity ?? undefined}
          variant="outlined"
        />
        <HSpacer size="5" />
        <ProductUomSelect
          allowedUoms={getAllowedUoms()}
          onChangeUoM={(uom) => {
            setOrderedProduct({ ...orderedProduct, uom });
          }}
          testID="product-uom-input"
          value={orderedProduct.uom ?? undefined}
          variant="outlined"
        />
      </Stack>
      <VSpacer size="6" />
      <Stack>
        <PackageTypeSelect
          allowedPackageTypes={product?.packageSize || []}
          onChangePackageType={(val) => setOrderedProduct({ ...orderedProduct, package: val })}
          packageType={orderedProduct.package || ''}
          testID="package-type-select"
          variant="outlined"
        />
      </Stack>
    </>
  );

  let title;
  if (isEditingCartProduct) {
    title = 'Edit Product';
  } else {
    title = isProductAdded ? 'Added to cart' : '';
  }

  return (
    <>
      <ModalBottomSheet
        containerStyle={{ padding: isMobile ? '16px 16px 0' : undefined }}
        footerSpacerSize="1"
        hideBottomCloseButton
        onClose={onClose}
        open={open && !isFetching}
        testID="product-modal"
        title={title}
        titleAccessory={(isProductAdded && !editMode) ? (
          <CheckCircle sx={{ color: 'success.main' }} />
        ) : undefined}
        width={360}
      >
        <Stack justifyContent="space-between">
          <Stack>
            <Stack direction="row" justifyContent="flex-start">
              {productImage}
              <HSpacer size="5" />
              <Stack>
                <Text category="title-medium" sx={styles.truncateText} testID="product-name">
                  {product?.name}
                </Text>
                <VSpacer size="2" />
                <Text category="body-medium" sx={styles.truncateText} testID="product-manufacturer">
                  {manufacturerName}
                </Text>
                {showQuantityUom && (
                  <>
                    <VSpacer size="2" />
                    <Text
                      category="title-medium"
                      sx={styles.truncateText}
                      testID="product-quantity-uom"
                    >
                      {`${orderedProduct.quantity} ${FriendlyUoM[orderedProduct.uom!]}`}
                    </Text>
                  </>
                )}
              </Stack>
            </Stack>
            {editMode && (
              <>
                <VSpacer size="8" />
                {productInputs}
                <VSpacer size="3" />
                <Stack alignItems="flex-start">
                  <HSpacer size="4" />
                  <Button
                    color="secondary"
                    onClick={() => setShowCalculateQuantityModal(true)}
                    startIcon={<Calculate />}
                    testID="calculate-quantity-button"
                    variant="text"
                  >
                    Calculate quantity
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
          <VSpacer mobileSize="8" size="7" />
          <Stack
            direction="row"
            justifyContent={(isMobile || editMode) ? 'center' : 'flex-end'}
          >
            {editMode ? (
              <Button
                disabled={!isValid}
                onClick={handleUpdateCart}
                testID="add-to-cart-button"
                variant="contained"
              >
                {isEditingCartProduct ? 'Update' : 'Add to cart'}
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    onClose();
                    setShowCart(true);
                  }}
                  sx={{ padding: '10px', width: '148px' }}
                  testID="view-cart-button"
                  variant="outlined"
                >
                  {`View Cart (${shoppingCart?.length ?? 0})`}
                </Button>
                <HSpacer size="5" />
                <Button
                  onClick={onClose}
                  sx={{ padding: '10px', width: '148px' }}
                  testID="continue-shopping-button"
                  variant="contained"
                >
                  Continue Shopping
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </ModalBottomSheet>
      {showCalculateQuantityModal && (
        <CalculateQuantityModal
          allowedUoms={getAllowedUoms()}
          onApplyAndClose={(quantity, uom) => {
            setOrderedProduct({ ...orderedProduct, quantity, uom });
            setShowCalculateQuantityModal(false);
          }}
          onClose={() => setShowCalculateQuantityModal(false)}
          open
          productUom={orderedProduct.uom ?? undefined}
        />
      )}
    </>
  );
};
