import { Button, HSpacer, TextLink } from '@/components/DesignSystem';
import { ProductCard } from '@/components/DesignSystem/ProductCard/ProductCard';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ReviewAction } from '@/components/shared/SharedCardComponents/ReviewAction';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { ProductOffers } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { MyOrderButton } from '@/pages/ReviewSelectOffers/MyOrderButton';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';

const styles: SXStyles = {
  box: {
    px: getResponsiveValue('17px', '0'),
    py: '20px',
  },
};

interface ProductOfferCardProps {
  isClosed?: boolean,
  isInOpenStatus?: boolean,
  onChange: (productOffers: ProductOffers) => void,
  onViewOrder: () => void,
  orders: number,
  parentProductName?: string,
  productOffers: ProductOffers,
  readonly?: boolean,
  testID: string,
}

export const ProductOfferCard = ({
  isClosed,
  isInOpenStatus,
  onChange,
  onViewOrder,
  orders,
  parentProductName,
  productOffers,
  readonly,
  testID,
}: ProductOfferCardProps) => {
  const { openSnackbar } = useSnackbar();
  const retailerOfferProduct = productOffers.retailers[0]?.offerProduct;
  const substituteName = retailerOfferProduct?.substituteProduct;
  const packageType = retailerOfferProduct?.packageType;

  const isCompanion = (
    !!productOffers.product.companionToProductId
    || !!retailerOfferProduct?.isCompanion
  );
  const isRetailerAlternate = !!retailerOfferProduct?.isAlternate;
  const isAlternate = (
    !!productOffers.product.alternativeTo
    || isRetailerAlternate
  );
  const alternateName = productOffers.product.alternativeTo
    ? productOffers.product.name
    : (
      retailerOfferProduct?.substituteProduct
      || retailerOfferProduct?.productDetail?.name
    );
  const alternativeTo = isAlternate
    ? productOffers.product.alternativeTo ?? productOffers.product.name
    : null;

  let productName;
  if (isAlternate) {
    productName = alternateName ?? productOffers.product.name;
  } else if (isCompanion) {
    productName = retailerOfferProduct?.productDetail?.name ?? productOffers.product.name;
  } else {
    productName = productOffers.product.name;
  }

  const product = {
    ...productOffers.product,
    alternativeTo,
    name: productName,
    package: ((isCompanion || isAlternate) && packageType)
      ? packageType
      : productOffers.product.package,
  } as unknown as OfferProduct;

  const acceptedRetailer = productOffers.retailers.find(({ offerProduct }) => (
    offerProduct?.id && offerProduct.id === productOffers.product.acceptedOfferProductId
  ));

  const productOfferRetailers = productOffers.retailers.filter(x => !!x.offerProduct);

  return (
    <Box sx={styles.box}>
      <ProductCard
        acceptMessage={!acceptedRetailer ? undefined : acceptedRetailer?.retailer?.name ?? ''}
        acceptedRetailer={acceptedRetailer}
        companionToProductName={isCompanion
          ? (parentProductName ?? productOffers.product.name)
          : undefined
        }
        isAlternate={isAlternate}
        isClosed={isClosed}
        isInOpenStatus={isInOpenStatus}
        product={product}
        productOfferRetailerAcceptButton={(onClick) => !productOfferRetailers.length ? (
          <DesktopOnly>
            <Button
              onClick={onClick}
              testID={`product-card-view-details-close-button-${testID}`}
              variant="text"
            >
              Close
            </Button>
          </DesktopOnly>
        ) : (
          <>
            <DesktopOnly>
              {!readonly && (
                <>
                  <Button
                    onClick={onViewOrder}
                    testID={`product-card-view-details-close-button-${testID}`}
                    variant="text"
                  >
                    View order
                  </Button>
                  <HSpacer size="3" />
                </>
              )}
              <Button
                onClick={onClick}
                testID={`product-card-view-details-close-button-${testID}`}
              >
                Done
              </Button>
            </DesktopOnly>
            <MobileOnly>
              <MyOrderButton
                onViewOrder={onViewOrder}
                orders={orders}
                readonly={readonly}
              />
            </MobileOnly>
          </>
        )}
        productOfferRetailerAccessory={({ offerProduct }) => {
          const isAdded = productOffers.product.acceptedOfferProductId === offerProduct?.id;

          if (readonly) {
            return <></>;
          }

          return isAdded ? (
            <>
              <ReviewAction
                onClick={() => onChange({
                  ...productOffers,
                  product: {
                    ...productOffers.product,
                    acceptedOfferProductId: null,
                  },
                })}
                testID={`${testID}-remove-product`}
                variant="remove-product"
              />
              <CheckCircle color="success"/>
            </>
          ) : (
            <ReviewAction
              onClick={() => {
                if (offerProduct?.id) {
                  openSnackbar(
                    'Product added to order',
                    undefined,
                    undefined,
                    <TextLink
                      category="label-large"
                      color="primary"
                      onClick={onViewOrder}
                      testID={`${testID}-view-order`}
                    >
                      View order
                    </TextLink>,
                  );
                  onChange({
                    ...productOffers,
                    product: {
                      ...productOffers.product,
                      acceptedOfferProductId: offerProduct.id,
                    },
                  });
                }
              }}
              testID={`${testID}-add-product`}
              variant="add-product"
            />
          );
        }}
        productOfferRetailers={productOfferRetailers}
        substituteProduct={substituteName}
        testID={testID}
        variant={isCompanion ? 'companion' : 'default'}
      />
    </Box>
  );
};
