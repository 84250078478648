import {
  Button,
  Dialog,
  Heading,
  HSpacer,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { InlineMessage } from '@/components/DesignSystem/InlineMessage/InlineMessage';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiError } from '@api/interfaces';
import { Stack } from '@mui/material';
import { GrowersContactInfo } from '@shared/enums';
import { useState } from 'react';
import { useMutation } from 'react-query';

type VerifyEmailProps = {
  flow: 'profile' | 'product-request',
  email: string,
  onClose: () => void,
  testID?: string,
  userId: string,
}
export const VerifyYourEmail = ({ email, flow, onClose, testID, userId }: VerifyEmailProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { openSnackbar } = useSnackbar();
  const emailSuccessMsg = "Email successfully resent";
  const errorMsg = "An error has occurred";

  const { mutate: resendEmail } = useMutation(
    () => UserApi.resendVerifyEmail(userId),
    {
      onError: (err: ApiError) => {
        setErrorMessage(err.message || errorMsg);
      },
      onSuccess: () => {
        openSnackbar(emailSuccessMsg);
        setEmailSent(true);
        onClose();
      },
    },
  );

  const message = flow === 'product-request' ? `
    Before submitting a product request, please verify your email by clicking the
    button below. An email will be sent to ${email}.
    Please follow the prompt in the email to complete the verification.
  ` : `
    Your email address has been updated and a verification email was sent to ${email}.
    In order to receive product request updates via email, please verify your email address
    by following the prompt in the email.
  `;

  return (
    <Dialog
      onClose={onClose}
      open
      testID="verify-email-dialog"
    >
      <Stack alignItems="center">
        <Heading level="1" testID={`${testID}-heading`} title="One last step..." />
        <VSpacer size="8" />
        <Text category="body-medium" testID={`${testID}-text-1`} textAlign="center">
          {message}
        </Text>
        <VSpacer size="8" />
        <Button
          disabled={emailSent}
          onClick={() => {
            setErrorMessage('');
            resendEmail();
          }}
          testID={`${testID}-resend-email`}
          variant="outlined"
        >
          {flow === 'profile' ? 'Resend Verification Email' : 'Send Verification Email'}
        </Button>
        <VSpacer size="6" />
        <Text category="body-small" textAlign="center">
          Need help? Get in touch
        </Text>
        <VSpacer size="6" />
        <Stack direction="row">
          <TextLink
            category="body-medium"
            href={`mailto:${GrowersContactInfo.supportEmail}`}
            testID={`${testID}-send-email-link`}
          >
            Send us an email
          </TextLink>
          <HSpacer size="3" />
          <Text>or</Text>
          <HSpacer size="3" />
          <TextLink
            category="body-medium"
            href={`tel:${GrowersContactInfo.supportPhone}`}
            testID={`${testID}-call-link`}
          >
            Give us a call
          </TextLink>
        </Stack>
        {!!errorMessage && (
          <>
            <VSpacer size="9" />
            <InlineMessage
              secondaryText={errorMessage}
              secondaryTextColor="error"
              testID={`${testID}-error`}
              title="Error:"
              titleColor="error"
            />
            <VSpacer mobileSize="5" size="8" />
          </>
        )}
      </Stack>
    </Dialog>
  );
};
