import React, { useState } from 'react';
import { Box, Container, List, ListItem } from '@mui/material';
import { Text, VSpacer } from '@/components/DesignSystem';
import SponsoredBanners from './SponsoredBanners';
import FeaturedManufacturer from './FeaturedManufacturer';
import FeaturedProduct from './FeaturedProduct';
import { HomeScreenList } from '@/constants/constant';

const AdminHomePage = () => {
  const [activeList, setActiveList] = useState(1);

  const handleListClick = (listNumber: number) => {
    setActiveList(listNumber);
  };

  const renderDescription = () => {
    let description;
    switch (activeList) {
      case 1:
        description = <SponsoredBanners />;
        break;
      case 2:
        description = <FeaturedProduct />;
        break;
      case 3:
        description = <FeaturedManufacturer />;
        break;
      default:
        description = <div />;
    }

    return description;
  };

  const lists = [
    { listNumber: 1, name: HomeScreenList.SponsoredBanner },
    { listNumber: 2, name: HomeScreenList.FeaturedProdcuts },
    { listNumber: 3, name: HomeScreenList.FeaturedManufacturers },
  ];

  return (
    <Container maxWidth='md'>
      <VSpacer size='8' />
      <Text category='h5'>GROWERS Home</Text>
      <VSpacer size='5' />
      <Box display='flex'>
        <List style={{ backgroundColor: 'transparent' }}>
          {lists.map(
            ({ listNumber, name }: { listNumber: number; name: string }) => (
              <ListItem
                key={listNumber}
                onClick={() => handleListClick(listNumber)}
                sx={{
                  color: activeList === listNumber ? '#EDF354' : 'inherit',
                  backgroundColor:
                    activeList === listNumber ? '#5C640C' : 'transparent',
                  fontWeight: activeList === listNumber ? 'medium' : 'normal',
                  cursor: 'pointer',
                  padding: '20px 20px',
                  borderRadius: '30px',
                }}
              >
                {name}
              </ListItem>
            ),
          )}
        </List>
        <Box ml={5}>{renderDescription()}</Box>
      </Box>
    </Container>
  );
};

export default AdminHomePage;
