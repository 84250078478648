import { Button, Card, HSpacer, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { Box, Stack } from "@mui/material";
import { State } from "@shared/enums";
import { useRef, useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { useAddCounties } from "@/hooks/useRetailerLocations";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { ToastMessages } from "@/constants/constant";

export interface ImportAllowedCountiesModalProps {
  isVisible: boolean,
  onClose: () => void,
  partnerRetailerId: string,
}

interface Counties {
  state: State,
  county: string,
}

const ImportAllowedCountiesModal = ({
  isVisible,
  onClose,
  partnerRetailerId,
}: ImportAllowedCountiesModalProps) => {

  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  const { openSnackbar } = useSnackbar();
  
  const { addCounties, isLoading } = useAddCounties(partnerRetailerId);

  const downloadTemplate = () => {
    const downloadLink = document.createElement("a");
    downloadLink.download = "import_counties.csv";

    const blob = new Blob(["state,county\n,"], { type: "text/csv" });
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.click();
  };

  const parseStringAsCsv = (csvString: string) => {
    const result = [];
    const lines = csvString.split("\n");
    const header = lines[0];
    const properties = header
      .split(",")
      .map((r) => r.trim());

    for (let i = 1; i < lines.length; i++) {
      const rowData = new Map<string, string>();
      const values = lines[i]
        .replace("\r", "")
        .split(",")
        .map((r) => r.trim());

      properties.forEach((prop, index) => {
        rowData.set(prop, values[index]);
      });

      result.push(rowData);
    }
    return result;
  };

  const validateData = (data: Map<string, string>[]) => {

    const rows: Counties[] = [];
    const states = Object.values(State).map((s) => `${s}`);
    const errors: string[] = [];

    data.forEach((row, index) => {
      const state = row.get("state");
      const county = row.get("county");

      const lineIndicator = `[Line ${index + 1}] `;

      if (state || county) {
        if (!state) {
          errors.push(`${lineIndicator} State was not defined`);
        } else if (!county) {
          errors.push(`${lineIndicator} County was not defined`);
        } else if (!states.includes(state)) {
          errors.push(`${lineIndicator} Invalid state: ${state}`);
        } else {
          rows.push({ state: state as State, county });
        }
      }
    });

    if (rows.length === 0 && errors.length === 0) {
      errors.push("No data to import was found");
    }
    return { rows, errors };
  };

  const handleServeAllCountiesButton = () => {
    addCounties({
      counties: [],
      serveAllCounties: true,
    });
    openSnackbar(ToastMessages.serveAllCounties);
    onClose();
  };

  const handleFileSelected = (files?: FileList) => {
    if (!files || files.length === 0) {
      return;
    }

    setErrorMessage("");
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      (inputRef.current as any).value = null;
      const bom = "ï»¿";
      const result = (reader.result as string).replace(bom, "");
      const parsedData = parseStringAsCsv(result);
      const { rows, errors } = validateData(parsedData);

      if (errors.length > 0) {
        setErrorMessage(errors.join('<br />'));
      } else {
        addCounties({
          counties: rows,
          serveAllCounties: false,
        });
        onClose();
      }
    };
    reader.readAsBinaryString(file);
  };

  return (
    <>
      <input
        accept=".csv"
        onChange={(e) => handleFileSelected(e.target.files as FileList)}
        ref={inputRef}
        style={{ display: "none" }}
        type="file" />
      <Modal
        acceptButton={(props) => (
          <Button onClick={handleServeAllCountiesButton} {...props}>
            Allow all counties
          </Button>
        )}
        cancelButton={(props) => (
          <Button onClick={onClose} {...props}>
            Close
          </Button>
        )}
        loading={isLoading}
        onClose={onClose}
        open={isVisible}
        testID="import-counties-modal"
        title="Import Counties"
      >
        <Stack>
          <Stack direction="row">
            <Button
              onClick={downloadTemplate}
              size="large"
              startIcon={<DownloadIcon />}
              testID="import-allowed-counties-modal-download-template"
            >
              Download template
            </Button>
            <HSpacer size="4" />
            <Button
              onClick={() => (inputRef.current as any).click()}
              size="large"
              startIcon={<UploadIcon />}
              testID="import-allowed-counties-modal-upload-template"
            >
              Upload import file
            </Button>
          </Stack>
          <VSpacer size="7" />
          <Card testID="import-allowed-counties-modal">
            {!errorMessage && !isLoading && (
              <Text category="p1">
                Please upload a file, errors will show up here (if any)
              </Text>
            )}
            {isLoading && (
              <Text category="p1">
                Importing file, please wait...
              </Text>
            )}
            <VSpacer size="5" />
            <Box color="error" dangerouslySetInnerHTML={{ __html: errorMessage }} sx={{
              overflowY: "scroll",
              height: "250px",
            }}>
            </Box>
          </Card>
        </Stack>
      </Modal>
    </>
  );
};

export default ImportAllowedCountiesModal;

