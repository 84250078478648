import { InlineMessage } from '@/components/DesignSystem';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';

interface ViewedBadgeProps {
  testID: string,
}

export const ViewedBadge = ({ testID }: ViewedBadgeProps) => {
  return (
    <InlineMessage
      icon={<RemoveRedEye color="info" />}
      testID={testID}
      title="VIEWED"
      titleCategory="label-medium"
      titleColor="info"
      titleSpacing="2"
    />
  );
};
