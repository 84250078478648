export enum PaymentMethod {
  Financing = 'financing',
  OnAccount = 'on account',
  Prepayment = 'prepayment',
  Rewards = 'rewards',
}

export enum PaymentMethodNew {
  Financing = 'financing',
  OnAccount = 'on_acccount',
  Rewards = 'rewards',
}
