import { QueryKeys } from '@/constants/QueryKeys';
import { ProductRecommendationApi } from '@/utilities/api/ProductRecommendationApi';
import { ProductRecommendationEndpoint } from '@api/endpoints';
import { useQuery } from 'react-query';

const useProductRecommendations = (
  filters: ProductRecommendationEndpoint.ListProductRecommendation.Query,
  enabled: boolean = true,
) => {
  const { data: productRecommendations, isFetching } = useQuery(
    [QueryKeys.GET_RECOMMENDATIONS, filters],
    () => ProductRecommendationApi.list(filters),
    {
      enabled,
      placeholderData: undefined,
    },
  );

  return { productRecommendations, isFetching };
};

const useProductRecommendationsById = (id: string) => {
  const { data: productRecommendationById, isLoading } = useQuery(
    [QueryKeys.GET_RECOMMENDATION_BY_ID, id],
    () => ProductRecommendationApi.getById(id),
  );

  return { productRecommendationById, isLoading };
};

export { useProductRecommendations, useProductRecommendationsById };
