import { Button, Card, Text, VSpacer } from '@/components/DesignSystem';
import { ApiUser, ApiUserPublic } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import React from 'react';

interface FarmerRecipientCardProps {
  isUserAdded: boolean,
  onAction: () => void,
  user: ApiUser | ApiUserPublic,
}

export const FarmerRecipientCard = ({
  isUserAdded,
  onAction,
  user,
}: FarmerRecipientCardProps) => {
  const getSublineText = (user: ApiUser | ApiUserPublic) => {
    let subline = '';
    if (user.businessName?.trim()) {
      subline += user.businessName;
    }
    if (user.countyData) {
      if (subline) {
        subline += ', ';
      }
      subline += `${user.countyData.county}, ${user.countyData.state}`;
    }
    return subline;
  };

  return (
    <Card testID={`${user.id}-recipient-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack justifyContent="flex-end">
          <Text category="title-small">{user.firstName} {user.lastName}</Text>
          <VSpacer size="2" />
          <Text category="body-small">
            {getSublineText(user)}
          </Text>
        </Stack>
        {isUserAdded ? (
          <Button
            onClick={onAction}
            testID={`${user.id}-recipient-remove-button`}
            variant="text"
          >
            Remove
          </Button>
        ): (
          <Button
            onClick={onAction}
            startIcon={<AddIcon />}
            testID={`${user.id}-recipient-add-button`}
            variant="outlined"
          >
            Add
          </Button>
        )}
      </Stack>
    </Card>
  );
};
