import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
  SxProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export interface IconButtonProps extends MuiIconButtonProps {
  children?: ReactNode,
  testID: string,
  variant?: 'outlined' | 'filled',
}

const FilledIconButton = (props: IconButtonProps) => {
  const theme = useTheme();
  const { sx, testID, ...rest } = props;
  return (
    <MuiIconButton
      color="primary"
      data-testid={testID}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        ...sx,
      } as SxProps}
      {...rest}
    />
  );
};

const OutlinedIconButton = styled((props: IconButtonProps) => {
  const { testID, ...rest } = props;
  return (
    <MuiIconButton data-testid={testID} {...rest} />
  );
})(() => ({
  border: '1px solid',
}));

export const IconButton = ({
  children,
  disabled = false,
  testID,
  variant,
  ...rest
}: IconButtonProps) => {

  const props = {
    disabled,
    focusRipple: false,
    variant,
    ...rest,
  };

  if (variant === 'outlined') {
    return (
      <OutlinedIconButton testID={`${testID}-outlined`} {...props}>
        {children}
      </OutlinedIconButton>
    );
  }

  if (variant === 'filled') {
    return (
      <FilledIconButton testID={`${testID}-filled`} {...props}>
        {children}
      </FilledIconButton>
    );
  }

  return (
    <MuiIconButton data-testid={testID} {...props}>
      {children}
    </MuiIconButton>
  );
};
