import { useMediaQuery } from '@/hooks/useMediaQuery';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankRounded from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Stack,
} from '@mui/material';
import { ResponsiveStyleValue } from "@mui/system";
import { ReactNode } from 'react';
import { HSpacer } from '../Spacer/HSpacer';

interface CheckboxProps extends MuiCheckboxProps {
  alignItems?: ResponsiveStyleValue<"flex-start" | "center">,
  children?: ReactNode,
  labelPlacement?: ResponsiveStyleValue<"start" | "end">,
  onChangeChecked?: (checked: boolean) => void,
  testID: string,
}

export const Checkbox = ({
  alignItems = "center",
  children,
  checked,
  disabled,
  labelPlacement = "end",
  onChangeChecked,
  testID,
  ...rest
}: CheckboxProps) => {
  const { getValueFromResponsiveValue } = useMediaQuery();

  const CustomCheckbox = () => (
    <MuiCheckbox
      checked={checked}
      checkedIcon={
        <CheckBox style={{
          color: disabled ? 'disabled' : 'primary',
        }} />
      }
      disabled={disabled}
      icon={
        <CheckBoxOutlineBlankRounded style={{
          color: disabled ? 'disabled' : undefined,
        }} />
      }
      inputProps={{
        "data-testid": testID,
      } as any}
      onChange={rest.onChange
        ? rest.onChange
        : ((e) => onChangeChecked?.(e.target.checked))}
      sx={{ padding: "0px" }}
      {...rest}
    />
  );

  const labelPlacementValue = getValueFromResponsiveValue(labelPlacement);

  return (
    <Stack
      alignItems={alignItems}
      direction="row"
      justifyContent={labelPlacementValue === "start" ? "space-between" : ""}
      sx={{ position: "relative" }}
    >
      {labelPlacementValue === "end" && (
        <>
          <CustomCheckbox />
          <HSpacer size="5" />
        </>
      )}
      <Box
        onClick={disabled ? undefined : () => onChangeChecked?.(!checked)}
        sx={disabled ? {} : { cursor: "pointer" }}
      >
        {children}
      </Box>
      {labelPlacementValue === "start" && (
        <CustomCheckbox />
      )}
    </Stack>
  );
};
