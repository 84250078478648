import { Logo, VSpacer } from '@/components/DesignSystem';
import { GlobalHeader } from '@/components/DesignSystem/GlobalHeader/GlobalHeader';
import { FullPageLayout } from '@/components/layouts/FullPageLayout';
import { ContactFooter } from '@/components/shared/ContactFooter';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSearch } from '@/hooks/useSearch';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { Stack } from '@mui/material';
import { Container } from '@mui/system';
import React, { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const GlobalHeaderLayout = ({ children }: { children: ReactNode })=> {
  const subdomain = getSubdomain();
  const navigate = useNavigate();
  const location = useLocation();
  const { search, setSearch, debouncedSearch } = useSearch();

  useEffect(() => {
    if (search && location.pathname !== Routes.SHOP) {
      navigate(Routes.SHOP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (debouncedSearch || location.pathname === Routes.SHOP) {
      navigate({
        pathname: Routes.SHOP,
        search: debouncedSearch ? `?search=${debouncedSearch}` : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <GlobalHeader onSearch={setSearch} />
      <Stack paddingTop="128px">
        {children}
      </Stack>
      {subdomain === 'landus' && <ContactFooter />}
    </>
  );
};

export const LoginLayout = ({ children }: { children: ReactNode }) => {
  const { windowHeight } = useMediaQuery();
  const subdomain = getSubdomain();
  return (
    <>
      <Container maxWidth={false} sx={{ maxWidth: 420 + 48, textAlign: 'center' }}>
        <VSpacer mobileSize="10" size="11" />
        <Logo subdomain={subdomain} />
        <VSpacer mobileSize="10" size="11" />
        {children}
      </Container>
      {subdomain === 'landus' ? (
        <ContactFooter />
      ) : (
        windowHeight > 770 ? (
          <ContactFooter fixedToBottom />
        ) : (
          <>
            <VSpacer size="8" />
            <ContactFooter />
            <VSpacer size="11" />
          </>
        )
      )}
    </>
  );
};

export const PasswordLayout = ({ children }: { children: ReactNode }) => {
  const subdomain = getSubdomain();
  return (
    <FullPageLayout
      footer={
        <>
          <ContactFooter />
          {subdomain !== 'landus' && <VSpacer size='10' />}
        </>
      }
    >
      <MobileOnly>
        <Logo size="small" />
        <VSpacer size="4" />
        <Container maxWidth="xs">
          {children}
        </Container>
      </MobileOnly>
      <DesktopOnly>
        <Container maxWidth="xs" sx={{ textAlign: 'center' }}>
          <VSpacer size="12" />
          <Logo />
          <VSpacer size="13" />
          {children}
        </Container>
      </DesktopOnly>
      <VSpacer mobileSize='11' size='9' />
    </FullPageLayout>
  );
};
