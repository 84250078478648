import { Button, Fab, Text, VSpacer } from "@/components/DesignSystem";
import { ConfirmDialog } from "@/components/shared/ConfirmDialog";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useSavedRetailers } from "@/hooks/useSavedRetailers";
import { ApiSavedRetailer } from "@api/interfaces";
import { Add } from "@mui/icons-material";
import { Container, Divider, Stack } from '@mui/material';
import { pluralize } from "@shared/utilities";
import { useState } from "react";
import { CreateEditSavedRetailerDialog } from './CreateEditSavedRetailerDialog';
import { SavedRetailerListItem } from "./SavedRetailerListItem";

const SavedRetailers = () => {
  const { isMobile } = useMediaQuery();
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState<ApiSavedRetailer | null>(null);
  const {
    deleteSavedRetailer,
    loadingSavedRetailers,
    savedRetailers: savedRetailersList,
  } = useSavedRetailers();

  return (
    <Container maxWidth="md">
      <VSpacer size="8" />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <Text category="headline-medium">
          Saved Retailers
        </Text>
        {!!savedRetailersList?.total && (
          <DesktopOnly>
            <Button
              onClick={() => {
                setShowCreateEditModal(true);
              }}
              startIcon={<Add />}
              testID="header-add-retailer-button"
            >
              Add Retailer
            </Button>
          </DesktopOnly>
        )}
      </Stack>
      <VSpacer size='9' />
      <Stack>
        {!!savedRetailersList?.total && (
          <Text
            category="p2"
            testID="saved-retailers-count"
          >
            {pluralize(savedRetailersList.total || 0, 'Retailer')}
          </Text>
        )}
        <VSpacer size="5" />
        {!savedRetailersList?.total && !loadingSavedRetailers && (
          <Stack alignItems="center">
            <VSpacer size="13" />
            <Text
              category="title-large"
              testID="no-retailers-text"
            >
              You have no saved retailers
            </Text>
            <VSpacer size="7" />
            <Button
              onClick={() => setShowCreateEditModal(true)}
              startIcon={<Add />}
              testID="no-retailers-add-retailer-button"
              variant="outlined"
            >
              Add Retailer
            </Button>
          </Stack>
        )}
        <Stack divider={isMobile ? undefined : <VSpacer size="3" />}>
          {savedRetailersList?.data.map((retailer, i) => (
            <SavedRetailerListItem
              key={retailer.id}
              onDelete={() => {
                setSelectedRetailer(retailer);
                setShowConfirmDeletePrompt(true);
              }}
              onEdit={() => {
                setSelectedRetailer(retailer);
                setShowCreateEditModal(true);
              }}
              retailer={retailer}
              testID={`saved-retailer-${i}`}
            />
          ))}
          <MobileOnly>
            {!!savedRetailersList?.data.length && <Divider />}
          </MobileOnly>
        </Stack>
        {!!savedRetailersList?.total && (
          <MobileOnly>
            <VSpacer size="13" />
            <Stack alignItems="center" bottom="24px" position="fixed" width="100%">
              <Fab
                onClick={() => setShowCreateEditModal(true)}
                testID="floating-add-retailer-button"
                variant="extended"
              >
                Add Retailer
              </Fab>
            </Stack>
          </MobileOnly>
        )}
      </Stack>
      <CreateEditSavedRetailerDialog
        onClose={() => {
          setShowCreateEditModal(false);
          setSelectedRetailer(null);
        }}
        open={showCreateEditModal}
        savedRetailerId={selectedRetailer?.id}
        testID="create-edit-saved-retailer-dialog"
      />
      {!!selectedRetailer && (
        <ConfirmDialog
          confirmText="Yes, remove"
          onCancel={() => {
            setShowConfirmDeletePrompt(false);
            setSelectedRetailer(null);
          }}
          onConfirm={() => {
            deleteSavedRetailer(selectedRetailer.id);
            setSelectedRetailer(null);
            setShowConfirmDeletePrompt(false);
          }}
          open={showConfirmDeletePrompt}
          testID="delete-retailer-dialog"
          title="Remove retailer?"
        >
          <Text category="p2">{selectedRetailer.name}</Text>
        </ConfirmDialog>
      )}
    </Container>
  );
};

export default SavedRetailers;
