import { SuccessCheckmark } from '@/components/shared/SuccessCheckmark';
import { Divider, Stack } from '@mui/material';
import { DemoBlock } from '../../components/DemoBlock';

export const OtherDemo = () => {
  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <DemoBlock label="Success graphic">
        <SuccessCheckmark />
      </DemoBlock>
    </Stack>
  );
};
