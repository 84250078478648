import { Client } from "./Client";
import { UserEndpoint } from "@api/endpoints";

export class AuthApi {
  static login (request: UserEndpoint.Login.Request): Promise<UserEndpoint.Login.Response> {
    return Client(`login`, { body: request });
  }

  static async logout () {
    await Client(`logout`, { method: 'POST' });
  }

  static async requestPasswordReset (request: UserEndpoint.RequestPasswordReset.Request) {
    await Client(`request-password-reset`, { body: request });
  }

  static async resetPassword (request: UserEndpoint.PasswordReset.Request) {
    await Client('password-reset', { body: request });
  }

  static async sendOtpCode (request: UserEndpoint.SendOtpCode.Request) {
    await Client('send-otp-code', { body: request });
  }

  static async validateOtp (
    data: UserEndpoint.ValidateOtp.Request,
  ): Promise<UserEndpoint.ValidateOtp.Response> {
    return Client(`validate-otp`, {
      body: data,
    });
  }

  static async validateToken (
    data: UserEndpoint.ValidateToken.Request,
  ): Promise<UserEndpoint.Update.Response> {
    return Client(`validate-token`, {
      body: data,
    });
  }
}
