import { useState } from 'react';
import {
  Modal,
  Button,
  Text,
  VSpacer,
  Select,
  MenuItem,
  Search,
} from '@/components/DesignSystem';
import { SelectChangeEvent } from '@mui/material';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { FeaturedProductApi } from '@/utilities/api/FeaturedProductApi';
import { useSearch } from '@/hooks/useSearch';
import { ToastMessages, errorMessages } from '@/constants/constant';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { HomeScreenSelectLabels } from '@/constants/constant';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ProductApi } from '@/utilities/api/ProductApi';
interface GetProducts {
  id?: string;
  name: string | null;
  categoryId: string | null;
  type?: string | null;
  image?: string | null;
  description: string | null;
  slug: string | null;
  createdBy: string | null;
  status: string | null;
  isFeatured: boolean | null;
  purchaseUom: Array<string> | null;
  packageSize: Array<string> | null;
  sellSheet: string | null;
  keywords: Array<string> | null;
  isRestrictedUse: boolean | null;
}

const AddFeaturedProduct = ({
  addFeaturedProduct,
  featuredProductData,
  close,
}: {
  addFeaturedProduct: boolean;
  close: (args: boolean) => void;
  featuredProductData:
  | { id: string; rank: number; name: string; productId: string }[]
  | undefined;
}) => {
  const [selectedProduct, setSelectedProduct] = useState<GetProducts>();

  const { openSnackbar } = useSnackbar();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: getProducts } = useQuery(
    [QueryKeys.GET_PRODUCTS, debouncedSearch],
    () => ProductApi.productListData({
      search: debouncedSearch,
      limit: 1000,
    }),
  );

  const queryClient = useQueryClient();

  const { mutate: addFeaturedProdcutData, isLoading: isFeaturedProductSaving } =
    useMutation(
      () =>
        FeaturedProductApi.Create({
          title: selectedProduct?.name ?? '',
          productId: selectedProduct?.id ?? '',
        }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.GET_FEATURED_PRODUCTS,
          ]);
          openSnackbar(ToastMessages.featuredProductAddSuccess);
          close(true);
        },
        onError: (error: DetailedApiError) => {
          if (error?.code == '500') {
            return openSnackbar(errorMessages.somethingWentWrong);
          }
          return openSnackbar(error?.message);
        },
      },
    );

  const unSelectedProducts = getProducts?.data?.filter(
    (product: GetProducts) =>
      !featuredProductData?.some(
        (featuredProduct) => featuredProduct.productId === product.id,
      ),
  );

  const handleSelect = (e?: SelectChangeEvent<unknown>) => {
    const selectedId = e?.target?.value;
    const selectedObject = unSelectedProducts?.find(
      (option: GetProducts) => option.id === selectedId,
    );
    setSelectedProduct(selectedObject);
  };

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!selectedProduct?.id}
          loading={isFeaturedProductSaving}
          onClick={() => {
            addFeaturedProdcutData();
          }}
          testID='save'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={() => (
        <Button
          disabled={isFeaturedProductSaving}
          onClick={() => close(false)}
          testID='Cancel'
          variant='text'
        >
          Cancel
        </Button>
      )}
      onClose={() => close(false)}
      open={addFeaturedProduct}
      testID='add-manufacturer'
    >
      <Text category='h5'>Add Featured Product</Text>
      <VSpacer size='7' />
      <Select
        label={HomeScreenSelectLabels.Products}
        labelId='demo-simple-select-label'
        onChange={(event) => {
          handleSelect(event);
        }}
        onClear={() => {
          setSelectedProduct(undefined);
        }}
        testID='demo-simple-select'
        value={selectedProduct?.id}
      >
        <VSpacer size='5' />
        <MenuItem onKeyDown={(e) => e.stopPropagation()} testID='search'>
          <Search
            fullWidth
            onChangeText={setSearch}
            testID='search'
            value={search}
          />
        </MenuItem>
        {unSelectedProducts?.length === 0 && (
          <MenuItem disabled testID='no-products'>
            No Products Available
          </MenuItem>
        )}
        {unSelectedProducts?.map((product: GetProducts) => (
          <MenuItem
            key={product?.id}
            testID={`product-${product?.id}`}
            value={product?.id}
          >
            {product.name}
          </MenuItem>
        ))}
      </Select>
    </Modal>
  );
};

export default AddFeaturedProduct;
