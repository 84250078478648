import { Filter, Text, VSpacer } from "@/components/DesignSystem";
import { useUser } from '@/hooks/useUser';
import { UserEndpoint } from '@api/endpoints';
import { ApiUser } from "@api/interfaces";
import { Container, Pagination, Stack } from "@mui/material";
import { UserType } from "@shared/enums";
import React, { Fragment, useState } from "react";
import FarmerListItem from "./FarmerListItem";

const FarmerList = () => {
  const [selectedFilters, setSelectedFilters] = (
    useState<UserEndpoint.List.Query>({})
  );

  const { users } = useUser({ ...selectedFilters, userType: [UserType.Farmer] });

  const pricingRequestFilterMapping: {
    [key: string]: { maxPricingRequests?: number, minPricingRequests?: number },
  } = {
    'none': { maxPricingRequests: 0 },
    '1-4': { maxPricingRequests: 4, minPricingRequests: 1 },
    '5-9': { maxPricingRequests: 5, minPricingRequests: 9 },
    '10+': { minPricingRequests: 10 },
  };

  return (
    <Container maxWidth="lg">
      <Stack>
        <VSpacer size="8" />
        <Text category="h3">Farmers</Text>
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="p2">{users?.total ?? 0} results</Text>
        </Stack>
        <VSpacer size="5" />
        <Filter<UserEndpoint.List.Query & { numPricingRequests: string }>
          filters={[
            {
              id: 'daysCreated',
              label: 'Sign-up date',
              options: [
                { id: 1, label: 'Past day' },
                { id: 2, label: 'Past 2 days' },
                { id: 7, label: 'Past week' },
                { id: 30, label: 'Past month' },
              ],
              singleSelect: true,
            },
            {
              id: 'numPricingRequests',
              label: 'Number of requests',
              options: [
                { id: 'none', label: 'None' },
                { id: '1-4', label: '1-4' },
                { id: '5-9', label: '5-9' },
                { id: '10+', label: '10+' },
              ],
              singleSelect: true,
            },
          ]}
          onChange={(filters) => {
            let maxPricingRequests = undefined;
            let minPricingRequests = undefined;
            if (filters.numPricingRequests) {
              ({
                maxPricingRequests,
                minPricingRequests,
              } = pricingRequestFilterMapping[filters.numPricingRequests]);
            }
            setSelectedFilters({ ...filters, maxPricingRequests, minPricingRequests });
          }}
          recordName="Farmer"
          testID="farmer-list-filter"
          totalResults={users?.total}
        />
        {users?.data.map((user) => (
          <Fragment key={user.id}>
            <FarmerListItem farmer={user as ApiUser}/>
            <VSpacer size="3" />
          </Fragment>
        ))}
        {users && users.lastPage !== 0 &&
          <Stack alignItems="center">
            <VSpacer size="9" />
            <Pagination
              count={users.lastPage + 1}
              onChange={
                (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
              }
              page={users.page + 1}
            />
          </Stack>
        }
        <VSpacer size="14" />
      </Stack>
    </Container>
  );
};

export default FarmerList;

