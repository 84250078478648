import { Routes } from '@/constants/Routes';
import ProductRecommendationDetails
  from '@/pages/Admin/ProductRecommendation/ProductRecommendationDetails';
import { useState } from 'react';
import { Card, IconButton } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import ProductRecommendationDetailsSlideSheet from './ProductRecommendationDetailsSlideSheet';
import { ApiProductRecommendation } from '@api/interfaces';
const ProductRecommendationCard = ({
  productRecommendation,
}: {
  productRecommendation: ApiProductRecommendation
}) => {
  const [isChat, setIsChat] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        marginTop: '2rem',
      }}
      testID='product-recommendation-card'
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent={'space-between'}
      >
        <ProductRecommendationDetails recommendation={productRecommendation} />
        <Stack alignItems='top' direction='row' spacing={2}>
          <IconButton onClick={() => setIsChat(true)} testID='product-recommendation-chats'>
            <ChatIcon />
          </IconButton>
          <IconButton
            onClick={() => navigate(
              Routes.ADMIN_PRODUCT_RECOMMENDATION_VIEW.replace(':id', productRecommendation.id),
            )}
            testID="recommendation-view-button"
          >
            <NavigateNextIcon />
          </IconButton>
        </Stack>
      </Stack>
      {isChat && (
        <ProductRecommendationDetailsSlideSheet
          isOpen={isChat}
          onClose={() => setIsChat(false)}
          onOpen={() => setIsChat(true)}
          productRecommendationId={productRecommendation?.id}
        />
      )}
    </Card>
  );
};

export default ProductRecommendationCard;
