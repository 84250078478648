import { forwardRef, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Input, InputProps } from './Input';

export type NumericInputProps = InputProps & {
  decimals?: number,
  maxValue?: number,
  minValue?: number,
  onChangeNumber?: (value?: number) => void,
  prefix?: string,
  showFixedDecimals?: boolean,
  suffix?: string,
  value?: number,
};

interface NumericInputCustomProps {
  decimals: number,
  fixedDecimalScale: boolean,
  maxValue: number,
  minValue: number,
  name: string,
  onChange: (event: { target: { name: string; value: string } }) => void,
  prefix: string,
  suffix: string,
}

const NumericFormatCustom = (forwardRef)<NumericFormatProps, NumericInputCustomProps>(
  function NumericFormatCustom (props, ref) {
    const {
      onChange,
      prefix,
      suffix,
      decimals,
      fixedDecimalScale,
      maxValue,
      minValue,
      ...other
    } = props;

    return (
      <NumericFormat
        {...other}
        decimalScale={decimals}
        fixedDecimalScale={fixedDecimalScale}
        getInputRef={ref}
        isAllowed={(values) => {
          if (
            values.floatValue === undefined
            || (minValue > 0 || maxValue < 0)
          ) {
            return true;
          }

          return (
            values.floatValue >= minValue && values.floatValue <= maxValue
          );
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator
      />
    );
  },
);

export const NumericInput = ({
  decimals = 2,
  maxValue = Number.MAX_SAFE_INTEGER,
  minValue = Number.MIN_SAFE_INTEGER,
  onBlur,
  onChangeNumber,
  prefix,
  required,
  showFixedDecimals = true,
  suffix,
  testID,
  value,
  ...rest
}: NumericInputProps) => {

  if (minValue >= maxValue) {
    throw Error("minValue can't be greater or equal than maxValue");
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleChangeText = (text: string) => {
    if (text === "-") {
      // This is done to prevent having to type the dash twice
      return;
    }
    const newValue = parseFloat(text);
    onChangeNumber?.(isNaN(newValue) ? undefined : newValue);
  };

  const validateMinMaxValue = () => {
    if (value === undefined) {
      return;
    }

    if (value < minValue) {
      onChangeNumber?.(minValue);
    }

    if (value > maxValue) {
      onChangeNumber?.(maxValue);
    }
  };

  return (
    <Input
      customInputComponentProps={{
        decimals,
        fixedDecimalScale: showFixedDecimals && !isFocused,
        maxValue,
        minValue,
        prefix,
        suffix,
      }}
      inputComponent={NumericFormatCustom}
      inputMode="decimal"
      onBlur={(event) => {
        setIsFocused(false);
        validateMinMaxValue();
        onBlur?.(event);
      }}
      onChangeText={handleChangeText}
      onFocus={() => setIsFocused(true)}
      prefix={prefix}
      required={required}
      testID={testID}
      value={value}
      {...rest}
    />
  );
};
