import { ApiPromotion } from '@api/interfaces';
import { formatDateOnly } from '@shared/utilities';

export const getStatuses = (promotion: ApiPromotion) => {
  const statuses: PromotionStatus[] = [];
  const currentDate = formatDateOnly(new Date());

  if (currentDate < promotion.startDate) {
    statuses.push(PromotionStatus.FUTURE);
  }

  if (currentDate > promotion.endDate) {
    statuses.push(PromotionStatus.EXPIRED);
  }

  if (currentDate >= promotion.startDate && currentDate <= promotion.endDate) {
    statuses.push(PromotionStatus.RUNNING);
  }

  return statuses;
};

export enum PromotionStatus {
  FUTURE = 'future',
  RUNNING = 'running',
  EXPIRED = 'expired',
}
