import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import {
  ProductBundleDetailsSideSheet,
} from '@/components/shared/ProductBundle/ProductBundleDetailsSideSheet';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ApiBundle } from '@api/interfaces/ApiBundle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonBase, Divider, Stack } from '@mui/material';
import { formatCurrency, formatDateOnly } from '@shared/utilities';
import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';

interface ProductBundleCardProps {
  bundle: ApiBundle,
  testID: string,
}
export const ProductBundleCard = ({
  bundle,
  testID,
}: ProductBundleCardProps) => {
  const [showDetailsSlideSheet, setShowDetailsSlideSheet] = useState(false);

  const productsLength = bundle.products?.length ?? 0;
  const promotions = bundle.products?.flatMap(
    (product) => product.productDetails?.promotions ?? [],
  ) ?? [];
  const currentDate = formatDateOnly(new Date());
  const activePromotions = promotions.filter(
    (promotion) => currentDate >= promotion.startDate && currentDate <= promotion.endDate,
  );
  const hasPromotions = !!activePromotions.length;
  return (
    <>
      <Stack sx={{ padding: '20px 0' }}>
        <ButtonBase
          disableRipple
          onClick={() => setShowDetailsSlideSheet(true)}
        >
          <Stack width="100%">
            <Stack direction="row" justifyContent="space-between">
              <Stack alignItems="flex-start">
                <Text category="overline">PRODUCT BUNDLE</Text>
                <VSpacer size="2" />
                <Text category="title-medium">{bundle.name}</Text>
                <VSpacer size="2" />
                <Text category="body-small">
                  {productsLength} product{productsLength> 1 ? 's' : ''}
                </Text>
                {hasPromotions && (
                  <>
                    <VSpacer size="3" />
                    <Stack alignItems="center" direction="row">
                      <StarIcon color="primary" />
                      <HSpacer size="2" />
                      <Text category="label" color="primary">PROMOS AVAILABLE</Text>
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack>
                <Button
                  endIcon={<ChevronRightIcon />}
                  onClick={() => setShowDetailsSlideSheet(true)}
                  testID={`${testID}-view-button`}
                  variant="outlined"
                >
                  View
                </Button>
              </Stack>
            </Stack>
            <VSpacer size="3" />
            <Stack direction="row" justifyContent="space-between">
              <Text>{bundle.quantity} {FriendlyUoM[bundle.uom]}</Text>
              <Text>Price per unit: {formatCurrency(bundle.pricePerUnit ?? 0)}</Text>
              <Text>
                Bundle cost: {formatCurrency((bundle.pricePerUnit ?? 0) * (bundle.quantity ?? 0))}
              </Text>
            </Stack>
          </Stack>
        </ButtonBase>
      </Stack>
      <ProductBundleDetailsSideSheet
        bundle={bundle}
        isOpen={showDetailsSlideSheet}
        onClose={() => setShowDetailsSlideSheet(false)}
      />
      <Divider />
    </>
  );
};
