import { Button, Card, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { Action } from '@/components/shared/ListSelector/helper';
import { SelectType } from '@/components/shared/ListSelector/RetailersList';
import { LocationDetails } from '@api/interfaces';
import { KeyboardArrowRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import React from 'react';

interface LocationCardProps {
  addedIds: string[],
  location: LocationDetails,
  onAction: (action: Action) => void,
  onSelectLocation: (location: LocationDetails) => void,
  selectType: SelectType,
}

export const LocationCard = ({
  addedIds,
  location,
  onAction,
  onSelectLocation,
  selectType,
}: LocationCardProps) => {
  const getSublineText = (location: LocationDetails) => {
    const locationsCount = location.salespersons?.length ?? 0;
    let subline = `${locationsCount} salesperson`;
    if (locationsCount !== 1) {
      subline += 's';
    }
    return subline;
  };

  const getAddedCount = () => {
    return location.salespersons?.filter(
      (salesperson) => addedIds.includes(salesperson.id),
    ).length ?? 0;
  };

  const addedRecipientsCount = getAddedCount();
  const salespersonCount = location.salespersons?.length ?? 0;
  const showAddAllButton = () => {
    if (selectType === 'salespersons') {
      return salespersonCount > 0 && addedRecipientsCount !== salespersonCount;
    }
    return !addedIds.includes(location.id);
  };
  const showRemoveAllButton = () => {
    if (selectType === 'salespersons') {
      return addedRecipientsCount > 0;
    }
    return addedIds.includes(location.id);
  };
  const showNavigateButton = salespersonCount > 0 && selectType === 'salespersons';

  return (
    <Card testID={`${location.id}-salesperson-recipient-location-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack justifyContent="flex-end">
          <Text category="title-small">{location.name}</Text>
          {selectType === 'salespersons' && (
            <>
              <VSpacer size="2" />
              <Text category="body-small">
                {getSublineText(location)}
              </Text>
            </>
          )}
        </Stack>
        <Stack alignItems="center" direction="row">
          {showRemoveAllButton() && (
            <>
              {selectType === 'salespersons' && (
                <>
                  <Text category="body-medium">
                    {addedRecipientsCount} recipient{addedRecipientsCount > 1 ? 's' : ''}
                  </Text>
                  <HSpacer size="4" />
                </>
              )}
              <Button
                onClick={() => onAction(Action.RemoveAll)}
                testID={`${location.id}-salesperson-recipient-location-remove-button`}
                variant="text"
              >
                {selectType === 'salespersons' ? 'Remove all' : 'Remove'}
              </Button>
            </>
          )}
          {showRemoveAllButton() && showAddAllButton() && (
            <HSpacer size="4" />
          )}
          {showAddAllButton() && (
            <Button
              onClick={() => onAction(Action.AddAll)}
              startIcon={<AddIcon />}
              testID={`${location.id}-salesperson-location-add-button`}
              variant="outlined"
            >
              {selectType === 'salespersons' ? 'Add all' : 'Add'}
            </Button>
          )}
          {showNavigateButton && (
            <>
              <HSpacer size="4" />
              <IconButton
                onClick={() => onSelectLocation(location)}
                testID={`${location.id}-location-navigate-button`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
