import { Button, Chip, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { QueryKeys } from "@/constants/QueryKeys";
import { OffersApi } from "@/utilities/api/OffersApi";
import { ApiOffer, ApiPricingRequestProduct, ApiPricingRequestRetailer } from "@api/interfaces";
import { Alert, Box, Divider, Stack } from "@mui/material";
import { formatCurrency } from "@shared/utilities";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

type Props = {
  offerId: string,
  onClose: () => void,
  requestedProducts: ApiPricingRequestProduct[] | undefined,
  salesperson: ApiPricingRequestRetailer | undefined,
}
const OfferModalReadOnly = ({
  offerId,
  onClose,
  salesperson,
  requestedProducts,
}: Props) => {
  const [offer, setOffer] = useState<ApiOffer>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  useQuery(
    [QueryKeys.GET_PRICING_REQUEST, offerId],
    () => OffersApi.getOffer(offerId),
    {
      onSuccess: (data) => {
        setOffer(data);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(
          error.message || "An error has occurred",
        );
      },
    },
  );

  const retailerInfo = (
    [salesperson?.retailerSalesperson?.businessName, 
      salesperson?.retailerSalesperson?.telephone, 
      salesperson?.retailerSalesperson?.email].filter(Boolean).join(' | ')
  );

  const productsOffered = (
    `${offer?.products?.length ?? 0} of ${requestedProducts?.length} products`
  );

  const productOfferDetails = useMemo(() => {
    return requestedProducts?.map((requestedProduct) => {
      const productOffer = (
        offer?.products?.find((p) => requestedProduct.id === p.pricingRequestProductId)
      );
      return {
        hasOffer: !!productOffer,
        id: requestedProduct.id,
        name: requestedProduct.name,
        price: productOffer?.price ?? 0,
        quantity: requestedProduct.quantity,
        uom: requestedProduct.uom,
      };
    });
  }, [offer, requestedProducts]);

  return (
    <Modal
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Close
        </Button>
      )}
      headerAccessoryRight={
        <Chip
          color="success"
          label={`Offer includes ${productsOffered}`}
          testID="offer-modal-read-only-accessory-right"
        />
      }
      largeModal
      onClose={onClose}
      open={!!offer}
      subline={retailerInfo}
      testID="offer-read-only-modal"
      title={`Offer #${offer?.publicId}`}
    >
      {!!errorMessage && (
        <Alert color="error" icon={false}>
          {errorMessage}
        </Alert>
      )}
      <Stack alignItems="center" direction="row" padding="34px">
        <Text category="h6">Products</Text>
        &nbsp;
        <Text category="p1">
          {`(${productsOffered})`}
        </Text>
      </Stack>
      <VSpacer size="6" />
      {productOfferDetails?.map((product) => (
        <Box key={product.id} padding="18px 50px">
          <Stack direction="row" justifyContent="space-between">
            <Text category="s1" disabled={!product.hasOffer}>
              {product.name}
            </Text>
            <Text category="s1"
              disabled={!product.hasOffer}
            >
              {(product.quantity && product.uom)
                ? `${product.quantity} ${FriendlyUoM[product.uom]}`
                : ''
              }
            </Text>
          </Stack>
          <VSpacer size="4" />
          <Divider />
          <VSpacer size="4" />
          <Stack direction="row-reverse">
            <Text category="s1" disabled={!product.hasOffer}>
              {product.hasOffer ? formatCurrency(product.price) : 'No offer'}
            </Text>
          </Stack>
        </Box>
      ))}
      <Box padding="18px 50px">
        <Text category="s1" upperCase>
          Total
        </Text>
        <VSpacer size="4" />
        <Divider />
        <VSpacer size="4" />
        <Stack direction="row-reverse">
          <Text category="s1">
            {formatCurrency(
              productOfferDetails?.reduce((acc, offer) => (offer.price + acc), 0) ?? 0)}
          </Text>
        </Stack>
      </Box>
    </Modal>
  );
};

export default OfferModalReadOnly;
