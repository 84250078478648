import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface DemoContainerProps {
  backgroundColor: string,
  children: ReactNode,
  padding: number,
}

export const DemoContainer = ({
  backgroundColor,
  children,
  padding,
}: DemoContainerProps) => {
  return (
    <Box p={padding} sx={{ width: "100%", backgroundColor }}>
      {children}
    </Box>
  );
};
