import { AppConfig } from '@/constants/AppConfig';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { AuthApi } from '@/utilities/api/AuthApi';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { StorefrontApi } from '@/utilities/api/StorefrontApi';
import { UserApi } from '@/utilities/api/UserApi';
import { getUserToken } from '@/utilities/Cookies';
import { LocalAccountList } from '@/utilities/LocalAccountList';
import { UserEndpoint } from '@api/endpoints';
import { ApiUser } from '@api/interfaces/ApiUser';
import { userIsInternal, userIsRetailer } from '@shared/utilities';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

export enum SessionState {
  UNKNOWN,
  LOGGED_OUT,
  LOGGED_IN,
}

interface LogoutOptions {
  removeFromAccountSwitcher?: boolean
}

export interface IAuthenticationContext {
  authenticate: (
    email: string,
    password: string,
    storefrontId?: string | null,
  ) => Promise<void>,
  internal: boolean,
  isRetailer: boolean,
  logout: (options?: LogoutOptions) => Promise<void>,
  preferredLocationId?: string,
  refreshUser: () => Promise<ApiUser | undefined>,
  sessionState: SessionState,
  setPreferredLocationId: (id: string) => void
  updateUser: (userData: Partial<UserEndpoint.Update.Request>) => Promise<ApiUser | undefined>,
  user?: ApiUser,
  validateOtp: (
    telephone: string,
    code: string,
    storefrontId?: string,
  ) => Promise<void>,
}

export const AuthenticationContext = React.createContext<IAuthenticationContext>(
  {} as IAuthenticationContext,
);

const { Provider } = AuthenticationContext;

export const AuthenticationProvider = (props: { children: ReactNode }) => {
  const [user, setUser] = useState<ApiUser>();
  const [internal, setInternal] = useState(false);
  const [isRetailer, setIsRetailer] = useState(false);
  const [sessionState, setSessionState] = useState(SessionState.UNKNOWN);
  const { guestShoppingCart, saveGuestShoppingCart } = useShoppingCart();

  // We need to store this in memory in case a user navigates to other pages
  // before creating their account. Arguably this is a good candidate for some
  // sort of AppContext, but storing it here for now to save time.
  const [preferredLocationId, setPreferredLocationId] = useState<string>();

  const checkLoggedInStatus = async () => {
    try {
      const user = await UserApi.me();
      setUser(user);
      setSessionState(SessionState.LOGGED_IN);
      let subdomain = null;
      if (user.storefrontId) {
        const storefront = await StorefrontApi.get(user.storefrontId);
        subdomain = storefront.subdomain;
      }
      LocalAccountList.set(user, getUserToken()!, subdomain);
      return user;
    } catch (error) {
      if (error instanceof DetailedApiError && error.code === '401') {
        await AuthApi.logout();
      }
      setSessionState(SessionState.LOGGED_OUT);
    }
  };

  useEffect(() => {
    void checkLoggedInStatus();
  }, []);

  useEffect(() => {
    setInternal(userIsInternal(user));
    setIsRetailer(userIsRetailer(user));
  }, [user]);

  const authenticate = async (
    email: string,
    password: string,
    storefrontId?: string | null,
  ) => {
    const user = await AuthApi.login({
      email,
      password,
      storefrontId,
    });
    await saveGuestShoppingCart(user, guestShoppingCart);
    setUser(user);
    setSessionState(SessionState.LOGGED_IN);

    let subdomain = null;
    if (user.storefrontId) {
      const storefront = await StorefrontApi.get(user.storefrontId);
      subdomain = storefront.subdomain;
    }
    LocalAccountList.set(user, getUserToken()!, subdomain);
  };

  const updateUser = async (userData: UserEndpoint.Update.Request) => {
    if (!user?.id) {
      return;
    }
    const updatedUser = await UserApi.update(
      user.id,
      {
        ...userData,
        postal: userData.postal || undefined,
      },
    );
    setUser(updatedUser);
    return updatedUser;
  };

  const logout = async (options: LogoutOptions = {}) => {
    const {
      removeFromAccountSwitcher = true,
    } = options;
    await AuthApi.logout();
    if (AppConfig.env.test && removeFromAccountSwitcher) {
      LocalAccountList.remove(user!.id);
    }
    setUser(undefined);
    setSessionState(SessionState.LOGGED_OUT);
  };

  const validateOtp = async (
    telephone: string,
    code: string,
    storefrontId?: string,
  ) => {
    const user = await AuthApi.validateOtp({
      code,
      telephone,
      storefrontId,
    });
    if (user) {
      await saveGuestShoppingCart(user, guestShoppingCart);
      setUser(user);
      setSessionState(SessionState.LOGGED_IN);

      let subdomain = null;
      if (user.storefrontId) {
        const storefront = await StorefrontApi.get(user.storefrontId);
        subdomain = storefront.subdomain;
      }
      LocalAccountList.set(user, getUserToken()!, subdomain);
    } else {
      throw new Error('Error logging in');
    }
  };

  return (
    <Provider value={{
      authenticate,
      internal,
      isRetailer,
      logout,
      preferredLocationId,
      refreshUser: checkLoggedInStatus,
      sessionState,
      setPreferredLocationId,
      updateUser,
      user,
      validateOtp,
    }}
    >
      {props.children}
    </Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }
  return context as IAuthenticationContext;
};
