import AttachMoney from '@mui/icons-material/AttachMoney';
import Clear from '@mui/icons-material/Clear';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Circle from '@mui/icons-material/Circle';
import Check from "@mui/icons-material/Check";
import Search from "@mui/icons-material/Search";
import HighlightOff from '@mui/icons-material/HighlightOff';
import Menu from '@mui/icons-material/Menu';
import Add from '@mui/icons-material/Add';
import Square from "@mui/icons-material/Square";
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Calculate from "@mui/icons-material/Calculate";
import Comment from "@mui/icons-material/Comment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import StickyNote2Outlined from "@mui/icons-material/StickyNote2Outlined";
import Percent from '@mui/icons-material/Percent';

import { Grid, Stack } from '@mui/material';
import { Text } from "@/components/DesignSystem";

export const IconDemo = () => {

  const Icons = [
    { name: "Add", icon: <Add /> }, 
    { name: "ArrowBack", icon: <ArrowBack /> },
    { name: "ArrowForward", icon: <ArrowForward /> },
    { name: "AttachMoney", icon: <AttachMoney /> },
    { name: "Calculate", icon: <Calculate /> },
    { name: "Check", icon: <Check /> },
    { name: "CheckCircleOutlined", icon: <CheckCircleOutlined /> },
    { name: "Circle", icon: <Circle /> },
    { name: "Clear", icon: <Clear /> },
    { name: "Comment", icon: <Comment /> },
    { name: "Delete", icon: <Delete /> },
    { name: "Edit", icon: <Edit /> }, 
    { name: "ErrorOutline", icon: <ErrorOutline /> },
    { name: "ExpandLess", icon: <ExpandLess /> },
    { name: "ExpandMore", icon: <ExpandMore /> },
    { name: "HighlightOff", icon: <HighlightOff /> },
    { name: "InfoOutlined", icon: <InfoOutlined /> },
    { name: "LocalOfferOutlined", icon: <LocalOfferOutlined /> },
    { name: "Menu", icon: <Menu /> },
    { name: "Percent", icon: <Percent /> },
    { name: "Search", icon: <Search /> },
    { name: "Square", icon: <Square /> },
    { name: "StickyNote2Outlined", icon: <StickyNote2Outlined /> },
    { name: "WarningAmberOutlined", icon: <WarningAmberOutlined /> },
  ];

  return (
    <Grid>
      {Icons.map(icon => (
        <Stack key={icon.name} m={2} sx={{ 
          maxWidth: "120px", 
          display: "inline-block", 
          textAlign: "center", 
        }}>
          {icon.icon}
          <Text>{icon.name}</Text>
        </Stack>
      ))}
    </Grid>
  );
};
