import { Button, Input, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { ApiManufacturer } from '@api/interfaces';
import { Alert, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';

interface SaveManufacturerModalProps {
  close: () => void;
  manufacturer?: ApiManufacturer;
}

export const SaveManufacturerModal = ({
  close,
  manufacturer,
}: SaveManufacturerModalProps) => {
  const { openSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [manufacturerName, setManufacturerName] = useState(
    manufacturer?.name ?? '',
  );
  const queryClient = useQueryClient();
  const { mutate: saveManufacturer, isLoading: isSaving } = useMutation(
    () => {
      if (!manufacturer?.id) {
        return ManufacturerApi.create({ name: manufacturerName });
      } else {
        return ManufacturerApi.update(manufacturer?.id, {
          name: manufacturerName,
        });
      }
    },
    {
      onError: (error: DetailedApiError) => {
        setErrorMessage(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        close();
        if (!manufacturer?.id) {
          return openSnackbar('Manufacturer added');
        }
        return openSnackbar('Manufacturer updated');
      },
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage('');
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  const onClose = () => {
    setErrorMessage('');
    close();
  };

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={
            !!errorMessage?.length ||
            !manufacturerName?.length ||
            manufacturer?.name === manufacturerName
          }
          loading={isSaving}
          onClick={() => saveManufacturer()}
          testID='save-manufacturer-button'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button disabled={isSaving} onClick={onClose} {...props}>
          Cancel
        </Button>
      )}
      onClose={onClose}
      open={true}
      testID='add-manufacturer-modal'
    >
      <Stack maxWidth='xs'>
        {!!errorMessage && (
          <>
            <Alert color='error' icon={false}>
              {errorMessage}
            </Alert>
            <VSpacer mobileSize='5' size='8' />
          </>
        )}
        <Text category='h6'>
          {manufacturer?.id ? 'Edit Manufacturer' : 'Add Manufacturer'}
        </Text>
        <VSpacer size='8' />
        <Input
          label='Manufacturer name'
          onChangeText={setManufacturerName}
          required
          testID='manufacturer-name'
          value={manufacturerName}
        />
      </Stack>
    </Modal>
  );
};
