import { TextAreaInput } from '@/components/DesignSystem';
import Square from '@mui/icons-material/Square';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import { Box } from '@mui/material';

export const TextAreaInputDemo = () => {

  const [text, setText] = useState("");

  return (
    <Box padding={1} sx={{ backgroundColor: "background.default" }}>
      <DemoBlock label="Expandable 1 -> 4 rows">
        <TextAreaInput
          emptyRows={1}
          label="Label"
          maxLength={2000}
          maxRows={4}
          onChangeText={setText}
          testID="text-area-demo-expandable"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Expandable with adornments">
        <TextAreaInput
          emptyRows={1}
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          maxLength={2000}
          onChangeText={setText}
          testID="text-area-demo-expandable-adornments"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Expandable: customized emptyRows(3) and maxRows(8)">
        <TextAreaInput
          emptyRows={3}
          label="Label"
          maxLength={2000}
          maxRows={8}
          onChangeText={setText}
          testID="text-area-demo-expandable-rows"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Basic">
        <TextAreaInput
          label="Label"
          onChangeText={setText}
          testID="text-area-demo-basic"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Required">
        <TextAreaInput
          label="Label"
          onChangeText={setText}
          required
          testID="text-area-demo-required"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Disabled">
        <TextAreaInput
          disabled
          label="Label"
          onChangeText={setText}
          testID="text-area-demo-disabled"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Error">
        <TextAreaInput
          error
          helperText="The specified name already exists"
          label="Label"
          onChangeText={setText}
          testID="text-area-demo-error"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading Icon">
        <TextAreaInput
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="text-area-demo-leading-icon"
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Trailing Icon">
        <TextAreaInput
          label="Label"
          onChangeText={setText}
          testID="text-area-demo-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading and Trailing Icon">
        <TextAreaInput
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="text-area-demo-leading-and-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Disabled">
        <TextAreaInput
          disabled
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="text-area-demo-trailing-icon-disabled"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Leading and Trailing Icon Error">
        <TextAreaInput
          error
          helperText="The specified name already exists"
          label="Label"
          leadingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          onChangeText={setText}
          testID="text-area-demo-leading-and-trailing-icon"
          trailingIcon={(iconProps) => (
            <Square {...iconProps} />
          )}
          value={text}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Transparent">
        <TextAreaInput
          isTransparent
          maxLength={2000}
          maxRows={6}
          multiline
          onChangeText={setText}
          testID="text-area-demo-trailing-icon-multiline-transparent"
          value={text}
          width={372}
        />
      </DemoBlock>
    </Box>
  );
};
