import { AppConfig } from '@/constants/AppConfig';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { Input, PhoneInput, Text, VSpacer } from '@/components/DesignSystem';
import {
  validateEmail,
  validatePhoneNumber,
} from "@shared/utilities/ValidatorUtils";

export type FormSavedRetailer = {
  name: string,
  email: string,
  telephone: string,
};

export const defaultSavedRetailer: FormSavedRetailer = {
  name: '',
  email: '',
  telephone: '',
};

interface SavedRetailerFormProps {
  onChange: (retailer: FormSavedRetailer) => void,
  retailer: FormSavedRetailer,
}

export const SavedRetailerForm = ({
  onChange,
  retailer,
}: SavedRetailerFormProps) => {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <Stack>
      <Input
        error={nameError}
        label="Retailer or Salesperson name"
        maxLength={150}
        onBlur={() => setNameError(!retailer?.name)}
        onChangeText={(name) => onChange({ ...retailer, name })}
        required
        testID="retailer-name-input"
        value={retailer?.name}
      />
      <VSpacer size="9" />
      <Text
        category="body-small"
        testID="add-retailer-info-text"
      >
        *Contact email or phone number required
      </Text>
      <VSpacer size="5" />
      <Input
        error={emailError}
        inputMode="email"
        label="Email"
        maxLength={75}
        onBlur={() => setEmailError(!!retailer?.email && !validateEmail(retailer.email))}
        onChangeText={(email) => onChange(
          { ...retailer, email },
        )}
        testID="retailer-email-input"
        value={retailer?.email}
      />
      <VSpacer size="7" />
      <VSpacer size="2" />
      <PhoneInput
        error={phoneError}
        label="Phone"
        onChangeText={(telephone) => {
          setPhoneError(!validatePhoneNumber(telephone, AppConfig.env.test));
          onChange({ ...retailer, telephone });
        }}
        required
        testID="retailer-phone-input"
        value={retailer?.telephone}
      />
    </Stack>
  );
};

