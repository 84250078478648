import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { PromotionChip } from '@/components/shared/Promotion/PromotionChip';
import { ApiPricingRequestProduct, ApiPromotion, RecommendationProduct } from '@api/interfaces';
import { ApiBundleProduct } from '@api/interfaces/ApiBundleProduct';
import { Divider, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatCurrency } from '@shared/utilities';
import React from 'react';

interface ProductCardProps {
  farmerPromotions: ApiPromotion[],
  product: ApiPricingRequestProduct | RecommendationProduct | ApiBundleProduct,
  retailerPromotions: ApiPromotion[],
  showPrices?: boolean,
}

export const ProductCard = ({
  farmerPromotions,
  product,
  retailerPromotions,
  showPrices,
}: ProductCardProps) => {
  let price;
  if ('price' in product) {
    price = product.price;
  } else if ('pricePerUnit' in product) {
    price = product.pricePerUnit;
  }
  return (
    <>
      <Stack sx={{ padding: '20px 0' }}>
        <Stack direction="row" justifyContent="space-between" pr="12px">
          <Text fontSize='20px' testID={`${product.id} name`}>
            {product.name}
          </Text>
          {showPrices && !!product.quantity && !!price && (
            <Text fontSize='20px' testID={`${product.id}-price`}>
              Total: {formatCurrency(product.quantity * price)}
            </Text>
          )}
        </Stack>
        <VSpacer size='5' />
        <Text category='overline' testID={`${product.id} quantity`}>
          {product.quantity} {product.uom}{product.package ? ` • ${product.package}` : ''}
        </Text>
        {(!!farmerPromotions.length || !!retailerPromotions.length) && !!product.productId && (
          <>
            <VSpacer size="3" />
            <Stack direction="row">
              {!!farmerPromotions.length && (
                <>
                  <PromotionChip
                    promotions={farmerPromotions}
                    targetUserType={PromotionTargetUserType.Farmer}
                  />
                  <HSpacer size="4" />
                </>
              )}
              {!!retailerPromotions.length && (
                <PromotionChip
                  promotions={retailerPromotions}
                  targetUserType={PromotionTargetUserType.Retailer}
                />
              )}
            </Stack>
          </>
        )}
      </Stack>
      <Divider />
    </>
  );
};
