import { PromotionsSideSheet } from '@/pages/Admin/PromotionsSideSheet';
import { ApiPromotion } from '@api/interfaces';
import { Chip } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import React, { useState } from 'react';

interface PromotionChipProps {
  promotions: ApiPromotion[],
  targetUserType: PromotionTargetUserType,
}

export const PromotionChip = ({
  promotions,
  targetUserType,
}: PromotionChipProps) => {
  const [showPromotionsModal, setShowPromotionsModal] = useState(false);
  return (
    <>
      <Chip
        color={targetUserType === PromotionTargetUserType.Farmer ? 'warning' : 'info'}
        label={targetUserType === PromotionTargetUserType.Farmer
          ? 'Farmer Rewards' : 'Active Sales Incentives'}
        onClick={(e) => {
          setShowPromotionsModal(true);
          e.stopPropagation();
        }}
      />
      {showPromotionsModal && (
        <PromotionsSideSheet
          onClose={() => setShowPromotionsModal(false)}
          promotionTargetUserType={targetUserType}
          promotions={promotions}
        />
      )}
    </>
  );
};
