import { ProductOfferRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { RetailerCard } from "@/pages/ReviewSelectOffers/RetailerCard";
import { DemoBlock } from "../../components/DemoBlock";
import { Stack } from "@mui/material";
import { DemoContainer } from "../../components/DemoContainer";
import {
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
} from "@shared/enums";
import { OfferProduct } from "@/pages/CreateSendPriceRequest/interfaces";

const demoProduct: OfferProduct = {
  allowSubstitutions: true,
  initialQuantity: 12,
  initialUom: DryFormulationUnitOfMeasure.TON,
  name: 'Super Product 9000',
  price: 1000,
  pricingRequestProductId: '',
  quantity: 12,
  uom: DryFormulationUnitOfMeasure.TON,
};

const productOfferRetailer: ProductOfferRetailer = {
  offerProduct: {
    createdAt: new Date(),
    id: 'id',
    isAccepted: true,
    offerId: 'offer-id',
    price: 1200,
    pricingRequestProductId: 'pricing-request-id',
    quantity: 4,
    substituteProduct: 'substitute product name',
    uom: LiquidFormulationUnitOfMeasure.GALLON,
    updatedAt: new Date(),
  },
  retailer: {
    createdAt: new Date(),
    id: 'id',
    lastLook: false,
    name: 'Test Salesperson',
    salespersonId: '12345',
    preferred: false,
    pricingRequestId: 'pricing-request-id',
    updatedAt: new Date(),
  },
};

export const RetailerCardDemo = () => {
  return (
    <>
      <DemoBlock label="Review">
        <Stack alignItems="center" spacing={2}>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
            >
            <RetailerCard
              product={demoProduct}
              productOfferRetailer={productOfferRetailer}
              testID="demo1"
            />
          </DemoContainer>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
            <RetailerCard
              product={{
                ...demoProduct,
                substituteProduct: 'Super Substitute 9001',
              }}
              productOfferRetailer={productOfferRetailer}
              testID="demo2"
            />
          </DemoContainer>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Accepted/Closed">
        <Stack alignItems="center" spacing={2}>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
            <RetailerCard
              product={demoProduct}
              productOfferRetailer={productOfferRetailer}
              testID="demo1"
            />
          </DemoContainer>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
            <RetailerCard
              product={{
                ...demoProduct,
                substituteProduct: 'Super Substitute 9001',
              }}
              productOfferRetailer={productOfferRetailer}
              testID="demo2"
            />
          </DemoContainer>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
            <RetailerCard
              isAccepted
              product={demoProduct}
              productOfferRetailer={productOfferRetailer}
              testID="demo2"
            />
          </DemoContainer>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
            <RetailerCard
              isAccepted
              product={{
                ...demoProduct,
                substituteProduct: 'Super Substitute 9001',
              }}
              productOfferRetailer={productOfferRetailer}
              testID="demo2"
            />
          </DemoContainer>
        </Stack>
      </DemoBlock>
    </>
  );
};
