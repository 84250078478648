import {
  Button, TextLink, VSpacer,
} from '@/components/DesignSystem';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { Divider, Stack } from '@mui/material';

export const SnackbarDemo = () => {

  const { openSnackbar } = useSnackbar();

  return (
    <>
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button
          onClick={() => openSnackbar('Single-line snackbar')}
          testID="snack-bar-demo-single-line-button"
        >
          Single-line
        </Button>
        <Button
          onClick={() => openSnackbar(
            "Single-line snackbar with close affordance",
            999999,
            true,
          )}
          testID="snack-bar-demo-single-line-close-button"
        >
          Single-line with close affordance
        </Button>
        <Button
          onClick={() => openSnackbar(
            "Single-line snackbar with action",
            undefined,
            true,
            <TextLink
              category="label-large"
              color="primary"
              testID="snackbar-demo-label-large-inverse-primary"
            >
              Action
            </TextLink>,
          )}
          testID="snack-bar-demo-single-line-action-button"
        >
          Single-line with action
        </Button>
      </Stack>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button
          onClick={() => openSnackbar(
            "Two-line snackbar <br/> without action",
            5000,
          )}
          testID="snack-bar-demo-two-line-no-action-button"
        >
          Two-line without action
        </Button>
        <Button
          onClick={() => openSnackbar(
            "Two-line snackbar <br/> with close affordance",
            999999,
            true,
          )}
          testID="snack-bar-demo-two-line-close-button"
        >
          Two-line with close affordance
        </Button>
        <Button
          onClick={() => openSnackbar(
            "Two-line snackbar <br/> with action",
            undefined,
            true,
            <TextLink
              category="label-large"
              color="primary"
              testID="snackbar-demo-label-large-inverse-primary"
            >
              Action
            </TextLink>,
          )}
          testID="snack-bar-demo-two-line-action-button"
        >
          Two-line snackbar with action
        </Button>
      </Stack>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button
          onClick={() => openSnackbar(
            "Two-line snackbar <br/> with longer action",
            undefined,
            false,
            <TextLink
              category="label-large"
              color="primary"
              testID="snackbar-demo-label-large-inverse-primary"
            >
              Longer Action
            </TextLink>,
            true,
          )}
          testID="snack-bar-demo-two-line-longer-action-button"
        >
          Two-line snackbar with longer action
        </Button>
        <Button
          onClick={() => openSnackbar(
            "Two-line snackbar <br/> with longer action",
            999999,
            true,
            <TextLink
              category="label-large"
              color="primary"
              testID="snackbar-demo-label-large-inverse-primary"
            >
              Longer Action
            </TextLink>,
            true,
          )}
          testID="snack-bar-demo-two-line-longer-action-button"
        >
          Two-line snackbar with longer action and close affordance
        </Button>
      </Stack>
    </>
  );
};
