import { Logo } from '@/components/DesignSystem';
import Stack from '@mui/material/Stack';
import { DemoBlock } from '../../components/DemoBlock';

export const LogoDemo = () => {
  return (
    <>
      <DemoBlock label="Black Full">
        <Logo category="black-full" />
      </DemoBlock>
      <DemoBlock label="Black Type">
        <Logo category="black-type" />
      </DemoBlock>
      <DemoBlock label="Black Symbol">
        <Logo category="black-symbol" />
      </DemoBlock>
      <DemoBlock label="Reverse Full">
        <Logo category="reverse-full" />
      </DemoBlock>
      <DemoBlock label="Reverse Type">
        <Logo category="reverse-type" />
      </DemoBlock>
      <DemoBlock label="Reverse Symbol">
        <Logo category="reverse-symbol" />
      </DemoBlock>
      <DemoBlock label="Sizes">
        <Stack direction="row" spacing={2}>
          <Logo size="giant" />
          <Logo />
          <Logo size="medium" />
          <Logo size="small" />
          <Logo size="tiny" />
        </Stack>
      </DemoBlock>
    </>
  );
};
