import { MenuItem, Select, Text } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { Color } from '@/themes/MUITheme/palette';
import { StorefrontApi } from '@/utilities/api/StorefrontApi';
import { UserApi } from '@/utilities/api/UserApi';
import { getUserToken } from '@/utilities/Cookies';
import { LocalAccountList } from '@/utilities/LocalAccountList';
import { navigateToStorefront } from '@/utilities/StorefrontUtilities';
import { UserType } from '@shared/enums';
import { useNavigate } from 'react-router-dom';

export const UserSwitcher = () => {
  const { user, refreshUser, logout } = useAuthentication();
  const navigate = useNavigate();

  if (!LocalAccountList.isActive()) { return null; }

  const addAccount = '[Add Account]';
  const clearAll = '[Clear All]';
  const accounts = LocalAccountList.list();
  const actions = [addAccount, clearAll];

  const colors: {[type in UserType]: Color} = {
    [UserType.Farmer]: 'success',
    [UserType.Internal]: 'warning',
    [UserType.RetailerAdmin]: 'info',
    [UserType.SalesPerson]: 'info',
  };

  const onSelectAccount = async (value: string) => {
    if (value === addAccount || value === clearAll) {
      if (value === clearAll) {
        LocalAccountList.clear();
      }
      await logout({ removeFromAccountSwitcher: false });
      navigate(Routes.LOGIN, { replace: true });
    } else {
      const account = LocalAccountList.get(value);
      const newUser = await UserApi.switchAccount(account.token, account.storefrontId);
      let subdomain = null;
      if (newUser.storefrontId) {
        const storefront = await StorefrontApi.get(newUser.storefrontId);
        subdomain = storefront.subdomain;
      }
      LocalAccountList.set(newUser, getUserToken()!, subdomain);
      await refreshUser();
      navigateToStorefront(subdomain);
    }
  };

  return (
    <Select
      label="Current user"
      onChangeValue={onSelectAccount}
      size="small"
      sx={{ maxWidth: '200px' }}
      testID={'user-switcher'}
      value={user?.id ?? addAccount}
    >
      {accounts.map((account) => (
        <MenuItem
          key={account.id}
          testID={'user-switcher-' + account.id}
          value={account.id}
        >
          <Text color={colors[account.type]}>
            {account.name}
            {account.subdomain ? `[${account.subdomain}]` : ''}
          </Text>
        </MenuItem>
      ))}
      {actions.map((action) => (
        <MenuItem
          key={action}
          testID={'user-switcher-' + action}
          value={action}
        >
          <Text>{action}</Text>
        </MenuItem>
      ))}
    </Select>
  );
};
