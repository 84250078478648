import {
  Button,
  Input,
  Logo,
  Modal,
  PhoneInput,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { CountySelect } from '@/components/shared/Select/CountySelect';
import { StateSelect } from '@/components/shared/Select/StateSelect';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useStorefront } from '@/hooks/useStorefront';
import OtpForm from '@/pages/Auth/OtpForm';
import { CreateFarmerAccountEventType, useLogEvent } from '@/utilities/Analytics';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ApiStorefront } from '@api/interfaces';
import { Box, CircularProgress, Stack, useTheme } from "@mui/material";
import { ApiErrors, GrowersContactInfo, State } from '@shared/enums';
import { validatePhoneNumber } from '@shared/utilities/ValidatorUtils';
import { Suspense, useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";

export const StorefrontCreateAccountForm = () => {
  const { isMobile } = useMediaQuery();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [state, setState] = useState<State>();
  const [county, setCounty] = useState<string>();
  const [farmName, setFarmName] = useState<string>();
  const [isDuplicatePhone, setIsDuplicatePhone] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const { storefront } = useStorefront(true);

  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const theme = useTheme();

  const isFormValid = useCallback(() => {
    return validatePhoneNumber(phoneNumber, AppConfig.env.test)
      && !!farmName?.trim()
      && !!firstName?.trim()
      && !!lastName?.trim()
      && !!state
      && !!county;
  }, [
    county,
    farmName,
    firstName,
    lastName,
    phoneNumber,
    state,
  ]);

  const getPhoneHelperText = () => {
    if (isDuplicatePhone) {
      return 'This number is already in use, please verify the number you typed in.';
    }
    if (isPhoneError) {
      return 'A valid phone number is required';
    }
    return '';
  };

  const FormHeader = ({ storefront }: { storefront: ApiStorefront }) => (
    <>
      <Stack direction='row' justifyContent='center'>
        <Logo size="giant" subdomain={storefront.subdomain} />
      </Stack>
      <VSpacer size='9' />
      <Text category='h3' textAlign='center'>
        Create a {storefront.name} Shop account
      </Text>
      <VSpacer size='4' />
      <Text textAlign='center'>
        A {storefront.name} Shop account lets you send product requests and receive
        offers directly from your local {storefront.name} retailer location.
      </Text>
    </>
  );

  return (
    <Stack
      direction='row'
      justifyContent='center'
      paddingTop={isMobile ? '0px' : '100px'}
    >
      <Stack maxWidth='512px'>
        <Box
          sx={{
            backgroundColor: isMobile ? undefined : theme.palette.background.paper,
            borderRadius: '12px',
            padding: isMobile ? '16px' : '48px',
          }}
        >
          <Suspense fallback={<CircularProgress />}>
            <FormHeader storefront={storefront!} />
          </Suspense>
          <VSpacer size='9' />
          <Stack spacing={3}>
            <PhoneInput
              error={isDuplicatePhone || isPhoneError}
              helperText={getPhoneHelperText()}
              label="Phone"
              onBlur={
                () => setIsPhoneError(!validatePhoneNumber(phoneNumber, AppConfig.env.test))
              }
              onChangeText={setPhoneNumber}
              required
              testID="storefront-create-account-telephone-input"
              value={phoneNumber}
            />
            <Input
              label="First Name"
              onChangeText={setFirstName}
              required
              testID="storefront-create-account-first-name-input"
              value={firstName}
            />
            <Input
              label="Last Name"
              onChangeText={setLastName}
              required
              testID="storefront-create-account-last-name-input"
              value={lastName}
            />
            <StateSelect
              onChangeState={setState}
              required
              testID="storefront-create-account-state-select"
              value={state}
            />
            <CountySelect
              disabled={!state}
              onChangeCounty={setCounty}
              required
              state={state}
              testID="storefront-create-account-county-select"
              value={county}
            />
            <Input
              label="Business Name"
              onChangeText={setFarmName}
              required
              testID="storefront-create-account-farm-name-input"
              value={farmName}
            />
          </Stack>
          <VSpacer size='7' />
          <VSpacer size='2' />
          <Text category='p2'>
            By continuing you agree to the GROWERS&nbsp;
            <TextLink
              href={GrowersContactInfo.privacyPolicyLink}
              target='_blank'
              testID='privacy-policy'
            >
              Privacy policy
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              href={GrowersContactInfo.termsOfUseLink}
              target='_blank'
              testID='tos-link'
            >
              Terms of service
            </TextLink>
          </Text>
          <VSpacer size='8' />
          <Stack direction='row' justifyContent='center'>
            <Button
              disabled={!isFormValid()}
              onClick={() => {
                logEvent(CreateFarmerAccountEventType.ClickConfirmCreateAccount);
                setShowOtpForm(true);
              }}
              testID='create-account-button'
            >
              Send Verification Code
            </Button>
          </Stack>
        </Box>
        <VSpacer size='7' />
        <Stack alignItems="center">
          <Text testID="new-user-text">
            Already have an account?
          </Text>
          <TextLink
            category="p2"
            onClick={() => navigate(Routes.STOREFRONT_LOGIN)}
            testID='login-link'
          >
            Sign in
          </TextLink>
        </Stack>
      </Stack>
      {showOtpForm && phoneNumber &&
        <Modal
          onClose={() => setShowOtpForm(false)}
          open
          testID="storefront-create-user-otp-form-modal"
        >
          <OtpForm
            isRegister
            onCancel={() => setShowOtpForm(false)}
            onError={(error: DetailedApiError) => {
              if (error.message === ApiErrors.userWithPhoneExists) {
                setIsDuplicatePhone(true);
              }
            }}
            telephone={phoneNumber}
            userToCreate={{
              businessName: farmName?.trim(),
              countyId: county,
              firstName: firstName?.trim(),
              lastName: lastName?.trim(),
              state,
              telephone: phoneNumber,
            }}
          />
        </Modal>
      }
    </Stack>
  );
};
