import { AppUrls } from '@/constants/AppUrls';
import { SplashPage } from '@/pages/RedirectToOs/SplashPage';
import { useCallback, useEffect } from 'react';

const RedirectToOs = () => {

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/i.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  };

  const DetectAndServe = useCallback(() => {
    const os = getMobileOperatingSystem();
    if (os === 'Android') {
      return (window.location.href = AppUrls.androidGrowersAppLink);
    }
    if (os === 'iOS') {
      return (window.location.href = AppUrls.iosGrowersAppLink);
    }
  }, []);

  useEffect(() => {
    DetectAndServe();
  }, [DetectAndServe]);

  return (
    <SplashPage />
  );
};

export default RedirectToOs;
