import {
  Button,
  DataPoint,
  DatePickerDropdown,
  HSpacer,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Radio,
  Select,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { InfoTooltip } from '@/components/DesignSystem/InfoTooltip/InfoTooltip';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { useColor } from '@/hooks/useColor';
import {
  DeleteBenefitConfirmDialog,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/DeleteBenefitConfirmDialog';
import {
  RewardsTierBenefitModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsTierBenefitModal';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { getImageAsDataURL } from '@/utilities/Image';
import { RewardsProgramEndpoint } from '@api/endpoints/RewardsProgramEndpoint';
import { ApiCategory } from '@api/interfaces';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import AddIcon from '@mui/icons-material/Add';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Edit from '@mui/icons-material/Edit';
import { Box, RadioGroup, Stack, useTheme } from '@mui/material';
import {
  formatCurrency,
  formatDateOnly,
  getDifferenceInDays,
  roundToFixed,
  sanitizedFileName,
} from '@shared/utilities';
import { isEqual, sortBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { formatDate, isCategoryHurdleInBushels } from './helpers';
import { RewardsTiersModal } from './RewardsTiersModal';

const DefaultProgramName = 'Rewards & Loyalty Program';

export type Benefit = {
  description: string,
  name: string,
  termsUrl: string | null,
  tier: Tier,
}

type Program = {
  endDate: string | null,
  name: string,
  startDate: string | null,
  tiers: Tier[],
}

export type Tier = {
  minimumDollars: number,
  minimumSegments: number,
  name: string,
  segmentMinimumAmounts: { [categoryId: string]: number },
  segmentRewards: { [categoryId: string]: number },
}

interface RewardsProgramModalProps {
  existingProgram?: ApiRewardsProgram,
  onClose: () => void,
  onCreate: (rewardsProgram: Omit<RewardsProgramEndpoint.Create.Request, 'retailerId'>) => void,
  onUpdate: (updates: RewardsProgramEndpoint.Update.Request) => void,
  retailerId: string,
}

export enum PreciousMetals {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export const RewardsProgramModal = ({
  existingProgram,
  onClose,
  onCreate,
  onUpdate,
  retailerId,
}: RewardsProgramModalProps) => {
  const [showRewardsTiersModal, setShowRewardsTiersModal] = useState(false);
  const [showRewardsTierBenefitsModal, setShowRewardsTierBenefitsModal] = useState(false);
  const [showDeleteBenefitDialog, setShowDeleteBenefitDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<ApiCategory[]>([]);
  const [initialSelectedCategories, setInitialSelectedCategories] = useState<ApiCategory[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [imageError, setImageError] = useState<string>('');
  const [uploadedImage, setUploadedImage] = useState<File | null | undefined>();
  const preciousMetals: string[] = Object.values(PreciousMetals);
  const initialPreciousMetals = existingProgram?.tiers
    ? existingProgram?.tiers?.every((tier) => preciousMetals.includes(tier.name)) ?? false
    : true;
  const [isPreciousMetals, setIsPreciousMetals] = useState(initialPreciousMetals);
  const [program, setProgram] = useState<Program>({
    endDate: existingProgram?.endDate ?? null,
    name: existingProgram?.name ?? DefaultProgramName,
    startDate: existingProgram?.startDate ?? null,
    tiers: existingProgram?.tiers ?? [],
  });
  const [benefitToEdit, setBenefitToEdit] = useState<Benefit>();
  const existingBenefits = existingProgram?.tiers?.flatMap(
    (tier) => (tier.benefits ?? []).map((benefit) => ({ ...benefit, tier })),
  ) ?? [];
  const imageUrl = useMemo(() => {
    if (uploadedImage !== undefined) {
      return uploadedImage && URL.createObjectURL(uploadedImage as Blob);
    } else {
      return existingProgram?.campaignImage && `${AppConfig.staticImageHost}/${existingProgram.campaignImage}`;
    }
  }, [uploadedImage, existingProgram?.campaignImage]);

  const [initialBenefits] = useState(existingBenefits);
  const [benefits, setBenefits] = useState<Benefit[]>(existingBenefits);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const { getColor } = useColor();

  const isViewing = !!existingProgram && !isEditing;
  const canEdit = (
    !!existingProgram?.endDate
    && existingProgram?.endDate >= formatDateOnly(new Date())
  );

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, retailerId],
    async () => {
      const result = await HierarchyOfRetailersApi.getRetailerCategories(retailerId);
      let initialSelectedCategories: ApiCategory[] = result;
      if (existingProgram) {
        const categoryIds = existingProgram.tiers?.flatMap(
          (tier) => Object.keys(tier.segmentRewards),
        ) ?? [];
        const existingProgramCategories = result.filter(
          (category) => categoryIds.includes(category.id),
        );
        initialSelectedCategories = existingProgramCategories;
      }
      setInitialSelectedCategories(initialSelectedCategories);
      setSelectedCategories(initialSelectedCategories);
      return result.filter(({ name }) => name);
    },
  );

  const ImageDisplay = (
    <Stack alignItems={'center'}>
      <img
        src={imageUrl!}
      />
      <VSpacer size="3" />
      <Stack direction='row' justifyContent={'space-between'}>
        <Text>{uploadedImage?.name}</Text>
        {!isViewing && (
          <>
            <HSpacer size="4" />
            <TextLink
              category="label-medium"
              color="primary"
              onClick={() => setUploadedImage(null)}
              testID="remove-textlink"
            >
              Remove
            </TextLink>
          </>
        )}
      </Stack>
    </Stack>
  );

  const prevTiersRef = useRef<Tier[]>(program.tiers);
  useEffect(() => {
    const tiersHaveChanged = JSON.stringify(prevTiersRef.current) !== JSON.stringify(program.tiers);
    if (tiersHaveChanged) {
      const sortedTiers = sortBy(program.tiers, ['minimumSegments', 'minimumDollars']);
      setProgram({ ...program, tiers: sortedTiers });
      prevTiersRef.current = sortedTiers;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program.tiers]);

  const dateError = useMemo(() => {
    const startDate = program.startDate;
    const endDate = program.endDate;
    const currentDate = formatDateOnly(new Date());
    if (endDate && endDate < currentDate) {
      return 'End date must be in the future';
    }
    if (!endDate || !startDate) {
      return;
    }
    if (startDate >= endDate) {
      return 'Start date must be before end date';
    }
    if (getDifferenceInDays(new Date(startDate), new Date(endDate)) < 365) {
      return 'Program duration must be at least 12 months';
    }
  }, [program.endDate, program.startDate]);

  const areBenefitsChanged = !isEqual(initialBenefits, benefits);
  const areSegmentsChanged = !isEqual(initialSelectedCategories, selectedCategories);
  const areTiersChanged = !!existingProgram?.tiers && program.tiers.some((tier) => {
    const existingTier = existingProgram.tiers!.find((t) => t.name === tier.name);
    return !existingTier
      || existingTier.minimumDollars !== tier.minimumDollars
      || existingTier.minimumSegments !== tier.minimumSegments
      || Object.entries(existingTier.segmentMinimumAmounts).some(([categoryId, value]) => (
        tier.segmentMinimumAmounts[categoryId] !== value
      ))
      || Object.entries(existingTier.segmentRewards).some(([categoryId, value]) => (
        tier.segmentRewards[categoryId] !== value
      ))
      || areSegmentsChanged;
  });
  const isImageChanged = (
    !!existingProgram?.campaignImage && (!!uploadedImage || uploadedImage === null)
  ) || (!existingProgram?.campaignImage && !!uploadedImage);
  const isChanged = (
    program.name !== existingProgram?.name
    || program.endDate !== existingProgram?.endDate
    || program.startDate !== existingProgram?.startDate
    || areTiersChanged
    || areBenefitsChanged
    || areSegmentsChanged
    || isImageChanged
  );
  const isValid = (
    !!program.name
    && !!program.endDate
    && !!program.startDate
    && !dateError
    && program.tiers.length > 0
    && selectedCategories.length > 0
    && isChanged
  );

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageError('');
    const imageFile = event.target.files?.[0];
    if (imageFile) {
      try {
        await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
              if (image.width > 360 || image.height > 60) {
                reject('Image must not be larger than 30 x 360 pixels');
              } else {
                setImageError('');
                resolve(getImageAsDataURL(image));
              }
              resolve(reader.result as string);
            };
          };
          reader.readAsDataURL(imageFile as Blob);
        });
      } catch (error) {
        setImageError(error as string);
        return;
      }
      setUploadedImage(imageFile);
      setImageError('');
    }
  };

  const getImageAsString = async () => {
    let imageBase64: string | null = null;
    let imageFilename: string | null = null;
    if (uploadedImage) {
      imageBase64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const imageDataUrl = getImageAsDataURL(img);
            resolve(imageDataUrl);
          };
        };
        reader.readAsDataURL(uploadedImage as Blob);
      });
      imageFilename = sanitizedFileName(uploadedImage.name);
    }
    return imageBase64 ? `${imageFilename}+${imageBase64}` : null;
  };

  const handleSave = async () => {
    onClose();
    if (isEditing) {
      const updateBody: RewardsProgramEndpoint.Update.Request = {};
      if (program.name !== existingProgram?.name) {
        updateBody.name = program.name;
      }
      if (program.startDate !== existingProgram?.startDate) {
        updateBody.startDate = program.startDate!;
      }
      if (program.endDate !== existingProgram?.endDate) {
        updateBody.endDate = program.endDate!;
      }
      if (areTiersChanged) {
        if (areSegmentsChanged) {
          const selectedSegmentIds = selectedCategories.map((category) => category.id);
          program.tiers.forEach((tier) => Object.keys(tier.segmentRewards).forEach((segmentId) => {
            const isSegmentSelected = selectedSegmentIds.includes(segmentId);
            if (!isSegmentSelected) {
              delete tier.segmentRewards[segmentId];
            }
          }));
        }
        updateBody.tiers = program.tiers;
      }
      if (areBenefitsChanged) {
        const tiers = program.tiers.map((tier) => {
          const tierBenefits = benefits.filter((benefit) => benefit.name === tier.name);
          return { ...tier, benefits: tierBenefits };
        });
        updateBody.tiers = tiers;
      }
      if (isImageChanged) {
        updateBody.campaignImage = await getImageAsString();
      }
      onUpdate(updateBody);
    } else {
      const tiers = program.tiers.map((tier) => {
        const tierBenefits = benefits.filter((benefit) => benefit.name === tier.name);
        return { ...tier, benefits: tierBenefits };
      });
      const createBody = {
        campaignImage: uploadedImage ? await getImageAsString() : undefined,
        endDate: program.endDate!,
        name: program.name,
        startDate: program.startDate!,
        tiers,
      };
      onCreate(createBody);
    }
  };

  const DateInputs = (
    isViewing ? (
      <Stack direction="row">
        <Stack>
          <Text category="label-large">
            Start Date
          </Text>
          <VSpacer size="2" />
          <Text category="body-large">
            {formatDate(new Date(program.startDate!))}
          </Text>
        </Stack>
        <HSpacer size="7" />
        <Stack>
          <Text category="label-large">
            End Date
          </Text>
          <VSpacer size="2" />
          <Text category="body-large">
            {formatDate(new Date(program.endDate!))}
          </Text>
        </Stack>
      </Stack>
    ) : (
      <Stack direction="row" width={464}>
        <DatePickerDropdown
          errorMessage={dateError}
          label="Start Date"
          onChange={(date) => (
            setProgram({ ...program, startDate: date ? formatDateOnly(date) : null })
          )}
          testID="start-date-input"
          value={program.startDate ? new Date(program.startDate) : undefined}
        />
        <HSpacer size="7" />
        <DatePickerDropdown
          errorMessage={dateError && ' '}
          label="End Date"
          onChange={(date) => (
            setProgram({ ...program, endDate: date ? formatDateOnly(date) : null })
          )}
          testID="end-date-input"
          value={program.endDate ? new Date(program.endDate) : undefined}
        />
      </Stack>
    )
  );

  const getCategoryName = (categoryId: string) => (
    categories?.find(({ id }) => id === categoryId)?.name
  );

  const TierBenefitsTable = () => {
    if (!categories) {
      return null;
    }
    return (
      <>
        <Table
          headers={['Tier Name', 'Benefit Title', 'Benefit Description', '']}
        >
          {benefits.map((benefit) => (
            <TableRow
              key={benefit.name}
              values={[
                benefit.tier.name,
                benefit.name,
                benefit.description,
                <Stack direction="row" justifyContent="flex-end" key={`${benefit.name}-actions`}>
                  {!!benefit.termsUrl && (
                    <>
                      <TextLink
                        href={benefit.termsUrl}
                        sx={{ color: getColor('info', 5) }}
                        target='_blank'
                        testID={`${benefit.name}-terms-url`}
                      >
                        Terms
                      </TextLink>
                      <HSpacer size="12" />
                    </>
                  )}
                  <Box width="96px">
                    {isViewing ? null : (
                      <IconButton
                        color="inherit"
                        key={`${benefit.name}-edit`}
                        onClick={() => {
                          setShowRewardsTierBenefitsModal(true);
                          setBenefitToEdit(benefit);
                        }}
                        testID={`${benefit.name}-edit-button`}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {isViewing ? null : (
                      <IconButton
                        color="inherit"
                        key={`${benefit.name}-delete`}
                        onClick={() => {
                          setBenefitToEdit(benefit);
                          setShowDeleteBenefitDialog(true);
                        }}
                        testID={`${benefit.name}-delete-button`}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Box>
                </Stack>,
              ]}
            />
          ))}
        </Table>
        {benefits.length === 0 && (
          <Box
            alignItems="center"
            bgcolor="#1B1B1B"
            display="flex"
            justifyContent="center"
            py="64px"
          >
            <Text category="body-xlarge">
              Add custom benefits per each rewards tier
            </Text>
          </Box>
        )}
      </>
    );
  };

  const TierTable = (
    !!categories && (
      <>
        <Table
          headers={['Tier', 'Min. Total Spend', 'Min. Total Segments']}
        >
          {program.tiers.map((tier) => (
            <TableRow
              expandedComponent={Object.keys(tier.segmentRewards).map((categoryId) => (
                <TableRow
                  key={categoryId}
                  values={[
                    '',
                    <Text category="body-small" key="name">
                      {getCategoryName(categoryId)}
                    </Text>,
                    <Stack key="hurdle">
                      <Text category="label-small">
                        Min. hurdle
                      </Text>
                      <VSpacer size="1" />
                      <Text category="body-small">
                        {formatCurrency(
                          tier.segmentMinimumAmounts[categoryId],
                          isCategoryHurdleInBushels(getCategoryName(categoryId)!) ? '' : '$',
                        )}
                      </Text>
                    </Stack>,
                    <Stack key="loyalty">
                      <Text category="label-small">
                        Loyalty %
                      </Text>
                      <VSpacer size="1" />
                      <Text category="body-small">
                        {roundToFixed(tier.segmentRewards[categoryId] * 100, 3)}
                      </Text>
                    </Stack>,
                  ]}
                />
              ))}
              key={tier.name}
              values={[
                tier.name,
                formatCurrency(tier.minimumDollars),
                tier.minimumSegments,
              ]}
            />
          ))}
        </Table>
        {program.tiers.length === 0 && (
          <Box
            alignItems="center"
            bgcolor="#1B1B1B"
            display="flex"
            justifyContent="center"
            py="64px"
          >
            <Text category="body-xlarge">
              Add a tier to create the rewards program
            </Text>
          </Box>
        )}
      </>
    )
  );

  let modalTitle: string;
  if (isEditing) {
    modalTitle = 'Edit Program';
  } else if (isViewing) {
    modalTitle = 'View Program';
  } else {
    modalTitle = 'Add Program';
  }

  return (
    <>
      <Modal
        acceptButton={!isViewing ? (props) => (
          <Button
            {...props}
            disabled={!isValid}
            onClick={handleSave}
            testID="rewards-program-modal-save-button"
          >
            Save
          </Button>
        ) : undefined}
        cancelButton={(props) => (
          <Button
            {...props}
            color="inherit"
            onClick={onClose}
            testID="rewards-program-modal-cancel-button"
          >
            {isViewing ? 'Close' : 'Cancel'}
          </Button>
        )}
        headerAccessoryRight={canEdit && !isEditing && (
          <Button
            onClick={() => setIsEditing(true)}
            startIcon={<Edit />}
            sx={{ paddingX: '20px' }}
            testID="rewards-program-modal-edit-button"
          >
            Edit
          </Button>
        )}
        largeModal
        onClose={onClose}
        open
        testID="rewards-program-modal"
        title={modalTitle}
      >
        <Text>
          Define the rewards program that is being offered by this retailer to their customers.
          The program will restart at the end of each period. Customers must re-earn the tier that
          they are currently in each period or will be moved to the tier that they have earned at
          the end of the period.
        </Text>
        <VSpacer size="8" />
        <Stack direction="row">
          <Text category="title-medium">
            Campaign Name
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Customize the name of the retailer rewards program
          </InfoTooltip>
        </Stack>
        <VSpacer size="5" />
        <Input
          disabled={isViewing}
          label="Campaign Name"
          onChangeText={(name) => setProgram({ ...program, name })}
          testID="rewards-program-name-input"
          value={program.name}
          width={464}
        />
        <VSpacer size="9" />
        <Text category="title-medium">
          Campaign Image
        </Text>
        <VSpacer size="4" />
        <Stack direction="row" gap="16px">
          <DataPoint label="File type:" py={0} testID="file-type-datapoint">
            PNG, SVG, JPG, JPEG
          </DataPoint>
          <DataPoint label="Max Height:" py={0} testID="max-height-datapoint">60px</DataPoint>
          <DataPoint label="Max Width:" py={0} testID="max-width-datapoint">360px</DataPoint>
          <DataPoint label="Max file size:" py={0} testID="max-file-size-datapoint">5MB</DataPoint>
        </Stack>
        {!!imageError && (
          <Text color="error">
            {imageError}
          </Text>
        )}
        <VSpacer size="4" />
        <Box
          alignContent="center"
          border={`1px dashed ${theme.palette.divider}`}
          minHeight={88}
          textAlign="center" width="464px"
        >
          {imageUrl ? (
            ImageDisplay
          ) : (
            <Button
              color="inherit"
              disabled={isViewing}
              onClick={() => (inputRef.current as HTMLInputElement)?.click()}
              startIcon={<AddIcon />}
              testID='upload-campaign-image-button'
              variant='outlined'
            >
              Add
              <input
                accept='image/png, image/jpg, image/jpeg, image/svg+xml'
                id='raised-button-file'
                onChange={(event) => handleImageChange(event)}
                ref={inputRef}
                style={{ display: 'none' }}
                type='file'
              />
            </Button>
          )}
        </Box>
        <VSpacer size="4" />
        <VSpacer size="9" />
        <Stack direction="row">
          <Text category="title-medium">
            Program Period
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Select the time period that the retailer rewards program is valid for
          </InfoTooltip>
        </Stack>
        <VSpacer size="6" />
        {DateInputs}
        <VSpacer size="9" />
        <Stack direction="row">
          <Text category="title-medium">
            Segments Included
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Select the time period that the retailer rewards program is valid for
          </InfoTooltip>
        </Stack>
        <VSpacer size="5" />
        <Select
          disabled={isViewing}
          label="Segments"
          onChangeValue={(categoryId) => {
            const isSelected = selectedCategories.find((category) => category.id === categoryId);
            let updatedSelectedCategories = [...selectedCategories];
            if (isSelected) {
              updatedSelectedCategories = updatedSelectedCategories.filter(
                (category) => category.id !== categoryId,
              );
            } else {
              const foundCategory = (categories ?? []).find(
                (category) => category.id === categoryId,
              );
              if (foundCategory) {
                updatedSelectedCategories.push(foundCategory);
              }
            }
            setSelectedCategories(updatedSelectedCategories.sort(
              (a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
            );
          }}
          placeholder={selectedCategories.map((category) => category.name).join(', ')}
          renderValue={() => selectedCategories.map((category) => category.name).sort().join(', ')}
          testID="segments-select"
          value={selectedCategories.map((category) => category.name).sort().join(', ')}
          width={464}
        >
          <Stack>
            {selectedCategories.map((category) => category.name).join(', ')}
          </Stack>
          {(categories ?? []).map((category) => (
            <MenuItem
              key={category.id}
              testID={`${category.id}-item`}
              value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
        <VSpacer size="9" />
        <Stack direction="row">
          <Text category="title-medium">
            Tier Graphics
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Please select the graphic option that will be associated to the tier name. If the tiers
            are 'Bronze, Gold, Silver, etc.', select 'Precious metals'. Otherwise, select 'None'.
          </InfoTooltip>
        </Stack>
        <VSpacer size="5" />
        <RadioGroup
          onChange={(event, value) => setIsPreciousMetals(value === 'true')}
          sx={{ flexDirection: 'row' }}
          value={isPreciousMetals}
        >
          <Radio disabled={isViewing} testID="precious-metal-radio" value={true}>
            Precious metals
          </Radio>
          <HSpacer size="10" />
          <Radio disabled={isViewing} testID="none-radio" value={false}>
            None
          </Radio>
        </RadioGroup>
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row">
              <Text category="title-medium">
                Tiers
              </Text>
              <HSpacer size="3" />
              <InfoTooltip>
                Create the rewards tiers each customer can earn through loyalty purchases
                with this retailer
              </InfoTooltip>
            </Stack>
          </Stack>
          {!isViewing && !!categories && (
            <Button
              onClick={() => setShowRewardsTiersModal(true)}
              startIcon={program.tiers.length ? <Edit /> : <Add />}
              testID="tiers-button"
              variant="outlined"
            >
              {program.tiers.length ? 'Edit Tiers' : 'Add Tiers'}
            </Button>
          )}
        </Stack>
        <VSpacer size="6" />
        {TierTable}
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row">
              <Text category="title-medium">
                Custom Benefits
              </Text>
              <HSpacer size="3" />
              <InfoTooltip>
                Add custom benefits per each rewards tier
              </InfoTooltip>
            </Stack>
          </Stack>
          {!isViewing && !!program.tiers.length && (
            <Button
              onClick={() => setShowRewardsTierBenefitsModal(true)}
              startIcon={<Add />}
              testID="tiers-button"
              variant="outlined"
            >
              Add Benefit
            </Button>
          )}
        </Stack>
        <VSpacer size="6" />
        <TierBenefitsTable />
      </Modal>
      {showRewardsTiersModal && !!categories && (
        <RewardsTiersModal
          categories={selectedCategories}
          existingTiers={program.tiers}
          isPreciousMetals={isPreciousMetals}
          onClose={() => setShowRewardsTiersModal(false)}
          onSave={(tiers) => setProgram({ ...program, tiers })}
        />
      )}
      {showRewardsTierBenefitsModal && !!program.tiers.length && (
        <RewardsTierBenefitModal
          benefit={benefitToEdit}
          onClose={() => setShowRewardsTierBenefitsModal(false)}
          onSave={(benefitSave) => {
            const updatedBenefits = [...benefits];
            if (benefitToEdit) {
              const index = benefits.indexOf(benefitToEdit);
              updatedBenefits[index] = benefitSave;
            } else {
              updatedBenefits.push(benefitSave);
            }
            setBenefits(updatedBenefits);
            setBenefitToEdit(undefined);
          }}
          tiers={program.tiers}
        />
      )}
      {showDeleteBenefitDialog && benefitToEdit && (
        <DeleteBenefitConfirmDialog
          onCancel={() => setShowDeleteBenefitDialog(false)}
          onConfirm={() => {
            const updatedBenefits =  [...benefits];
            setShowDeleteBenefitDialog(false);
            const index = updatedBenefits.indexOf(benefitToEdit);
            updatedBenefits.splice(index, 1);
            setBenefitToEdit(undefined);
            setBenefits(updatedBenefits);
          }}
          open
        />
      )}
    </>
  );
};
