import { HSpacer, Text } from '@/components/DesignSystem';
import {
  ApiPricingRequest,
  ApiProductRecommendation,
  ApiPromotionForProduct,
} from '@api/interfaces';
import Star from '@mui/icons-material/Star';
import { Stack } from '@mui/material';
import { formatDateOnly } from '@shared/utilities';
import React from 'react';

interface EarnRewardsChipProps {
  pricingRequest?: ApiPricingRequest,
  recommendation?: ApiProductRecommendation,
}
export const EarnRewardsChip = ({
  pricingRequest,
  recommendation,
}: EarnRewardsChipProps) => {
  const hasPromotions = () => {
    let promotions: ApiPromotionForProduct[] = [];
    if (recommendation) {
      recommendation.productRecommendationData?.forEach((product) => {
        if (product.productDetail?.promotions?.length) {
          promotions = [...promotions, ...product.productDetail.promotions];
        }
      });
      recommendation.bundles?.forEach((bundle) => bundle.products?.forEach((product) => {
        if (product?.productDetails?.promotions?.length) {
          promotions = [...promotions, ...product.productDetails.promotions];
        }
      }));
    } else if (pricingRequest) {
      pricingRequest.products?.forEach((product) => {
        if (product.productDetail?.promotions?.length) {
          promotions = [...promotions, ...product.productDetail.promotions];
        }
      });
      pricingRequest.bundles?.forEach((bundle) => bundle.products?.forEach((product) => {
        if (product.productDetails?.promotions?.length) {
          promotions = [...promotions, ...product.productDetails.promotions];
        }
      }));
    }
    if (promotions.length === 0) {
      return false;
    }
    const currentDate = formatDateOnly(new Date());
    const activePromotions = promotions.filter((promotion) => (
      promotion.startDate <= currentDate
    ));
    return !!activePromotions.length;
  };

  return (
    <>
      {hasPromotions() && (
        <Stack
          alignItems="center"
          borderRadius="4px"
          direction="row"
          p="4px 8px"
          sx={{
            background:
              'var(--Material-Gradient, linear-gradient(180deg, #FFE082 0%, #FFC107 100%))',
          }}
        >
          <Star />
          <HSpacer size="2" />
          <Text category="label-small">Earn Rewards</Text>
        </Stack>
      )}
    </>
  );
};
