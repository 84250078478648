// More info about statuses:
// https://growersnc.atlassian.net/wiki/spaces/PM/pages/71598081/Pricing+Request+and+Offer+statuses

export enum PricingRequestStatus {
  Accepted = 'Accepted',
  AwaitingApproval = 'Awaiting Approval',
  AwaitingDelivery = 'Awaiting Delivery',
  AwaitingFulfillment = 'Awaiting Fulfillment',
  AwaitingPickup = 'Awaiting Pickup',
  Closed = 'Closed',
  Draft = 'Draft',
  DraftOrder = 'Draft Order',
  FinancingRequested = 'Financing Requested',
  OnAccountRequested = 'On Account',
  Open = 'Open',
  OrderShipped = 'Order Shipped',
  Rejected = 'Rejected',
  Review = 'Review',
}

export enum PricingRequestStatusNew {
  AwaitingDelivery= 'Awaiting Delivery',
  AwaitingFulfillment= 'Awaiting Fulfillment',
  AwaitingOffer= 'Awaiting offer',
  AwaitingPickup= 'Awaiting Pickup',
  Draft = 'Draft',
  DraftOrder = 'Draft Order',
  FinancingRequested= 'Financing Requested',
  OfferClosed= 'Closed',
  OfferExpired= 'Expired',
  OnAccountRequested= 'On Account',
  OrderShipped= 'Order Shipped',
  PaymentDue= 'Payment Due',
  PendingApproval= 'Pending Approval',
  ReviewOffer= 'Review Offer',
}

export enum PricingRequestPaymentStatus {
  PAYMENT_INITIATED = "Payment Initiated",
  PAYMENT_INITIATED_PROCESSING = "Payment Processing",
  PAYMENT_INITIATED_FAIL = "Payment Failed",
  PAYMENT_RECEIVED = "Payment Success",
  PAYMENT_TRANSFER_INITIATED = "Transfered Initiated",
  PAYMENT_TRANSFER_SUCCESS = "Transfered Successfully",
  PAYMENT_TRANSFER_FAIL = "Transfered Failed",
}

export enum FarmerRequestType {
  Checkout = "checkout",
  Order = "order",
  PricingRequest = "pricing-request",
}

export const AcceptedRequestStatuses: PricingRequestStatus[] = [
  PricingRequestStatus.Accepted,
  PricingRequestStatus.AwaitingDelivery,
  PricingRequestStatus.AwaitingFulfillment,
  PricingRequestStatus.FinancingRequested,
  PricingRequestStatus.OnAccountRequested,
  PricingRequestStatus.OrderShipped,
  PricingRequestStatus.AwaitingPickup,
];

export const statusMapping: { [key: string]: string[] } = {
  'chat-request': ['Accepted', 'Awaiting Delivery', 'Awaiting Fulfillment',
    'Awaiting Pickup', 'Financing Requested', 'On Account', 'Open', 'Order Shipped', 'Review'],
  'checkout': ['Accepted'],
  'my-orders': ['Closed', 'Expired'],
  'pricing-request-listing': ['Accepted', 'Awaiting Approval', 'Awaiting Delivery',
    'Awaiting Fulfillment','Awaiting Pickup', 'Financing Requested', 'On Account', 'Open',
    'Order Shipped', 'Rejected', 'Review'],
};

export const PricingRequestStatusFilter = [
  {
    "key": "Awaiting Fulfillment",
    "value": "Awaiting Fulfillment",
  },
  {
    "key": "Awaiting Offer",
    "value": "Open",
  },
  {
    "key": "Order Shipped",
    "value": "Order Shipped",
  },
  {
    "key": "Pending Approval",
    "value": "Awaiting Approval",
  },
  {
    "key": "Awaiting Pickup",
    "value": "Awaiting Pickup",
  },
  {
    "key": "Review Offer",
    "value": "Review",
  },
  {
    "key": "Payment Due",
    "value": "Accepted",
  },
];

export const DaysLeftFilter = [
  {
    "key": "One Day Left",
    "value": 1,
  },
  {
    "key": "Two Days Left",
    "value": 2,
  },
  {
    "key": "Three Days Left",
    "value": 3,
  }];
