export const AppUrls = {
  androidDownloadBadge: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
  androidGrowersAppLink: 'https://play.google.com/store/apps/details?id=com.growers_farmer',
  androidRetailAppLink: 'https://play.google.com/store/apps/details?id=com.growers_retailer',
  growersAppIcon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/02/3c/f1/023cf176-564d-9791-4291-813adba49545/AppIcon-1x_U007emarketing-1-85-220.png/540x540bb.jpg',
  iosDownloadBadge: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698364800',
  iosGrowersAppLink: 'https://apps.apple.com/us/app/growers/id6462903105?itscg=30200&amp;itsct=apps_box_appicon',
  iosRetailAppLink: 'https://apps.apple.com/us/app/growers-retail/id6463931594?itscg=30200&amp;itsct=apps_box_appicon',
  retailAppIcon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/d5/65/4a/d5654a47-2b0c-17ea-93a4-b2a9c04ce6af/AppIcon-1x_U007emarketing-0-7-0-85-220-0.png/540x540bb.jpg',
} as const;
