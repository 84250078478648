import { GlobalHeader } from '@/components/DesignSystem/GlobalHeader/GlobalHeader';
import { DemoBlock } from '../../components/DemoBlock';
import { useState } from 'react';
import { Text } from '@/components/DesignSystem';
import { Stack } from '@mui/material';

export const GlobalHeaderDemo = () => {
  const [search, setSearch] = useState('');
  return (
    <>
    <DemoBlock label="Signed out">
      <Stack>
        <GlobalHeader isSignedOut isStatic onSearch={setSearch} />
      </Stack>
    </DemoBlock>
    <DemoBlock label="Signed in">
      <GlobalHeader isStatic />
    </DemoBlock>
    <Text>Search: {search}</Text>
    </>
  );
};