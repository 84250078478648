import {
  DateInput,
} from '@/components/DesignSystem';
import { Divider, Stack } from '@mui/material';
import { useState } from 'react';

export const DateInputDemo = () => {

  const [date, setDate] = useState<Date | undefined>();

  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <DateInput
        label="Basic"
        onChangeDate={setDate}
        testID="date-input-demo-basic"
        value={date}
      />
      <DateInput
        label="Required"
        onChangeDate={setDate}
        required
        testID="date-input-demo-required"
        value={date}
      />
      <DateInput
        disabled
        label="Disabled"
        onChangeDate={setDate}
        testID="date-input-demo-disabled"
        value={date}
      />
      <DateInput
        error
        helperText="The specified date is invalid"
        label="Error"
        onChangeDate={setDate}
        testID="date-input-demo-error"
        value={date} />
    </Stack>
  );
};
