import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';
import { Stack } from '@mui/material';
import { FarmerCard } from "@/components/DesignSystem/FarmerCard/FarmerCard";
import { ApiRecommendationFarmerUser } from "@api/interfaces";
import { State } from "@shared/enums";
import { omit } from "@shared/utilities";

const farmer: ApiRecommendationFarmerUser = {
  id: 'demo-farmer',
  businessName: 'Business Name',
  firstName: 'FirstName',
  lastName: 'LastName',
  countyData: {
    county: 'County name',
    createdAt: new Date(),
    id: 'county-data',
    state: State.NorthCarolina,
    updatedAt: new Date(),
  },
  telephone: '(555) 555-5555',
};

const farmerWithoutBusinessName = omit(farmer, ['businessName']);

export const FarmerCardDemo = () => {
  return (
    <Stack spacing={3}>
      <DemoBlock label="Farmer card (Default)">
        <FarmerCard farmer={farmer} testID="farmer-card-demo-1"/>
      </DemoBlock>
      <DemoBlock label="Farmer card with phone number (stacked)">
        <FarmerCard farmer={farmer} showTelephone="stacked" testID="farmer-card-demo-2"/>
      </DemoBlock>
      <DemoBlock label="Farmer card with phone number (inline)">
        <FarmerCard farmer={farmer} showTelephone="inline" testID="farmer-card-demo-3"/>
      </DemoBlock>
      <DemoBlock label="No business name">
        <FarmerCard
          farmer={farmerWithoutBusinessName}
          testID="farmer-card-demo-4"
        />
      </DemoBlock>
    </Stack>
  );
};
