import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Action } from '@/components/shared/ListSelector/helper';
import { LocationCard } from '@/components/shared/ListSelector/LocationCard';
import { SelectType } from '@/components/shared/ListSelector/RetailersList';
import { ApiRetailer, LocationDetails } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Container, Stack } from '@mui/material';
import React from 'react';

interface RetailerLocationsListProps {
  locations: LocationDetails[],
  onBack: () => void,
  onChange: (updatedIds: string[]) => void,
  onSelectLocation: (location: LocationDetails) => void,
  retailer: ApiRetailer,
  selectedIds: string[],
  selectType?: SelectType,
}

export const RetailerLocationsList = ({
  locations,
  onBack,
  onChange,
  onSelectLocation,
  retailer,
  selectedIds,
  selectType = 'salespersons',
}: RetailerLocationsListProps) => {
  const salespersonIds = locations.flatMap((location) => (
    location.salespersons.map((salesperson) => salesperson.id)
  )) ?? [];
  const locationIds = locations.map((location) => location.id);

  const addAll = () => {
    const newSelectedIds = new Set(selectedIds);
    if (selectType === 'salespersons') {
      salespersonIds.forEach((id) => newSelectedIds.add(id));
    } else {
      locationIds.forEach((id) => newSelectedIds.add(id));
    }
    onChange(Array.from(newSelectedIds));
  };

  const removeAll = () => {
    const newSelectedIds = new Set(selectedIds);
    if (selectType === 'salespersons') {
      salespersonIds.forEach((id) => newSelectedIds.delete(id));
    }
    else {
      locationIds.forEach((id) => newSelectedIds.delete(id));
    }
    onChange(Array.from(newSelectedIds));
  };

  const showAddAllButton = selectType === 'salespersons'
    ? !salespersonIds.every((id) => selectedIds.includes(id))
    : !locationIds.every((id) => selectedIds.includes(id));
  const showRemoveAllButton = selectType === 'salespersons'
    ? selectedIds.some((id) => salespersonIds.includes(id))
    : locationIds.every((id) => selectedIds.includes(id));

  return (
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <Button
            color="inherit"
            onClick={onBack}
            startIcon={<ArrowBack />}
            testID="location-back-button"
            variant="outlined"
          >
            Back
          </Button>
          <HSpacer size="4" />
          <Text category="title-medium">
            {retailer.name} / Locations ({locations.length})
          </Text>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end">
          {showRemoveAllButton && (
            <Button
              onClick={removeAll}
              testID="remove-all-recipients-button"
              variant="text"
            >
              Remove all
            </Button>
          )}
          {showAddAllButton && showRemoveAllButton && (
            <HSpacer size="5" />
          )}
          {showAddAllButton && (
            <Button
              onClick={addAll}
              startIcon={<AddIcon />}
              testID="remove-all-recipients-button"
            >
              Add all
            </Button>
          )}
        </Stack>
      </Stack>
      <VSpacer size="5" />
      {locations.map((location) => (
        <React.Fragment key={location.id}>
          <LocationCard
            addedIds={selectedIds}
            location={location}
            onAction={(action: Action) => {
              const updatedSelectedIds = new Set(selectedIds);
              if (selectType === 'salespersons') {
                const salespersonIds = location.salespersons.map(
                  (salesperson) => salesperson.id,
                ) ?? [];
                salespersonIds.forEach((id) => {
                  if (action === Action.AddAll) {
                    updatedSelectedIds.add(id);
                  } else {
                    updatedSelectedIds.delete(id);
                  }
                });
              } else {
                if (action === Action.AddAll) {
                  updatedSelectedIds.add(location.id);
                } else {
                  updatedSelectedIds.delete(location.id);
                }
              }
              onChange(Array.from(updatedSelectedIds));
            }}
            onSelectLocation={onSelectLocation}
            selectType={selectType}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
    </Container>
  );
};
