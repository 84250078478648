import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { palette as landusPalette } from '../Landus/palette';
import { components } from './components';
import { palette } from './palette';
import { defaultTypography } from './typography';

const baseTheme = createTheme({ palette });
const muiTheme = createTheme({
  components: components(baseTheme),
  palette,
  typography: defaultTypography,
});

const landusTheme = createTheme({
  components: components(baseTheme),
  palette: landusPalette,
  typography: defaultTypography,
});

export const themes: { [domain: string]: Theme } = {
  marketplace: muiTheme,
  landus: landusTheme,
};
