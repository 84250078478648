import { DemoBlock } from '../../components/DemoBlock';
import { Table } from '@/components/DesignSystem/Table/Table';

export const TableDemo = () => {
  const headers = ['Name', 'Address', 'Zip Code'];
  const rows = [
    ['Damo Suzuki', '100 Avenue St', '90000'],
    ['Holger Czukay', '1428 Elm St', '88888'],
    ['Irmin Schmidt', '5010 Paper House Rd', '59182'],
  ];

  return (
    <DemoBlock>
      <Table headers={headers} rows={rows} />
    </DemoBlock>
  );
};
