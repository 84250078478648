import { Logo, Text, VSpacer } from '@/components/DesignSystem';
import { UserSwitcher } from '@/components/shared/UserSwitcher';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { SessionState, useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import PartnerRetailerAreaModal from '@/pages/PartnerRetailers/PartnerRetailerAreaModal';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, IconButton, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import { getFarmerName, userIsInternal, userIsRetailer } from '@shared/utilities';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopOnly } from './DesktopOnly';

const styles = {
  container: {
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  },
  header: {
    height: '48px',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
};

export const AppHeader = () => {
  const authentication = useAuthentication();
  const { internal, user } = authentication;

  const [showMyAreaModal, setShowMyAreaModal] = useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const subdomain = getSubdomain();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authentication.logout(); // eslint-disable-line @typescript-eslint/no-floating-promises
    setAnchorEl(null);
    navigate(Routes.LOGIN, { replace: true });
  };

  const handleNavigation = (route: Routes) => {
    navigate(route);
    setAnchorEl(null);
  };

  const formatVersion = (version: string) =>
    version.match(/\d+\.\d+\.\d+/) ? 'v' + version : version;
  const version = (AppConfig.env.test || userIsInternal(user)) && (
    <Text>{formatVersion(AppConfig.version)}</Text>
  );

  return (
    <>
      <Box sx={{ ...styles.container, backgroundColor: theme.palette.background.default }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={styles.header}
        >
          <Stack alignItems='center' direction='row' spacing={2}>
            {authentication.sessionState === SessionState.LOGGED_IN && (
              <IconButton edge='start' onClick={handleClick}>
                <MenuIcon />
              </IconButton>
            )}
            <Logo
              onClick={() => navigate(Routes.HOMEPAGE)}
              size={subdomain ? undefined : 'tiny'}
              subdomain={subdomain}
            />
            <UserSwitcher />
          </Stack>
          <DesktopOnly>
            {userIsRetailer(user) ? (
              <Stack>
                <VSpacer size='3' />
                <Text category='p2'>
                  Hi, {authentication.user?.businessName}
                </Text>
                <Stack direction='row' justifyContent='right'>
                  <Text category='c1'>{DateTime.now().toLocaleString()}</Text>
                </Stack>
              </Stack>
            ) : (
              <Stack alignItems='center' direction='row' spacing={2}>
                {version}
                <Text>{getFarmerName(authentication.user, true)}</Text>
              </Stack>
            )}
          </DesktopOnly>
        </Stack>
      </Box>
      <VSpacer size='10' />
      <Menu
        anchorEl={anchorEl}
        data-testid='app-header-menu'
        onClose={handleClose}
        open={open}
        sx={{ marginTop: '10px' }}
      >
        {!internal && (
          <MenuItem key='my-profile' onClick={() => handleNavigation(Routes.MY_PROFILE)}>
            My Account
          </MenuItem>
        )}
        {!internal && userIsRetailer(user) && (
          <MenuItem key='my-area' onClick={() => setShowMyAreaModal(true)}>
            My Area
          </MenuItem>
        )}
        {!internal &&
          !userIsRetailer(user) && [
            <MenuItem
              key='my-pricing-requests'
              onClick={() => handleNavigation(Routes.PAST_PRICING_REQUESTS)}
            >
              Product Requests
            </MenuItem>,
            <MenuItem
              key='my-saved-retailers'
              onClick={() => handleNavigation(Routes.SAVED_RETAILERS)}
            >
              Saved Retailers
            </MenuItem>,
            <MenuItem key='shop' onClick={() => handleNavigation(Routes.SHOP)}>
              Shop
            </MenuItem>,
        ]}
        {!internal && userIsRetailer(user) && (
          <MenuItem
            key='my-pricing-offers'
            onClick={() => handleNavigation(Routes.VIEW_OFFERS)}
          >
            Requests and Offers
          </MenuItem>
        )}
        {internal && [
          <MenuItem
            key='farmers'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_FARMERS)}
          >
            Farmers
          </MenuItem>,
          <MenuItem
            key="retailers"
            onClick={() => handleNavigation(Routes.ADMIN_HIERARCHY_OF_RETAILERS)}
          >
            Retailers
          </MenuItem>,
          <MenuItem
            key='manufacturers'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_MANUFACTURERS)}
          >
            Manufacturers
          </MenuItem>,
          <MenuItem
            key='product'
            onClick={() => handleNavigation(Routes.PRODUCT)}
          >
            Products
          </MenuItem>,
          <MenuItem
            key='promotions'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_PROMOTIONS)}
          >
            Promotions
          </MenuItem>,
          <Divider key="divider-1" />,
          <MenuItem
            key='home'
            onClick={() => handleNavigation(Routes.ADMIN_HOME_PAGE)}
            sx={{ minWidth: '319px' }}
          >
            GROWERS Home
          </MenuItem>,
          <MenuItem
            key='productRecommendation'
            onClick={() => handleNavigation(Routes.ADMIN_PRODUCT_RECOMMENDATION)}
          >
            Recommendations
          </MenuItem>,
          <MenuItem
            key='requests'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_REQUESTS)}
          >
            Orders
          </MenuItem>,
          <MenuItem
            key='customNotifications'
            onClick={() => handleNavigation(Routes.ADMIN_CUSTOM_NOTIFICATIONS)}
          >
            Custom Notifications
          </MenuItem>,
          <Divider key='divider' />,
          <MenuItem
            key='notifications'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_NOTIFICATIONS)}
          >
            Test Notifications
          </MenuItem>,
          <MenuItem
            key='admins'
            onClick={() => handleNavigation(Routes.ADMIN_LIST_ADMINS)}
          >
            Admin Users
          </MenuItem>,
          <MenuItem
            key='raw'
            onClick={() => {
              window.location.href = AppConfig.biddingApiUrl + '/raw/farmers';
              return true;
            }}
          >
            Raw Data
          </MenuItem>,
          <MenuItem
            key='product-events'
            onClick={() => handleNavigation(Routes.ADMIN_PRODUCT_EVENTS)}
          >
            Product Events
          </MenuItem>,
          <MenuItem
            key='feature-flags'
            onClick={() => handleNavigation(Routes.FEATURE_FLAGS)}
          >
            Feature Flagging
          </MenuItem>,
          <Divider key="divider-1" />,
        ]}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        {internal && AppConfig.env.test && (
          <MenuItem onClick={() => handleNavigation(Routes.UX_SANDBOX)}>
            UX Sandbox
          </MenuItem>
        )}
      </Menu>
      {showMyAreaModal && authentication.user && (
        <PartnerRetailerAreaModal
          isVisible
          onClose={() => setShowMyAreaModal(false)}
          partnerRetailer={authentication.user}
          title='My Area'
        />
      )}
    </>
  );
};
