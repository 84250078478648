import { OfferProduct } from "@/pages/CreateSendPriceRequest/interfaces";
import { ApiOfferProduct } from '@api/interfaces';

export const calculateOfferSubtotal = (products: (OfferProduct | ApiOfferProduct)[]) => {
  return products.reduce((total, product) => {
    if ('companionProducts' in product) {
      return total + ((product.companionProducts ?? []).reduce(
        (companionTotal, companionProduct) => (
          companionTotal + (companionProduct.price ?? 0)
        ), 0) + (product.price ?? 0));
    }
    return total + (product.price ?? 0);
  }, 0);
};

