import { Button, Logo, Text, VSpacer } from '@/components/DesignSystem';
import { ContactFooter } from '@/components/shared/ContactFooter';
import { Routes } from "@/constants/Routes";
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { Stack } from "@mui/material";
import { userIsRetailer } from '@shared/utilities';
import { DateTime } from 'luxon';
import { useNavigate } from "react-router-dom";

export const RetailerHomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const { windowHeight } = useMediaQuery();
  const subdomain = getSubdomain();

  const getIntroductionText = () => {
    let greeting = 'Hi, ';
    if (!user) {
      greeting += 'Retailer';
    }
    if (userIsRetailer(user)) {
      greeting += user?.businessName;
      return greeting;
    }
  };

  return (
    <Stack alignItems="center">
      <VSpacer mobileSize="10" size="14" />
      <Logo category="reverse-symbol" size="giant" />
      <VSpacer size="5" />
      <Text
        breakWord
        fontSize={42}
        fontWeight={300}
        lineHeight={1.45}
        px={1}
        textAlign="center"
      >
        {getIntroductionText()}
      </Text>
      <VSpacer size="4" />
      <Text category="p2">
        {DateTime.now().toFormat("MMMM dd, yyyy")}
      </Text>
      <VSpacer size="13" />
      {userIsRetailer(user) &&
        <Button
          onClick={() => navigate(Routes.VIEW_OFFERS)}
          size="giant"
          testID="my-price-offers">
          View Requests and Offers
        </Button>
      }
      {subdomain === 'landus' ? undefined : (
        windowHeight > 770 ? (
          <ContactFooter fixedToBottom />
        ) : (
          <>
            <VSpacer size="8" />
            <ContactFooter />
            <VSpacer size="11" />
          </>
        )
      )}
    </Stack>
  );
};
