import { Chip } from '@/components/DesignSystem';

interface StorefrontChipProps {
  subdomain: string | null | undefined,
}

export const StorefrontChip = ({ subdomain }: StorefrontChipProps) => {
  const label = subdomain
    ? `${subdomain.charAt(0).toUpperCase() + subdomain.slice(1)} Storefront` : 'Marketplace';
  return (
    <Chip
      color="primary"
      label={label}
      testID="storefront-chip"
    />
  );
};
