import { Box, Stack } from '@mui/material';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import FarmerDesktopImage from '../../assets/images/farmer-desktop.png';
import PhoneImage0 from '../../assets/images/iPhone0.png';
import PhoneImage1 from '../../assets/images/iPhone1.png';
import PhoneImage2 from '../../assets/images/iPhone2.png';
import GrowersAppLogo from '../../assets/logos/reverse/growers_app_full_reverse.svg';

export const SlideOne = () => {
  return (
    <Box
      minHeight='100vh'
      sx={{ backgroundColor: 'black' }}
    >
      <Stack
        display='flex'
        flexDirection='column'
        minHeight='75vh'
        paddingTop='78px'
        sx={{
          backgroundImage: `url(${FarmerDesktopImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack direction='row' justifyContent='center'>
          <Box
            component='img'
            src={GrowersAppLogo}
            sx={{
              height: 52,
              opacity: 1,
              width: 238,
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
export const SlideTwo = () => {
  const { isMobile } = useMediaQuery();
  return (
    <Box
      minHeight='100vh'
    >
      <Stack
        display='flex'
        flexDirection='column'
        paddingTop='78px'
      >
        <Stack direction='row' justifyContent='center'>
          <Box
            component='img'
            maxHeight={isMobile ? '40vh' : '50vh'}
            src={PhoneImage0}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
export const SlideThree = () => {
  const { isMobile } = useMediaQuery();
  return (
    <Box
      minHeight='100vh'
    >
      <Stack
        display='flex'
        flexDirection='column'
        paddingTop='78px'
      >
        <Stack direction='row' justifyContent='center'>
          <Box
            component='img'
            maxHeight={isMobile ? '40vh' : '50vh'}
            src={PhoneImage1}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
export const SlideFour = () => {
  const { isMobile } = useMediaQuery();
  return (
    <Box
      minHeight='100vh'
    >
      <Stack
        display='flex'
        flexDirection='column'
        paddingTop='78px'
      >
        <Stack direction='row' justifyContent='center'>
          <Box
            component='img'
            maxHeight={isMobile ? '40vh' : '50vh'}
            src={PhoneImage2}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export type CarouselSlides = {
  component: () => JSX.Element,
  description: string,
  header?: string,
}[];

/* eslint-disable max-len */
export const slides: CarouselSlides = [
  {
    component: SlideOne,
    description: 'The GROWERS App allows you to access everyone you buy ag inputs from, saving you time and keeping you organized.',
    header: 'All of your retailers. All in one place.',
  },
  {
    component: SlideTwo,
    description: 'Add all the products and services you\'d like to receive product and pricing information for.',
  },
  {
    component: SlideThree,
    description: 'Add your own local retailer, or select retailers from the GROWERS Retail Network.',
  },
  {
    component: SlideFour,
    description: 'Decide how you\'d like to pay, add fulfillment method details - and your Product Request is ready to go!',
  },
];
