import { Text } from '@/components/DesignSystem';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { Box, Divider, Stack } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import { Fragment } from 'react';

const styles = {
  name: {
    marginRight: '25px',
    wordBreak: 'break-all',
  },
} as const;

interface PriceListProps {
  onClose: () => void,
  products: OfferProduct[],
}

export const PriceList = ({
  onClose,
  products,
}: PriceListProps) => {
  return (
    <ModalBottomSheet
      hideScrollbarOnDesktop
      onClose={onClose}
      onOpen={() => null}
      open
      showTopButton={false}
      testID="price-list-bottom-sheet"
      title="Price list"
      width={343}
    >
      <Box p={getResponsiveValue('0 16px 16px', '0')}>
        <Stack
          direction="row"
          justifyContent="space-between"
          py="18px"
        >
          <Text category="label-large">
            Product
          </Text>
          <Text category="label-large">
            Total Price
          </Text>
        </Stack>
        <Divider />
        {products.map((product, index) => (
          <Fragment key={index}>
            {!!product.price && (
              <>
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  py="18px"
                >
                  <Text
                    category="body-medium"
                    sx={styles.name}
                    testID={`price-list-product-${index}-name`}
                  >
                    {product.name}
                  </Text>
                  <Text
                    category="body-medium"
                    testID={`price-list-product-${index}-price`}
                  >
                    {product.price === undefined ? '' : formatCurrency(product.price)}
                  </Text>
                </Stack>
                <Divider />
              </>
            )}
            {product.companionProducts?.map(
              (companionProduct, companionIndex) => !companionProduct.price ? null : (
                <Fragment key={companionIndex}>
                  <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    py="18px"
                  >
                    <Text
                      category="body-medium"
                      sx={styles.name}
                      testID={`price-list-companion-product-${companionIndex}-name`}
                    >
                      {companionProduct.name}
                    </Text>
                    <Text
                      category="body-medium"
                      testID={`price-list-companion-product-${companionIndex}-price`}
                    >
                      {companionProduct.price === undefined
                        ? ''
                        : formatCurrency(companionProduct.price)}
                    </Text>
                  </Stack>
                  <Divider />
                </Fragment>
              ))}
          </Fragment>
        ))}
      </Box>
    </ModalBottomSheet>
  );
};
