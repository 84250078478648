import {
  Button,
  HSpacer,
  IconButton,
  Modal,
  ProgressLoader,
  Search,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import { ApiRetailer, ApiRetailerLocation } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Store from '@mui/icons-material/Store';
import { Avatar, Box, Divider, Stack } from '@mui/material';
import { localizeNumber } from '@shared/utilities';
import { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface ViewSelectedLocationsModalProps {
  onChange?: (locationIds: string[]) => void,
  onClose: () => void,
  locationIds?: string[],
  readOnly?: boolean,
  testID: string,
}

export const ViewSelectedLocationsModal = ({
  onChange,
  onClose,
  locationIds = [],
  readOnly = false,
  testID,
}: ViewSelectedLocationsModalProps) => {
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>(undefined);
  const { debouncedSearch, search, setSearch } = useSearch();

  const currentPage = useMemo(() => {
    if (selectedRetailer !== undefined) {
      return 'location';
    }
    return 'retailer';
  }, [selectedRetailer]);

  const locationsCount = locationIds.length;

  const { data: locations, isFetching: isFetchingLocations } = useQuery(
    [QueryKeys.GET_RETAILER_LOCATION_LIST, locationIds],
    async () => await RetailerLocationApi.list({
      id: locationIds,
      isActive: true,
    }),
    {
      enabled: !!locationsCount,
    },
  );

  const retailerIds = Array.from(
    new Set(locations?.data.map((location) => location.retailerId)),
  );

  const { data: retailers, isFetching: isFetchingRetailers } = useQuery(
    [QueryKeys.GET_RETAILERS, debouncedSearch, retailerIds],
    () => HierarchyOfRetailersApi.listRetailers({
      id: retailerIds,
      search: debouncedSearch,
      sort: 'name',
      sortDesc: false,
    }),
    { enabled: !!retailerIds.length },
  );

  const getRetailerLocations = (retailer: ApiRetailer) => {
    const retailerLocations = locations?.data.filter(
      (location) => location.retailerId === retailer.id,
    ) ?? [];
    return retailerLocations;
  };

  const title = selectedRetailer
    ? `Retailer Locations (${getRetailerLocations(selectedRetailer).length})`
    : `Added Retail Locations (${locationIds.length})`;

  const showBackButton = currentPage === 'location';

  const handleRemoveRetailer = async (retailer: ApiRetailer) => {
    const retailerLocationIds = retailer.locationDetails?.map((location) => location.id) ?? [];
    const newLocationIds = locationIds.filter((locationId) => {
      return !retailerLocationIds.includes(locationId);
    });
    onChange?.(newLocationIds);
  };

  const handleRemoveLocation = (location: ApiRetailerLocation) => {
    const newLocationIds = locationIds.filter((locationId) => {
      return locationId !== location.id;
    });
    onChange?.(newLocationIds);
  };

  const showRemoveAllButton = currentPage === 'location' && !readOnly;

  const RetailerPage = (
    <>
      <Search
        fullWidth
        onChangeText={setSearch}
        testID="view-recipients-search"
        value={search}
      />
      <VSpacer size="4" />
      {(isFetchingLocations || isFetchingRetailers) ? (
        <Stack alignItems="center" pt="80px">
          <ProgressLoader type="circular" />
        </Stack>
      ) : (
        <Stack gap="4px">
          <Divider />
          {retailers?.data.map((retailer) => (
            <Fragment key={retailer.id}>
              <Stack
                alignItems="center"
                direction="row"
                height="56px"
                justifyContent="space-between"
              >
                <Stack alignItems="center" direction="row">
                  <Avatar
                    alt={`${retailer.name} logo`}
                    src={retailer?.image ? `${AppConfig.staticImageHost}/${retailer.image}` : undefined}
                    sx={{ bgcolor: '#EBEBEB' }}
                  >
                    {retailer?.image ? null : <Store />}
                  </Avatar>
                  <HSpacer size="4" />
                  <Text category="title-medium">
                    {retailer.name}
                  </Text>
                </Stack>
                <Stack alignItems="center" direction="row">
                  {!readOnly && (
                    <TextLink
                      category="label-medium"
                      onClick={() => handleRemoveRetailer(retailer)}
                      sx={{ padding: '8px 12px' }}
                      testID={`${testID}-remove-all-textlink-${retailer.name}`}
                    >
                      Remove all
                    </TextLink>
                  )}
                  <Text category="label-medium">
                    {localizeNumber(getRetailerLocations(retailer).length)}
                    {' '}
                    location{getRetailerLocations(retailer).length !== 1 ? 's' : ''}
                  </Text>
                  <HSpacer size="4" />
                  <IconButton
                    onClick={() => setSelectedRetailer(retailer)}
                    testID={`${testID}-select-retailer-button-${retailer.name}`}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
            </Fragment>
          ))}
        </Stack>
      )}
    </>
  );

  const LocationPage = (
    <Stack gap="4px">
      <Divider />
      {!!selectedRetailer && getRetailerLocations(selectedRetailer).map((location) => (
        <Fragment key={location.id}>
          <Stack
            alignItems="center"
            direction="row"
            height="56px"
            justifyContent="space-between"
          >
            <Text category="title-medium">
              {location.name}
            </Text>
            {!readOnly && (
              <TextLink
                onClick={() => handleRemoveLocation(location)}
                testID={`${testID}-remove-location-textlink-${location.id}`}
              >
                Remove
              </TextLink>
            )}
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );


  const getModalContent = () => {
    switch (currentPage) {
      case 'location':
        return LocationPage;
      default:
        return RetailerPage;
    }
  };

  return (
    <Modal
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          variant="outlined"
        >
          Close
        </Button>
      )}
      headerAccessoryLeft={showBackButton && (
        <IconButton
          onClick={() => setSelectedRetailer(undefined)}
          testID={`${testID}-back-button`}
          variant="outlined"
        >
          <ArrowBack />
        </IconButton>
      )}
      headerAccessoryRight={showRemoveAllButton && (
        <Stack direction="row">
          <Button
            onClick={() => handleRemoveRetailer(selectedRetailer!)}
            testID={`${testID}-state-page-remove-all-button`}
            variant="text"
          >
            <DeleteOutlined />
            <HSpacer size="3" />
            Remove all
          </Button>
        </Stack>
      )}
      onClose={onClose}
      open
      testID={testID}
      title={title}
      width={800}
    >
      <Box minHeight={250}>
        {getModalContent()}
      </Box>
    </Modal>
  );
};
