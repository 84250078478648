import { IconButton, Text, VSpacer } from "@/components/DesignSystem";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Divider, Stack } from '@mui/material';

export const IconButtonDemo = () => {
  return (
    <>
      <Text>Standard</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <IconButton testID="icon-button-demo-standard">
          <EditOutlined />
        </IconButton>
        <IconButton disabled testID="icon-button-demo-disabled-standard">
          <EditOutlined />
        </IconButton>
      </Stack>
      <VSpacer size="5" />
      <Text>Filled</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <IconButton testID="icon-button-demo-filled" variant="filled">
          <EditOutlined />
        </IconButton>
        <IconButton disabled testID="icon-button-demo-disabled-filled" variant="filled">
          <EditOutlined />
        </IconButton>
      </Stack>
      <VSpacer size="5" />
      <Text>Outlined</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <IconButton testID="icon-button-demo-outlined" variant="outlined">
          <EditOutlined />
        </IconButton>
        <IconButton disabled testID="icon-button-demo-disabled-outline" variant="outlined">
          <EditOutlined />
        </IconButton>
      </Stack>
    </>
  );
};
