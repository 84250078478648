import { Chip, ChipProps } from '@/components/DesignSystem';
import { PricingRequestStatus, PricingRequestStatusNew } from '@shared/enums';
import {
  getStatusChipPropsFromPRStatus,
  getStatusChipPropsFromPRStatusNew,
} from '@/utilities/PricingRequest';

interface PricingRequestStatusChipProps {
  status: PricingRequestStatus | PricingRequestStatusNew,
  orderCompletedOn?: Date | null,
}

const PricingRequestStatusChip = ({
  status,
  orderCompletedOn,
}: PricingRequestStatusChipProps) => {
  let chipProps: ChipProps;
  const isPricingRequestStatus = Object.values(PricingRequestStatus)
    .includes(status as PricingRequestStatus);
  if (isPricingRequestStatus) {
    chipProps = getStatusChipPropsFromPRStatus(status as PricingRequestStatus);
    if (status === PricingRequestStatus.Closed && !orderCompletedOn) {
      chipProps.label = 'Offer Expired';
    }
  } else {
    chipProps = getStatusChipPropsFromPRStatusNew(status as PricingRequestStatusNew);
  }

  return (
    <Chip
      color={chipProps?.color}
      label={chipProps?.label ?? ''}
      testID={chipProps?.testID ?? 'chip'}
    />
  );
};

export default PricingRequestStatusChip;
