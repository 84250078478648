import { Filter } from "@/components/DesignSystem/Toolbar/interfaces";

export const filters = [
  {
    hideClearButton: true,
    id: 'sortDesc',
    label: 'Sort By',
    options: [
      {
        default: true,
        id: 'asc',
        label: 'A - Z',
      },
      {
        id: 'desc',
        label: 'Z - A',
      },
    ],
    selectionMethod: 'single-select',
  },
  {
    hideClearButton: true,
    id: 'isActive',
    label: 'Status',
    options: [
      {
        default: true,
        id: 'isActive',
        label: 'Active',
      },
      {
        id: 'isInactive',
        label: 'Inactive',
      },
      {
        id: 'all',
        label: 'All',
      },
    ],
    selectionMethod: 'single-select',
  },
] as Filter[];

export const filterOptions = {
  all: 'all',
  isActive: true,
  isInactive: false,
} as const;