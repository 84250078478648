import FarmerDesktopImage from '@/assets/images/farmer-desktop.png';
import { Logo, Text, VSpacer } from '@/components/DesignSystem';
import { AndroidAppDownloadButton } from '@/components/shared/AndroidAppDownloadButton';
import { IosAppDownloadButton } from '@/components/shared/IosAppDownloadButton';
import { AppUrls } from '@/constants/AppUrls';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { Box, Stack } from '@mui/material';
import { Container } from '@mui/system';

const styles: SXStyles = {
  appIcon: {
    borderRadius: '22%',
    display: 'inline-block',
    height: '100px',
    overflow: 'hidden',
    verticalAlign: 'middle',
    width: '100px',
  },
} as const;

export const UnderConstruction = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Box
      minHeight='100vh'
      sx={{ backgroundColor: 'black' }}
    >
      <Stack
        display='flex'
        flexDirection='column'
        minHeight='75vh'
        paddingTop='78px'
        sx={{
          backgroundImage: `url(${FarmerDesktopImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <>
          <Container maxWidth={false} sx={{ maxWidth: 600 + 48, textAlign: 'center' }}>
            <Logo />
            <VSpacer mobileSize="10" size="12" />
            <Text category="h2" fontWeight={600}>
              The web application is being updated and new functionality is coming soon!
            </Text>
            <VSpacer size="10" />
            <Text category="h5">
              Please download the new GROWERS app for farmers or GROWERS Retail app for retailers by
              searching “GROWERS” in the Apple App Store or Google Play Store on your mobile device,
              or click one of the buttons below.
            </Text>
            <VSpacer size="10" />
            <Stack
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
            >
              <Stack alignItems="center" width="40%">
                <Box
                  alt="GROWERS"
                  component="img"
                  src={AppUrls.growersAppIcon}
                  sx={styles.appIcon}
                />
                <VSpacer size="4" />
                <Text category="h4">GROWERS</Text>
                <Stack alignItems="center">
                  <VSpacer size="5" />
                  <IosAppDownloadButton href={AppUrls.iosGrowersAppLink} />
                  <VSpacer size="2" />
                  <AndroidAppDownloadButton href={AppUrls.androidGrowersAppLink} />
                  <VSpacer size="6" />
                </Stack>
              </Stack>
              <Stack alignItems="center" width="40%">
                <Box
                  alt="GROWERS Retail"
                  component="img"
                  src={AppUrls.retailAppIcon}
                  sx={styles.appIcon}
                />
                <VSpacer size="4" />
                <Text category="h4">GROWERS Retail</Text>
                <Stack alignItems="center">
                  <VSpacer size="5" />
                  <IosAppDownloadButton href={AppUrls.iosRetailAppLink} />
                  <VSpacer size="2" />
                  <AndroidAppDownloadButton href={AppUrls.androidRetailAppLink} />
                  <VSpacer size="6" />
                </Stack>
                <VSpacer size="12" />
              </Stack>
            </Stack>
          </Container>
        </>
      </Stack>
    </Box>
  );
};
