import {
  Button,
  HSpacer,
  Input,
  Logo,
  Modal,
  PhoneInput,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { CountySelect } from '@/components/shared/Select/CountySelect';
import { StateSelect } from '@/components/shared/Select/StateSelect';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from "@/constants/Routes";
// import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import OtpForm from '@/pages/Auth/OtpForm';
import { CreateFarmerAccountEventType, useLogEvent } from '@/utilities/Analytics';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { Box, Divider, Stack } from "@mui/material";
import { ApiErrors, GrowersContactInfo, State } from '@shared/enums';
import { validateEmail, validatePhoneNumber } from '@shared/utilities/ValidatorUtils';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

export const FarmerCreateAccountForm = () => {
  const { isMobile } = useMediaQuery();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [state, setState] = useState<State>();
  const [county, setCounty] = useState<string>();
  const [farmName, setFarmName] = useState<string>();
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isDuplicatePhone, setIsDuplicatePhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  // TODO: Uncomment when we have a way to set the preferred sales person id
  // const { preferredLocationId, setPreferredLocationId } = useAuthentication();
  const referralId = searchParams.get('ref');
  const [showOtpForm, setShowOtpForm] = useState(false);
  const subdomain = getSubdomain();

  const navigate = useNavigate();
  const logEvent = useLogEvent();

  useEffect(() => {
    if (referralId) {
      // setPreferredLocationId(referralId);
      logEvent(
        CreateFarmerAccountEventType.CreateNewAccountFromReferral,
        { preferredLocationId: referralId },
      );
    }
  }, []);

  const isFormValid = useCallback(() => {
    return validatePhoneNumber(phoneNumber, AppConfig.env.test)
      && validateEmail(email)
      && !!firstName?.trim()
      && !!lastName?.trim()
      && !!state
      && !!county
      && !!farmName?.trim();
  }, [
    county,
    email,
    farmName,
    firstName,
    lastName,
    phoneNumber,
    state,
  ]);

  const getEmailHelperText = () => {
    if (isDuplicateEmail) {
      return 'This email is already in use, please verify the email you typed in.';
    }
    if (!isValidEmail) {
      return 'A valid email address is required';
    }
    return '';
  };

  const getPhoneHelperText = () => {
    if (isDuplicatePhone) {
      return 'This number is already in use, please verify the number you typed in.';
    }
    if (!isValidPhone) {
      return 'A valid phone number is required';
    }
    return '';
  };

  return (
    <Stack
      direction='row'
      justifyContent='center'
      paddingTop={isMobile ? '0px' : '100px'}
    >
      <Stack maxWidth='512px'>
        <Box
          sx={{
            borderRadius: '12px',
            padding: isMobile ? '16px' : '48px',
          }}
        >
          <Stack direction='row' justifyContent='center'>
            <Logo subdomain={subdomain} />
          </Stack>
          <VSpacer size='9' />
          <Text category='h3' textAlign='center'>Create your account</Text>
          <VSpacer size='4' />
          <Text category='p1' textAlign='center'>Welcome to GROWERS</Text>
          <VSpacer size='9' />
          <Stack spacing={3}>
            <PhoneInput
              error={isDuplicatePhone || !isValidPhone}
              helperText={getPhoneHelperText()}
              label="Phone"
              onBlur={() => setIsValidPhone(validatePhoneNumber(phoneNumber, AppConfig.env.test))}
              onChangeText={setPhoneNumber}
              required
              testID="farmer-create-account-telephone-input"
              value={phoneNumber}
            />
            <Input
              label="First Name"
              onChangeText={setFirstName}
              required
              testID="farmer-create-account-first-name-input"
              value={firstName}
            />
            <Input
              label="Last Name"
              onChangeText={setLastName}
              required
              testID="farmer-create-account-last-name-input"
              value={lastName}
            />
            <StateSelect
              onChangeState={setState}
              required
              testID="farmer-create-account-state-select"
              value={state}
            />
            <CountySelect
              disabled={!state}
              onChangeCounty={setCounty}
              required
              state={state}
              testID="farmer-create-account-county-select"
              value={county}
            />
            <Input
              label="Farm Name"
              onChangeText={setFarmName}
              required
              testID="farmer-create-account-farm-name-input"
              value={farmName}
            />
            <Input
              error={isDuplicateEmail || !isValidEmail}
              helperText={getEmailHelperText()}
              inputMode="email"
              label="Email"
              onBlur={() => setIsValidEmail(validateEmail(email))}
              onChangeText={setEmail}
              required
              testID="farmer-create-account-form-email"
              type="email"
              value={email}
            />
          </Stack>
          <VSpacer size='7' />
          <VSpacer size='2' />
          <Text category='p2'>
            By continuing you agree to the GROWERS&nbsp;
            <TextLink
              href={GrowersContactInfo.privacyPolicyLink}
              target='_blank'
              testID='privacy-policy'
            >
              Privacy policy
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              href={GrowersContactInfo.termsOfUseLink}
              target='_blank'
              testID='tos-link'
            >
              Terms of service
            </TextLink>
          </Text>
          <VSpacer size='8' />
          <Stack direction='row' justifyContent='center'>
            <Button
              disabled={!isFormValid()}
              onClick={() => {
                logEvent(CreateFarmerAccountEventType.ClickConfirmCreateAccount);
                setShowOtpForm(true);
              }}
              testID='create-account-button'
            >
              Send Verification Code
            </Button>
          </Stack>
        </Box>
        <VSpacer mobileSize='8' size='7' />
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Divider sx={{ height: "1px", width: "40%" }} />
          <HSpacer size="6" />
          <Text>
            or
          </Text>
          <HSpacer size="6" />
          <Divider sx={{ height: "1px", width: "40%" }}  />
        </Stack>
        <VSpacer mobileSize='9' size='7' />
        <Stack alignItems="center">
          <Text testID="new-user-text">
            Have an account?
          </Text>
          <TextLink
            category="p2"
            onClick={() => navigate(Routes.LOGIN)}
            testID='login-link'
          >
            Login
          </TextLink>
        </Stack>
        <VSpacer mobileSize='8' size='7' />
      </Stack>
      {showOtpForm && phoneNumber &&
        <Modal
          onClose={() => setShowOtpForm(false)}
          open
          testID="create-farmer-otp-form-modal"
        >
          <OtpForm
            isRegister
            onCancel={() => setShowOtpForm(false)}
            onError={(error: DetailedApiError) => {
              if (error.message === ApiErrors.userWithEmailExists) {
                setShowOtpForm(false);
                setIsDuplicateEmail(true);
                setIsDuplicatePhone(false);
              } else if (error.message === ApiErrors.userWithPhoneExists) {
                setIsDuplicateEmail(false);
                setIsDuplicatePhone(true);
              }
            }}
            telephone={phoneNumber}
            userToCreate={{
              businessName: farmName?.trim(),
              countyId: county,
              email,
              firstName: firstName?.trim(),
              lastName: lastName?.trim(),
              state,
              telephone: phoneNumber,
            }}
          />
        </Modal>
      }
    </Stack>
  );
};
