import {
  Button,
  HSpacer,
  IconButton,
  Modal,
  ProgressLoader,
  Search,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { PromotionChip } from '@/components/shared/Promotion/PromotionChip';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { getFarmerPromotions, getRetailerPromotions } from '@/utilities/Product';
import { ApiProduct, ApiRetailer } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Store from '@mui/icons-material/Store';
import { Avatar, Box, Divider, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { SharedConfig } from '@shared/SharedConfig';
import { localizeNumber } from '@shared/utilities';
import React, { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface ViewSelectedProductsModalProps {
  onChange?: (productIds: string[]) => void,
  onClose: () => void,
  productIds?: string[],
  readOnly?: boolean,
  testID: string,
}

export const ViewSelectedProductsModal = ({
  onChange,
  onClose,
  productIds = [],
  readOnly = false,
  testID,
}: ViewSelectedProductsModalProps) => {
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>(undefined);
  const { debouncedSearch, search, setSearch } = useSearch();

  const currentPage = useMemo(() => {
    if (selectedRetailer !== undefined) {
      return 'product';
    }
    return 'retailer';
  }, [selectedRetailer]);

  const productsCount = productIds.length;

  const { data: products, isFetching: isFetchingProducts } = useQuery(
    [QueryKeys.GET_PRODUCTS, productIds],
    async () => await ProductApi.getProductList({
      id: productIds,
      includeRetailerProducts: true,
      limit: SharedConfig.maxPageLimit,
    }),
    {
      enabled: !!productsCount,
    },
  );

  const selectedRetailerProductIds = selectedRetailer?.productRetailers?.map(
    (productRetailer) => productRetailer.productId,
  ) ?? [];
  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!selectedRetailerProductIds.length,
    },
  );

  const retailerIds = Array.from(new Set(
    products?.data.flatMap((product) => (product.productRetailers ?? []).map(
      (productRetailer) => productRetailer.retailerId)),
  )) ?? [];

  const { data: retailers, isFetching: isFetchingRetailers } = useQuery(
    [QueryKeys.GET_RETAILERS, debouncedSearch, retailerIds],
    () => HierarchyOfRetailersApi.listRetailers({
      id: retailerIds,
      search: debouncedSearch,
      sort: 'name',
      sortDesc: false,
    }),
    { enabled: !!retailerIds.length },
  );

  const getRetailerProducts = (retailer: ApiRetailer) => {
    const retailerProducts = products?.data.filter(
      (product) => product.productRetailers?.some(
        (productRetailer) => productRetailer.retailerId === retailer.id,
      ) ?? false,
    ) ?? [];
    return retailerProducts;
  };

  const title = selectedRetailer
    ? `${selectedRetailer.name} / Products (${getRetailerProducts(selectedRetailer).length})`
    : 'Retailers';

  const showBackButton = currentPage === 'product';

  const handleRemoveRetailer = async (retailer: ApiRetailer) => {
    const retailerProductIds = getRetailerProducts(retailer).map((product) => product.id);
    const newProductIds = productIds.filter((productId) => {
      return !retailerProductIds.includes(productId);
    });
    onChange?.(newProductIds);
  };

  const handleRemoveProduct = (product: ApiProduct) => {
    const newProductIds = productIds.filter((productId) => {
      return productId !== product.id;
    });
    onChange?.(newProductIds);
  };

  const showRemoveAllButton = currentPage === 'product' && !readOnly;

  const RetailerPage = (
    <>
      <Search
        fullWidth
        onChangeText={setSearch}
        testID="view-recipients-search"
        value={search}
      />
      <VSpacer size="4" />
      {(isFetchingProducts || isFetchingRetailers) ? (
        <Stack alignItems="center" pt="80px">
          <ProgressLoader type="circular" />
        </Stack>
      ) : (
        <Stack gap="4px">
          <Divider />
          {retailers?.data.map((retailer) => (
            <Fragment key={retailer.id}>
              <Stack
                alignItems="center"
                direction="row"
                height="56px"
                justifyContent="space-between"
              >
                <Stack alignItems="center" direction="row">
                  <Avatar
                    alt={`${retailer.name} logo`}
                    src={retailer?.image ? `${AppConfig.staticImageHost}/${retailer.image}` : undefined}
                    sx={{ bgcolor: '#EBEBEB' }}
                  >
                    {retailer?.image ? null : <Store />}
                  </Avatar>
                  <HSpacer size="4" />
                  <Text category="title-medium">
                    {retailer.name}
                  </Text>
                </Stack>
                <Stack alignItems="center" direction="row">
                  {!readOnly && (
                    <TextLink
                      category="label-medium"
                      onClick={() => handleRemoveRetailer(retailer)}
                      sx={{ padding: '8px 12px' }}
                      testID={`${testID}-remove-all-textlink-${retailer.name}`}
                    >
                      Remove all
                    </TextLink>
                  )}
                  <Text category="label-medium">
                    {localizeNumber(getRetailerProducts(retailer).length)}
                    {' '}
                    product{getRetailerProducts(retailer).length !== 1 ? 's' : ''}
                  </Text>
                  <HSpacer size="4" />
                  <IconButton
                    onClick={() => setSelectedRetailer(retailer)}
                    testID={`${testID}-select-retailer-button-${retailer.name}`}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
            </Fragment>
          ))}
        </Stack>
      )}
    </>
  );

  const ProductPage = (
    <Stack gap="4px">
      <Divider />
      {!!selectedRetailer && getRetailerProducts(selectedRetailer).map((product) => (
        <Fragment key={product.id}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Stack>
              <Text category="title-medium">
                {product.name}
              </Text>
              {!!(getFarmerPromotions(promotions?.data ?? [], product).length
                || getRetailerPromotions(promotions?.data ?? [], product).length) && (
                <>
                  <VSpacer size="4" />
                  <Stack direction="row">
                    {!!getFarmerPromotions(promotions?.data ?? [], product).length && (
                      <>
                        <PromotionChip
                          promotions={getFarmerPromotions(promotions?.data ?? [], product)}
                          targetUserType={PromotionTargetUserType.Farmer}
                        />
                        <HSpacer size="4" />
                      </>
                    )}
                    {!!getRetailerPromotions(promotions?.data ?? [], product).length && (
                      <PromotionChip
                        promotions={getRetailerPromotions(promotions?.data ?? [], product)}
                        targetUserType={PromotionTargetUserType.Retailer}
                      />
                    )}
                  </Stack>
                </>
              )}
            </Stack>
            {!readOnly && (
              <TextLink
                onClick={() => handleRemoveProduct(product)}
                testID={`${testID}-remove-product-textlink-${product.id}`}
              >
                Remove
              </TextLink>
            )}
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );


  const getModalContent = () => {
    switch (currentPage) {
      case 'product':
        return ProductPage;
      default:
        return RetailerPage;
    }
  };

  return (
    <Modal
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          variant="outlined"
        >
          Close
        </Button>
      )}
      headerAccessoryLeft={showBackButton && (
        <IconButton
          onClick={() => setSelectedRetailer(undefined)}
          testID={`${testID}-back-button`}
          variant="outlined"
        >
          <ArrowBack />
        </IconButton>
      )}
      headerAccessoryRight={showRemoveAllButton && (
        <Stack direction="row">
          <Button
            onClick={() => handleRemoveRetailer(selectedRetailer!)}
            testID={`${testID}-state-page-remove-all-button`}
            variant="text"
          >
            <DeleteOutlined />
            <HSpacer size="3" />
            Remove all
          </Button>
        </Stack>
      )}
      onClose={onClose}
      open
      testID={testID}
      title={title}
      width={800}
    >
      <Box minHeight={250}>
        {getModalContent()}
      </Box>
    </Modal>
  );
};
