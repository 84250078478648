import { Box, Stack, Tab } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import Circle from '@mui/icons-material/Circle';
import { Tabs, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';

export const TabsDemo = () => {

  const [tabsValue, setTabsValue] = useState<number>(1);

  const CircleIcon = () => <Circle sx={{
    width: "12px",
    height: "12px",
  }} />;

  return (
    <Box p={2}>
      <DesktopOnly>
        <Text>
          Make your screen smaller to see the mobile view
        </Text>
      </DesktopOnly>
      <MobileOnly>
        <Text>
          Make your screen bigger to see the desktop view
        </Text>
      </MobileOnly>
      <VSpacer size="6" />
      <DemoBlock label="No Icon">
        <Stack width="375px">
          <Tabs
            onChange={(_, value) => setTabsValue(value)}
            testID="demo1"
            value={tabsValue}>
            <Tab label="Tab"  />
            <Tab label="Tab" />
            <Tab label="Tab" />
          </Tabs>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Icons and Labels">
        <Stack width="375px">
          <Tabs
            onChange={(_, value) => setTabsValue(value)}
            testID="demo2"
            value={tabsValue}>
          <Tab label={
            <Stack alignItems="center">
              <CircleIcon />
              <VSpacer size="3" />
              Tab
            </Stack>
          }/>
          <Tab label={
            <Stack alignItems="center">
              <CircleIcon />
              <VSpacer size="3" />
              Tab
            </Stack>
          }/>
          <Tab label={
            <Stack alignItems="center">
              <CircleIcon />
              <VSpacer size="3" />
              Tab
            </Stack>
          }/>
          </Tabs>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Icons only">
        <Stack width="375px">
          <Tabs
            onChange={(_, value) => setTabsValue(value)}
            testID="demo3"
            value={tabsValue}>
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
          </Tabs>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Centered">
        <Stack width="375px">
          <Tabs
            justifyContent="center"
            onChange={(_, value) => setTabsValue(value)}
            testID="demo4"
            value={tabsValue}>
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
          </Tabs>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Right Aligned">
        <Stack width="375px">
          <Tabs
            justifyContent="end"
            onChange={(_, value) => setTabsValue(value)}
            testID="demo5"
            value={tabsValue}>
            <Tab label={
              <Stack alignItems="center">
                <CircleIcon />
                <VSpacer size="3" />
                Tab
              </Stack>
            }/>
            <Tab label={
              <Stack alignItems="center">
                <CircleIcon />
                <VSpacer size="3" />
                Tab
              </Stack>
            }/>
            <Tab label={
              <Stack alignItems="center">
                <CircleIcon />
                <VSpacer size="3" />
                Tab
              </Stack>
            }/>
          </Tabs>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Dense">
        <Stack width="375px">
          <Tabs
            dense
            onChange={(_, value) => setTabsValue(value)}
            testID="demo6"
            value={tabsValue}>
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
            <Tab label={<CircleIcon />} />
          </Tabs>
        </Stack>
      </DemoBlock>
    </Box>
  );
};
