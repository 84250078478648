import { Client } from './Client';
import { FeatureFlagEndpoint } from '@api/endpoints';

export class FeatureFlagApi {
  static list (): Promise<FeatureFlagEndpoint.List.Response> {
    return Client('feature-flags');
  }
  static update (
    id: string,
    body: FeatureFlagEndpoint.Update.Request,
  ): Promise<FeatureFlagEndpoint.Update.Response> {
    return Client(`feature-flags/${id}`, {
      method: 'PATCH',
      body,
    });
  }
}
