import { Button, DataPoint } from '@/components/DesignSystem';
import { PaymentTypePicker } from '@/components/shared/Picker/PaymentTypePicker';
import { FriendlyPaymentType } from '@/constants/FriendlyPaymentType';
import Stack from '@mui/material/Stack';
import { ActivePaymentType, PaymentType } from '@shared/enums';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const PaymentTypePickerDemo = () => {
  const [paymentType, setPaymentType] = useState<ActivePaymentType>(PaymentType.Finance);
  const [open, setOpen] = useState(false);

  return (
    <DemoBlock label="Payment Picker">
      <Stack spacing={2}>
        <Button onClick={() => setOpen(true)} testID="test">
          Open
        </Button>
        <PaymentTypePicker
          onChange={(item) => setPaymentType(item)}
          onClose={() => setOpen(false)}
          open={open}
          paymentType={paymentType}
          testID="payment-picker"
        />
        <DataPoint label="Payment type:" size="large" testID="test">
          {FriendlyPaymentType[paymentType]}
        </DataPoint>
      </Stack>
    </DemoBlock>
  );
};
