import { MenuItem, Select } from '../../DesignSystem';
import { SelectProps } from '../../DesignSystem/Select/Select';
import { PackageType } from '@shared/enums';

interface PackageTypeSelectProps extends SelectProps {
  allowedPackageTypes?: string[],
  onChangePackageType: (packageType: PackageType) => void,
  packageType: string,
}

export const PackageTypeSelect = ({
  allowedPackageTypes,
  onChangePackageType,
  packageType,
  ...rest
}: PackageTypeSelectProps) => {
  const options = Object.values(PackageType).map((pt) => pt);

  const filteredOptions = options.filter((option) => (
    allowedPackageTypes?.length
      ? allowedPackageTypes?.includes(option)
      : true
  ));

  return (
    <Select
      label="Package type (optional)"
      onChangeValue={(val) => onChangePackageType(val as PackageType)}
      value={packageType}
      {...rest}
    >
      {filteredOptions.map((option, idx) => (
        <MenuItem
          key={option}
          testID={`package-type-option-${idx}`}
          value={option}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};