import { DataPoint, Text, Toolbar, VSpacer } from "@/components/DesignSystem";
import {
  Filter,
  FilterResult,
} from "@/components/DesignSystem/Toolbar/interfaces";
import { Stack } from '@mui/material';
import { PriceOfferStatusFilter } from "@shared/enums/PriceOfferStatusFilter";
import { useCallback, useState } from "react";

const filters: Filter[] = [
  {
    id: 'sortBy',
    label: 'Sort By',
    options: [
      { id: 'alpha', label: 'A-Z', default: true },
      { id: 'location', label: 'Location' },
      { id: 'time', label: 'Time' },
    ],
    selectionMethod: 'single-select',
    hideClearButton: true,
  },
  {
    id: 'offerStatus',
    label: 'Status',
    options: Object.values(PriceOfferStatusFilter).map((value) => ({
      id: value.toString(),
      label: value.toString(),
    })),
    selectionMethod: 'single-select',
  },
  {
    id: 'locations',
    label: 'Locations',
    options: [
      { id: 'North Caldwell', label: 'North Caldwell' },
      { id: 'Wallace', label: 'Wallace' },
      { id: 'Sun City', label: 'Sun City' },
      { id: 'Lewiston', label: 'Lewiston' },
      { id: 'Newdale', label: 'Newdale' },
      { id: 'Georgetown', label: 'Georgetown' },
    ],
    selectionMethod: 'chips',
  },
  {
    id: 'retailers',
    label: 'Retailers',
    options: [
      { id: 'A+ Agriculture', label: 'A+ Agriculture' },
      { id: 'Ag Retail Plus', label: 'Ag Retail Plus' },
      { id: 'Easthampton Agri Supply', label: 'Easthampton Agri Supply' },
      { id: 'Firestone Feed & Farm', label: 'Firestone Feed & Farm' },
      { id: 'Grows in Rows Retail', label: 'Grows in Rows Retail' },
      { id: 'Hardwick Farmer Co-Op', label: 'Hardwick Farmer Co-Op' },
      { id: 'Landus Ag', label: 'Landus Ag' },
    ],
    selectionMethod: 'multi-select',
  },
  {
    helperText: 'Showing retailers with \'preferred\' status',
    id: 'retailer-locations',
    label: 'Retailer Locations',
    options: [
      { id: 'California', label: 'California', subOptionLabel: 'Cities', subOptions: [
        { id: 'Fresno', label: 'Fresno' },
        { id: 'Los Angeles', label: 'Los Angeles' },
        { id: 'Sacramento', label: 'Sacramento' },
        { id: 'San Diego', label: 'San Diego' },
        { id: 'San Jose', label: 'San Jose' },
        { id: 'San Francisco', label: 'San Francisco' },
      ] },
      { id: 'North Carolina', label: 'North Carolina', subOptionLabel: 'Cities', subOptions: [
        { id: 'Cary', label: 'Cary' },
        { id: 'Chapel Hill', label: 'Chapel Hill' },
        { id: 'Durham', label: 'Durham' },
        { id: 'Fayetteville', label: 'Fayetteville' },
        { id: 'Greenville', label: 'Greenville' },
        { id: 'Raleigh', label: 'Raleigh' },
      ] },
      { id: 'Ohio', label: 'Ohio', subOptionLabel: 'Cities', subOptions: [
        { id: 'Akron', label: 'Akron' },
        { id: 'Cincinnati', label: 'Cincinnati' },
        { id: 'Cleveland', label: 'Cleveland' },
        { id: 'Columbus', label: 'Columbus' },
        { id: 'Dayton', label: 'Dayton' },
        { id: 'Toledo', label: 'Toledo' },
      ] },
    ],
    selectionMethod: 'multi-select',
  },
  {
    id: 'label',
    label: 'Label',
    options: [
      { id: 'Accepted', label: 'Accepted' },
      { id: 'Open', label: 'Open' },
      { id: 'Closed', label: 'Closed' },
    ],
    selectionMethod: 'segmented-button',
  },
  {
    id: 'preferred',
    label: 'Preferred',
    options: [{ id: 'preferred', label: 'Preferred' }],
    selectionMethod: 'boolean',
  },
];


export const ToolbarDemo = () => {
  const [filterResult, setFilterResult] = useState<FilterResult>({});

  const handleChange = useCallback((result: FilterResult) => setFilterResult(result), []);

  return (
    <Stack>
      <Toolbar
        filters={filters}
        onChange={handleChange}
        testID="demo-toolbar"
      />
      <VSpacer size="5" />
      <Text category="label-large">
        Returned search:
        <DataPoint
          label="search:"
          testID="demo-search-result"
        >
          {filterResult.search}
        </DataPoint>
      </Text>
      <Text category="label-large">
        Returned filter selection:
        {filterResult.selections && (
          Array.from(filterResult.selections, ([filterId, selection]) => (
            <DataPoint
              key={filterId}
              label={`${filterId}:`}
              spacing="fixed"
              testID="demo-filter-result"
            >
              {Array.from(selection).join(', ')}
            </DataPoint>
          )))}
      </Text>
    </Stack>
  );
};
