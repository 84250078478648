import { Stack, Divider, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Button,
  FullscreenDialog,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { Modal as MuiModal } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Today from '@mui/icons-material/Today';
import './DatePickerStyles.css';
import { DatePickerCalendar } from './DatePickerCalendar';
import { DatePickerInput } from './DatePickerInput';
import Close from '@mui/icons-material/Close';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

const styles: SXStyles = {
  bottomBar: {
    alignItems: "center",
    height: 52,
    justifyContent: "flex-end",
    padding: "2.4px 12px 8px 12px",
  },
  calendarModeHeader: {
    alignItems: "center",
    direction: "row",
    height: 48,
    justifyContent: "space-between",
    padding: "16px 10.4px 0 10.4px",
  },
  calendarModeTitle: {
    justifyContent: "space-between",
    paddingLeft: "64px",
    paddingRight: "16.8px",
  },
  container: {
    boxShadow: 24,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  inputModeHeader: {
    padding: "16px 24px 0 24px",
  },
  inputModeContainer: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
} as const;

export interface DateRangePickerModalProps {
  endDate?: Date,
  onChange: (startDate?: Date, endDate?: Date) => void,
  onClose: () => void,
  open?: boolean,
  showCalendarMode?: boolean,
  startDate?: Date,
  testID: string,
  title: string,
}

interface DatePickerProps {
  calendarMode: boolean,
  endDate?: Date,
  onChangeCalendarMode: (calendarMode: boolean) => void,
  onChangeEndDate: (date?: Date) => void,
  onChangeStartDate: (date?: Date) => void,
  onClose: () => void,
  onSave: () => void,
  startDate?: Date,
  testID: string,
  title: string,
}

const DatePicker = ({
  calendarMode,
  endDate,
  onChangeCalendarMode,
  onChangeEndDate,
  onChangeStartDate,
  onClose,
  onSave,
  startDate,
  testID,
  title,
}: DatePickerProps) => {
  const handleStartDateChange = (value?: Date) => {
    if (!!value && !!endDate && value > endDate) {
      onChangeStartDate(new Date(endDate.getTime()));
    } else {
      onChangeStartDate(value);
    }
  };

  const handleEndDateChange = (value?: Date) => {
    if (!!value && !!startDate && value < startDate) {
      onChangeEndDate(new Date(startDate.getTime()));
    } else {
      onChangeEndDate(value);
    }
  };

  const formattedDates = useMemo(() => {
    const formattedStartDate = startDate
      ? DateTime.fromJSDate(startDate).toFormat("LLL dd")
      : "";
    const formattedEndDate = endDate
      ? DateTime.fromJSDate(endDate).toFormat("LLL dd")
      : "";
    return `${formattedStartDate}${formattedStartDate && formattedEndDate ? " - " : ""}${formattedEndDate}`;
  }, [startDate, endDate]);

  return (
    <Stack>
      {calendarMode && (
        <>
          <DesktopOnly>
            <Stack direction="row" sx={styles.calendarModeHeader}>
              <IconButton onClick={onClose} testID={`${testID}-close`}>
                <Close />
              </IconButton>
              <Button
                onClick={onSave}
                testID={`${testID}-save`}
                variant="text"
              >
                Save
              </Button>
            </Stack>
          </DesktopOnly>
          <Stack direction="row" sx={styles.calendarModeTitle}>
            <Stack>
              <Text category="label-large">
                {title}
              </Text>
              <Text category="title-large">
                {formattedDates}
              </Text>
            </Stack>
            <IconButton
              onClick={() => onChangeCalendarMode(!calendarMode)}
              testID={`${testID}-mode-toggle`}
            >
              <Edit />
            </IconButton>
          </Stack>
        </>
      )}
      {!calendarMode && (
        <Box sx={styles.inputModeHeader}>
          <Text category="label-large">
            Select date
          </Text>
          <VSpacer size="8" />
          <VSpacer size="2" />
          <Stack direction="row" justifyContent="space-between">
            <Text category="headline-large">
              Enter dates
            </Text>
            <IconButton
              onClick={() => onChangeCalendarMode(!calendarMode)}
              testID={`${testID}-mode-toggle`}
            >
              <Today />
            </IconButton>
          </Stack>
        </Box>
      )}
      <VSpacer size="5" />
      <Divider />
      {calendarMode ? (
        <DatePickerCalendar
          endDate={endDate}
          internalEndDate={endDate}
          internalStartDate={startDate}
          onChange={(startDate, endDate) => {
            onChangeStartDate(startDate);
            onChangeEndDate(endDate);
          }}
          startDate={startDate}
          testID={testID}
          variant="modal-range"
        />
      ) : (
        <Box sx={styles.inputModeContainer}>
          <VSpacer size="5" />
          <Stack direction="row">
            <DatePickerInput
              label="Start date"
              onChangeDate={handleStartDateChange}
              testID={`${testID}-start-date-input`}
              value={startDate}
              width={134}
            />
            <HSpacer size="4" />
            <DatePickerInput
              label="End date"
              onChangeDate={handleEndDateChange}
              testID={`${testID}-end-date-input`}
              value={endDate}
              width={134}
            />
          </Stack>
          <VSpacer size="6" />
        </Box>
      )}
      {calendarMode && <Divider />}
      <Stack
        direction="row"
        sx={styles.bottomBar}
      >
        <Button
          onClick={onClose}
          testID={`${testID}-cancel-button`}
          variant="text"
        >
          Cancel
        </Button>
        <HSpacer size="2" />
        <Button
          onClick={onSave}
          testID={`${testID}-ok-button`}
          variant="text"
        >
          OK
        </Button>
      </Stack>
    </Stack>
  );
};

export const DateRangePickerModal = ({
  endDate,
  onChange,
  onClose,
  open = false,
  showCalendarMode = false,
  startDate,
  testID,
  title,
}: DateRangePickerModalProps) => {

  const [calendarMode, setCalendarMode] = useState(showCalendarMode);
  const [internalStartDate, setInternalStartDate] = useState(startDate);
  const [internalEndDate, setInternalEndDate] = useState(endDate);

  const handleSave = () => {
    onChange(internalStartDate, internalEndDate);
    onClose();
  };

  return (
    <>
      <DesktopOnly>
        <MuiModal
          data-testid={testID}
          onClose={onClose}
          open={open}
        >
          <Box sx={{
            ...styles.container,
            borderRadius: calendarMode ? 0 : "28px",
            width: calendarMode ? 360 : 328,
          }}>
            <DatePicker
              calendarMode={calendarMode}
              endDate={internalEndDate}
              onChangeCalendarMode={setCalendarMode}
              onChangeEndDate={setInternalEndDate}
              onChangeStartDate={setInternalStartDate}
              onClose={onClose}
              onSave={handleSave}
              startDate={internalStartDate}
              testID={testID}
              title={title}
            />
          </Box>
        </MuiModal>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={
            <Button
              onClick={handleSave}
              testID={`${testID}-save`}
              variant="text"
            >
              Save
            </Button>
          }
          contentStyle={{ padding: 0, zIndex: 0 }}
          onClose={onClose}
          open={open}
          testID={testID}
        >
          <DatePicker
            calendarMode={calendarMode}
            endDate={internalEndDate}
            onChangeCalendarMode={setCalendarMode}
            onChangeEndDate={setInternalEndDate}
            onChangeStartDate={setInternalStartDate}
            onClose={onClose}
            onSave={handleSave}
            startDate={internalStartDate}
            testID={testID}
            title={title}
          />
        </FullscreenDialog>
      </MobileOnly>
    </>
  );
};
