import {
  Button,
  DatePickerDropdown,
  HSpacer,
  Input,
  Modal,
  NumericInput,
  ProgressLoader,
  SegmentedButton,
  Text,
  TextAreaInput,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { InfoTooltip } from '@/components/DesignSystem/InfoTooltip/InfoTooltip';
import {
  ViewSelectedLocationsModal,
} from '@/components/shared/ListSelector/ViewSelectedLocationsModal';
import {
  ViewSelectedProductsModal,
} from '@/components/shared/ListSelector/ViewSelectedProductsModal';
import { QueryKeys } from '@/constants/QueryKeys';
import { AddLocationsDialog } from '@/pages/Admin/Promotions/AddLocationsDialog';
import { AddProductsDialog } from '@/pages/Admin/Promotions/AddProductsDialog';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Stack } from '@mui/material';
import { SponsorType } from '@shared/enums';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly, pick } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

export type Mode = 'add' | 'edit' | 'view';

interface PromotionModalProps {
  mode: Mode,
  onClose: () => void,
  onSave: (promotion: PromotionInputs) => void,
  promotionIdToEdit?: string,
  testID: string,
}

export type PromotionInputs = {
  description: string,
  endDate?: Date,
  id?: string,
  locationIds: string[],
  name: string,
  productIds: string[],
  sponsorType: SponsorType,
  startDate?: Date,
  targetUserType: PromotionTargetUserType,
  value?: number,
}

function getDate (dateString: string) {
  return DateTime.fromISO(dateString).toJSDate();
}

export const PromotionModal = ({
  mode,
  onClose,
  onSave,
  promotionIdToEdit,
  testID,
}: PromotionModalProps) => {
  const defaultPromotion = {
    id: undefined,
    description: '',
    name: '',
    sponsorType: SponsorType.Retailer,
    targetUserType: PromotionTargetUserType.Farmer,
    locationIds: [],
    productIds: [],
    startDate: undefined,
    endDate: undefined,
    value: undefined,
  };
  const [showSelectProductsModal, setShowSelectProductsModal] = useState(false);
  const [showSelectLocationsModal, setShowSelectLocationsModal] = useState(false);
  const [selectedRecipientIndex, setSelectedRecipientIndex] = useState<number | null>(null);
  const [selectedSponsorIndex, setSelectedSponsorIndex] = useState<number | null>(null);
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedMode, setSelectedMode] = useState<Mode>(mode);
  const [initialPromotion, setInitialPromotion] = useState<PromotionInputs>(defaultPromotion);
  const [promotion, setPromotion] = useState<PromotionInputs>(defaultPromotion);

  const { data: promotionToEdit, isFetching: isPromotionFetching } = useQuery(
    [QueryKeys.GET_PROMOTION, promotionIdToEdit],
    async () => await PromotionApi.get(promotionIdToEdit!),
    {
      enabled: !!promotionIdToEdit,
    },
  );

  useEffect(() => {
    if (promotionToEdit) {
      const promotion = {
        locationIds: promotionToEdit.locationIds ?? [],
        productIds: promotionToEdit.productIds ?? [],
        startDate: getDate(promotionToEdit.startDate),
        endDate: getDate(promotionToEdit.endDate),
        ...pick(
          promotionToEdit,
          ['id', 'description', 'name', 'sponsorType', 'targetUserType', 'value'],
        ),
      };
      setPromotion(promotion);
      setInitialPromotion(promotion);
      setSelectedRecipientIndex(
        promotion.targetUserType === PromotionTargetUserType.Farmer ? 0 : 1,
      );
      setSelectedSponsorIndex(
        promotion.sponsorType === SponsorType.Retailer ? 0 : 1,
      );
    }
  }, [promotionToEdit, selectedMode]);

  const statuses = promotionToEdit && getStatuses(promotionToEdit);
  const canBeEdited = !!statuses?.includes(PromotionStatus.FUTURE);

  const isStartDateValid = useMemo(() => (
    !!promotion.startDate
    && formatDateOnly(promotion.startDate) >= formatDateOnly(new Date())
  ), [promotion.startDate]);

  const isEndDateValid = useMemo(() => (
    !!promotion.endDate
    && formatDateOnly(promotion.endDate) >= formatDateOnly(new Date())
    && formatDateOnly(promotion.endDate) >= formatDateOnly(promotion.startDate ?? new Date())
  ), [promotion.startDate, promotion.endDate]);

  const readOnly = selectedMode === 'view';
  const isFormValid = (
    !!promotion.sponsorType
    && !!promotion.targetUserType
    && !!promotion.name
    && !!promotion.description
    && !!promotion.productIds?.length
    && !!promotion.locationIds?.length
    && !!promotion.value
    && isStartDateValid
    && isEndDateValid
    && !readOnly
  );

  const SponsorSelector = (
    <Stack>
      <Stack alignItems="center" direction="row">
        <Text category="body-large">
          Promotion Sponsor
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          Select the entity sponsoring the promotion. Points earned from this promotion will be
          paid for by the selected entity. For example: a retailer may be offering a promotion to
          their customers that is sponsored by the manufacturer. In this case, the points earned
          will be deposited into the farmers "Manufacturer" wallet with that retailer and when
          applied to an order, the manufacturer will pay for the cost of those points.
        </InfoTooltip>
      </Stack>
      <VSpacer size="3" />
      <Stack direction="row" width="500px">
        <SegmentedButton
          color="primary"
          disabled={readOnly}
          fullWidth
          selectedIndex={selectedSponsorIndex}
        >
          <Button
            onClick={() => {
              setSelectedSponsorIndex(0);
              setPromotion({ ...promotion, sponsorType: SponsorType.Retailer });
            }}
            startIcon={selectedSponsorIndex === 0 && <Check />}
            testID="retailer-sponsor-button"
          >
            Retailer
          </Button>
          <Button
            onClick={() => {
              setSelectedSponsorIndex(1);
              setPromotion({ ...promotion, sponsorType: SponsorType.Manufacturer });
            }}
            startIcon={selectedSponsorIndex === 1 && <Check />}
            testID="manufacturer-sponsor-button"
          >
            Manufacturer
          </Button>
        </SegmentedButton>
      </Stack>
    </Stack>
  );

  const TypeSelector = (
    <Stack>
      <Stack alignItems="center" direction="row">
        <Text category="body-large">
          Promotion Recipient
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          Farmer promotions display in GROWERS and the farmer will receive the promotion loyalty %
          when they purchase the product. Salesperson promotions are restricted to only display in
          GROWERS Retail and are used to inform the salesperson of which products they should push
          in new orders.
        </InfoTooltip>
      </Stack>
      <VSpacer size="3" />
      <Stack direction="row" width="500px">
        <SegmentedButton
          color="primary"
          disabled={readOnly}
          fullWidth
          selectedIndex={selectedRecipientIndex}
        >
          <Button
            onClick={() => {
              setSelectedRecipientIndex(0);
              setPromotion({ ...promotion, targetUserType: PromotionTargetUserType.Farmer });
            }}
            startIcon={selectedRecipientIndex === 0 && <Check />}
            testID="farmer-recipient-type-button"
          >
            Farmer
          </Button>
          <Button
            onClick={() => {
              setSelectedRecipientIndex(1);
              setPromotion({ ...promotion, targetUserType: PromotionTargetUserType.Retailer });
            }}
            startIcon={selectedRecipientIndex === 1 && <Check />}
            testID="salesperson-recipient-type-button"
          >
            Salesperson
          </Button>
        </SegmentedButton>
      </Stack>
    </Stack>
  );

  const selectedProductsCount = promotion.productIds.length;
  const selectedLocationsCount = promotion.locationIds.length;
  const ProductSelector = (
    <Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Stack alignItems="center" direction="row">
            <Text category="body-large">
              Products
            </Text>
            <HSpacer size="3" />
            <InfoTooltip>
              Select the product(s) that the promotion applies to. For example: if a manufacturer
              is running a promotion on all/some of their products, you must select all of the
              products that are included in the promotion. Also, if a promotion is running on a
              particular product category, you must filter for that category and then select all
              of the products that are included in the promotion.
            </InfoTooltip>
          </Stack>
          {!!selectedProductsCount && (
            <>
              <VSpacer size="3" />
              <TextLink
                category="title-small"
                onClick={() => setShowProductsModal(true)}
                testID="view-locations-button"
              >
                View {selectedProductsCount} product{selectedProductsCount > 1 ? 's' : ''}
              </TextLink>
            </>
          )}
        </Stack>
        {!readOnly && (
          <Stack direction="row">
            <Button
              onClick={() => setShowSelectProductsModal(true)}
              startIcon={<Add />}
              testID={`${testID}-select-products-button`}
              variant="outlined"
            >
              Add
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  const LocationSelector = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Stack>
        <Stack alignItems="center" direction="row">
          <Text category="body-large">
            Retailer(s) & Locations
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Select each retailer and its location(s) that are offering this promotion.
            All selected locations will display the promotion in GROWERS and GROWERS Retail
            (as required) when it is actively running.
          </InfoTooltip>
        </Stack>
        {!!selectedLocationsCount && (
          <>
            <VSpacer size="3" />
            <TextLink
              category="title-small"
              onClick={() => setShowLocationsModal(true)}
              testID="view-locations-button"
            >
              View {selectedLocationsCount} retail location{selectedLocationsCount > 1 ? 's' : ''}
            </TextLink>
          </>
        )}
      </Stack>
      {!readOnly && (
        <Stack direction="row">
          <Button
            onClick={() => setShowSelectLocationsModal(true)}
            startIcon={<Add />}
            testID={`${testID}-select-locations-button`}
            variant="outlined"
          >
            Add
          </Button>
        </Stack>
      )}
    </Stack>
  );

  const Inputs = (
    <>
      <Stack>
        <Stack alignItems="center" direction="row">
          <Text category="body-large">Running dates</Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Select the dates when the promotion will be actively running.
          </InfoTooltip>
        </Stack>
        <VSpacer size="3" />
        <Stack alignSelf="flex-start" direction="row">
          <DatePickerDropdown
            disabled={readOnly}
            errorMessage={(readOnly || isStartDateValid || !promotion.startDate)
              ? undefined
              : 'Invalid start date'}
            label="Start date"
            onChange={(startDate) => setPromotion({ ...promotion, startDate })}
            testID={`${testID}-start-date`}
            value={promotion.startDate}
            width={200}
          />
          <HSpacer size="7" />
          <DatePickerDropdown
            disabled={readOnly}
            errorMessage={(readOnly || isEndDateValid || !promotion.endDate)
              ? undefined
              : 'Invalid end date'}
            label="End date"
            onChange={(endDate) => setPromotion({ ...promotion, endDate })}
            testID={`${testID}-end-date`}
            value={promotion.endDate}
            width={200}
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack alignItems="center" direction="row">
          <Text category="body-large">Loyalty percentage</Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Provide the "cash back points" percentage that the customer will earn when they
            qualify for this promotion on a completed order.
          </InfoTooltip>
        </Stack>
        <VSpacer size="3" />
        <NumericInput
          decimals={3}
          disabled={readOnly}
          maxValue={100}
          minValue={0}
          onChangeNumber={(number) => setPromotion({
            ...promotion,
            value: number ? Number((number / 100).toFixed(5)) : undefined,
          })}
          placeholder="%"
          showFixedDecimals
          suffix="%"
          sx={{ padding: "4px 0 5px" }}
          testID="loyalty-percentage-input"
          value={promotion.value ? promotion.value * 100 : undefined}
          width={210}
        />
      </Stack>
      <TextAreaInput
        disabled={readOnly}
        label="Description"
        maxCharacterLimit={500}
        onChangeText={(description) => setPromotion({ ...promotion, description })}
        showCharacterCountdown
        testID={`${testID}-description`}
        value={promotion.description}
      />
    </>
  );

  return (
    <>
      <Modal
        acceptButton={readOnly ? undefined : (props) => (
          <Button
            {...props}
            disabled={!isFormValid}
            onClick={() => {
              onClose();
              onSave(promotion);
            }}
            variant="contained"
          >
            Save
          </Button>
        )}
        cancelButton={(props) => (
          <Button
            {...props}
            onClick={() => {
              if (selectedMode === 'edit') {
                setSelectedMode('view');
                setPromotion(initialPromotion);
              } else {
                onClose();
              }
            }}
            sx={{ color: '#D8D8D8' }}
          >
            {readOnly ? 'Close' : 'Cancel'}
          </Button>
        )}
        headerAccessoryRight={
          canBeEdited && selectedMode !== 'edit' && (
            <Button
              onClick={() => setSelectedMode('edit')}
              startIcon={<EditIcon />}
              testID={`${testID}-edit-mode`}
            >
              Edit
            </Button>
          )
        }
        onClose={onClose}
        open
        testID={testID}
        title={`${selectedMode.charAt(0).toUpperCase() + selectedMode.slice(1)} Promotion`}
        width={560}
      >
        {isPromotionFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : (
          <Stack gap="24px">
            {TypeSelector}
            {SponsorSelector}
            <Input
              disabled={readOnly}
              isTransparent={readOnly}
              label="Promotion Name"
              maxCharacterLimit={75}
              onChangeText={(name) => setPromotion({ ...promotion, name })}
              testID={`${testID}-name`}
              value={promotion.name}
              width="500px"
            />
            {LocationSelector}
            {ProductSelector}
            {Inputs}
          </Stack>
        )}
      </Modal>
      {showSelectProductsModal && (
        <AddProductsDialog
          close={() => setShowSelectProductsModal(false)}
          onChange={(productIds) => setPromotion({ ...promotion, productIds })}
          selectedProductIds={promotion.productIds}
        />
      )}
      {showSelectLocationsModal && (
        <AddLocationsDialog
          close={() => setShowSelectLocationsModal(false)}
          onChange={(locationIds) => setPromotion({ ...promotion, locationIds })}
          selectedLocationIds={promotion.locationIds}
        />
      )}
      {showLocationsModal && (
        <ViewSelectedLocationsModal
          locationIds={promotion.locationIds}
          onChange={(locationIds) => setPromotion({ ...promotion, locationIds })}
          onClose={() => setShowLocationsModal(false)}
          readOnly={readOnly}
          testID="promotion-locations"
        />
      )}
      {showProductsModal && (
        <ViewSelectedProductsModal
          onChange={(productIds) => setPromotion({ ...promotion, productIds })}
          onClose={() => setShowProductsModal(false)}
          productIds={promotion.productIds}
          readOnly={readOnly}
          testID="promotion-products"
        />
      )}
    </>
  );
};
