import React from 'react';
import { SideSheet, Text } from '@/components/DesignSystem';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { ChatLogsApi } from '@/utilities/api/ChatLogsApi';
import { DateTime } from 'luxon';

const staticStyle = {
  namePadding: '5px 20px',
  messagePadding: '15px 20px',
  borderRadius: '25px',
};

interface IProcessedChatLog {
  sender: string;
  name: string;
  message: string;
  timestamp: string;
}

const ChatLogs = ({
  chatCredentials,
  openChat,
  setOpenChat,
  title,
}: {
  chatCredentials: {
    chatId: string;
    publicId: string
  };
  openChat: boolean;
  setOpenChat: (args: boolean) => void;
  title?: string;
}) => {
  const theme = useTheme();
  const { data: chatLogs, isFetching } = useQuery([], () =>
    ChatLogsApi.getChatLogs(chatCredentials?.chatId, String(chatCredentials?.publicId)),
  {
    cacheTime: 0,
    retry: false,
  },
  );

  const processedChatLogs = chatLogs?.messages?.map((message) => {
    const isFarmer = message?.sender?._id === chatLogs?.farmer.id;
    return {
      sender: isFarmer ? 'farmer' : 'retailer',
      name: isFarmer ? chatLogs?.farmer?.name : chatLogs?.retailer?.name,
      message: message?.message,
      timestamp: DateTime.fromISO(
        message?.createdAt,
      ).toFormat("ccc, LLLL d 'at' h:mma"),
    };
  });

  const filteredNonEmptyMessages = processedChatLogs?.filter(
    ({ message }) => message,
  );

  return (
    <>
      <SideSheet
        hideScrollbar
        isBorderless
        onClose={() => setOpenChat(false)}
        onOpen={() => setOpenChat(true)}
        open={openChat}
        testID='sidesheet'
        title={title ?? `Product #${chatCredentials?.publicId} Chat Log`}
        width={'425px'}
      >
        <Stack p='12px 12px 20px 18px'>
          {isFetching && (
            <Stack
              alignItems='center'
              display='flex'
              justifyContent='center'
              p='32px 0px 0px 0px'
            >
              <CircularProgress sx={{ color: 'yellow' }} />
            </Stack>
          )}
          {!isFetching &&
            (filteredNonEmptyMessages?.length === 0 || !filteredNonEmptyMessages) && (
              <Text
                display={'flex'}
                justifyContent='center'
                margin={'10px 0px'}
              >
                No chats yet
              </Text>
          )}
          <Stack gap={2}>
            {!!filteredNonEmptyMessages?.length &&
              filteredNonEmptyMessages?.map((processedChatLog: IProcessedChatLog) => {
                return (
                  <>
                    <Text
                      category='label-medium'
                      display={'flex'}
                      justifyContent='center'
                      margin={'10px 0px'}
                    >
                      {processedChatLog?.timestamp}
                    </Text>
                    {processedChatLog.sender === 'retailer' ? (
                      <Stack maxWidth='80%' sx={{ marginRight: 'auto' }}>
                        <Text
                          category='label-medium'
                          sx={{
                            textAlign: 'left',
                            padding: staticStyle.namePadding,
                          }}
                        >
                          {processedChatLog?.name}
                        </Text>
                        <Typography
                          sx={{
                            backgroundColor: theme.palette.background.default,
                            padding: staticStyle.messagePadding,
                            borderRadius: staticStyle.borderRadius,
                            wordBreak: 'break-word',
                          }}
                        >
                          {processedChatLog?.message}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        maxWidth='80%'
                        minWidth={'30%'}
                        sx={{ marginLeft: 'auto' }}
                      >
                        <Text
                          category='label-medium'
                          sx={{
                            textAlign: 'right',
                            padding: staticStyle.namePadding,
                          }}
                        >
                          {processedChatLog?.name}
                        </Text>
                        <Typography
                          sx={{
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: staticStyle.borderRadius,
                            padding: staticStyle.messagePadding,
                            wordBreak: 'break-word',
                          }}
                        >
                          {processedChatLog?.message}
                        </Typography>
                      </Stack>
                    )}
                  </>
                );
              })}
          </Stack>
        </Stack>
      </SideSheet>
    </>
  );
};

export default ChatLogs;
