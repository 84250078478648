import {
  NavigationDrawer,
  NavigationDrawerItem,
  NavigationRail, 
  Text,
} from '@/components/DesignSystem';
import Home from '@mui/icons-material/Home';
import Storefront from '@mui/icons-material/Storefront';
import RequestPage from '@mui/icons-material/RequestPage';
import Person from '@mui/icons-material/Person';
import { Box, Divider } from '@mui/material';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ContentRenderingProps } from '@/components/DesignSystem/NavigationRail/NavigationRail';

export const NavigationRailDemo = () => {

  const { isMobile } = useMediaQuery();

  const actions = (contentRenderingProps: ContentRenderingProps) => [
    <NavigationDrawerItem 
      collapsed={!contentRenderingProps.open} 
      icon={(props) => <Home {...props} /> } 
      isTransitioning={contentRenderingProps.isTransitioning}
      key="option-1"
      onClick={() => contentRenderingProps.closeDrawer()}
      selected={true}
      testID="option-1" 
      text="Home" />,
    <NavigationDrawerItem 
      collapsed={!contentRenderingProps.open} 
      icon={(props) => <RequestPage {...props} /> }
      isTransitioning={contentRenderingProps.isTransitioning}
      key="option-2"
      onClick={() => contentRenderingProps.closeDrawer()}
      secondaryText='3'
      selected={false}
      testID="option-2"
      text="Product Requests" />,
    <NavigationDrawerItem 
      collapsed={!contentRenderingProps.open}
      icon={(props) => <Storefront {...props} /> }
      isTransitioning={contentRenderingProps.isTransitioning}
      key="option-3"
      onClick={() => contentRenderingProps.closeDrawer()}
      selected={false}
      testID="option-3"
      text="Retailers" />,
    <NavigationDrawerItem 
      collapsed={!contentRenderingProps.open}
      icon={(props) => <Person {...props} /> }
      isTransitioning={contentRenderingProps.isTransitioning}
      key="option-4"
      onClick={() => contentRenderingProps.closeDrawer()}
      selected={false}
      testID="option-4"
      text="My Account" />,
    contentRenderingProps.open && (
      <Divider key="divider" sx={{ margin: "8px 12px 8px 12px" }} />
    ),
    (!contentRenderingProps.open && !isMobile) && (
      <Box key="spacer" sx={{ height: "calc(100vh - 408px)" }} />
    ),
    !(!contentRenderingProps.open && isMobile) && (
      <NavigationDrawerItem 
        collapsed={!contentRenderingProps.open} 
        isTransitioning={contentRenderingProps.isTransitioning}
        key="option-5"
        onClick={() => contentRenderingProps.closeDrawer()}
        selected={false}
        testID="option-5" 
        text="Logout" />
    ),
  ];

  return (
    <>
      <NavigationRail
        content={(renderingProps) => (
          <NavigationDrawer
            collapsed={!renderingProps.open}
            isTransitioning={renderingProps.isTransitioning}
            testID="navigation-drawer">
            {actions(renderingProps)}
          </NavigationDrawer>
        )}
        testID="navigation-rail" />
      <Text>
        The navigation rail is shown on the left of the screen (desktop) or bottom (mobile)
      </Text>
    </>
  );
};
