import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { Input, InputProps } from './Input';

interface ZipMaskInputProps {
  name: string,
  onChange: (event: { target: { name: string; value: string } }) => void,
}

const ZipMaskInput = forwardRef<HTMLElement, ZipMaskInputProps>(
  function TextMaskCustom (props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        definitions={{ '#': /[0-9]/ }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputRef={ref as any}
        mask="#0000-0000"
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // To prevent the warning from showing up in the console
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        overwrite
      />
    );
  },
);

export const ZipInput = ({
  ...rest
}: InputProps) => {
  return (
    <Input
      inputComponent={ZipMaskInput}
      inputMode="numeric"
      placeholder="_____-____"
      {...rest}
    />
  );
};
