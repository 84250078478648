// More info about statuses:
// https://growersnc.atlassian.net/wiki/spaces/PM/pages/71598081/Pricing+Request+and+Offer+statuses

export enum OfferStatus {
  Accepted = 'Accepted',
  AwaitingDelivery = 'Awaiting Delivery',
  AwaitingFulfillment = 'Awaiting Fulfillment',
  AwaitingPickup = 'Awaiting Pickup',
  Closed = 'Closed',
  Draft = 'Draft',
  FinancingRequested = 'Financing Requested',
  OnAccountRequested = 'On Account Requested',
  Open = 'Open',
  OrderShipped = 'Order Shipped',
  Partial = 'Partial',
  Rejected = 'Rejected',
}

export enum OfferStatusForSalesperson {
  AwaitingDelivery = 'Awaiting Delivery',
  AwaitingFulfillment = 'Awaiting Fulfillment',
  AwaitingOffer = 'Awaiting Offer',
  DraftDirectOrder = 'Draft Direct Order',
  FinancingRequested= 'Financing Requested',
  OfferClosed = 'Offer Closed',
  OfferExpired = 'Offer Expired',
  OfferSent = 'Offer Sent',
  OnAccountRequested= 'On Account Requested',
  OrderShipped = 'Order Shipped',
  Partial = 'Partial',
  PaymentDue = 'Payment Due',
  PickupReady = 'Awaiting Pickup',
}

export enum RetailerRequestType {
  Active = 'active',
  Order = 'my-orders',
  PastOrder = 'past-order',
  Pending = 'pending',
}

export const AcceptedStatuses: OfferStatus[] = [
  OfferStatus.Accepted,
  OfferStatus.AwaitingDelivery,
  OfferStatus.AwaitingFulfillment,
  OfferStatus.AwaitingPickup,
  OfferStatus.FinancingRequested,
  OfferStatus.OrderShipped,
  OfferStatus.OnAccountRequested,
  OfferStatus.Partial,
];
