import reverseFull from '@/assets/logos/reverse/growers_app_full_reverse.svg';
import { Stack } from '@mui/system';

export const SplashPage = () => (
  <Stack
    alignItems="center"
    height="100vh"
    justifyContent="center"
  >
    <img
      alt="logo"
      src={reverseFull}
    />
  </Stack>
);
