import { OfferEndpoint } from "@api/endpoints";
import { QueryUtil } from "../Query";
import { Client } from "./Client";
import { FulfillmentMethod } from '@shared/enums';

export class OffersApi {
  static createOffer (
    pricingRequestId: string,
    request: OfferEndpoint.Create.Request,
    token?: string,
  ): Promise<OfferEndpoint.Create.Response> {
    const query = QueryUtil.stringify({ token });
    return Client(`pricing-requests/${pricingRequestId}/offers?${query}`, { body: request });
  }

  static getOffer (offerId: string, token?: string): Promise<OfferEndpoint.Get.Response> {
    const query = QueryUtil.stringify({ token });
    return Client(`offers/${offerId}?${query}`);
  }

  static async markShipment (
    offerId: string,
    fulFillmentMethod: FulfillmentMethod.DELIVERY | FulfillmentMethod.PICKUP,
  ): Promise<void> {
    return Client(`offer/${offerId}/mark-shipment`, {
      body: { fulFillmentMethod },
      method: 'PATCH',
    });
  }
}
