import { Button, FullscreenDialog, VSpacer } from '@/components/DesignSystem';
import { RetailerLocationsList } from '@/components/shared/ListSelector/RetailerLocationsList';
import { RetailersList } from '@/components/shared/ListSelector/RetailersList';
import {
  ViewSelectedLocationsModal,
} from '@/components/shared/ListSelector/ViewSelectedLocationsModal';
import { ApiRetailer, LocationDetails } from '@api/interfaces';
import { People } from '@mui/icons-material';
import React, { useState } from 'react';

interface AddLocationsDialogProps {
  close: () => void,
  onChange: (updatedIds: string[]) => void,
  selectedLocationIds: string[],
}

export const AddLocationsDialog = ({
  close,
  onChange,
  selectedLocationIds = [],
}: AddLocationsDialogProps) => {
  const [showViewAddedLocationsModal, setShowViewAddedLocationsModal] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer>();
  const [selectedLocation, setSelectedLocation] = useState<LocationDetails>();

  return (
    <>
      <FullscreenDialog
        actionButton={
          <Button
            onClick={() => {
              onChange(selectedLocationIds);
              close();
            }}
            testID="action-button"
          >
            Done adding locations
          </Button>
        }
        contentStyle={{ alignSelf: 'center', width: '100%' }}
        open
        testID="add-locations-dialog"
        title="Retailers & Locations"
        titleDescriptor={
          <Button
            onClick={() => setShowViewAddedLocationsModal(true)}
            startIcon={<People />}
            testID="added-locations-button"
            variant="text"
          >
            Added retailer locations ({selectedLocationIds.length})
          </Button>
        }
      >
        <VSpacer size="6" />
        {!selectedRetailer && !selectedLocation && (
          <RetailersList
            onChange={onChange}
            onSelectRetailer={(retailer) => {
              setSelectedRetailer(retailer);
            }}
            selectType='locations'
            selectedIds={selectedLocationIds}
          />
        )}
        {!!selectedRetailer && !selectedLocation && (
          <RetailerLocationsList
            locations={selectedRetailer.locationDetails ?? []}
            onBack={() => {
              setSelectedRetailer(undefined);
            }}
            onChange={onChange}
            onSelectLocation={setSelectedLocation}
            retailer={selectedRetailer}
            selectType="locations"
            selectedIds={selectedLocationIds}
          />
        )}
      </FullscreenDialog>
      {showViewAddedLocationsModal && (
        <ViewSelectedLocationsModal
          locationIds={selectedLocationIds}
          onChange={onChange}
          onClose={() => setShowViewAddedLocationsModal(false)}
          testID="view-added-locations-modal"
        />
      )}
    </>
  );
};
