import React from 'react';
import { Search, SideSheet, Text } from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import MemberProfileCard from './MemberProfileCard';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiMember } from '@api/interfaces';
import { stringifyCount } from "@/constants/HierarchyOfRetailers";

type ViewMemberProps = {
  isLocation?: boolean;
  members: RetailerEndpoint.MemberList.Response | undefined;
  onClose: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
};

const ViewMember = ({
  isLocation,
  members,
  onClose,
  search,
  setSearch,
  show,
}: ViewMemberProps) => {
  return (
    <SideSheet
      hideScrollbar
      isBorderless
      onClose={onClose}
      onOpen={onClose}
      open={show}
      testID='view-member'
      title='Members'
      width={isLocation ? '475px' : '425px'}
    >
      <Box px='24px'>
        <Search
          disabled={members?.total === 0 && !search}
          fullWidth
          onChangeText={setSearch}
          testID='search-view-member'
          value={search}
        />
        <Text sx={{ margin: '25px 0px' }}>
          {stringifyCount(members?.total, 'member', 'members')}
        </Text>
      </Box>
      {members?.total ? (
        <>
          {members?.data?.map((member: ApiMember) => (
            <MemberProfileCard
              isLocation={isLocation}
              key={member.id}
              length={members?.data?.length}
              member={member}
            />
          ))}
        </>
      ) : (
        <Stack alignItems='center'>
          <Text>No members found.</Text>
        </Stack>
      )}
    </SideSheet>
  );
};

export default ViewMember;
