import { UserType } from '../enums';

export function userIsFarmer (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.Farmer]);
}

export function userIsInternal (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.Internal]);
}

export function userIsRetailer (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.RetailerAdmin, UserType.SalesPerson]);
}

export function userIsRetailerAdmin (user?: { userType: UserType }) {
  return userHasTypes(user, [UserType.RetailerAdmin]);
}

function userHasTypes (user: { userType: UserType } | undefined, types: UserType[]) {
  return !!user && types.includes(user.userType);
}
