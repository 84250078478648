import { DataPoint, VSpacer } from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useFarmerOfferInfo } from '@/pages/ViewPriceRequest/helpers';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { ApiOffer, ApiPricingRequest } from '@api/interfaces';
import { Box } from '@mui/material';

const styles: SXStyles = {
  dataPointContainer: {
    paddingY: 0,
  },
  fullWidthDataPoint: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  mobilePadding: {
    paddingX: '16px',
  },
};

interface CostSummaryProps {
  offer: ApiOffer,
  priceRequest: ApiPricingRequest,
}

export const CostSummary = ({
  offer,
  priceRequest,
}: CostSummaryProps) => {
  const { isMobile } = useMediaQuery();
  const {
    offerSubtotal,
    offerShipping,
    offerTotal,
  } = useFarmerOfferInfo(priceRequest, offer, offer.products);

  return (
    <Box sx={isMobile ? styles.mobilePadding : undefined}>
      {!!offer.totalShipmentCost && (
        <>
          <DataPoint
            childrenStyle={styles.fullWidthDataPoint}
            containerStyle={styles.dataPointContainer}
            fullWidth
            label="Subtotal"
            labelStyle={styles.costLabel}
            spacing="fixed"
            testID={`${offer.publicId}-subtotal`}
          >
            {offerSubtotal}
          </DataPoint>
          <VSpacer size="4" />
          <DataPoint
            childrenStyle={styles.fullWidthDataPoint}
            containerStyle={styles.dataPointContainer}
            fullWidth
            label="Shipping"
            labelStyle={styles.costLabel}
            spacing="fixed"
            testID={`${offer.publicId}-shipping`}
          >
            {offerShipping}
          </DataPoint>
          <VSpacer size="4" />
        </>
      )}
      <DataPoint
        childrenStyle={styles.fullWidthDataPoint}
        containerStyle={styles.dataPointContainer}
        fullWidth
        label="Total cost"
        labelStyle={styles.costLabel}
        size="title"
        spacing="fixed"
        testID={`${offer.publicId}-total-cost`}
      >
        {offerTotal}
      </DataPoint>
    </Box>
  );
};
