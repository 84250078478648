import { Input } from '@/components/DesignSystem';
import { InputProps } from './Input';

export type TextAreaInputProps = InputProps & {
  emptyRows?: number,
  maxLength?: number,
  maxRows?: number,
}

export const TextAreaInput = ({
  emptyRows = 4,
  maxLength = 150,
  maxRows = 4,
  ...rest
}: TextAreaInputProps) => {
  return (
    <Input
      maxLength={maxLength}
      maxRows={maxRows}
      minRows={emptyRows}
      multiline
      {...rest}
    />
  );
};
