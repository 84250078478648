import { OfferStatus } from "@shared/enums";

export const FriendlyOfferStatus = {
  [OfferStatus.Accepted]: "The farmer has accepted this offer",
  [OfferStatus.Open]: "The farmer is reviewing this offer",
  [OfferStatus.Partial]: "The farmer has accepted some products within this offer",
  [OfferStatus.Rejected]: "The farmer has rejected this offer",
  [OfferStatus.Draft]: "The Retailer has created some draft offers",
  [OfferStatus.Closed]: "Farmer does not respond to a specific offer within offer window",
  [OfferStatus.AwaitingFulfillment]:
   "farmer completes payment within payment window for both Delivery & Pickup",
  [OfferStatus.AwaitingDelivery]:
   "Once retailer selects & confirm order is ready for pickup",
  [OfferStatus.OrderShipped]: "Once retailer selects & confirm order is shipped",
  [OfferStatus.OnAccountRequested]: "Account Request",
  [OfferStatus.FinancingRequested]: "Finance Request",
  [OfferStatus.AwaitingDelivery]: "Awaiting Delivery",
  [OfferStatus.AwaitingPickup]: "Awaiting Pickup",
};
