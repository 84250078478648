import { Card, HSpacer, Text } from '@/components/DesignSystem';
import { PromotionChip } from '@/components/shared/Promotion/PromotionChip';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import React from 'react';

type ProductListData = {
  id: string, name: string
}

interface ProductListItemProps {
  farmerPromotions: ApiPromotion[],
  getoneDataList: (id:string) => void,
  handleEdit: (id:string) => void,
  handleViewProductListDrawer: (e: React.MouseEvent<HTMLDivElement>) => void,
  productListData: ProductListData,
  retailerPromotions: ApiPromotion[],
  setIsEditProductField: (isEdited:boolean) => void,
}

const ProductListItem = ({
  farmerPromotions,
  getoneDataList,
  handleEdit,
  handleViewProductListDrawer,
  productListData,
  retailerPromotions,
  setIsEditProductField,
}: ProductListItemProps) => {
  return (
    <>
      <Card
        onClick={(e) => {
          handleViewProductListDrawer(e);
          getoneDataList(productListData?.id);
        }}
        sx={{ cursor: 'pointer' }}
        testID="product-list-item"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack spacing={2}>
            <Text category="h6" testID='productName' >
              {productListData && productListData?.name}
            </Text>
            <Text category="h6" testID='productCategoryId'>
              {productListData && productListData?.id}
            </Text>
            {!!(farmerPromotions.length || retailerPromotions.length) && (
              <>
                <Stack direction="row">
                  {!!farmerPromotions.length && (
                    <>
                      <PromotionChip
                        promotions={farmerPromotions}
                        targetUserType={PromotionTargetUserType.Farmer}
                      />
                      <HSpacer size="4" />
                    </>
                  )}
                  {!!retailerPromotions.length && (
                    <PromotionChip
                      promotions={retailerPromotions}
                      targetUserType={PromotionTargetUserType.Retailer}
                    />
                  )}
                </Stack>
              </>
            )}
          </Stack>
          <Box>
            <IconButton
              data-testid='Edit'
              onClick={(e) => {
                e.stopPropagation();
                getoneDataList(productListData?.id);
                handleEdit(productListData?.id);
                setIsEditProductField(true);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        </Stack>
      </Card>
    </>
  );
};
export default ProductListItem;
