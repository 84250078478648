import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { ReactNode } from 'react';
import { Modify } from '@shared/utilities/UtilityTypes';
import { Text } from '@/components/DesignSystem';

type InfoTooltipProps = Modify<
  Omit<TooltipProps, 'title'>,
  { children: ReactNode }
>;

export const InfoTooltip = ({
  children,
  ...rest
}: InfoTooltipProps) => {
  if (typeof children === 'string') {
    children = <Text>{children}</Text>;
  }
  return (
    <Tooltip placement="bottom-start" title={children} {...rest}>
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          height: 18,
          width: 18,
        }}
      >
        <InfoOutlined sx={{ height: 18, width: 18 }} />
      </IconButton>
    </Tooltip>
  );
};
