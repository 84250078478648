import { Category } from '@/themes/variant-interfaces/Category';
import { ReactNode } from 'react';
import { Text, TextProps, HSpacer } from '..';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { Stack } from '@mui/material';

export type HeadingLevel = '1' | '2' | '3' | '4';

interface HeadingProps {
  actions?: ReactNode[],
  breakWord?: boolean,
  centered?: boolean,
  leftAccessory?: ReactNode,
  subtitle?: string,
  testID: string,
  title?: string,
}

interface HeadingOneAndTwoProps extends HeadingProps {
  level: '1' | '2',
  overline?: string,
}

interface HeadingThreeAndFourProps extends HeadingProps {
  level: '3' | '4',
  overline?: never,
}


interface LevelStyleTextProps extends TextProps {
  category: Category,
}

const getHeadingLevel = (level: HeadingLevel) => {
  let titleStyles: LevelStyleTextProps = { category: 'headline-medium' };
  const overlineStyles: LevelStyleTextProps = { category: 'overline' };
  const subtitleStyles: LevelStyleTextProps = { category: 'body-medium' };
  switch (level) {
    case '2':
      titleStyles = {
        category: 'headline-small',
      };
      break;
    case '3':
      titleStyles = {
        category: 'title-medium',
      };
      break;
    case '4':
      titleStyles = {
        category: 'label-large',
      };
      break;
  }
  return { titleStyles, subtitleStyles, overlineStyles };
};

export const Heading = ({
  actions,
  breakWord = false,
  centered = false,
  leftAccessory,
  level,
  overline,
  subtitle,
  testID,
  title,
}: HeadingOneAndTwoProps | HeadingThreeAndFourProps) => {
  const { titleStyles, subtitleStyles, overlineStyles } = getHeadingLevel(level);
  return (
    <Stack direction="row" justifyContent={centered ? 'center' : 'space-between'}>
      <Stack textAlign={centered ? 'center' : 'left'}>
        <Text breakWord={breakWord} testID={`${testID}-overline`} {...overlineStyles}>
          {overline}
        </Text>
        <Stack alignItems="center" direction="row" spacing={1}>
          {leftAccessory}
          <Text breakWord={breakWord} testID={`${testID}-title`} {...titleStyles}>
            {title}
          </Text>
        </Stack>
        <Text breakWord={breakWord} testID={`${testID}-subtitle`} {...subtitleStyles}>
          {subtitle}
        </Text>
      </Stack>
      <DesktopOnly>
        <Stack
          alignContent="center"
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
        >
          {actions?.map((action, idx, arr) => (
            <Stack direction="row" key={`action-${idx}`}>
              {action}
              {actions.length > 1 && action !== arr[arr.length - 1] && <HSpacer size="5" />}
            </Stack>
          ))}
        </Stack>
      </DesktopOnly>
    </Stack>
  );
};
