import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { SpacerSize } from '@/themes/variant-interfaces/Size';
import { Box } from '@mui/material';

export interface SpacerProps {
  mobileSize?: SpacerSize,
  size?: SpacerSize,
}

export const getSize = (size?: SpacerSize) => {
  switch (size) {
    case '1': return 2;
    case '2': return 4;
    case '3': return 8;
    case '4': return 12;
    case '5': return 16;
    case '6': return 20;
    case '7': return 24;
    case '8': return 32;
    case '9': return 40;
    case '10': return 48;
    case '11': return 64;
    case '12': return 80;
    case '13': return 96;
    case '14': return 160;
    default:
      return 0;
  }
};

export const HSpacer = ({ mobileSize, size }: SpacerProps) => {
  const width = getResponsiveValue(getSize(mobileSize ?? size), getSize(size));

  return (
    <Box sx={{ display: "inline-block", height: 0, minWidth: width, width }} />
  );
};
