import { SplashPage } from '@/pages/RedirectToOs/SplashPage';
import { useLocation } from 'react-router-dom';

export const GetAppPage = () => {
  const location = useLocation();
  const { state } = location;
  window.location.href = 'https://thegrowersapp.mygrowers.io/#/redirect/Home';
  if (state?.redirect) {
    window.location.href = state.redirect;
  }
  return <SplashPage />;
};
