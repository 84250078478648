import {
  ProgressStepper,
} from '@/components/DesignSystem';
import { DemoBlock } from '../../components/DemoBlock';

export const ProgressStepperDemo = () => {
  return (
    <>
      <DemoBlock label="No subline 12px">
        <ProgressStepper
          activeStep={0}
          heading="Section Heading"
          steps={10}
          testID="stepper-1" />
      </DemoBlock>
      <DemoBlock label="Subline 12px">
        <ProgressStepper
          activeStep={4}
          heading="Section Heading"
          steps={10}
          subline="Subline"
          testID="stepper-2" />
      </DemoBlock>
      <DemoBlock label="No subline 8px">
        <ProgressStepper
          activeStep={7}
          heading="Section Heading"
          size={8}
          steps={10}
          testID="stepper-3" />
      </DemoBlock>
      <DemoBlock label="Subline 8px">
        <ProgressStepper
          activeStep={9}
          heading="Section Heading"
          size={8}
          steps={10}
          subline="Subline"
          testID="stepper-4" />
      </DemoBlock>
    </>
  );
};
