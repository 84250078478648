import {
  Radio,
  Text,
} from '@/components/DesignSystem';
import { Divider, RadioGroup, Stack } from '@mui/material';
import { useState } from 'react';

export const RadioDemo = () => {

  const [radioValue, setRadioValue] = useState("3");

  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <Text>Selected value: {radioValue}</Text>
      <RadioGroup
        name="radio"
        onChange={(e) => setRadioValue(e.target.value)}
        row
        value={radioValue}>
          <Radio testID="radio-test-1" value="1">One</Radio>
          <Radio testID="radio-test-2" value="2">Two</Radio>
          <Radio disabled testID="radio-test-3" value="3">Disabled</Radio>
          {/* No Label */}
          <Radio testID="radio-test-4" value="4" />
      </RadioGroup>
    </Stack>
  );
};
