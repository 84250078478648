import { Text, VSpacer } from '@/components/DesignSystem';
import { productRequestConstants } from '@/constants/ProductRequest';
import { ApiPricingRequest } from '@api/interfaces';
import { Card } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';

const DeliveryAddress = ({
  pricingRequestById,
}: {
  pricingRequestById: ApiPricingRequest | undefined;
}) => {
  const getFormattedAddress = () => {
    const address = [
      pricingRequestById?.deliveryAddress1,
      pricingRequestById?.deliveryAddress2,
      pricingRequestById?.deliveryCity,
      pricingRequestById?.deliveryPostalCode,
      pricingRequestById?.deliveryState,
    ]
      .filter(Boolean)
      .join(', ');
    return address ?? '-';
  };
  return (
    <>
      <Text
        testID='delivery-address-label'
      >
        {productRequestConstants.deliveryAddress}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='delivery-address-data'>
        {getFormattedAddress()}
      </Text>
      <VSpacer size='8' />
    </>
  );
};

const UserProfile = ({
  pricingRequestById,
}: {
  pricingRequestById: ApiPricingRequest | undefined;
}) => {
  return (
    <Card sx={{ padding: '20px' }}>
      <Text testID='card-text'>
        {pricingRequestById?.user?.userType}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='card-text'>
        {`${[
          pricingRequestById?.user?.firstName,
          pricingRequestById?.user?.lastName,
        ]
          ?.filter(Boolean)
          .join(' ') || '-'}`}
      </Text>
      <VSpacer size='8' />
      <Text testID='card-text'>
        {productRequestConstants.fulfillment}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='card-text'>
        {pricingRequestById?.fulfillmentMethod}
      </Text>
      <VSpacer size='8' />
      {pricingRequestById?.fulfillmentMethod !== FulfillmentMethod.PICKUP && (
        <DeliveryAddress pricingRequestById={pricingRequestById} />
      )}
      <Text testID='card-text'>
        {productRequestConstants.contactPhone}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='card-text'>
        {pricingRequestById?.user?.telephone ? 
          `+1 ${formatPhoneNumber(pricingRequestById?.user?.telephone ?? '', 'paren')}` : '-'}
      </Text>
      <VSpacer size='8' />
      <Text testID='card-text'>
        {productRequestConstants.contactEmail}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='card-text'>
        {pricingRequestById?.user?.email}
      </Text>
      <VSpacer size='8' />
      <Text testID='card-text'>
        {productRequestConstants.farmName}
      </Text>
      <VSpacer size='3' />
      <Text category='body-large' testID='card-text'>
        {`${
          pricingRequestById?.user?.businessName
            ?? '-'
        }`}
      </Text>
    </Card>
  );
};

export default UserProfile;
