import { Button, IconButton, Switch, Text, VSpacer } from '@/components/DesignSystem';
import ConfirmModal from '@/components/shared/ConfirmModal';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';
import { productRequestConstants } from '@/constants/ProductRequest';
import {
  useActiveSalespersonLastLook,
  useRemoveSalespersonById,
} from '@/hooks/useIndividualPricingRequestQueries';
import { ApiOffer, ApiPricingRequest, ApiPricingRequestRetailer } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, Divider, Stack } from '@mui/material';
import { PricingRequestStatus } from '@shared/enums';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddSalesPerson from './AddSalesPerson';
import ChatLogs from './ChatLog';
import SalesPersonTab from './SalesPersonTab';

interface SalesPersonListProps {
  chatEssentials: {
    userId: string;
    publicId: number;
  };
  isPricingRequestByIdLoading: boolean;
  pricingRequestById: ApiPricingRequest | undefined;
  pricingRequestStatus: PricingRequestStatus | undefined;
}
const SalespersonList = ({
  chatEssentials,
  isPricingRequestByIdLoading,
  pricingRequestById,
  pricingRequestStatus,
}: SalesPersonListProps) => {
  const [isAddSalesperson, setIsAddSalesperson] = useState(false);
  const [salespersonId, setSalespersonId] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [chatCredentials, setChatCredentials] = useState({
    chatId: '',
    publicId: '',
  });
  const [showConfirmLastLook, setShowConfirmLastLook] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [salespersonLastLookId, setSalespersonLastLookId] = useState('');
  const [haveLastLook, setHaveLastLook] = useState(false);
  const [pricingRequestSalespersons, setPricingRequestSalespersons] = useState(
    pricingRequestById?.salespersons,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!isPricingRequestByIdLoading) {
      setPricingRequestSalespersons(pricingRequestById?.salespersons);
    }
  }, [isPricingRequestByIdLoading, pricingRequestById]);
  const { id: pricingRequestId = '' } = useParams();
  const { activeSalespersonLastLook } = useActiveSalespersonLastLook();
  const { deleteRetailer } = useRemoveSalespersonById();

  const onViewOffer = (pricingRequestId: string, salespersonId: string) => {
    navigate(`/admin/view-detail/${pricingRequestId}_${salespersonId}`);
  };

  useEffect(() => {
    const hasRetailers =
      pricingRequestById && pricingRequestById?.salespersons?.length;
    setHaveLastLook(
      (hasRetailers &&
        pricingRequestById?.salespersons?.some(
          (item: ApiPricingRequestRetailer) => item.lastLook === true,
        )) ||
        false,
    );
  }, [pricingRequestById]);

  const productActionForPending = (
    id: string,
    isPreferred: boolean = false,
    isLastLook: boolean = false,
  ) => {
    const actionsForPending: ReactNode[] = [
      <IconButton
        key='delete-button'
        onClick={() => {
          null;
        }}
        size='small'
        testID='delete-button'
      >
        <Delete
          onClick={() => {
            setSalespersonId(id);
            setShowConfirmDelete(true);
          }}
        />
      </IconButton>,
    ];
    if (isPreferred || isLastLook) return [<React.Fragment key="empty-fragment" />];
    return actionsForPending;
  };

  const productActions = ({
    chatId,
    index,
    lastLook,
    preferred,
    pricingRequestId,
    publicId,
    salespersonId,
  }: {
    chatId: string;
    index: number;
    lastLook: boolean;
    preferred: boolean;
    pricingRequestId: string;
    publicId: string;
    salespersonId: string;
  }) => {
    const foundObject = pricingRequestById?.offers?.find(
      (obj: ApiOffer) =>
        obj?.pricingRequestRetailerId === salespersonId && obj?.status !== 'Draft',
    );
    const actions: ReactNode[] = [
      <IconButton key='open-chat-window' size='small' testID='test-dataset-edit-button'>
        <QuestionAnswerIcon
          onClick={() => {
            setChatCredentials({
              chatId,
              publicId,
            });
            setIsChatWindowOpen(true);
          }}
        />
      </IconButton>,
    ];
    if (!lastLook && !preferred && !pricingRequestById?.storefrontId) {
      actions.push(
        <IconButton
          key='delete-salesperson-button'
          size='small'
          testID='delete-salesperson-button'
        >
          <Delete
            onClick={() => {
              setSalespersonId(salespersonId);
              setShowConfirmDelete(true);
            }}
          />
        </IconButton>,
      );
    }
    if (foundObject) {
      actions.unshift(
        <Button
          key='view-offer'
          onClick={() => onViewOffer(pricingRequestId, salespersonId)}
          testID='offer'
          variant='outlined'
        >
          {productRequestConstants.viewOffer}
        </Button>,
      );
    }
    if (pricingRequestById?.status !== 'Draft' && !pricingRequestById?.storefrontId) {
      actions.unshift(
        <Switch
          checked={lastLook}
          disabled={lastLook ? false : haveLastLook}
          labelPlacement='start'
          name={salespersonId}
          onChange={() => {
            if (!lastLook) {
              setIndexValue(index);
              setSalespersonLastLookId(salespersonId);
              setShowConfirmLastLook(true);
            }
          }}
          sx={{ marginRight: '10px', paddingTop: '5px' }}
          testID={`${salespersonId}-switch`}
        >
          Last Look
        </Switch>,
      );
    }
    return actions;
  };

  const toggleSwitch = (index: number) => {
    const modifiedRetailerData = pricingRequestSalespersons;
    if (modifiedRetailerData?.length) {
      if (modifiedRetailerData[index]) {
        modifiedRetailerData[index].lastLook =
          !modifiedRetailerData[index]?.lastLook;
        setPricingRequestSalespersons([...modifiedRetailerData]);
      }
    }
    modifiedRetailerData?.forEach((item: ApiPricingRequestRetailer) => {
      if (item.id === salespersonLastLookId) {
        const id = item.id;
        const lastLook = item.lastLook;
        activeSalespersonLastLook({ id, lastLook: lastLook || false });
      }
    });
  };

  const retailerListData =
    pricingRequestSalespersons?.filter(({ salespersonId }) => {
      return salespersonId;
    }) || [];

  return (
    <Fragment>
      <VSpacer size='5' />
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'revert',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Text>
            {stringifyCount(pricingRequestSalespersons?.length, 'Salesperson', 'Salespersons')}
          </Text>
        </Box>
        {!pricingRequestById?.storefrontId &&
          <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setIsAddSalesperson(true)}
              startIcon={<AddIcon />}
              testID='addRetailer-button'
              variant='text'
            >
              {productRequestConstants.addSalesPerson}
            </Button>
          </Box>
        }
      </Stack>
      <VSpacer size='5' />
      {pricingRequestSalespersons?.map(
        (pricingRequestRetailer: ApiPricingRequestRetailer, index: number) => {
          return (
            <Fragment key={pricingRequestRetailer.id}>
              <VSpacer size='3' />
              <SalesPersonTab
                actions={
                  pricingRequestStatus === productRequestConstants?.awaitingApproval
                    ? productActionForPending(
                      pricingRequestRetailer?.id,
                      pricingRequestRetailer?.preferred,
                      pricingRequestRetailer?.lastLook,
                    )
                    : productActions({
                      chatId: `${chatEssentials?.userId}|${pricingRequestRetailer?.salespersonId}`,
                      index: index,
                      lastLook: pricingRequestRetailer?.lastLook || false,
                      preferred: pricingRequestRetailer?.preferred || false,
                      pricingRequestId,
                      publicId: String(chatEssentials?.publicId),
                      salespersonId: pricingRequestRetailer?.id,
                    })
                }
                salespersonCard={{
                  email: pricingRequestRetailer?.retailerSalesperson?.email ?? '',
                  isPreferredRetailer: pricingRequestRetailer?.preferred,
                  lastLook: pricingRequestRetailer?.lastLook || false,
                  location: pricingRequestRetailer?.retailerSalesperson?.userLocation?.name ?? '',
                  name: pricingRequestRetailer?.retailerSalesperson?.businessName ?? '',
                  phone: pricingRequestRetailer?.retailerSalesperson?.telephone ?? '',
                }}
                testID={pricingRequestRetailer?.id}
              />
              {index < pricingRequestSalespersons?.length - 1 && (
                <>
                  <Divider />
                  <VSpacer size='3' />
                </>
              )}
            </Fragment>
          );
        },
      )}
      {isAddSalesperson && !!pricingRequestById && (
        <AddSalesPerson
          onClose={() => setIsAddSalesperson(false)}
          partners={pricingRequestSalespersons}
          pricingRequestId={pricingRequestId}
          salespersonListData={retailerListData}
          userId={pricingRequestById.userId}
        />
      )}
      {isChatWindowOpen && (
        <ChatLogs
          chatCredentials={chatCredentials}
          openChat={isChatWindowOpen}
          setOpenChat={setIsChatWindowOpen}
        />
      )}
      {showConfirmDelete && (
        <ConfirmModal
          confirmButtonText={productRequestConstants.delete}
          message={productRequestConstants.confirmationMsg}
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={() => {
            deleteRetailer({
              pricingRequestId: pricingRequestById?.id ?? '',
              salespersonId,
            });
            setShowConfirmDelete(false);
          }}
          open
          testID='confirm-delete-modal'
          title={productRequestConstants.deleteSalesPerson}
        />
      )}
      {showConfirmLastLook && (
        <ConfirmModal
          confirmButtonText={productRequestConstants.submit}
          message={productRequestConstants.confirmMsgLastLook}
          onCancel={() => setShowConfirmLastLook(false)}
          onConfirm={() => {
            toggleSwitch(indexValue);
            setShowConfirmLastLook(false);
          }}
          open
          testID='confirm-last-look'
          title={productRequestConstants.confirmLastLook}
        />
      )}
    </Fragment>
  );
};

export default SalespersonList;
