import {
  ApiOffer,
  ApiPricingRequestRetailer,
} from "@api/interfaces";
import { ProductOffers } from '@/pages/CompareAcceptOffers/interfaces';

export const getProductOffers = (
  offers: ApiOffer[],
  retailers?: ApiPricingRequestRetailer[],
) => {
  const offersByProduct: ProductOffers[] = [];
  offers.forEach((offer) => {
    offer.products?.forEach((offerProduct) => {
      if (!offerProduct.pricingRequestProduct) {
        return;
      }
      const offerRetailers
        = retailers?.filter(({ id }) => id === offer.pricingRequestRetailerId);
      const existingOfferProduct = offersByProduct.find(({ product, retailers }) => (
        !!offerProduct.productId
        && product.productId === offerProduct.productId
        && retailers.some(({ offerProduct: retailerOfferProduct }) => (
          retailerOfferProduct?.productId === offerProduct.productId
        ))
      ));
      if (existingOfferProduct) {
        existingOfferProduct.retailers = existingOfferProduct.retailers.concat(
          offerRetailers?.map((retailer) => ({
            retailer: {
              ...retailer,
              offer,
            },
            offerProduct,
          })) ?? [],
        );
        if (offerProduct.isAccepted) {
          existingOfferProduct.product.acceptedOfferProductId = offerProduct.id;
        }
      } else {
        offersByProduct.push({
          product: {
            ...offerProduct.pricingRequestProduct,
            acceptedOfferProductId: offerProduct.isAccepted ? offerProduct.id : undefined,
          },
          retailers: offerRetailers?.map((retailer) => ({
            retailer: {
              ...retailer,
              offer,
            },
            offerProduct,
          })) ?? [],
        });
      }
    });
  });
  return offersByProduct;
};
