import { Button, Search, SideSheet as ResponsiveSideSheet, Text } from '@/components/DesignSystem';
import { useSelectFarmers } from '@/hooks/useSelectFarmers';
import { ApiUser } from '@api/interfaces';
import { Checkbox, Divider, ListItem, ListItemText, Stack } from '@mui/material';
import React from 'react';

const SelectFarmers = ({
  isSelectFarmers,
  locationId,
  setIsSelectFarmers,
}: {
  isSelectFarmers: boolean;
  locationId: string;
  setIsSelectFarmers: (args: boolean) => void;
}) => {
  const {
    selectedFarmers,
    setSelectedFarmers,
    setSearch,
    search,
    allFarmersData,
    saveFarmersByIds,
    isSaveFarmersLoading,
    lastItemRef,
  } = useSelectFarmers({ isSelectFarmers, locationId, setIsSelectFarmers });
  const handleToggle = (farmer: ApiUser) => {
    setSelectedFarmers((previousFarmer) => {
      const isSelectedFarmers = previousFarmer.some(
        (previousSelectedFarmer) => previousSelectedFarmer.id === farmer.id,
      );

      if (
        isSelectedFarmers
      ) {
        return previousFarmer.filter(
          (previousSelectedFarmer) => previousSelectedFarmer.id !== farmer.id,
        );
      } else {
        // TODO
        /* if (
          farmer?.preferredLocationId &&
          farmer?.preferredLocationId !== partnerRetailerId
        )
          openSnackbar(ToastMessages.preferredRetailerFarmerSelected);
         */
        return [...previousFarmer, farmer];
      }
    });
  };

  const handleSaveFarmers = () => {
    saveFarmersByIds(selectedFarmers.map((farmer) => farmer.id));
  };

  const getConstant = () => {
    const total = allFarmersData?.pages[0]?.total;
    if (total && total <= 1) {
      return `${total} Farmer`;
    }
    return `${total} farmers`;
  };

  const farmersListing = allFarmersData?.pages
    ?.flatMap(({ data }) => [...data]);

  return (
    <ResponsiveSideSheet
      actionButton={(props) => (
        <Button
          fullWidth
          onClick={() => setIsSelectFarmers(false)}
          variant='outlined'
          {...props}
        >
          Cancel
        </Button>
      )}
      hideScrollbar
      onClose={() => setIsSelectFarmers(false)}
      onOpen={() => setIsSelectFarmers(true)}
      open={isSelectFarmers}
      secondaryActionButton={() => (
        <Button
          disabled={!selectedFarmers.length}
          fullWidth
          loading={isSaveFarmersLoading}
          onClick={() => handleSaveFarmers()}
          testID='add-farmer'
          variant='contained'
        >
          Save
        </Button>
      )}
      testID='sidesheet'
      title='Select a Farmer'
    >
      <Stack p='12px 12px 20px 24px'>
        <Search
          onChangeText={setSearch}
          testID='counties-modal-search'
          value={search}
        />
        <Text paddingTop={'24px'}>{getConstant()}</Text>
      </Stack>
      <Divider />
      {allFarmersData?.pages[0]?.total === 0 && (
        <Stack
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          mt='10px'
        >
          <Text>No farmers found</Text>
        </Stack>
      )}
      {farmersListing?.map((farmer, index: number) => (
        <>
          <Stack p='6px 2px 6px 14px'>
            <ListItem
              data-testid={`list-item-${farmer?.id}`}
              key={farmer.id}
              ref={index === farmersListing.length - 1 ? lastItemRef : null}
              secondaryAction={
                <Checkbox
                  checked={selectedFarmers.some(
                    (selectedFarmer) => selectedFarmer.id === farmer.id,
                  )}
                  data-testid={`checkbox-${farmer.id}`}
                  edge='start'
                  onClick={() => {
                    handleToggle(farmer as ApiUser);
                  }}
                  sx={{
                    color: 'white',
                  }}
                />
              }
              sx={{ background: 'rgba(33,33,32,1)' }}
            >
              <ListItemText
                primary={`${farmer?.firstName ?? ''} ${farmer?.lastName ?? ''}`}
                sx={{
                  textTransform: 'capitalize',
                  maxWidth: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              />
            </ListItem>
          </Stack>
          <Divider />
        </>
      ))}
    </ResponsiveSideSheet>
  );
};

export default SelectFarmers;
