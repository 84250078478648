import { Text, Toolbar } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { useGetRetailerList } from '@/hooks/useProductQuery';
import { useProductRecommendations } from '@/hooks/useProductRecommendations';
import { useSearch } from '@/hooks/useSearch';
import { ProductRecommendationEndpoint } from '@api/endpoints';
import { ApiProductRecommendation, ApiRetailerList } from '@api/interfaces';
import { CircularProgress, Container, Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import ProductRecommendationCard from './ProductRecommendationCard';

const ProductRecommendation = () => {
  const [page, setPage] = useState(0);
  const [filterSelections, setFilterSelections] = (
    useState<FilterSelections | undefined>(new Map())
  );
  const { setSearch, debouncedSearch } = useSearch();

  const { retailerList: partnerRetailerList } = useGetRetailerList();

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, selectionsString]);

  const retailerOptions = partnerRetailerList?.map(
    (retailer: ApiRetailerList) => ({
      id: retailer.id,
      label: retailer.name ?? '',
    }),
  );

  const getStatusFilter = () => {
    const isExpired = !!filterSelections?.get('is-expired')?.has('is-expired');
    const statusFilters: Set<
      ProductRecommendationEndpoint.ListProductRecommendation.ListRecommendationStatusFilter
    > = new Set();
    if (isExpired) {
      statusFilters.add('expired');
    }
    const isUnshared = !!filterSelections?.get('is-unshared')?.has('is-unshared');
    if (isUnshared) {
      statusFilters.add('unshared');
    }
    const isActive = !!filterSelections?.get('is-active')?.has('is-active');
    if (isActive) {
      statusFilters.add('active');
    }
    return statusFilters.size ? Array.from(statusFilters) : undefined;
  };

  const sortDesc = filterSelections?.get('sortDesc')?.has('desc');

  const { productRecommendations, isFetching } = useProductRecommendations(
    {
      page,
      retailerId: filterSelections?.get('retailerId')?.values().next().value,
      search: debouncedSearch,
      sort: 'createdAt',
      sortDesc,
      status: getStatusFilter(),
    },
    !!filterSelections?.size,
  );

  const filters: Filter[] = [
    {
      hideClearButton: true,
      id: 'sortDesc',
      label: 'Sort by',
      options: [
        { default: true, id: 'desc', label: 'Newest to Oldest' },
        { id: 'asc', label: 'Oldest to Newest' },
      ],
      selectionMethod: 'single-select',
    },
    {
      hideClearButton: false,
      id: 'retailerId',
      label: 'Retailer',
      options: retailerOptions ?? [],
      selectionMethod: 'single-select',
    },
    {
      id: 'is-active',
      label: 'Active',
      options: [
        { id: 'is-active', label: 'Active' },
      ],
      selectionMethod: 'boolean',
    },
    {
      id: 'is-unshared',
      label: 'Unshared',
      options: [
        { id: 'is-unshared', label: 'Unshared' },
      ],
      selectionMethod: 'boolean',
    },
    {
      id: 'is-expired',
      label: 'Expired',
      options: [
        { id: 'is-expired', label: 'Expired' },
      ],
      selectionMethod: 'boolean',
    },
  ];

  return (
    <Container maxWidth='lg'>
      <Stack>
        <Text
          category='h3'
          style={{ margin: '2rem 0' }}
          testID='product-recommendation-header'
        >
          Recommendations
        </Text>
        <Toolbar
          filterSelections={filterSelections}
          filters={filters}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            if (selections) {
              const newSelections = Array.from(selections.keys());
              const oldSelections = Array.from(filterSelections?.keys() ?? []);
              if (newSelections.includes('is-active') && !oldSelections.includes('is-active')) {
                selections.delete('is-expired');
              }
              if (newSelections.includes('is-expired') && !oldSelections.includes('is-expired')) {
                selections.delete('is-active');
              }
            }
            setFilterSelections(selections);
          }}
          testID='product-recommendation-toolbar'
          totalItems={productRecommendations?.total}
          totalUnit="recommendation"
        />
        {!productRecommendations?.data?.length && !isFetching && (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <Text
              category='p2'
              style={{
                color: '#A0A0A0',
              }}
            >
              No recommendations available
            </Text>
          </Stack>
        )}
        {!isFetching ? (
          <>
            {productRecommendations?.data?.map(
              (
                productRecommendation: ApiProductRecommendation,
                index: number,
              ) => (
                <ProductRecommendationCard
                  key={index}
                  productRecommendation={productRecommendation}
                />
              ),
            )}
            {!!productRecommendations?.total && productRecommendations?.lastPage > 0 && (
              <Stack alignItems="center" py="20px">
                <Pagination
                  count={productRecommendations.lastPage + 1}
                  onChange={(event, page) => {
                    setPage(page - 1);
                  }}
                  page={productRecommendations.page + 1}
                />
              </Stack>
            )}
          </>
        ) : (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ProductRecommendation;