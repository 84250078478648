import { Card, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ApiUserPublic } from '@api/interfaces';
import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateTime } from 'luxon';

export interface OfferLicensesProps {
  user: ApiUserPublic,
  showTitle?: boolean
}

const MissingLabel = () => (
  <Text category="p2" color="error">Missing</Text>
);

const OfferLicenses = ({
  user,
}: OfferLicensesProps) => {
  const formatDate = (date?: Date) => {
    if (date) {
      return DateTime.fromJSDate(date).toFormat("MM/dd/yyyy");
    } else {
      return "Please coordinate with the farmer directly";
    }
  };

  const containsLicenses = !!user.restrictedUseLicense
    || !!user.restrictedUseLicenseExpirationDate
    || !!user.taxExemptLicense
    || !!user.taxExemptLicenseExpirationDate
    || !!user.technologyLicense
    || !!user.technologyLicenseExpirationDate;

  return (
    <>
      {
        containsLicenses ? (
          <>
            <Card sx={{ background: 'transparent' }} testID="offer-licenses">
              <DesktopOnly>
                <Table sx={{ background: 'transparent' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Text category="s2">
                          License type
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text category="s2">
                          Number
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text category="s2">
                          Expiration date
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Text category="p2">Restricted Use License</Text>
                      </TableCell>
                      <TableCell>
                        {
                          user?.restrictedUseLicense ? (
                            <Text category="p2">
                              {user?.restrictedUseLicense}
                            </Text>
                          ) : (
                            <MissingLabel />
                          )
                        }
                      </TableCell>
                      <TableCell>
                        <Text category={user?.restrictedUseLicenseExpirationDate ? "p2" : "c1"}>
                          {formatDate(user?.restrictedUseLicenseExpirationDate ?? undefined)}
                        </Text>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Text category="p2">Tax-Exempt License</Text>
                      </TableCell>
                      <TableCell>
                        {
                          user?.taxExemptLicense ? (
                            <Text category="p2">
                              {user?.taxExemptLicense}
                            </Text>
                          ) : (
                            <MissingLabel />
                          )
                        }
                      </TableCell>
                      <TableCell>
                        <Text category={user?.taxExemptLicenseExpirationDate ? "p2" : "c1"}>
                          {formatDate(user?.taxExemptLicenseExpirationDate ?? undefined)}
                        </Text>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Text category="p2">Technology License</Text>
                      </TableCell>
                      <TableCell>
                        {
                          user?.technologyLicense ? (
                            <Text category="p2">
                              {user?.technologyLicense}
                            </Text>
                          ) : (
                            <MissingLabel />
                          )
                        }
                      </TableCell>
                      <TableCell>
                        <Text category={user?.technologyLicenseExpirationDate ? "p2" : "c1"}>
                          {formatDate(user?.technologyLicenseExpirationDate ?? undefined)}
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DesktopOnly>
              <MobileOnly>
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Restricted Use License
                    </Text>
                    <Text category="p1">{user?.restrictedUseLicense}</Text>
                  </Stack>
                  <VSpacer size="4" />
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Expiration date
                    </Text>
                    <Text category="p2">
                      {formatDate(user?.restrictedUseLicenseExpirationDate ?? undefined)}
                    </Text>
                  </Stack>
                  <VSpacer size="5" />
                  <Divider />
                  <VSpacer size="5" />
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Tax-Exempt License
                    </Text>
                    <Text category="p1">{user?.taxExemptLicense}</Text>
                  </Stack>
                  <VSpacer size="4" />
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Expiration date
                    </Text>
                    <Text category="p2">
                      {formatDate(user?.taxExemptLicenseExpirationDate ?? undefined)}
                    </Text>
                  </Stack>
                  <VSpacer size="5" />
                  <Divider />
                  <VSpacer size="5" />
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Technology License
                    </Text>
                    <Text category="p1">{user?.technologyLicense}</Text>
                  </Stack>
                  <VSpacer size="4" />
                  <Stack direction="row" justifyContent="space-between">
                    <Text category="p2">
                      Expiration date
                    </Text>
                    <Text category="p2">
                      {formatDate(user?.technologyLicenseExpirationDate ?? undefined)}
                    </Text>
                  </Stack>
                </Stack>
              </MobileOnly>
            </Card>
          </>
        ) : (
          <Text category="p2">
            This farmer hasn't added their licenses to The GROWERS App yet - please coordinate
            with them directly.
          </Text>
        )
      }
    </>
  );
};

export default OfferLicenses;
