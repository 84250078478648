import {
  Button,
  CalculateQuantityModal,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { ProductUomSelect } from '@/components/shared/Select/ProductUomSelect';
import { Stack } from '@mui/system';
import { ProductUom, SeedProductUnitOfMeasure, ServiceUnitOfMeasure } from '@shared/enums';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const CalculateQuantityModalDemo = () => {
  const [showModal, setShowModal] = useState(false);
  const [productUom, setProductUom] = useState<ProductUom>();
  const [output, setOutput] = useState<{ quantity: number, uom?: ProductUom }>({
    quantity: 0,
    uom: undefined,
  });

  return (
    <Stack>
      <CalculateQuantityModal
        onApplyAndClose={(quantity, uom) => {
          setOutput({ quantity, uom });
          setShowModal(false);
        }}
        onClose={() => setShowModal(false)}
        open={showModal}
        productUom={productUom}
      />
      <DemoBlock label="Calculate Quantity Modal - First Select a Product Uom">
        <ProductUomSelect
          isUomDisabled={(uom) => (
            uom === SeedProductUnitOfMeasure.BAG
            || uom === SeedProductUnitOfMeasure.BUSHEL)
            || uom === ServiceUnitOfMeasure.ACRE}
          onChangeUoM={setProductUom}
          testID="test"
          value={productUom}
        />
        <VSpacer size="5" />
        <Button
          onClick={() => setShowModal(true)}
          testID="test"
        >
          Show Modal
        </Button>
        <VSpacer size="5" />
        <Text>{JSON.stringify(output, null, 2)}</Text>
      </DemoBlock>
    </Stack>
  );
};
