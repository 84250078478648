import { Chip, DataPoint, VSpacer } from '@/components/DesignSystem';
import {
  getInitialQuantityWithUom,
  getQuantityWithUom,
} from '@/components/shared/SharedCardComponents/helpers';
import { FriendlyProductType } from '@/constants/FriendlyProductType';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { Divider, Stack } from '@mui/material';
import { isEmptyText } from '@shared/utilities';

interface ProductDetailsProps {
  product: OfferProduct,
  showAcreLabel?: boolean,
  testID: string,
}

export const ProductDetails = ({
  product,
  showAcreLabel,
  testID,
}: ProductDetailsProps) => {
  const showPackageType = !isEmptyText(product.package);
  const showDaysToMaturity = !isEmptyText(product.daysToMaturity);
  const showTraitPackage = !isEmptyText(product.traitPackage);
  const showTraitPackageDetails = !isEmptyText(product.traitPackageDetails);
  const showTreatment = !isEmptyText(product.seedTreatment);
  const showCrop = !isEmptyText(product.crop);
  const showPreferredManufacturer = !isEmptyText(product.preferredManufacturer);
  const showCompanionBadge = !!product.companionToProductId;

  const quantityWithUom = getQuantityWithUom(product, showAcreLabel);
  const initialQuantityWithUom = getInitialQuantityWithUom(product, showAcreLabel);

  const Headers = (
    showCompanionBadge && (
      <>
        <Chip
          color="warning"
          label="COMPANION"
          testID={`product-details-companion-chip-${testID}`}
        />
        <VSpacer size="7" />
      </>
    )
  );

  return (
    <Stack
      data-testid={testID}
      divider={(
        <Divider sx={{ my: 1 }} />
      )}
      px={getResponsiveValue(2, 0)}
    >
      {Headers}
      {!!quantityWithUom && (
        <DataPoint
          label="Quantity:"
          spacing="fixed"
          testID={`product-details-data-point-quantity-${testID}`}
        >
          {quantityWithUom}
        </DataPoint>
      )}
      {!!initialQuantityWithUom && (
        <DataPoint
          label="Initial quantity:"
          spacing="fixed"
          testID={`product-details-data-point-initial-quantity-${testID}`}
        >
          {initialQuantityWithUom}
        </DataPoint>
      )}
      {!!product.type && (
        <DataPoint
          label="Product type:"
          spacing="fixed"
          testID={`product-details-data-point-product-type-${testID}`}
        >
          {FriendlyProductType[product.type]}
        </DataPoint>
      )}
      {showDaysToMaturity && (
        <DataPoint
          label="Days to maturity:"
          spacing="fixed"
          testID={`product-details-data-point-days-to-maturity-${testID}`}
        >
          {product.daysToMaturity}
        </DataPoint>
      )}
      {showTraitPackage && (
        <DataPoint
          label="Trait package:"
          spacing="fixed"
          testID={`product-details-data-point-trait-package-${testID}`}
        >
          {product.traitPackage}
        </DataPoint>
      )}
      {showTraitPackageDetails && (
        <DataPoint
          label="Trait package details:"
          spacing="fixed"
          testID={`product-details-data-point-trait-package-details-${testID}`}
        >
          {product.traitPackageDetails}
        </DataPoint>
      )}
      {showTreatment && (
        <DataPoint
          label="Treatment:"
          spacing="fixed"
          testID={`product-details-data-point-treatment-${testID}`}
        >
          {product.seedTreatment}
        </DataPoint>
      )}
      {showCrop && (
        <DataPoint
          label="Crop:"
          spacing="fixed"
          testID={`product-details-data-point-crop-${testID}`}
        >
          {product.crop}
        </DataPoint>
      )}
      {showPackageType && (
        <DataPoint
          label="Package type:"
          spacing="fixed"
          testID={`product-details-data-point-package-type-${testID}`}
        >
          {product.package}
        </DataPoint>
      )}
      {showPreferredManufacturer && (
        <DataPoint
          label="Preferred manufacturer:"
          spacing="fixed"
          testID={`product-details-data-point-preferred-manufacturer-${testID}`}
        >
          {product.preferredManufacturer}
        </DataPoint>
      )}
    </Stack>
  );
};
