import { Button, Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Action } from '@/components/shared/ListSelector/helper';
import { PromotionChip } from '@/components/shared/Promotion/PromotionChip';
import { ApiProduct, ApiPromotion } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import React from 'react';

interface ProductCardProps {
  farmerPromotions: ApiPromotion[],
  onAction: (action: Action) => void,
  product: ApiProduct,
  retailerPromotions: ApiPromotion[],
  selectedIds: string[],
}

export const ProductCard = ({
  farmerPromotions,
  onAction,
  product,
  retailerPromotions,
  selectedIds,
}: ProductCardProps) => {
  const isProductAdded = selectedIds.includes(product.id);
  return (
    <Card testID={`product-${product.id}-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="title-small">{product.name}</Text>
          {!!(farmerPromotions.length || retailerPromotions.length) && (
            <>
              <VSpacer size="4" />
              <Stack direction="row">
                {!!farmerPromotions.length && (
                  <>
                    <PromotionChip
                      promotions={farmerPromotions}
                      targetUserType={PromotionTargetUserType.Farmer}
                    />
                    <HSpacer size="4" />
                  </>
                )}
                {!!retailerPromotions.length && (
                  <PromotionChip
                    promotions={retailerPromotions}
                    targetUserType={PromotionTargetUserType.Retailer}
                  />
                )}
              </Stack>
            </>
          )}
        </Stack>
        <Stack>
          {isProductAdded ? (
            <Button
              onClick={() => onAction(Action.RemoveAll)}
              testID={`product-${product.id}-remove-button`}
              variant="text"
            >
              Remove
            </Button>
          ) : (
            <Button
              onClick={() => onAction(Action.AddAll)}
              startIcon={<AddIcon />}
              testID={`product-${product.id}-add-button`}
              variant="outlined"
            >
              Add
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
