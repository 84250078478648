import { Text, Input, Button } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Alert,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { LocationFormProps } from './Types';
import { getOriginalFileName } from '@shared/utilities';
import { useRef } from 'react';

const styledSpankey = (key: string) => (
  <span style={{ color: '#C9C6BE' }}>{key}:</span>
);

const styledSpanValue = (value: string) => (
  <span style={{ color: '#fff' }}>{value}</span>
);

const RemoveImage = (onRemove: () => void) => (
  <Text
    color='primary'
    onClick={onRemove}
    sx={{ display: 'inline-block', cursor: 'pointer', marginLeft: '10px' }}
    testID='remove-image'
  >
    remove
  </Text>
);

const LocationForm = ({
  location,
  areAnyInputsEmpty,
  error,
  image,
  isAddLocationLoading,
  isEditDisabled,
  onAddMember,
  onClose,
  onImageChange,
  onInputChange,
  selectedImage,
  show,
  showMemberForm,
  onSave,
  isEdit,
  onRemove,
}: LocationFormProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  return (
    <Dialog
    fullWidth
    onClose={onClose}
    open={show}
    scroll='body'
    sx={{
      display: showMemberForm ? 'none' : 'block',
    }}
  >
    <DialogContent sx={{
      background: '#181817',

    }}>
      <Stack spacing={3}>
        {!!error?.serverError && (
          <Alert color={'error'} icon={false}>
            {error?.serverError}
          </Alert>
        )}
        <Text category='h6'>{isEdit ? 'Edit' : 'Add'} Location</Text>
        <Input
          label='Location Name'
          name='name'
          onChangeText={(e) => onInputChange(e, 'name')}
          required
          testID='location-name'
          value={location.name}
        />
        {!!error?.imageError?.length && (
          <Text color='error' sx={{ fontSize: '12px' }}>
            {error?.imageError}
          </Text>
        )}
        {
          !error?.imageError?.length && (
            <Text sx={{ fontSize: '12px', fontFamily: 'roboto' }}>
              <>
              {styledSpankey('File type')} {styledSpanValue('PNG')} &nbsp; &nbsp; &nbsp;
              {styledSpankey('Ratio')} {styledSpanValue('1:1')} &nbsp; &nbsp; &nbsp;
              {styledSpankey('Size')} {styledSpanValue('220 x 220 pixels')} &nbsp; &nbsp; &nbsp;
              {styledSpankey('Max file size')} {styledSpanValue('1 MB')} &nbsp; &nbsp; &nbsp;
              </>
            </Text>
          )
        }
        <Box
          onClick={() => fileUploadRef.current?.click()}
          style={{
            alignItems: 'center',
            border: '0.7px dashed grey',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {!location.image && !selectedImage && (
            <MuiButton
              data-testid='location-upload-logo'
              startIcon={<UploadIcon />}
              sx={{
                color: 'white',
                borderColor: 'white',
                margin: '25px auto',
              }}
              variant='outlined'
            >
              Upload logo
              <input
                accept='image/png'
                id='raised-button-file'
                onChange={onImageChange}
                ref={fileUploadRef}
                style={{ display: 'none' }}
                type='file'
              />
            </MuiButton>
          )}
          {location.image && (
            <Stack alignItems={'center'}>
            <img
              alt={location.image}
              height={'60%'}
              src={`${AppConfig.staticImageHost}/${location.image}`}
              style={{ objectFit: 'cover', padding: '20px 0px' }}
              width={'60%'}
            />
            <Stack direction='row' justifyContent={'space-between'}>
                <Text
                  category='body-medium'
                  sx={{
                    display: 'inline-block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {getOriginalFileName(location.image)}
                </Text>
                {RemoveImage(onRemove)}
              </Stack>
            </Stack>
          )}
          {selectedImage && (
            <Stack alignItems={'center'}>
              <img
                alt={image.name}
                height={'60%'}
                src={URL.createObjectURL(selectedImage)}
                style={{ objectFit: 'cover', padding: '20px 0px' }}
                width={'60%'}
              />
              <Stack direction='row' justifyContent={'space-between'}>
                <Text
                  category='body-medium'
                  sx={{
                    display: 'inline-block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {image.name}
                </Text>
                {RemoveImage(onRemove)}
              </Stack>
            </Stack>
          )}
        </Box>
        <Input
          label='Address Line 1'
          name='address1'
          onChangeText={(e) => onInputChange(e, 'address1')}
          required
          testID='location-address-line-1'
          value={location.address1}
        />
        <Input
          label='Address Line 2'
          name='address2'
          onChangeText={(e) => onInputChange(e, 'address2')}
          testID='location-address-line-2'
          value={location.address2}
        />
        <Stack alignItems='center' direction='row' spacing={2}>
          <Input
            label='City'
            name='city'
            onChangeText={(e) => onInputChange(e, 'city')}
            required
            testID='location-city'
            value={location.city}
          />
          <Input
            label='Zip Code'
            maxLength={5}
            name='postal'
            onChangeText={(value) => onInputChange(value, 'postal')}
            pattern='[0-9]*'
            required
            testID='location-postal-code'
            value={location.postal}
          />
        </Stack>
      </Stack>
    </DialogContent>
    <DialogActions sx={{
      background: '#181817',
      paddingTop: '32px',
      paddingBottom: '32px',
    }}>
      <Stack direction='row' justifyContent={'space-between'} width={'100%'}>
      <Button
        disabled={isAddLocationLoading}
        onClick={onClose}
        style={{
          color: 'white',
        }}
        testID='location-cancel'
        variant='text'
      >
        Cancel
      </Button>
      <Stack direction='row' spacing={2}>
      {!isEdit && (
        <Button
        disabled={
          areAnyInputsEmpty
          || isEditDisabled
          || !!error?.serverError
          || isAddLocationLoading
        }
        onClick={onAddMember}
        testID='location-add-member'
        variant='outlined'
      >
        Add Member
      </Button>
      )}
        <Button
        disabled={areAnyInputsEmpty || isEditDisabled || !!error?.serverError}
        loading={isAddLocationLoading}
        onClick={onSave}
        testID='location-save'
      >
        Save
      </Button>
      </Stack>
      </Stack>
    </DialogActions>
  </Dialog>
  );
};
export default LocationForm;
