import { Text, Card, VSpacer } from '@/components/DesignSystem';
import { Stack } from "@mui/material";

interface OfferFarmerNoteProps {
  note: string,
}

const OfferFarmerNote = ({
  note,
}: OfferFarmerNoteProps) => {

  return (
    <Card testID="offer-farmer-note">
      <Stack>
        <Text category="h6">Farmer Note</Text>
        <VSpacer size="3" />
        <Text category="p2">
          {note}
        </Text>
      </Stack>
    </Card>
  );
};

export default OfferFarmerNote;


