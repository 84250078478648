import { Chip, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Color } from '@/themes/MUITheme/palette';
import EnergySavingsLeaf from '@mui/icons-material/EnergySavingsLeaf';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import { Divider, Stack } from '@mui/material';

export const ChipDemo = () => {
  const ChipColors: Color[] = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'error',
  ];
  return (
    <>
      <Text>Colors</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {ChipColors.map((color) => (
          <Chip
            color={color}
            key={color}
            label={color}
            testID={color}
          />
        ))}
      </Stack>
      <VSpacer size="5" />
      <Text>Filled chip</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Chip
          label="Enabled"
          onDelete={() => alert("On Delete")}
          testID="filled-enabled"
          variant="filled"
        />
        <Chip
          disabled
          label="Disabled"
          onDelete={() => alert("On Delete")}
          testID="filled-disabled"
          variant="filled"
        />
        <Chip
          label={
            <Stack alignItems="center" direction="row">
              <EnergySavingsLeaf />
              <HSpacer size="3" />
              Enabled
            </Stack>
          }
          onDelete={() => alert("On Delete")}
          testID="filled-enabled-2"
          variant="filled"
        />
      </Stack>
      <VSpacer size="5" />
      <Text>Outlined chip</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Chip
          label="Enabled"
          onDelete={() => alert("On Delete")}
          testID="outlined-enabled"
          variant="outlined"
        />
        <Chip
          disabled
          label="Disabled"
          onDelete={() => alert("On Delete")}
          testID="outlined-disabled"
          variant="outlined"
        />
        <Chip
          label={
            <Stack alignItems="center" direction="row">
              <EnergySavingsLeaf color="primary" />
              <HSpacer size="3" />
              Enabled
            </Stack>
          }
          onDelete={() => alert("On Delete")}
          testID="outlined-enabled-2"
          variant="outlined"
        />
      </Stack>
      <VSpacer size="5" />
      <Text>Rounded chip</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Chip
          label={
            <Stack alignItems="center" direction="row" padding={0} >
              <AccountCircleRounded color="primary" />
              <HSpacer size="2" />
              Enabled
            </Stack>
          }
          onDelete={() => alert("On Delete")}
          rounded
          testID="outlined-enabled-3"
          variant="outlined"
        />
        <Chip
          disabled
          label={
            <Stack alignItems="center" direction="row" padding={0} >
              <AccountCircleRounded color="primary" />
              <HSpacer size="2" />
              Disabled
            </Stack>
          }
          onDelete={() => alert("On Delete")}
          rounded
          testID="outlined-disabled-2"
          variant="outlined"
        />
      </Stack>
    </>

  );
};
