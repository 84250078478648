import { DataPoint, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { Stack } from "@mui/material";
import { StarTag } from "@/components/shared/StarTag";
import { formatCurrency } from "@shared/utilities";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { InlineMessage } from "@/components/DesignSystem/InlineMessage/InlineMessage";
import { LastLookData } from "../ReceivePlaceOffers/interfaces";

export interface LastLookPricesProps {
  lastLookData: LastLookData,
  product: OfferProduct,
  testID: string,
}

export const LastLookPrices = ({
  lastLookData,
  product,
  testID,
}: LastLookPricesProps) => {
  const lowestOverallPrices = !lastLookData?.lowestPrice
    ? undefined
    : Object
      .values(lastLookData.lowestPrice)
      .map((products) => products.find((price) => (
        price.pricingRequestProductId === product.pricingRequestProductId
      )));

  const lowestPriceSameOrganizations = !lastLookData?.sameOrganization?.prices
    ? undefined
    : Object
      .values(lastLookData.sameOrganization.prices)
      .map((products) => products.find((price) => (
        price.pricingRequestProductId === product.pricingRequestProductId
      )));


  const SubstituteProductText = (testIDSuffix: string) => (
      <>
        <VSpacer size="2" />
        <Text
          category="body-small"
          testID={`${testID}-substitute-product-label-${testIDSuffix}`}
        >
          For a substitute product
        </Text>
      </>
  );

  return (
    <>
      {lowestOverallPrices?.map((lowestOverallPrice) => !lowestOverallPrice ? null : (
        <Stack key={lowestOverallPrice.uom}>
          <Text
            category="label-medium"
            testID={`${testID}-most-competitive-offer-label`}
            upperCase
          >
            most competitive offer
          </Text>
          {!!lowestOverallPrice.substituteProduct && SubstituteProductText('lowest')}
          <VSpacer size="3"/>
          <Stack alignItems="flex-start" direction="row">
            {!!lowestOverallPrice.quantity && !!lowestOverallPrice.uom && (
              <>
                <InlineMessage
                  icon={<StarTag/>}
                  testID={`${testID}-most-competitive-price-per-uom`}
                  textWidth={130}
                  title={`${formatCurrency(lowestOverallPrice.price / lowestOverallPrice.quantity)} 
                    /${FriendlyUoM[lowestOverallPrice.uom]}`}
                />
                <HSpacer size="7"/>
              </>
            )}
            <DataPoint
              containerStyle={{ py: 0, minWidth: 130 }}
              label="Total:"
              testID={`${testID}-most-competitive-price-total`}
            >
              {formatCurrency(lowestOverallPrice.price)}
            </DataPoint>
          </Stack>
        </Stack>
      ))}
      {lowestPriceSameOrganizations?.map(
        (lowestPriceSameOrganization) => !lowestPriceSameOrganization ? null : (
          <Stack key={lowestPriceSameOrganization.uom}>
            <VSpacer size="3"/>
            <Text
              category="body-small"
              testID={`${testID}-preferred-prices-label`}
            >
              {`From ${lastLookData?.sameOrganization?.organizationName}`}
            </Text>
            {
              !!lowestPriceSameOrganization.substituteProduct
              && SubstituteProductText('same-org')
            }
            <VSpacer size="3"/>
            <Stack alignItems="flex-start" direction="row">
              {!!lowestPriceSameOrganization.quantity && !!lowestPriceSameOrganization.uom && (
                <>
                  <InlineMessage
                    icon={<StarTag/>}
                    testID={`${testID}-preferred-most-competitive-price-per-uom`}
                    textWidth={130}
                    title={`${formatCurrency(lowestPriceSameOrganization.price / lowestPriceSameOrganization.quantity)} 
                    /${FriendlyUoM[lowestPriceSameOrganization.uom]}`}
                  />
                  <HSpacer size="7"/>
                </>
              )}
              <DataPoint
                containerStyle={{ py: 0, minWidth: 130 }}
                label="Total:"
                testID={`${testID}-preferred-most-competitive-price-total`}
              >
                {formatCurrency(lowestPriceSameOrganization.price)}
              </DataPoint>
            </Stack>
          </Stack>
        ),
      )}
    </>
  );
};
