import { Text, VSpacer } from "@/components/DesignSystem";
import { Filter } from '@/components/DesignSystem/Filter/Filter';
import { QueryKeys } from "@/constants/QueryKeys";
import ViewOffersItem from '@/pages/ViewOffers/ViewOffersItem';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { debounce } from "@/utilities/debounce";
import { PricingRequestEndpoint } from "@api/endpoints";
import { ApiPricingRequestPublic, PaginatedData } from "@api/interfaces";
import { Alert, Container, Pagination, Skeleton, Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";

export const ViewOffers = () => {
  const [debouncedSearch, setDebouncedSearch] = (
    useState<PricingRequestEndpoint.ListForRetailer.Query>({})
  );
  const [results, setResults] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFilters, setSelectedFilters] = (
    useState<PricingRequestEndpoint.ListForRetailer.Query>({})
  );

  const { data: pricingRequests, isLoading } = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS_FOR_RETAILER, debouncedSearch],
    () => PricingRequestsApi.getPricingRequestsForRetailerUser(selectedFilters),
    {
      keepPreviousData: true,
      onSuccess: (data: PaginatedData<ApiPricingRequestPublic>) => {
        setResults(data.total);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
    },
  );

  useEffect(() => debounce(() => setDebouncedSearch(selectedFilters), 600), [selectedFilters]);

  return (
    <>
      <Container maxWidth="md">
        <Stack>
          <VSpacer size="8" />
          <Text category="h3">Requests and Offers</Text>
          <VSpacer size="9" />
          <Stack>
            <Filter<PricingRequestEndpoint.ListForRetailer.Query>
              filters={[]}
              onChange={(filters) => setSelectedFilters(filters)}
              recordName="Product Request"
              testID="view-offers-filter"
              totalResults={results}
            />
          </Stack>
          <VSpacer size="3" />
          {
            !!errorMessage && (
              <Alert color="error" icon={false}>{errorMessage}</Alert>
            )
          }
          {
            isLoading && [1,2,3,4,5,6,7].map((index) => (
              <Fragment key={index}>
                <Skeleton height={76} variant="rectangular" />
                <VSpacer mobileSize="5" size="3" />
              </Fragment>
            ))
          }
          {
            pricingRequests?.data.map((item: ApiPricingRequestPublic, index) => (
              <Fragment key={item.id}>
                <ViewOffersItem index={index} pricingRequest={item} />
                <VSpacer mobileSize="5" size="3" />
              </Fragment>
            ))
          }
          {
            pricingRequests && pricingRequests.lastPage !== 0 &&
            <Stack alignItems="center">
              <VSpacer size="9" />
              <Pagination
                count={pricingRequests.lastPage + 1}
                onChange={
                  (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
                }
                page={pricingRequests.page + 1}
              />
            </Stack>
          }
        </Stack>
      </Container>
    </>
  );
};
