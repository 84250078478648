import { TextList } from '@/components/shared/TextList/TextList';

export const TextListDemo = () => {
  return (
    <TextList
      items={[
        'Price',
        'Substitute product',
        'Quantity recommendation',
      ]}
      testID="text-list-demo"
      title="This action will permanently delete:"
    />
  );
};
