import { Client } from '@/utilities/api/Client';
import {
  AlternativeProductEndpoint,
  CompanionProductEndpoint,
  PrimaryNutrientEndPoint,
} from '@api/endpoints';
import { CategoryEndPoint } from '@api/endpoints/CategoryEndPoint';
import { CropEndpoint } from '@api/endpoints/CropEndpoint';
import { ManufacturerEndpoint } from '@api/endpoints/ManufacturerEndpoint';
import { PackageEndpoint } from '@api/endpoints/PackageEndpoint';
import { PracticeEndpoint } from '@api/endpoints/PracticeEndpoint';
import { ProductEndpoint } from '@api/endpoints/ProductEndpoint';
import { UomEndpoint } from '@api/endpoints/UomEndpoint';
import { ApiProduct } from '@api/interfaces';
import { QueryUtil } from '../Query';

export class ProductApi {
  static list (): Promise<ProductEndpoint.ProductList.Response> {
    return Client('product/');
  }
  static getCategories (): Promise<CategoryEndPoint.List.Response> {
    return Client(`category/`);
  }
  static getPrimaryNutrient (): Promise<PrimaryNutrientEndPoint.ListPrimaryNutrient.Response> {
    return Client(`primary-nutrient/`);
  }
  static getActiveIngredients (): Promise<any> {
    return Client(`active-ingredients/`);
  }
  static getManufacturers (
    filters: ManufacturerEndpoint.List.Query,
  ): Promise<ManufacturerEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`manufacturers?${query}`);
  }
  static getCrop (): Promise<CropEndpoint.ListCrop.Response> {
    return Client(`crop/`);
  }
  static getPractice (): Promise<PracticeEndpoint.ListPractice.Response> {
    return Client(`practice/`);
  }

  static getUoM (): Promise<UomEndpoint.ListUom.Response> {
    return Client(`uom/`);
  }
  static getPackageSize (): Promise<PackageEndpoint.ListPackage.Response> {
    return Client(`package/`);
  }
  static create (request: ProductEndpoint.Create.Request):
    Promise<ProductEndpoint.Create.Response> {
    return Client(`product/`, { body: request, method: 'POST' });
  }
  static update (request: ProductEndpoint.Create.Request, id: string):
    Promise<ProductEndpoint.Update.Response> {
    return Client(`product/${id}/`, { body: request, method: 'PATCH' });
  }
  static getOneProductList (id: string, isDetailed = false): Promise<ApiProduct> {
    const query = QueryUtil.stringify({ isDetailed });
    return Client(`product/${id}?${query}`);
  }
  static delete (id: string): Promise<ProductEndpoint.ProductList.Response> {
    return Client(`product/${id}/`, { method: 'DELETE' });
  }
  static productListData (filters: ProductEndpoint.ProductList.Query):
    Promise<ProductEndpoint.ProductList.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`product?${query}`);
  }

  static getProductList (
    query: ProductEndpoint.ProductList.Query,
  ): Promise<ProductEndpoint.ProductList.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`product-list?${queryString}`, { method: 'GET' });
  }

  static getCompanions (
    id: string,
    filter: CompanionProductEndpoint.ListCompanionProduct.Query,
  ): Promise<CompanionProductEndpoint.ListCompanionProduct.Response> {
    const query = QueryUtil.stringify({ ...filter });
    return Client(`companion-product/${id}?${query}`);
  }

  static getAlternatives (
    id: string,
    filter: AlternativeProductEndpoint.ListAlternativeProduct.Query,
  ): Promise<AlternativeProductEndpoint.ListAlternativeProduct.Response> {
    const query = QueryUtil.stringify({ ...filter });
    return Client(`alternative-product/${id}?${query}`);
  }

  static get (
    id: string,
    isDetailed?: boolean,
  ): Promise<ApiProduct> {
    if (isDetailed) {
      const query = QueryUtil.stringify({ isDetailed });
      return Client(`product/${id}?${query}`);
    } else {
      return Client(`product/${id}`);
    }
  }
}
