import { ResponsiveDialog } from '@/components/shared/ResponsiveDialog/ResponsiveDialog';
import { useState } from 'react';
import { Button, Text } from '@/components/DesignSystem';

export const ResponsiveDialogDemo = () => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => {
          setShow(true);
        }}
        testID="responsive-dialog-demo-show-button"
      >
        Show responsive dialog
      </Button>
      {show && (
        <ResponsiveDialog
          acceptButton={() => (
            <Button testID="responsive-dialog-demo-save-button" variant="text">
              Save
            </Button>
          )}
          cancelButton={() => (
            <Button
              onClick={() => setShow(false)}
              testID="responsive-dialog-demo-save-button"
              variant="text"
            >
              Cancel
            </Button>
          )}
          onClose={() => setShow(false)}
          testID="responsivedialog-demo"
          title="Responsive dialog demo"
        >
          <Text category="body-medium">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </ResponsiveDialog>
      )}
    </>
  );
};
