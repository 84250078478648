import { Client } from './Client';

interface ChatUser {
  _id: string;
  name: string
}
interface ChatLog {
  sender: ChatUser;
  message: string;
  createdAt: string;
}
interface ChatLogs {
  messages: ChatLog[];
  farmer: { id: string; name: string };
  retailer: { id: string; name: string };
}

export class ChatLogsApi {
  static getChatLogs (chatId: string, publicId: string): Promise<ChatLogs> {
    return Client(`users/chat/${chatId}/${publicId}`);
  }
}
