import {
  Button,
  Card,
  HSpacer,
  IconButton,
  InfoTooltip,
  Input,
  Modal,
  NumericInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import {
  isCategoryHurdleInBushels,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/helpers';
import {
  PreciousMetals,
  Tier,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal';
import { ApiCategory } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import { SharedConfig } from '@shared/SharedConfig';
import { Modify } from '@shared/utilities/UtilityTypes';
import { round } from 'lodash';
import { useEffect, useState } from 'react';

const { rewardsMaxTiers, rewardsMaxRewardPercent } = SharedConfig.settings;

type EditableTier = Modify<Tier,
    {
      minimumSegments?: number,
      segmentMinimumAmounts: { [categoryId: string]: number | undefined },
      segmentRewards: { [categoryId: string]: number | undefined },
    }
  > & {
    isDefault?: boolean,
  };

const DefaultTier: EditableTier = {
  isDefault: true,
  minimumDollars: 0,
  minimumSegments: undefined,
  name: '',
  segmentMinimumAmounts: {},
  segmentRewards: {},
};

interface RewardsTiersModalProps {
  categories: ApiCategory[],
  isPreciousMetals: boolean,
  existingTiers?: Tier[],
  onClose: () => void,
  onSave: (tiers: Tier[]) => void,
}

export const RewardsTiersModal = ({
  categories,
  existingTiers,
  isPreciousMetals,
  onClose,
  onSave,
}: RewardsTiersModalProps) => {
  const preciousMetalTiers = Object.values(PreciousMetals).map((metal) => {
    const isDefault = metal === PreciousMetals.Bronze;
    return {
      ...DefaultTier,
      isDefault,
      minimumSegments: isDefault ? 0 : undefined,
      name: metal,
    };
  });
  const defaultNewTiers = isPreciousMetals
    ? preciousMetalTiers : [{ ...DefaultTier, minimumSegments: 0 }];
  let initialTiers: EditableTier[] = existingTiers?.map((tier) => ({
    ...tier,
    isDefault: !tier.minimumSegments && !tier.minimumDollars,
    segmentRewards: Object.fromEntries(Object.entries(tier.segmentRewards).map(
      ([key, value]) => (
        [key, value ? round(value * 100, 2) : undefined]
      ),
    )),
  })) ?? [];
  if (isPreciousMetals) {
    initialTiers = initialTiers.filter(
      (tier) => (Object.values(PreciousMetals) as string[]).includes(tier.name),
    );
  }
  if (initialTiers.length === 0) {
    initialTiers = defaultNewTiers;
  }
  const [tiers, setTiers] = useState<EditableTier[]>(initialTiers);
  const [selectedTierIndex, setSelectedTierIndex] = useState<number>(0);

  const selectedTier = tiers.at(selectedTierIndex)!;

  let minimumSegmentsError: string = '';
  if (
    !!selectedTier.minimumSegments
    && selectedTier.minimumSegments > categories.length
  ) {
    minimumSegmentsError = `Cannot exceed the total number of categories (${categories.length})`;
  }
  if (selectedTier.minimumSegments !== undefined && tiers.some((tier, i) => (
    i !== selectedTierIndex
    && selectedTier.minimumSegments === tier.minimumSegments
  ))) {
    minimumSegmentsError = 'Cannot have the same minimum segments as another tier';
  }
  const isDuplicateName = tiers.some((tier, i) => (
    i !== selectedTierIndex && tier.name === selectedTier.name
  ));
  const isValid = !tiers.some((tier) => (
    !tier.name
    || tier.minimumSegments === undefined
    || !!minimumSegmentsError
    || Object.values(tier.segmentMinimumAmounts).some((amount) => amount === undefined)
    || Object.values(tier.segmentRewards).some((amount) => (
      !amount || amount > (rewardsMaxRewardPercent * 100)
    ))
  ));

  const handleAddTier = () => {
    let newTiers = [...tiers, { ...DefaultTier, isDefault: false }];
    if (isPreciousMetals) {
      newTiers = [...preciousMetalTiers];
      newTiers.forEach((newTier, index) => {
        const existingMetalTier = tiers.find((tier) => tier.name === newTier.name);
        if (existingMetalTier) {
          newTiers[index] = { ...existingMetalTier };
        }
      });
    }
    setTiers(newTiers);
    if (!isPreciousMetals) {
      setSelectedTierIndex(tiers.length);
    }
  };

  const handleDeleteTier = (index: number) => {
    setTiers(tiers.filter((_, i) => i !== index));
    if (selectedTierIndex >= index) {
      setTiers(tiers.filter((_, i) => i !== index));
      setSelectedTierIndex(selectedTierIndex - 1);
    }
  };

  const handleUpdateSelectedTier = (updates: Partial<EditableTier>) => {
    const newTiers = [...tiers];
    newTiers[selectedTierIndex] = {
      ...newTiers[selectedTierIndex],
      ...updates,
    };
    setTiers(newTiers);
  };

  const handleSave = () => {
    const tiersToSave = tiers.map((tier) => ({
      ...tier,
      segmentRewards: Object.fromEntries(Object.entries(tier.segmentRewards).map(([key, value]) => (
        [key, value === undefined ? undefined : round(value / 100, 5)]
      ))),
    })) as Tier[];
    onSave(tiersToSave);
  };

  useEffect(() => {
    if (categories) {
      const tiersWithCategories = tiers.map((tier) => ({
        ...tier,
        segmentMinimumAmounts: {
          ...Object.fromEntries(categories.map(({ id }) => [id, tier.isDefault ? 0 : undefined])),
          ...tier.segmentMinimumAmounts,
        },
        segmentRewards: {
          ...Object.fromEntries(categories.map(({ id }) => [id, undefined])),
          ...tier.segmentRewards,
        },
      }));
      setTiers(tiersWithCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, tiers.length]);

  const TierSelector = (
    <Stack minWidth="380px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {tiers.length}/{rewardsMaxTiers} tiers
        </Text>
        <Button
          disabled={tiers.length >= rewardsMaxTiers}
          onClick={handleAddTier}
          startIcon={<Add />}
          testID="tiers-modal-add-tier-button"
          variant="text"
        >
          Add
        </Button>
      </Stack>
      <List sx={{ paddingX: '1px' }}>
        {tiers.map((tier, i) => (
          <>
            <ListItem
              key={i}
              onClick={() => setSelectedTierIndex(i)}
              secondaryAction={tier.isDefault ? (
                <Text category="label-medium">
                  Default
                </Text>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    handleDeleteTier(i);
                    e.stopPropagation();
                  }}
                  testID={`tiers-modal-delete-tier-button-${i}`}
                >
                  <DeleteOutline />
                </IconButton>
              )}
              sx={[
                {
                  bgcolor: '#212120',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  height: '56px',
                },
                i === selectedTierIndex
                  ? { outline: '1px solid yellow' }
                  : { cursor: 'pointer' },
              ]}
            >
              <ListItemText
                primary={tier.name || 'Untitled'}
              />
            </ListItem>
            {i < tiers.length - 1 && (
              <VSpacer size="3" />
            )}
          </>
        ))}
      </List>
    </Stack>
  );

  const TierEditor = (
    <Card sx={{ maxHeight: 570, overflowY: 'auto', width: '100%' }} testID="tiers-modal-editor">
      <Stack direction="row">
        <Text fontSize="18px" fontWeight={500}>
          Details
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          The tier name must be unique to this retailer rewards program.
          The lowest tier within the program must default the minimum total spend ($)
          and minimum total segments to 0
        </InfoTooltip>
      </Stack>
      <VSpacer size="5" />
      <Input
        disabled={isPreciousMetals}
        error={isDuplicateName}
        helperText={isDuplicateName && 'Tier name must be unique'}
        label="Tier Name"
        onChangeText={(name) => handleUpdateSelectedTier({ name })}
        testID="tiers-modal-name-input"
        value={selectedTier.name}
      />
      <VSpacer size="7" />
      <Stack direction="row">
        <NumericInput
          disabled
          label="Minimum total spend ($)"
          onChangeNumber={(minimumDollars) => handleUpdateSelectedTier({ minimumDollars })}
          prefix="$"
          showFixedDecimals
          testID="tiers-modal-minimum-dollars-input"
          value={selectedTier.minimumDollars}
        />
        <HSpacer size="5" />
        <NumericInput
          decimals={0}
          disabled={selectedTier.isDefault}
          error={!!minimumSegmentsError}
          helperText={minimumSegmentsError}
          label="Minimum total segments"
          minValue={0}
          onChangeNumber={(minimumSegments) => handleUpdateSelectedTier({ minimumSegments })}
          testID="tiers-modal-minimum-segments-input"
          value={selectedTier.minimumSegments}
        />
      </Stack>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="5" />
      <Stack direction="row">
        <Text fontSize="18px" fontWeight={500}>
          Segments
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          Each of the retailer's product categories are listed below.
          The minimum hurdle and loyalty percentage must be set for each product category.
          The only exception is that a minimum hurdle value is not allowed to be entered
          for the default tier within the program
        </InfoTooltip>
      </Stack>
      {categories?.map((category, i) => (
        <>
          <VSpacer size="5" />
          <Text category="title-medium">
            {category.name}
          </Text>
          <VSpacer size="3" />
          <Stack direction="row">
            <NumericInput
              disabled={selectedTier.isDefault}
              label={`Minimum hurdle (${isCategoryHurdleInBushels(category.name!) ? 'in bushels' : '$'})`}
              minValue={0}
              onChangeNumber={(minimumAmount) => {
                const newMinimumAmounts = {
                  ...selectedTier.segmentMinimumAmounts,
                };
                if (minimumAmount === undefined) {
                  delete newMinimumAmounts[category.id];
                } else {
                  newMinimumAmounts[category.id] = minimumAmount;
                }
                handleUpdateSelectedTier({ segmentMinimumAmounts: newMinimumAmounts });
              }}
              prefix={isCategoryHurdleInBushels(category.name!) ? undefined : '$'}
              testID={`tiers-modal-${category.id}-amount-input`}
              value={selectedTier.segmentMinimumAmounts[category.id]}
            />
            <HSpacer size="5" />
            <NumericInput
              decimals={3}
              error={(
                selectedTier.segmentRewards[category.id] !== undefined
                && selectedTier.segmentRewards[category.id]! > rewardsMaxRewardPercent * 100
              )}
              helperText={(
                selectedTier.segmentRewards[category.id] !== undefined
                && selectedTier.segmentRewards[category.id]! > rewardsMaxRewardPercent * 100
              ) ? `Cannot exceed ${rewardsMaxRewardPercent*100}%` : undefined}
              label="Loyalty percentage (%)"
              minValue={.001}
              onChangeNumber={(rewards) => {
                const newRewards = {
                  ...selectedTier.segmentRewards,
                };
                newRewards[category.id] = rewards;
                handleUpdateSelectedTier({ segmentRewards: newRewards });
              }}
              suffix="%"
              testID={`tiers-modal-${category.id}-rewards-input`}
              value={selectedTier.segmentRewards[category.id]}
            />
          </Stack>
          {i !== categories.length - 1 && (
            <>
              <VSpacer size="5" />
              <Divider />
            </>
          )}
        </>
      ))}
    </Card>
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            handleSave();
          }}
          testID="tiers-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="tiers-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      largeModal
      onClose={onClose}
      open
      testID="tiers-modal"
      title="Add/Edit Tiers"
    >
      <Text>
        Create the rewards tiers that will be offered to the customers
      </Text>
      <VSpacer size="7" />
      <Text>
        A minimum of 1 tier is required. All fields are required.
      </Text>
      <VSpacer size="7" />
      <Stack direction="row">
        {TierSelector}
        <HSpacer size="7" />
        {TierEditor}
      </Stack>
    </Modal>
  );
};
