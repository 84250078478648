import { Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { Category } from '@/themes/variant-interfaces/Category';
import { Box, Stack } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import './ShowMoreText.css';

interface ShowMoreTextProps {
  maxLines?: number,
  text: string,
  textCategory?: Category,
}

export const ShowMoreText = ({
  maxLines = 4,
  text,
  textCategory = 'body-medium',
}: ShowMoreTextProps) => {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setClamped(!clamped);
  };

  const addClampStyle = () => {
    if (containerRef.current) {
      containerRef.current.classList.add('clamp');
      containerRef.current.style.webkitLineClamp = maxLines.toString();
    }
  };

  const removeClampStyle = () => {
    if (containerRef.current) {
      containerRef.current.classList.remove('clamp');
      containerRef.current.style.webkitLineClamp = 'initial';
    }
  };

  useEffect(() => {
    if (clamped) {
      addClampStyle();
    } else {
      removeClampStyle();
    }
  }, [clamped]);

  useEffect(() => {
    const hasClamping = (el: HTMLDivElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        const hadClampClass = containerRef.current.classList.contains('clamp');
        if (!hadClampClass) {
          addClampStyle();
        }
        setShowButton(hasClamping(containerRef.current));
        if (!hadClampClass) {
          removeClampStyle();
        }
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <Stack>
      <Box
        ref={containerRef}
      >
        <Text category={textCategory} testID="show-more-text">{text}</Text>
      </Box>
      {showButton && (
        <>
          <VSpacer size="2" />
          <TextLink category="label-medium" onClick={handleClick} testID="show-more-text-link">
            Show {clamped ? "more" : "less"}
          </TextLink>
        </>
      )}
    </Stack>
  );
};
