import { Button, Text, VSpacer, Input, TextLink, Dialog } from '@/components/DesignSystem';
import { useStorefront } from '@/hooks/useStorefront';
import { Stack } from "@mui/system";
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Routes } from '@/constants/Routes';
import { AuthApi } from '@/utilities/api/AuthApi';
import { Alert } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useLogEvent, CreateFarmerAccountEventType } from '@/utilities/Analytics';
import { GrowersContactInfo } from '@shared/enums';

export interface SetPasswordFormProps {
  isResetPasswordForm?: boolean,
  termsAndConditions?: boolean,
}

const SetPasswordForm = ({
  isResetPasswordForm,
  termsAndConditions,
}: SetPasswordFormProps) => {
  const [password, setPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [emailToken, setEmailToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);

  const navigate = useNavigate();
  const { authenticate } = useAuthentication();
  const logEvent = useLogEvent();
  const { storefront } = useStorefront();

  useEffect(() => {
    if (!isResetPasswordForm) {
      logEvent(CreateFarmerAccountEventType.SetInitialPassword);
    }
  }, []);

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      setIsInvalidToken(true);
    } else {
      setEmailToken(token);
    }
  }, [searchParams]);

  const { data: userData, isLoading: verifyingToken } = useQuery(
    [QueryKeys.VALIDATE_TOKEN],
    () => AuthApi.validateToken({ token: emailToken }),
    {
      enabled: !!emailToken,
      onError: () => {
        setIsInvalidToken(true);
      },
      retry: false,
    },
  );

  const { mutate: resetPassword, isLoading } = useMutation(
    () => AuthApi.resetPassword({ newPassword: password, token: emailToken }),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(
          error.message || "An error has occurred",
        );
      },
      onSuccess: async () => {
        if (!isResetPasswordForm) {
          await authenticate(userData?.email || "", password, storefront?.id);
          logEvent(CreateFarmerAccountEventType.SetPassword);
        }
        navigate(Routes.HOMEPAGE, { replace: true });
      },
    },
  );

  const isPasswordValid = useCallback(() => {
    return password.length >= 8
      && password === reenterPassword
      && userData?.firstName !== password
      && userData?.email !== password;
  }, [password, reenterPassword]);

  if (isInvalidToken) {
    return (
      <Stack>
        <Alert color="error" icon={false}>
          The link is invalid or it has expired. Please verify it and try again.
        </Alert>
      </Stack>
    );
  }

  return (
    <Stack>
      <Text category="h3">
        {isResetPasswordForm ? 'Set a new password' : 'Set your password'}
      </Text>
      <VSpacer mobileSize="9" size="10" />
      { !!errorMessage && (
        <>
          <Alert color="error" icon={false}>{errorMessage}</Alert>
          <VSpacer mobileSize="5" size="8" />
        </>
      )}
      <Input
        label="Password"
        onChangeText={setPassword}
        required
        testID="farmer-password-form"
        type="password"
        value={password}
      ></Input>
      <VSpacer size="7" />
      <Input
        label="Re-enter password"
        onChangeText={setReenterPassword}
        required
        testID="farmer-password-reenter-form"
        type="password"
        value={reenterPassword}
      ></Input>
      <VSpacer size="7" />
      <Stack textAlign="left">
        <Text category="p2">Password must:</Text>
        <ul style={{
          paddingLeft: 16,
          marginBottom: 0,
          fontSize: 14,
        }}>
          <li>Be at least 8 characters</li>
          <li>Not match your email address</li>
          <li>Not match your name</li>
        </ul>
      </Stack>
      <VSpacer mobileSize="13" size="11" />
      <Stack alignItems="center">
        <Button
          disabled={!isPasswordValid()}
          loading={isLoading || verifyingToken}
          onClick={() => resetPassword()}
          size="giant"
          testID="farmer-password-form-reset-button"
        >
          {isResetPasswordForm ? 'Reset password' : 'Set password'}
        </Button>
      </Stack>
      {termsAndConditions && (
        <>
          <VSpacer mobileSize="11" size="9" />
          <Text category='p2' paddingX='20px' textAlign='center'>
            By setting a password and using The GROWERS App you agree to the GROWERS&nbsp;
            <TextLink
              href={GrowersContactInfo.privacyPolicyLink}
              target='_blank'
              testID='privacy-policy'
            >
              Privacy policy
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              href={GrowersContactInfo.termsOfUseLink}
              target='_blank'
              testID='tos-link'
            >
              Terms of service
            </TextLink>
          </Text>
        </>
      )}
      <Dialog
        onClose={() => setShowTermsConditions(false)}
        open={showTermsConditions}
        testID="terms-and-conditions-dialog"
        title="TERMS OF SERVICE"
      >
        {termsAndConditions}
      </Dialog>
    </Stack>
  );
};

export default SetPasswordForm;
