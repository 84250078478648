import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import { RetailerLocationApi } from "@/utilities/api/RetailerLocationApi";
import { Resolved } from "@shared/utilities/UtilityTypes";
import { Filter, FilterOption } from "@/components/DesignSystem/Toolbar/interfaces";

export const useRetailerLocationFilter = (): Filter => {
  const { data: locations } = useQuery(
    [QueryKeys.GET_LOCATIONS],
    async () => RetailerLocationApi.list({ isActive: true, limit: 1000 }),
    { staleTime: 5 * 60 * 1000 },
  );

  const filterOptionsMap:
    Map<string, Resolved<FilterOption, 'subOptionLabel' | 'subOptions'>> = new Map();

  locations?.data.forEach(({ id, name, retailer }) => {
    if (!retailer) {
      return;
    }
    if (filterOptionsMap.has(retailer.id)) {
      filterOptionsMap.get(retailer.id)?.subOptions.push({
        id,
        label: name,
      });
    } else {
      filterOptionsMap.set(retailer.id, {
        id: retailer.id,
        label: retailer.name,
        subOptionLabel: 'Locations',
        subOptions: [{
          id,
          label: name,
        }],
      });
    }
  });

  return {
    id: 'retail-location',
    label: 'Retailer & Locations',
    options: [...filterOptionsMap.values()]
      .sort((a, b) => a.label.localeCompare(b.label)),
    selectionMethod: 'multi-select',
  };
};
