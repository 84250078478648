import { Box, Stack } from '@mui/material';
import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import React, { useState } from 'react';
import { ApiRetailer } from '@api/interfaces';
import {
  DeliverableTemplateModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/DeliverableTemplateModal';
import { LogoModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/LogoModal';
import { RetailerEndpoint } from '@api/endpoints';
import { BrandColorModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandColorModal';
import { FriendlyDeliverableTemplateType } from '@/constants/FriendlyDeliverableTemplateType';
import ConfirmModal from '@/components/shared/ConfirmModal';
import { BrandIdentityInput } from "@/pages/Admin/HierarchyOfRetailers/Retailer/BrandIdentityInput";

interface BrandIdentityInputsProps {
  onChange: (updates: RetailerEndpoint.Update.Request) => void,
  retailer: ApiRetailer,
}

export const BrandIdentityInputs = ({
  onChange,
  retailer,
}: BrandIdentityInputsProps) => {
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [showBrandColorModal, setShowBrandColorModal] = useState(false);
  const [showDeliverableTemplateModal, setShowDeliverableTemplateModal] = useState(false);
  const [showRemoveLogoDialog, setShowRemoveLogoDialog] = useState(false);
  const [showRemoveBrandColorDialog, setShowRemoveBrandColorDialog] = useState(false);
  const [showRemoveTemplateDialog, setShowRemoveTemplateDialog] = useState(false);

  return (
    <>
      <Stack>
        <Text category="body-xlarge">
          Brand Identity
        </Text>
        <VSpacer size="5"/>
        <Stack direction="row" gap="16px">
          <Stack display="flex" flex={1}>
            <BrandIdentityInput
              defaultText="No logo added"
              name="logo"
              onAddClicked={() => setShowLogoModal(true)}
              onEditClicked={() => setShowLogoModal(true)}
              onRemoveClicked={() => setShowRemoveLogoDialog(true)}
              titleText="Logo"
            >
              {retailer.image}
            </BrandIdentityInput>
          </Stack>
          <Stack display="flex" flex={1}>
            <BrandIdentityInput
              defaultText="No color added"
              name="brand-color"
              onAddClicked={() => setShowBrandColorModal(true)}
              onEditClicked={() => setShowBrandColorModal(true)}
              onRemoveClicked={() => setShowRemoveBrandColorDialog(true)}
              titleText="Brand color"
            >
              {retailer.brandColor && (
                <Stack direction="row">
                  <Box bgcolor={retailer.brandColor} height="16px" margin="2px" width="16px" />
                  <HSpacer size="3" />
                  <Text category="body-medium">
                    {retailer.brandColor}
                  </Text>
                </Stack>
              )}
            </BrandIdentityInput>
          </Stack>
          <Stack display="flex" flex={1}>
            <BrandIdentityInput
              defaultText="No template added"
              name="deliverable-template"
              onAddClicked={() => setShowDeliverableTemplateModal(true)}
              onEditClicked={() => setShowDeliverableTemplateModal(true)}
              onRemoveClicked={() => setShowRemoveTemplateDialog(true)}
              titleText="Deliverable template"
            >
              {retailer.templateType && FriendlyDeliverableTemplateType[retailer.templateType]}
            </BrandIdentityInput>
          </Stack>
        </Stack>
      </Stack>
      {showLogoModal && (
        <LogoModal
          imageFilename={retailer.image}
          onClose={() => setShowLogoModal(false)}
          onSave={(image) => onChange({ image })}
        />
      )}
      {showBrandColorModal && (
        <BrandColorModal
          brandColor={retailer.brandColor}
          onClose={() => setShowBrandColorModal(false)}
          onSave={(brandColor) => onChange({ brandColor })}
        />
      )}
      {showDeliverableTemplateModal && (
        <DeliverableTemplateModal
          onClose={() => setShowDeliverableTemplateModal(false)}
          onSaveBrandColor={(brandColor) => onChange({ brandColor })}
          onSaveTemplateType={(templateType) => onChange({ templateType })}
          retailer={retailer}
        />
      )}
      {showRemoveLogoDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this logo?
            </Text>
          }
          onCancel={() => setShowRemoveLogoDialog(false)}
          onConfirm={() => {
            onChange({ image: null });
            setShowRemoveLogoDialog(false);
          }}
          open
          testID="remove-logo-dialog"
        />
      )}
      {showRemoveBrandColorDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this brand color?
            </Text>
          }
          onCancel={() => setShowRemoveBrandColorDialog(false)}
          onConfirm={() => {
            onChange({ brandColor: null, templateType: null });
            setShowRemoveBrandColorDialog(false);
          }}
          open
          testID="remove-brand-color-dialog"
        />
      )}
      {showRemoveTemplateDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this deliverable template?
            </Text>
          }
          onCancel={() => setShowRemoveTemplateDialog(false)}
          onConfirm={() => {
            onChange({ templateType: null });
            setShowRemoveTemplateDialog(false);
          }}
          open
          testID="remove-template-dialog"
        />
      )}
    </>
  );
};
