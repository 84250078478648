import { Text, VSpacer } from '@/components/DesignSystem';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ApiBundle } from '@api/interfaces/ApiBundle';
import { Stack } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import React from 'react';

interface DeliverableBundleCardProps {
  bundle: ApiBundle,
}

export const DeliverableBundleCard = ({ bundle }: DeliverableBundleCardProps) => (
  <>
    <Stack
      alignItems="center"
      bgcolor="#ededed"
      borderRadius="4px 4px 0px 0px" p="12px 6px"
    >
      <Text category="label-small">
        PRODUCT BUNDLE
      </Text>
    </Stack>
    <Stack bgcolor="#f7f7f7" borderRadius="2px" p="16px">
      <Stack direction="row" justifyContent="space-between">
        <Text category="title-medium">{bundle.name}</Text>
        <Text category="title-medium">{formatCurrency(bundle.priceTotal ?? 0)}</Text>
      </Stack>
      <VSpacer size="2" />
      <Stack direction="row" justifyContent="space-between">
        <Text category="title-small">{bundle.quantity} {FriendlyUoM[bundle.uom]}</Text>
        <Text category="body-small">
          Unit Price: {formatCurrency(bundle.pricePerUnit ?? 0)}
        </Text>
      </Stack>
    </Stack>
  </>
);
