import {
  Button,
  Modal,
  VSpacer,
  Select,
  MenuItem,
} from "@/components/DesignSystem";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";
import { Alert } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { dateExtention } from "@/constants/ProductRequest";
import { confirmationText } from "@/constants/ProductRequest";
import { productDrawerConstant } from "@/constants/productConstant";
import { QueryKeys } from "@/constants/QueryKeys";


interface ExtendPricingRequestModalProps {
  onClose: () => void;
  pricingRequest: {
    id: string;
    expiration: Date;
    isExpired: boolean | undefined;
    reviewExpiration: Date | null | undefined;
  };
}
const ExtendedModal = ({
  onClose,
  pricingRequest,
}: ExtendPricingRequestModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [newExpiration, setNewExpiration] = useState<string>("");
  const { openSnackbar } = useSnackbar();
  const isOpen = !pricingRequest.isExpired && !pricingRequest.reviewExpiration;
  const queryClient = useQueryClient();
  const openDescription = confirmationText.extendWindow;
  const inReviewDescription = confirmationText.receivedOffer;

  const getUpdateBody = () => {
    const currentDate = DateTime.fromJSDate(pricingRequest.expiration);
    const addedDays = currentDate.plus({ days: Number(newExpiration) });
    const expirationDate = addedDays.toJSDate();
    if (isOpen) {
      return { expiration: expirationDate };
    }
    return { reviewExpiration: expirationDate };
  };

  const { mutate: extendExpiration } = useMutation(
    () =>
      PricingRequestsApi.extendExpiration(pricingRequest.id, getUpdateBody()),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
      onSuccess: async () => {
        openSnackbar('Expiration extended!');
        await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUEST);
        onClose();
      },
    },
  );

  const getSubLine = () => {
    if (isOpen) {
      return openDescription;
    }
    return inReviewDescription;
  };

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!newExpiration || !!errorMessage}
          onClick={() => extendExpiration()}
          testID="button-demo-enable"
        >
          {productDrawerConstant.save}
        </Button>
      )}
      cancelButton={() => (
        <Button onClick={onClose} testID="cancel"  variant="text">
          {productDrawerConstant.cancel}
        </Button>
      )}
      onClose={onClose}
      open
      subline={getSubLine()}
      testID="extend-pricing-request-modal"
      title="Extend window"
    >
      <Select
        label="Extended offer window by"
        name="extendedDays"
        onChange={(e) => {
          setNewExpiration(e.target.value  as string);
        }}
        required
        testID="extendedDays"
        value={newExpiration}
      >
        {dateExtention?.map((item: number) => {
          return (
            <MenuItem key={item} testID={`${item}days`} value={item}>
              {`${item} ${item === 1 ? "day" : "days"}`}
            </MenuItem>
          );
        })}
      </Select>
      {!!errorMessage && (
        <>
          <VSpacer size="3" />
          <Alert color="error" icon={false}>
            {errorMessage}
          </Alert>
        </>
      )}
    </Modal>
  );
};

export default ExtendedModal;
