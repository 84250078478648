import { Color } from '@/themes/MUITheme/palette';
import { Link, LinkProps, Stack, useTheme } from '@mui/material';
import { Category } from '@/themes/variant-interfaces/Category';
import { ReactNode } from 'react';
import { HSpacer } from '..';
import { Typography } from '@/themes/MUITheme/typography';
import { SpacerSize } from '@/themes/variant-interfaces/Size';

interface TextLinkProps extends LinkProps {
  accessoryLeft?: ReactNode,
  accessoryLeftSpacing?: SpacerSize,
  accessoryRight?: ReactNode,
  accessoryRightSpacing?: SpacerSize,
  category?: Category,
  children?: ReactNode,
  color?: Color,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  testID: string,
}

export const TextLink = ({
  accessoryLeft,
  accessoryLeftSpacing = "3",
  accessoryRight,
  accessoryRightSpacing = "3",
  category,
  children,
  color,
  disabled = false,
  href = "#",
  onClick,
  testID,
  underline = 'none',
  ...rest
}: TextLinkProps) => {
  const theme = useTheme();
  return (
    <Link
      color={disabled ? theme.palette.text.disabled : color}
      data-testid={testID}
      display="inline-flex"
      fontSize={category ? Typography[category].fontSize : undefined}
      fontWeight={category ? Typography[category].fontWeight : undefined}
      href={!disabled ? href : undefined}
      letterSpacing={category ? Typography[category].letterSpacing : undefined}
      lineHeight={category ? Typography[category].lineHeight : undefined}
      onClick={onClick ? (e) => {
        if (disabled) {
          return false;
        }
        onClick();
        e.stopPropagation();
        e.preventDefault();
        return false;
      } : undefined}
      underline={underline}
      {...rest}>
      <Stack alignItems="center" component="span" direction="row" display="inline-flex">
        {!!accessoryLeft && (
          <>
            {accessoryLeft}
            <HSpacer size={accessoryLeftSpacing} />
          </>
        )}
        {children}
        {!!accessoryRight && (
          <>
            <HSpacer size={accessoryRightSpacing} />
            {accessoryRight}
          </>
        )}
      </Stack>
    </Link>
  );
};
