import { Client } from '@/utilities/api/Client';
import { PaymentMethodNew } from '@shared/enums/PaymentMethod';

export class PaymentApi {
  static async requestPayment (
    pricingRequestId: string,
    paymentMethod: PaymentMethodNew,
  ): Promise<void> {
    return Client(`payments/${pricingRequestId}/request-payment`, {
      method: 'POST',
      body: { paymentMethod },
    });
  }
}
