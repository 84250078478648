import { Text } from '@/components/DesignSystem';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';

interface AddAllProductsConfirmDialogProps {
  onCancel: () => void,
  onConfirm: () => void,
  open: boolean,
}

export const AddAllProductsConfirmDialog = ({
  onCancel,
  onConfirm,
  open,
}: AddAllProductsConfirmDialogProps) => {
  return (
    <ConfirmDialog
      cancelText="Go back"
      confirmText="Yes, add all products"
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      showCloseButton={false}
      testID="add-retailer-products-confirm-dialog"
      title="Are you sure?"
    >
      <Text category="body-medium">
        This will add all of the products in this list to this promotion
      </Text>
    </ConfirmDialog>
  );
};
