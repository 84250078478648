import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getResponsiveValue, useMediaQuery } from '@/hooks/useMediaQuery';
import { Button, Card, Text, VSpacer } from '@/components/DesignSystem';
import { Rating, Stack, useTheme } from '@mui/material';
import { useState } from 'react';

interface ConfirmReceiptModalProps {
  onClose: () => void,
  onConfirm: (ratings: Map<string, number | undefined>) => void,
  retailerNames: string[],
}

interface RatingCardProps {
  name: string,
  onChange: (rating: number | undefined) => void,
  rating?: number | null,
  testID: string,
}

const RatingCard = ({
  name,
  onChange,
  rating,
  testID,
}: RatingCardProps) => {
  const theme = useTheme();
  return (
    <Card
      testID={testID}
    >
      <Stack
        alignItems="center"
        direction="row"
        divider={<VSpacer size="3"/>}
        justifyContent="space-between"
      >
        <Text category="p1" testID={`${testID}-name`}>
          {name}
        </Text>
        <Rating
          color={theme.palette.secondary.main}
          data-testid={`${testID}-rating`}
          onChange={(event, value) => onChange(value ?? undefined)}
          sx={{
            '& .MuiRating-iconFilled': {
              color: theme.palette.primary.main,
            },
          }}
          value={rating ?? null}
        />
      </Stack>
    </Card>
  );
};

export const ConfirmReceiptModal = ({
  onClose,
  onConfirm,
  retailerNames,
}: ConfirmReceiptModalProps) => {

  const { isMobile } = useMediaQuery();
  const [ratings, setRatings] = useState<Map<string, number | undefined>>(
    () => new Map(retailerNames.map((name) => [name, undefined])),
  );

  const updateRating = (retailerName: string, rating: number | undefined) => {
    const updatedRatings = new Map(ratings);
    updatedRatings.set(retailerName, rating);
    setRatings(updatedRatings);
  };

  const handleConfirm = () => {
    onConfirm(ratings);
    onClose();
  };

  const isValid = Array.from(ratings).some(([, rating]) => rating !== null);

  return (
    <ModalBottomSheet
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      )}
      containerSpacing={1}
      containerStyle={{
        px: getResponsiveValue(2, 0),
      }}
      onClose={onClose}
      open
      testID="confirm-receipt-modal"
      title="Confirm and rate"
      topButtonAction={isMobile && (
        <Button
          disabled={!isValid}
          onClick={handleConfirm}
          testID="confirm-receipt-modal-accept-button"
        >
          Confirm
        </Button>
      )}
      width={395}
    >
      {retailerNames.map((retailerName) => (
        <RatingCard
          key={retailerName}
          name={retailerName}
          onChange={(rating) => updateRating(retailerName, rating)}
          rating={ratings.get(retailerName)}
          testID={`rating-card-${retailerName}`}
        />
      ))}
    </ModalBottomSheet>
  );
};
