import { sortBy } from 'lodash';

export const getUserToken = () => getCookie('biddingUserToken');

export const getCookie = (cookieName: string) => {
  const cookieStrings = document.cookie.split(/;\s*/);
  const matchingCookies = cookieStrings.filter((cookie) => (
    cookie.toLowerCase().startsWith(cookieName.toLowerCase())
  ));

  /*
  It is possible to have two different values for a single cookie if the values were set
  for different subdomains (e.g. `.foo.com` and `api.foo.com`). In case one of them was set to
  null or some other invalid value, as a simple heuristic, in this scenario, return the longer one.
  */
  const longestCookie = sortBy(matchingCookies, (s) => s.length).pop();

  return longestCookie?.split('=')[1] || null;
};
