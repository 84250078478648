import { HSpacer, Text } from "@/components/DesignSystem";
import { Stack, SvgIconProps } from "@mui/material";

interface OfferFeatureBadgeProps {
  icon: (props: SvgIconProps) => React.ReactNode,
  label: string,
  onClick?: () => void,
  testID: string,
}

const OfferFeatureChip = ({
  icon,
  label,
  onClick,
  testID,
}: OfferFeatureBadgeProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : undefined }}>
      {icon({ fontSize: "small", sx: { color: 'info' } })}
      <HSpacer size="2" />
      <Text
        color="info"
        fontSize="12px"
        fontWeight="500"
        testID={testID} >
        {label}
      </Text>
    </Stack>
  );
};

export default OfferFeatureChip;
