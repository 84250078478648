import { QueryKeys } from '@/constants/QueryKeys';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { useQuery, useQueryClient } from 'react-query';

export const useManufacturerList = () => {
  const queryClient = useQueryClient();

  const { data: manufacturers, isLoading } = useQuery(
    [QueryKeys.GET_MANUFACTURERS],
    () => ManufacturerApi.list(),
  );

  const getManufacturer = (id: string) => {
    return manufacturers?.find((m) => m.id === id);
  };

  const invalidateManufacturerList = async () => {
    await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
  };

  return {
    getManufacturer,
    invalidateManufacturerList,
    isLoading,
    manufacturers,
  };
};