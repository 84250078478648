import { ProductEventApi } from '@/utilities/api/ProductEventApi';
import { PricingRequestEndpoint } from '@api/endpoints';
import { ProductEventEndpoint } from '@api/endpoints/ProductEventEndpoint';
import {
  ApiOffer,
  ApiPricingRequest,
  ApiPricingRequestPublic,
} from '@api/interfaces';
import { ProductEventType } from '@shared/enums';
import { useState } from 'react';

export type ProductEventLogger = ReturnType<typeof useProductEventLogging>;

type FarmerCartEventOptions = {
  alternativeTo?: string | null,
  companionTo?: string | null,
  productName: string,
  replacedOriginal?: boolean,
};

type RetailerOfferEventOptions = FarmerCartEventOptions & {
  substitute?: string | null,
};

export const useProductEventLogging = (options: {
  token?: string,
  priceRequestId?: string
}) => {
  const priceRequestId = options.priceRequestId || undefined;
  const { token } = options;
  const [
    loggedDetailsViewed,
    setLoggedDetailsViewed,
  ] = useState<string[]>([]);

  function logDetailsViewed (options: FarmerCartEventOptions) {
    if (loggedDetailsViewed.includes(options.productName)) { return; }

    void ProductEventApi.logEvent({
      ...options,
      eventType: ProductEventType.DetailsViewed,
      requestId: priceRequestId,
    });

    setLoggedDetailsViewed([...loggedDetailsViewed, options.productName]);
  }

  function logOfferAccepted (
    pricingRequest: ApiPricingRequest,
    offers: ApiOffer[],
    acceptedOfferProductIds: string[],
  ) {
    const accepted: ProductEventEndpoint.Create.CreateOfferAccepted['products'] = {
      alternatives: [],
      companions: [],
      generic: [],
    };

    offers.forEach((offer) => {
      offer.products!.forEach((offerProduct) => {
        if (!acceptedOfferProductIds.includes(offerProduct.id)) { return; }

        const { pricingRequestProduct } = offerProduct;
        if (pricingRequestProduct?.companionToProductId) {
          const companionTo = pricingRequest.products!.find(
            (p) => p.id === pricingRequestProduct?.companionToProductId,
          );

          accepted.companions.push({
            companionTo: companionTo!.name,
            offerId: offer.id,
            productName: pricingRequestProduct.name,
            substitute: offerProduct.substituteProduct,
          });
        } else if (pricingRequestProduct?.alternativeTo) {
          accepted.alternatives.push({
            alternativeTo: pricingRequestProduct.alternativeTo,
            offerId: offer.id,
            productName: pricingRequestProduct.name,
            substitute: offerProduct.substituteProduct,
          });
        } else {
          accepted.generic.push({
            offerId: offer.id,
            productName: pricingRequestProduct!.name,
          });
        }
      });
    });

    void ProductEventApi.logEvent({
      eventType: ProductEventType.OfferAccepted,
      products: accepted,
      requestId: priceRequestId,
    });
  }

  function logOfferSubmitted (
    pricingRequest: ApiPricingRequestPublic,
    offer: ApiOffer,
  ) {
    const submitted: ProductEventEndpoint.Create.CreateOfferSubmitted['products'] = {
      alternatives: [],
      companions: [],
      generic: [],
    };

    offer.products?.forEach((product) => {
      if (product.pricingRequestProduct?.alternativeTo) {
        submitted.alternatives.push({
          alternativeTo: product.pricingRequestProduct.alternativeTo,
          productName: product.pricingRequestProduct.name,
          substitute: product.substituteProduct,
        });
      } else if (product.pricingRequestProduct?.companionToProductId) {
        const companionTo = pricingRequest.products!.find(
          (p) => p.id === product.pricingRequestProduct?.companionToProductId,
        );

        submitted.companions.push({
          companionTo: companionTo!.name,
          productName: product.pricingRequestProduct.name,
          substitute: product.substituteProduct,
        });
      } else {
        submitted.generic.push({
          productName: product.pricingRequestProduct!.name,
          substitute: product.substituteProduct,
        });
      }
    });

    void ProductEventApi.logEvent({
      eventType: ProductEventType.OfferSubmitted,
      offerId: offer.id,
      products: submitted,
      requestId: pricingRequest.id,
    }, { token });
  }

  function logPriceAdded (options: RetailerOfferEventOptions) {
    void ProductEventApi.logEvent({
      ...options,
      eventType: ProductEventType.PriceAdded,
      requestId: priceRequestId,
    }, {
      token,
    });
  }

  function logProductAdded (options: FarmerCartEventOptions) {
    void ProductEventApi.logEvent({
      ...options,
      eventType: ProductEventType.CartAdded,
      requestId: priceRequestId,
    });
  }

  function logProductRemoved (options: FarmerCartEventOptions) {
    void ProductEventApi.logEvent({
      ...options,
      eventType: ProductEventType.CartRemoved,
      requestId: priceRequestId,
    });
  }

  function logRequestSubmitted (
    products: Partial<PricingRequestEndpoint.Save.Product>[],
  ) {
    const submitted: ProductEventEndpoint.Create.CreateRequestSubmitted['products'] = {
      alternatives: [],
      companions: [],
      generic: [],
    };

    products.forEach((product) => {
      product.companionProducts?.forEach((companionProduct) => {
        submitted.companions.push({
          companionTo: product.name!,
          productName: companionProduct.name!,
        });
      });

      if (product.alternativeTo) {
        submitted.alternatives.push({
          alternativeTo: product.alternativeTo,
          productName: product.name!,
        });
      } else {
        submitted.generic.push({
          productName: product.name!,
        });
      }
    });

    void ProductEventApi.logEvent({
      eventType: ProductEventType.RequestSubmitted,
      products: submitted,
      requestId: priceRequestId,
    } as ProductEventEndpoint.Create.CreateRequestSubmitted);
  }

  return {
    logDetailsViewed,
    logOfferAccepted,
    logOfferSubmitted,
    logPriceAdded,
    logProductAdded,
    logProductRemoved,
    logRequestSubmitted,
  };
};
