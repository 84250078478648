import { calculateOfferSubtotal } from '@/utilities/Offer';
import { StringUtility } from '@/utilities/String';
import {
  ApiOffer,
  ApiOfferProduct,
  ApiPricingRequest,
  ApiPricingRequestPublic,
} from '@api/interfaces';
import {
  compactJoin,
  formatCurrency,
  formatExpirationDate,
  formatPhoneNumber,
} from '@shared/utilities';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { OfferProduct } from '../CreateSendPriceRequest/interfaces';
import { Offer } from '../ReceivePlaceOffers/interfaces';
import { FulfillmentMethod, OfferStatusForSalesperson } from '@shared/enums';

const fulfillmentStatuses = [
  OfferStatusForSalesperson.OnAccountRequested,
  OfferStatusForSalesperson.FinancingRequested,
  OfferStatusForSalesperson.AwaitingFulfillment,
];

export const doesFulfillmentMatch = (
  priceRequest: ApiPricingRequestPublic | undefined,
  fulfillmentMethod: FulfillmentMethod.DELIVERY | FulfillmentMethod.PICKUP,
) => {
  return !!priceRequest?.retailerOfferStatus
    && fulfillmentStatuses.includes(priceRequest.retailerOfferStatus)
    && priceRequest.fulfillmentMethod === fulfillmentMethod;
};

export const useFarmerOfferInfo = (
  priceRequest?: (ApiPricingRequestPublic | ApiPricingRequest),
  offer?: Offer | ApiOffer,
  offerProducts?: (OfferProduct | ApiOfferProduct)[],
) => {
  return useMemo(() => {
    if (!priceRequest) {
      return {
        address: '',
        deliveryAddress: '',
        expirationDate: '',
        farmerName: '',
        offerDiscount: '',
        offerDiscountsTotal: '',
        offerSubtotal: '',
        offerTotalAfterDiscounts: '',
        offerShipping: '',
        offerTotal: '',
        reviewExpirationDate: '',
        telephone: '',
      };
    }

    const address = StringUtility.formatAddress(
      priceRequest.user?.address1,
      priceRequest.user?.address2,
      priceRequest.user?.city,
      priceRequest.user?.state,
      priceRequest.user?.postal,
    );

    const expiration = priceRequest.expiration;
    const expirationDate = expiration && (
      formatExpirationDate(DateTime.fromJSDate(expiration), true)
    );
    const reviewDate = priceRequest.reviewExpiration;
    const reviewExpirationDate = reviewDate && (
      formatExpirationDate(DateTime.fromJSDate(reviewDate), true)
    );

    const deliveryAddress = StringUtility.formatAddress(
      priceRequest.deliveryAddress1,
      priceRequest.deliveryAddress2,
      priceRequest.deliveryCity,
      priceRequest.deliveryState,
      priceRequest.deliveryPostalCode,
    );

    const farmerName = compactJoin([
      priceRequest.user?.firstName,
      priceRequest.user?.lastName,
    ], ' ');

    const telephone = formatPhoneNumber(priceRequest.user?.telephone ?? '', 'paren');

    const offerSubtotal = (
      offer
        ? calculateOfferSubtotal(offerProducts || [])
        : 0
    );

    const offerShipping = offer?.totalShipmentCost ?? 0;
    const offerTotal = offerSubtotal + offerShipping;

    return {
      address,
      deliveryAddress,
      expirationDate,
      farmerName,
      offerSubtotal: formatCurrency(offerSubtotal),
      offerShipping: formatCurrency(offerShipping),
      offerTotal: formatCurrency(offerTotal),
      reviewExpirationDate,
      telephone,
    };
  }, [priceRequest, offer, offerProducts]);
};
