import { ReactNode } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { DataSet } from "../DataSet/DataSet";
import { Button, Card, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getStatusChipPropsFromPRStatus } from "@/utilities/PricingRequest";
import { ApiPricingRequest } from "@api/interfaces";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";

export interface PricingRequestCardProps {
  children?: ReactNode,
  pricingRequest: ApiPricingRequest,
  showNote?: boolean,
  showOffers?: boolean,
  showOverline?: boolean,
  showStatus?: boolean,
  testID: string,
}

export const PricingRequestCard = ({
  children,
  pricingRequest,
  showNote = false,
  showOffers = false,
  showOverline = false,
  showStatus = false,
  testID,
}: PricingRequestCardProps) => {

  const { isMobile } = useMediaQuery();

  const desktopActions: ReactNode[] = [
    (
      <Button
        key="desktopAction-1"
        onClick={() => { null; }}
        testID={`${testID}-desktopAction-button-1`}
        variant="outlined"
      >
        Enabled
      </Button>
    ),
    (
      <Button
        key="desktopAction-2"
        onClick={() => { null; }}
        testID={`${testID}-desktopAction-button-2`}
        variant="outlined"
      >
        Copy
      </Button>
    ),
  ];

  const mobileActions: ReactNode[] = [
    (
      <IconButton
        key="mobileAction-1"
        onClick={() => { null; }}
        size="small"
        testID={`${testID}-mobile-Action-button-1`}
      >
        <MoreHorizIcon/>
      </IconButton>
    ),
  ];

  const offerCount = pricingRequest.offers?.length;

  return (
    <DataSet
      actions={!isMobile ? desktopActions : mobileActions}
      badges={showStatus ? [getStatusChipPropsFromPRStatus(pricingRequest.status)] : undefined}
      hasDrawer={!isMobile}
      overline={(showOverline && !!pricingRequest.referralSender) ?
        pricingRequest.referralSender : undefined
      }
      subtitle={(showOffers && !!pricingRequest.offers) ?
        `${offerCount} offer${offerCount !== 1 ? 's' : ''} received`
        : undefined
      }
      testID={testID}
      title={`Product Request - #${pricingRequest.publicId}`}
    >
      <VSpacer size="4"/>
        <Text category="title-medium" testID={`${testID}-table-header`}>Products</Text>
        <VSpacer size="5"/>
        <DesktopOnly>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Product name</TableCell>
                <TableCell variant="head">Quantity/UoM</TableCell>
                <TableCell variant="head">Type</TableCell>
                <TableCell variant="head">Package size</TableCell>
                <TableCell variant="head">Crop</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricingRequest?.products?.map((product, idx) => (
                <TableRow key={`product-row-${idx}`}>
                  <TableCell data-testid={`${testID}-product-name-row-${idx}`} variant="body">
                    {product.name}
                  </TableCell>
                  <TableCell data-testid={`${testID}-product-quantity-uom-row-${idx}`} variant="body">
                    {(product.quantity && product.uom)
                      ? `${product.quantity} ${FriendlyUoM[product.uom]}`
                      : ''
                    }
                  </TableCell>
                  <TableCell data-testid={`${testID}-product-type-row-${idx}`} variant="body">
                    API product Type
                  </TableCell>
                  <TableCell data-testid={`${testID}-product-package-size-row-${idx}`} variant="body">
                    API product Package Size
                  </TableCell>
                  <TableCell data-testid={`${testID}-product-crop-row-${idx}`} variant="body">
                    API product Crop
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        {(showNote && !!pricingRequest.note) && (
          <Stack>
            <VSpacer size="9"/>
            <Text category="title-medium" testID={`${testID}-note-title`}>Note to Farmer</Text>
            <VSpacer size="5"/>
            <Card sx={{ width: 432 }} testID={`${testID}-note-card`} variant="outlined">
              <Text category="body-medium" testID={`${testID}-note-text`}>{pricingRequest.note}</Text>
            </Card>
          </Stack>
        )}
      </DesktopOnly>
      <MobileOnly>TODO: Mobile drawer view coming soon...</MobileOnly>
      <VSpacer size="6"/>
      {children}
    </DataSet>
  );
};
