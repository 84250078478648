import { FullPageLayout } from '@/components/layouts/FullPageLayout';
import { ContactFooter } from '@/components/shared/ContactFooter';
import StorefrontLoginForm from '@/pages/Auth/StorefrontLoginForm';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import FarmerLoginForm from './FarmerLoginForm';
import { LoginHeader } from './LoginHeader';

export const LoginPage = () => {
  const subdomain = getSubdomain();
  return (
    <FullPageLayout footer={<ContactFooter />} header={<LoginHeader />}>
      {subdomain ? <StorefrontLoginForm /> : <FarmerLoginForm />}
    </FullPageLayout>
  );
};
