import { Button, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { productRequestConstants } from '@/constants/ProductRequest';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiRetailerLocation } from '@api/interfaces';
import { ApiPricingRequestRetailer } from '@api/interfaces/ApiPricingRequestRetailer';
import HighlightOff from '@mui/icons-material/HighlightOff';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import { formatPhoneNumber, pick } from '@shared/utilities';
import { Fragment, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SalesPersonContactCard from './SalesPersonContactCard';
import { ToastMessages } from '@/constants/constant';
import { PricingRequestEndpoint } from '@api/endpoints';

interface AddRetailerModalProps {
  onClose: () => void,
  partners: ApiPricingRequestRetailer[] | undefined
  pricingRequestId: string,
  salespersonListData: ApiPricingRequestRetailer[] | undefined,
  userId: string,
}

interface ISalesPerson {
  email?: string | null,
  preferred?: boolean,
  location?: string | null,
  name: string,
  salespersonId?: string | null,
  telephone?: string | null,
}

const initialSalesPerson: ISalesPerson = {
  email: null,
  preferred: false,
  location: null,
  name: '',
  salespersonId: null,
  telephone: null,
};
const AddSalesPerson = ({
  onClose,
  partners,
  pricingRequestId,
  salespersonListData,
  userId,
}: AddRetailerModalProps) => {
  const [selectedLocation, setSelectedLocation] = useState<ApiRetailerLocation>();
  const [salesPerson, setSalesPerson] =
    useState<ISalesPerson>(initialSalesPerson);
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const { data: locations, isLoading } = useQuery(
    [QueryKeys.GET_USER_LOCATIONS],
    () => UserApi.getLocationsForUser(userId),
  );

  const retailersAlreadyOnProductRequest = partners?.map(
    (partner) => partner.retailerSalesperson!.userLocation!.retailerId,
  ) || [];

  const locationsNotFromAddedRetailers = locations?.filter(
    (location) => !retailersAlreadyOnProductRequest.includes(location.retailerId),
  ) || [];

  const salesPersons = useMemo(() => {
    return locations?.find(
      (location) => location.id === selectedLocation?.id,
    )?.salespersons ?? [];
  }, [locations, selectedLocation?.id]);

  const salesPersonsList = useMemo(() => {
    if (salesPersons) {
      const unselectedRetailers = salesPersons.filter((salesperson) => {
        return !salespersonListData?.some(
          ({ salespersonId }: { salespersonId: string | null }) =>
            salespersonId === salesperson.id,
        );
      }).map((salesPerson) => ({
        email: salesPerson?.email,
        isPreferred: salesPerson?.userLocation?.isPreferred,
        location: salesPerson?.userLocation?.name,
        name: salesPerson?.businessName,
        salespersonId: salesPerson?.id,
        telephone: salesPerson?.telephone,
      }));
      return unselectedRetailers;
    }
    return [];
  }, [salesPersons, salespersonListData]) as ISalesPerson[];

  const filterOptions = createFilterOptions({
    stringify: (option: ISalesPerson) =>
      option.name + option.email + option.telephone,
  });

  const { mutate: addSalesPerson, isLoading: isAddSalespersonLoading } = useMutation(
    ({
      pricingRequestId,
      salesPerson,
    }: {
      pricingRequestId: string;
      salesPerson: PricingRequestEndpoint.Save.Salesperson,
    }) =>
      PricingRequestsApi.addSalesPersonForAdmin(
        pricingRequestId,
        {
          ...pick(salesPerson, ['preferred', 'name', 'salespersonId']),
        },
      ),
    {
      onSuccess: async () => {
        openSnackbar(ToastMessages.addSalespersonSuccess);
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST]);
        onClose();
      },
      onError: (err: Error) => {
        openSnackbar(err.message);
        onClose();
      },
    },
  );

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!salesPerson.name || !salesPerson.salespersonId}
          loading={isAddSalespersonLoading}
          onClick={() => addSalesPerson({
            pricingRequestId,
            salesPerson: salesPerson as PricingRequestEndpoint.Save.Salesperson,
          })}
          testID='button-demo-enable'
          variant='contained'
        >
          {productRequestConstants.add}
        </Button>
      )}
      cancelButton={() => (
        <Button
          onClick={onClose}
          testID='button-demo-enable'
          variant='outlined'
        >
          {productRequestConstants.cancel}
        </Button>
      )}
      loading={isAddSalespersonLoading}
      onClose={onClose}
      open={true}
      overflow='visible'
      testID='add-salesPerson-modal'
      title={productRequestConstants.addSalesPerson}
      width={512}
    >
      <>
        <Stack direction='row' spacing={2}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <VSpacer size="2" />
            <Autocomplete
              clearIcon={<HighlightOff />}
              disablePortal
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                if (value) {
                  setSelectedLocation(value);
                }
              }}
              options={locationsNotFromAddedRetailers}
              renderInput={(params) => (
                <TextField
                  label='Select Location'
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {isLoading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
            <VSpacer size="5" />
            <Autocomplete
              clearIcon={<HighlightOff />}
              disablePortal
              disabled={!selectedLocation}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                if (value) {
                  setSalesPerson(value);
                } else {
                  setSalesPerson(initialSalesPerson);
                }
              }}
              options={salesPersonsList}
              renderInput={(params) => (
                <TextField
                  label='Select Salesperson'
                  {...params}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <Fragment key={option.salespersonId}>
                    <li {...props}>
                      <Stack alignItems={'center'} direction='row'>
                        {option?.preferred ? (
                          <VerifiedIcon color="success" />
                        ) : (
                          <Box sx={{ width: 24 }} />
                        )}
                        <Stack
                          direction='column'
                          spacing={0.2}
                          sx={{ marginLeft: '12px' }}
                        >
                          <Text
                            fontSize='18px'
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {option.name}
                          </Text>
                          <Text
                            fontSize='13px'
                            sx={{
                              color: '#CAC4D0',
                            }}
                          >
                            {formatPhoneNumber(option.telephone ?? '', 'paren')}
                            {' • '}
                            {option.email}
                          </Text>
                        </Stack>
                      </Stack>
                    </li>
                  </Fragment>
                );
              }}
            />
          </Box>
        </Stack>
        {salesPerson.name !== '' && (
          <Box>
            <Divider />
            <VSpacer size='4' />
            <Stack alignItems={'center'} direction='row'>
              <Stack
                direction='column'
                spacing={0.2}
                sx={{ marginLeft: '12px' }}
              >
                <Text
                  fontSize='16px'
                  sx={{ margin: '10px 0', textTransform: 'capitalize' }}
                >
                  {salesPerson?.name}
                </Text>
                <SalesPersonContactCard
                  salesPersonContactInfo={{
                    email: salesPerson.email ?? '',
                    phone: salesPerson.telephone ?? '',
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        )}
      </>
    </Modal>
  );
};

export default AddSalesPerson;
