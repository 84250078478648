import {
  Button,
  Dialog,
  Heading,
  HSpacer,
  NumericInput,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import {
  MultiPageModal,
  MultiPageStep,
} from '@/components/DesignSystem/MultiPageModal/MultiPageModal';
import { AddPrices } from '@/components/shared/CreateOffer/AddPrices';
import { PriceList } from '@/components/shared/CreateOffer/PriceList';
import { ReviewAndSubmit } from '@/components/shared/CreateOffer/ReviewAndSubmit';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { useProductEventLogging } from '@/hooks/useProductEventLogging';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { CreateOfferEventType, useLogEvent } from '@/utilities/Analytics';
import { OffersApi } from '@/utilities/api/OffersApi';
import { ApiOffer, ApiPricingRequestPublic } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Stack } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { defaultOffer, Offer } from '../ReceivePlaceOffers/interfaces';

const styles = {
  box: {
    maxHeight: getResponsiveValue(undefined, '490px'),
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '11px',
  },
} as const;

interface CreateOfferModalProps {
  inviteToken?: string,
  onClose: () => void,
  onSubmit: (offer: ApiOffer) => void,
  onViewDetails: () => void,
  priceRequest: ApiPricingRequestPublic,
  products: OfferProduct[],
}

interface HeaderProps {
  onViewPriceList: () => void,
  products: OfferProduct[],
  title: string,
}

interface EditShippingModalProps {
  onClose: () => void,
  onSave: (shipping: number) => void,
  shipping?: number | null,
  testID: string,
}

const Header = ({
  onViewPriceList,
  products,
  title,
}: HeaderProps) => {
  const productsCount = products.reduce((acc, product) => (
    acc + (product.companionProducts?.length ?? 0)
  ), products.length);

  const allPricesAdded = products.reduce((acc, product) => (
    (product.companionProducts ?? []).reduce((accc, companionProduct) => (
      accc + (!companionProduct.price ? 0 : 1)
    ), acc + (!product.price ? 0 : 1))
  ), 0);

  return (
    <Stack alignItems="center">
      <Heading
        centered={true}
        level="2"
        subtitle={
          !allPricesAdded
            ? `${productsCount} product${productsCount !== 1 ? 's': ''}`
            : ''
        }
        testID="create-offer-modal-heading"
        title={title}
      />
      {!!allPricesAdded && (
        <>
          <VSpacer size="2" />
          <TextLink
            category="body-small"
            color="primary"
            onClick={onViewPriceList}
            testID="view-price-list"
          >
            {`Price list for ${allPricesAdded} of ${productsCount} ` +
            `product${productsCount !== 1 ? 's' : ''}`}
          </TextLink>
        </>
      )}
      <VSpacer size="10" />
    </Stack>
  );
};

const EditShippingModal = ({
  onClose,
  onSave,
  shipping,
  testID,
}: EditShippingModalProps) => {
  const [localShipping, setLocalShipping] = useState<number>(shipping ?? 0);
  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          {...props}
          onClick={() => {
            onSave(localShipping);
            onClose();
          }}
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID={testID}
      title="Shipping"
    >
      <NumericInput
        decimals={2}
        label="Cost"
        minValue={0}
        onChangeNumber={(value) => setLocalShipping(value ?? 0)}
        prefix="$"
        testID={`${testID}-input`}
        value={localShipping}
      />
    </Dialog>
  );
};

export const CreateOfferModal = ({
  inviteToken,
  onClose,
  onSubmit,
  onViewDetails,
  priceRequest,
  products: defaultProducts,
}: CreateOfferModalProps) => {
  const { openSnackbar } = useSnackbar();
  const [
    showCreateOfferCloseConfirmationDialog,
    setShowCreateOfferCloseConfirmationDialog,
  ] = useState(false);
  const [offer, setOffer] = useState<Offer>({
    ...defaultOffer,
    fulfillmentMethod: priceRequest.fulfillmentMethod === FulfillmentMethod.NO_PREFERENCE
      ? FulfillmentMethod.PICKUP
      : priceRequest.fulfillmentMethod,
  });

  const [showEditShipping, setShowEditShipping] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number | undefined>(1);
  const [products, setProducts] = useState(cloneDeep(defaultProducts));
  const [showPriceList, setShowPriceList] = useState(false);

  const productEventLogger = useProductEventLogging({
    priceRequestId: priceRequest.id,
    token: inviteToken,
  });
  const logEvent = useLogEvent();

  const { mutate: createOffer, isLoading: isCreatingOffer } = useMutation(
    () => OffersApi.createOffer(
      priceRequest.id,
      {
        ...offer,
        products: products.reduce((allOfferProducts, { companionProducts, ...product }) => {
          allOfferProducts.push(product);
          return (companionProducts ?? []).reduce((offerProducts, companionProduct) => {
            offerProducts.push(companionProduct as OfferProduct);
            return offerProducts;
          }, allOfferProducts);
        }, [] as OfferProduct[]),
        totalShipmentCost: offer.fulfillmentMethod === FulfillmentMethod.PICKUP
          ? null
          : offer.totalShipmentCost,
      },
      inviteToken,
    ),
    {
      onSuccess: (offer: ApiOffer) => {
        productEventLogger.logOfferSubmitted(priceRequest, offer);
        onSubmit(offer);
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || "An error has occurred",
        );
      },
    },
  );

  const validOfferProducts = products.some(({ price, companionProducts }) => (
    !!price || companionProducts?.some((companionProduct) => !!companionProduct.price)
  ));

  return (
    <>
      <MultiPageModal
        actionRight={
          <Button
            onClick={() => {
              onViewDetails();
              logEvent(
                activeStep === 1
                  ? CreateOfferEventType.ClickAddPricesViewDetails
                  : CreateOfferEventType.ClickReviewAndSubmitViewDetails,
              );
            }}
            testID="create-offer-view-details-button"
            variant="text"
          >
            View Details
          </Button>
        }
        activeStep={activeStep}
        onClose={() => {
          setShowCreateOfferCloseConfirmationDialog(true);
        }}
        onStepChange={setActiveStep}
        showProgressIndicator={false}
        testID="create-offer-multi-page-modal"
        title="Create Offer"
      >
        <MultiPageStep
          containerStyle={{ px: 0 }}
          nextButton={(handleClick) => (
            <Button
              onClick={() => {
                handleClick();
                logEvent(
                  validOfferProducts
                    ? CreateOfferEventType.ClickReviewOfferWithPrices
                    : CreateOfferEventType.ClickReviewOfferWithoutPrices,
                );
              }}
              testID="create-offer-multi-step-modal-review-offer-button"
            >
              Review Offer
            </Button>
          )}
        >
          <Box sx={styles.box}>
            <Header
              onViewPriceList={() => setShowPriceList(true)}
              products={products}
              title="Add Prices"

            />
            <AddPrices
              onChange={setProducts}
              productEventLogger={productEventLogger}
              products={products}
            />
          </Box>
        </MultiPageStep>
        <MultiPageStep
          nextButton={() => (
            <Button
              disabled={!validOfferProducts}
              loading={isCreatingOffer}
              onClick={() => {
                createOffer();
                logEvent(CreateOfferEventType.ClickSubmitOffer);
              }}
              testID="create-offer-submit-button"
            >
              Submit
            </Button>
          )}
          previousButton={(handleClick) => (
            <>
              <Button
                onClick={() => {
                  handleClick();
                  logEvent(CreateOfferEventType.ClickBackReviewOffer);
                }}
                startIcon={<ArrowBack />}
                testID="create-offer-previous-button"
                variant="text"
              >
                Back
              </Button>
              <HSpacer size="2" />
            </>
          )}
        >
          <Box sx={styles.box}>
            <Header
              onViewPriceList={() => setShowPriceList(true)}
              products={products}
              title="Review & Submit Offer"
            />
            <ReviewAndSubmit
              handleShippingChange={() => setShowEditShipping(true)}
              offer={offer}
              priceRequest={priceRequest}
              products={products}
            />
          </Box>
        </MultiPageStep>
      </MultiPageModal>
      <Dialog
        acceptButton={() => (
          <Button
            onClick={() => {
              setShowCreateOfferCloseConfirmationDialog(false);
              onClose();
              logEvent(CreateOfferEventType.ClickDiscardCreateOffer);
            }}
            testID="create-offer-discard-button"
            variant="text"
          >
            Discard
          </Button>
        )}
        cancelButton={() => (
          <Button
            onClick={() => setShowCreateOfferCloseConfirmationDialog(false)}
            testID="create-offer-discard-button"
            variant="text"
          >
            Cancel
          </Button>
        )}
        onClose={() => setShowCreateOfferCloseConfirmationDialog(false)}
        open={showCreateOfferCloseConfirmationDialog}
        showCloseButton={false}
        testID="add-price-modal"
        title="Discard Offer?"
      />
      {showPriceList && (
        <PriceList
          onClose={() => {
            setShowPriceList(false);
          }}
          products={products}
        />
      )}
      {showEditShipping && (
        <EditShippingModal
          onClose={() => setShowEditShipping(false)}
          onSave={(shipping) => setOffer({ ...offer, totalShipmentCost: shipping })}
          shipping={offer.totalShipmentCost}
          testID="edit-shipping-modal"
        />
      )}
    </>
  );
};
