export enum PaymentType {
  /** @deprecated */
  Cash = 'Cash',
  Finance = 'Finance',
  /** @deprecated */
  Prequalified = 'Prequalified',
  PrequalifiedGrowersFinancing = 'Prequalified Growers Financing',
  Rewards = 'Rewards',
}

// These can be consolidated back to just `PaymentType` when CASH and PREQUALIFIED are removed in a migration
export const ActivePaymentTypes = [
  PaymentType.Finance,
  PaymentType.PrequalifiedGrowersFinancing,
] as const;

export type ActivePaymentType = typeof ActivePaymentTypes[number];
