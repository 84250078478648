import { AppConfig } from '@/constants/AppConfig';
import { Retailer } from "@/pages/SavedRetailers/interfaces";
import { validateEmail, validatePhoneNumber } from "@shared/utilities";

export const validateRetailer = (retailer: Retailer) => {
  const { businessName, email, telephone } = retailer;

  const trimmedName = businessName?.trim();

  if (!trimmedName) {
    return false;
  }

  const isEmailValid = validateEmail(email);
  const isPhoneValid = validatePhoneNumber(telephone, AppConfig.env.test);

  if ((!isEmailValid && email) || (!isPhoneValid && telephone)) {
    return false;
  }

  const emailOrPhoneValid = isEmailValid || isPhoneValid;
  return [trimmedName, emailOrPhoneValid].every(Boolean);
};
