import TagManager from 'react-gtm-module';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';

export enum CreateFarmerAccountEventType {
  ClickCreateYourAccountHere = "F_ClickCreateYourAccountHere",
  CreateNewAccountFromReferral = "F_CreateNewAccountFromReferral",
  ClickCreateAccount = "F_ClickCreateAccount",
  ClickConfirmCreateAccount = "F_ClickConfirmCreateAccount",
  SetInitialPassword = "F_SetInitialPassword",
  SetPassword = "F_SetPassword",
  CompleteCreateAccount = "F_CompleteCreateAccount",
}

export enum CreatePriceRequestEventType {
  ClickCreatePriceRequest = "F_ClickCreatePriceRequest",
  ChangeProductName = "F_ChangeProductName",
  ChangeQuantity = "F_ChangeQuantity",
  SelectUoM = "F_SelectUoM",
  ClickCalculator = "F_ClickCalculator",
  SaveCalculator = "F_SaveCalculator",
  ChangePackageType = "F_ChangePackageType",
  CheckAllowSubstitute = "F_CheckAllowSubstitute",
  ClickAddProduct = "F_ClickAddProduct",
  ClickViewCart = "F_ClickViewCart",
  ClickNextPostProducts = "F_ClickNextPostProducts",
  ChangeRetailerName = "F_ChangeRetailerName",
  ChangeRetailerEmail = "F_ChangeRetailerEmail",
  ChangeRetailerPhone = "F_ChangeRetailerPhone",
  ClickAddRetailer = "F_ClickAddRetailer",
  ClickPartnerRetailers = "F_ClickPartnerRetailers",
  ClickSelectedRetailers = "F_ClickSelectedRetailers",
  ClickAddPartnerRetailers = "F_ClickAddPartnerRetailers",
  ClickNextStepPostRetailers = "F_ClickNextStepPostRetailers",
  SelectPaymentType = "F_SelectPaymentType",
  SelectOfferWindow = "F_SelectOfferWindow",
  SelectFulfillmentMethod = "F_SelectFulfillmentMethod",
  ChangeNote = "F_ChangeNote",
  ClickReviewAndSubmit = "F_ClickReviewAndSubmit",
  ClickExitOut = "F_ClickExitOut",
  ClickSaveDraft = "F_ClickSaveDraft",
  ClickDiscardDraft = "F_ClickDiscardDraft",
  ClickExitOutCancel = "F_ClickExitOutCancel",
  ClickSubmitPriceRequest = "F_ClickSubmitPriceRequest",
}

export enum CreateOfferEventType {
  ViewPriceRequest = "R_ViewPriceRequest",
  ClickCreateOffer = "R_ClickCreateOffer",
  ClickFarmerInfoIcon = "R_ClickFarmerInfoIcon",
  ClickViewPriceRequestViewDetails = "R_ClickViewPriceRequestViewDetails",
  ClickAddPricesViewDetails = "R_ClickAddPricesViewDetails",
  ClickAddProductPrice = "R_ClickAddProductPrice",
  ClickEditProductPrice = "R_ClickEditProductPrice",
  ClickReviewOfferWithoutPrices = "R_ClickReviewOfferWithoutPrices",
  ClickReviewOfferWithPrices = "R_ClickReviewOfferWithPrices",
  ChangeUnitPrice = "R_ChangeUnitPrice",
  ChangeTotalPrice = "R_ChangeTotalPrice",
  ClickSavePrice = "R_ClickSavePrice",
  ClickCancelPrice = "R_ClickCancelPrice",
  CheckSubstitute = "R_CheckSubstitute",
  ChangeSubstitute = "R_ChangeSubstitute",
  ClickReviewAndSubmitViewDetails = "R_ClickReviewAndSubmitViewDetails",
  ClickDiscardCreateOffer = "R_ClickDiscardCreateOffer",
  ClickChangeFulfillment = "R_ClickChangeFulfillment",
  SelectFulfillmentMethod = "R_SelectFulfillmentMethod",
  ClickSaveFulfillmentMethod = "R_ClickSaveFulfillmentMethod",
  ClickCancelFulfillmentMethod = "R_ClickCancelFulfillmentMethod",
  ClickAddDiscount = "R_ClickAddDiscount",
  ClickEditDiscount = "R_ClickEditDiscount",
  ChangeDiscountName = "R_ChangeDiscountName",
  ClickDiscountType = "R_ClickDiscountType",
  ChangeDiscountAmount = "R_ChangeDiscountAmount",
  ClickCancelDiscount = "R_ClickCancelDiscount",
  ClickDeleteDiscount = "R_ClickDeleteDiscount",
  ClickApplyDiscount = "R_ClickApplyDiscount",
  ClickAddNote = "R_ClickAddNote",
  ChangeOfferNote = "R_ChangeOfferNote",
  ClickCancelOfferNote = "R_ClickCancelOfferNote",
  ClickSaveOfferNote = "R_ClickSaveOfferNote",
  ClickDeleteOfferNote = "R_ClickDeleteOfferNote",
  ClickBackReviewOffer = "R_ClickBackReviewOffer",
  ClickSubmitOffer = "R_ClickSubmitOffer",
}

export enum ReviewAndSelectOffersEventType {
  ViewMyPriceRequests = "F_ViewMyPriceRequests",
  ClickReviewAndSelectOffers = "F_ClickReviewAndSelectOffers",
  ClickCompareAndSelectProducts = "F_ClickCompareAndSelectProducts",
  ClickSubmitPartialOfferSingleRetailer = "F_ClickSubmitPartialOfferSingleRetailer",
  ClickSubmitPartialOfferMultiRetailer = "F_ClickSubmitPartialOfferMultiRetailer",
  ClickSubmitFullOfferSingleRetailer = "F_ClickSubmitFullOfferSingleRetailer",
  ClickSubmitFullOfferMultiRetailer = "F_ClickSubmitFullOfferMultiRetailer",
  ClickCloseReviewAndSelectPriceRequest = "F_ClickCloseReviewAndSelectPriceRequest",
  ClickCloseCompareAndSelectProducts = "F_ClickCloseCompareAndSelectProducts",
  ClickDone = "F_ClickDone",
  ClickEndOfferPeriod = "F_ClickEndOfferPeriod",
  ClickEndReviewPeriod = "F_ClickEndReviewPeriod",
}

export enum OtherEventType {
  PageView = 'SPA_pageview',
}

export type EventType =
  CreateFarmerAccountEventType
  | CreatePriceRequestEventType
  | CreateOfferEventType
  | ReviewAndSelectOffersEventType
  | OtherEventType;

export function initializeGTM (gtmId: string) {
  TagManager.initialize({ gtmId });
}

export function logEvent (eventType: EventType, data?: Record<string, string>) {
  TagManager.dataLayer({
    dataLayer: {
      event: eventType,
      ...data,
    },
  });
}

export function useLogEvent () {
  const { user } = useAuthentication();
  return (eventType: EventType, data?: Record<string, string>) => {
    logEvent(
      eventType,
      {
        ...(
          user ? {
            appUserId: user.id,
            state: user.state ?? '',
          } : {}
        ),
        ...data,
      },
    );
  };
}
