export const productConstant={
  addProduct:'Add Product',
  searchMessage :'Searched product not available',
  products:'Products',

};
export const productDrawerConstant={
  save: 'Save',
  editProduct: 'Edit product',
  cancel:'Cancel',
  addProduct:'Add product',
  addKeyword:'Add keyword',
  companionProducts:' Companion Products',
  add:'Add',
  yes: 'Yes',
  no:'No',
  alternativeProducts:'Alternative Products',
  update:'Update',
  companionSearchText:'products can be added as Companion products',
  alternativeSearchText:'products can be added as Alternative products',
  companionSelectedRankTex:'Companion products currently display with',
  alternativeSelectedRankTex:'Alternative products currently display with',
  saveChanges: 'Save Changes',
  close:"Close",
};
export const viewProductConstant={
  alternativeProduct:'Alternative Product',
  companionProduct: 'Companion Product',
  thumbnaillink:'Thumbnail link',
  Keywords:'Keywords',
  sellSheet:'Sell sheet',
  restrictedUse:'Restricted use',
  packageSize:'Package size',
  purchaseUoM:'Purchase UoM',
  practice:'Practice',
  cropType:'Crop type',
  subcategoryA1:'Subcategory A.1',
  activeIngredient:'Active ingredient',
  primaryNutrient:'Primary nutrient',
  SubcategoryA:'Subcategory A',
  categoryFilters:'Category filters',
  retailers:'Retailers',
  Manufacturer:'Manufacturer',
  productName:'Product name',
  roundupPowerMax:'Roundup PowerMax',
  deleteProduct:' Delete product',
  edit:'Edit',
};
export const deleteProductConstant={
  deleteConfirmation:"Delete this product?",
  deleteMessage:'This action cannot be undone.',
};

export const stepperConstant ={
  next:'Next',
  finish:'Finish',
  selectAll:'Select all',
  product:'Product',
  position:'Position',
  cancel:'Cancel',
  clear:'Clear',
};

export const manufacturerModal={
  addManufacturer:' Add Manufacturer',
  cancel:'Cancel',
  yes:'yes',
  no:'No',
};
