import { Card, Stack } from "@mui/material";
import { Button, Text, VSpacer } from "@/components/DesignSystem";
import React from "react";

interface BrandIdentityInputProps {
  children?: React.ReactNode;
  defaultText: string,
  name: string,
  onAddClicked: () => void,
  onEditClicked: () => void,
  onRemoveClicked: () => void,
  titleText: string,
}

export const BrandIdentityInput = ({
  children,
  defaultText,
  name,
  onAddClicked,
  onEditClicked,
  onRemoveClicked,
  titleText,
}: BrandIdentityInputProps) => {
  return <Card sx={{ padding: '20px 16px' }}>
    <Text category="title-medium">
      {titleText}
    </Text>
    <VSpacer size="4" />
    {children ? (
      <Stack>
        {typeof children === 'string' ? (
          <Text category='body-medium'
            sx={{
              height: '40px',
              overflow: 'hidden',
              display: '-webkit-box',
              wordBreak: 'break-all',
              WebkitLineClamp: '2',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
            }}>
            {children}
          </Text>
        ) :
          <Stack height='40px'>
            {children}
          </Stack>
        }
        <VSpacer size="5"/>
        <Stack direction="row" gap="16px">
          <Stack display="flex" flex={1}>
            <Button
              color="inherit"
              onClick={onEditClicked}
              testID={`${name}-edit-button`}
              variant="outlined"
            >
              Edit
            </Button>
          </Stack>
          <Stack display="flex" flex={1}>
            <Button
              color="inherit"
              onClick={onRemoveClicked}
              testID={`${name}-remove-button`}
              variant="outlined"
            >
              Remove
            </Button>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Stack>
        <Text category="body-medium" height="40px">
          {defaultText}
        </Text>
        <VSpacer size="5"/>
        <Stack direction="row" justifyContent="end">
          <Stack display="flex" flex={0.5}>
            <Button
              color="primary"
              onClick={onAddClicked}
              testID={`${name}-add-button`}
              variant="outlined"
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Stack>
    )}
  </Card>;
};