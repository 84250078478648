import {
  Button,
  VSpacer,
} from '@/components/DesignSystem';
import { PriceRequestDetailsModal } from '@/pages/ReviewSelectOffers/PriceRequestDetailsModal';
import { testPricingRequest } from '@/test/data/testPricingRequest';
import { ApiPricingRequest, ApiUser } from '@api/interfaces';
import { Stack } from '@mui/system';
import { FulfillmentMethod, State } from '@shared/enums';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

const demoPriceRequest: ApiPricingRequest = {
  ...testPricingRequest,
  deliveryAddress1: "Test",
  deliveryAddress2: "Test",
  deliveryCity: "Test",
  deliveryPostalCode: "12345",
  deliveryState: State.NewYork,
  reviewExpiration: new Date(),
  fulfillmentMethod: FulfillmentMethod.DELIVERY,
  user: {
    email: "test@test.com",
    businessName: "Test farm name",
    telephone: "1234567891",
  } as ApiUser,
};

export const PriceRequestDetailsModalDemo = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Stack>
      <PriceRequestDetailsModal
        onClose={() => setShowModal(false)}
        open={showModal}
        priceRequest={demoPriceRequest}
      />
      <DemoBlock label="Product Request Details Modal">
        <Button
          onClick={() => setShowModal(true)}
          testID="test"
        >
          Show Modal
        </Button>
        <VSpacer size="5" />
      </DemoBlock>
    </Stack>
  );
};
