import {
  IconButton,
  Table as MuiTable,
  TableCell,
  TableHead,
  TableProps as MuiTableProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@mui/material';
import { Children, ReactElement, ReactNode, useState } from 'react';
import { Text } from '@/components/DesignSystem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export interface TableProps extends MuiTableProps {
  headers: string[],
  rows?: ReactNode[][],
}

export interface TableRowProps extends MuiTableRowProps {
  expandedComponent?: ReactNode,
  values: ReactNode[],
}

export const Table = ({
  children,
  headers,
  rows,
  ...rest
}: TableProps) => {
  const hasExpandableRow = Children.toArray(children).some((child) => (
    (child as ReactElement).props.expandedComponent
  ));

  return (
    <MuiTable {...rest} sx={{ bgcolor: '#1B1B1B' }}>
      <TableHead>
        <MuiTableRow>
          {hasExpandableRow && (
            <TableCell sx={{ borderBottom: '1px solid #FFFFFF7A', width: '60px' }} />
          )}
          {headers.map((headerName) => (
            <TableCell key={headerName} sx={{ borderBottom: '1px solid #FFFFFF7A' }}>
              <Text fontSize="14px" fontWeight={700}>{headerName}</Text>
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
      {children ?? rows?.map((row, i) => (
        <MuiTableRow key={i}>
          {row.map((value, j) => (
            <TableCell key={j}>
              {value}
            </TableCell>
          ))}
        </MuiTableRow>
      ))}
    </MuiTable>
  );
};

export const TableRow = ({
  expandedComponent,
  values,
  ...rest
}: TableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <MuiTableRow {...rest}>
        {!!expandedComponent && (
          <TableCell sx={{ borderBottom: '1px solid #FFFFFF0F' }}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}
        {values.map((value, i) => (
          <TableCell key={i} sx={{ borderBottom: '1px solid #FFFFFF0F' }}>
            {value}
          </TableCell>
        ))}
      </MuiTableRow>
      {isExpanded && expandedComponent}
    </>
  );
};