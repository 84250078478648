import { Checkbox, HSpacer, Select } from '@/components/DesignSystem';
import Circle from '@mui/icons-material/Circle';
import { MenuItem, Stack } from '@mui/material';
import { FC } from 'react';

export type FilterOption = {
  disabled?: boolean,
  id: number | string,
  label: string,
};

type FilterMenuProps = {
  defaultOpen?: boolean,
  label: string,
  onSelect: (option: FilterOption, value: boolean) => void,
  options: FilterOption[],
  selectedOptions?: Set<FilterOption>,
  singleSelect?: boolean,
  testID: string,
};

export const FilterMenu: FC<FilterMenuProps> = ({
  defaultOpen = false,
  label,
  onSelect,
  options,
  selectedOptions= new Set(),
  singleSelect= false,
  testID,
}) => {
  const isOptionSelected = (option: FilterOption) => {
    return Array.from(selectedOptions).some((selected) => selected.id === option.id);
  };

  const onSelection = (optionId: string) => {
    const selectedOption = options.find((option) => option.id === optionId);
    if (selectedOption) {
      onSelect(selectedOption, !isOptionSelected(selectedOption));
    }
  };

  const renderMenuOption = (option: FilterOption, index: number) => {
    return singleSelect
      ? option.label
      : (
        <Checkbox
          checked={isOptionSelected(option)}
          testID={`${testID}-option-${index}`}>
          {option.label}
        </Checkbox>
      );
  };

  return (
    <Select
      defaultOpen={defaultOpen}
      displayEmpty
      multiple={!singleSelect}
      onChangeValue={onSelection}
      renderValue={() => (
        <Stack alignItems="center" direction="row">
          { label }
          <HSpacer size="4" />
          { !!selectedOptions.size &&
            <>
              <Circle color="info" sx={{ fontSize: 6 }} />
              <HSpacer size="4" />
            </>
          }
        </Stack>
      )}
      testID={testID}
      value={!singleSelect ? [] : undefined}
      variant="outlined"
      width={200}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.id}
          onClick={() => {
            if (!singleSelect) {
              onSelection(`${option.id}`);
            }
          }}
          value={option.id}
        >
          {renderMenuOption(option, index)}
        </MenuItem>
      ))}
    </Select>
  );
};
