import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Stack, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Fragment, ReactNode, useState } from 'react';
import { Card, Chip, ChipProps, HSpacer, IconButton, IconButtonProps, Text, VSpacer } from '..';
import { DataPoint, DataPointProps } from '../DataPoint/DataPoint';

export interface DataSetProps {
  actions?: ReactNode[],
  badges?: ChipProps[],
  children?: ReactNode,
  hasDrawer?: boolean,
  isOpen?: boolean,
  metadata?: DataPointGroupProps[],
  overline?: string,
  rightContent?: ReactNode,
  subtitle?: string,
  testID: string,
  title?: string,
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean,
  testID: string,
}

export type DataPointGroupProps = Omit<DataPointProps, 'size' | 'spacing' | 'testID'>;

export const ExpandMoreButton = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => {
  const rotated: number = expand ? 0 : 1;
  return ({
    transform: rotated ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  });
});

export const DataSet = ({
  actions,
  badges,
  children,
  hasDrawer = false,
  metadata,
  isOpen = false,
  overline,
  rightContent,
  subtitle,
  testID,
  title,
}: DataSetProps) => {
  const { isMobile } = useMediaQuery();
  const [expanded, setExpanded] = useState(isOpen);

  return (
    <Card
      cardContentStyle={{ padding: 0 }}
      sx={{ padding: '20px 8px 0 20px' }}
      testID={`${testID}-card`}
    >
      <Grid alignItems="flex-start" container direction="row" p={0}>
        {/* Title and badges */}
        <Grid xs={isMobile ? true : actions?.length ? 10 : 12}>
          <>
            {/* Title area */}
            <Stack>
              {!!overline && (
                <Text
                  category="overline"
                  testID={`${testID}-overline`}
                >
                  {overline}
                </Text>
              )}
              <Stack direction="row" justifyContent="space-between">
              {!!title && (
                <Text category="body-large" flex={1} testID={`${testID}-title`}>{title}</Text>
              )}
              {!!rightContent && (
                <>
                  <HSpacer size="5" />
                  {rightContent}
                  {!actions?.length && <HSpacer size="6" />}
                </>
              )}
              </Stack>
              {!!subtitle && (
                <Text
                  category="body-medium"
                  testID={`${testID}-subtitle`}
                >
                  {subtitle}
                </Text>
              )}
            </Stack>
            {/* Badges */}
            <VSpacer size="5" />
            <Stack
              alignItems="top"
              direction="row"
              spacing={1}
            >
              {badges?.map((badge, idx) => (
                <Chip
                  key={`chip-${idx}`}
                  label={badge.label}
                  testID={`${testID}-chip-${idx}`}
                />
              ))}
            </Stack>
            {!!metadata && (
              <Stack>
                <VSpacer size="5" />
                <Grid
                  columnSpacing={4}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  container
                  direction="row"
                >
                  {metadata?.map((props: DataPointGroupProps, idx) => (
                    <Grid key={`data-point-${idx}`} xs={4}>
                      <DataPoint spacing="flushed" testID={`data-point-${idx}`} {...props}>
                        {props.children}
                      </DataPoint>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            )}
          </>
        </Grid>
        {!!actions?.length && (
          <DesktopOnly>
            <Grid xs>
              <Stack
                alignContent="center"
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
              >
                {actions?.map((action, idx) => (
                  <Stack
                    direction="row"
                    key={`button-container-${idx}`}
                  >
                    {action}
                    {!!actions?.length && <HSpacer size="3" />}
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </DesktopOnly>
        )}
        {hasDrawer && (
          <Stack>
            <ExpandMoreButton
              // expects a non-boolean value so an unary operator is added to convert to number.
              // https://maximeblanc.fr/blog/how-to-fix-the-received-true-for-a-non-boolean-attribute-error/
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              testID={`${testID}-expand-more-button`}
            >
              <ExpandMore />
            </ExpandMoreButton>
          </Stack>
        )}
      </Grid>
      {!!children && (
        <>
          {(expanded || isOpen) && <VSpacer size="5" />}
          <Collapse
            component={Stack}
            in={expanded}
            sx={{
              paddingRight: '12px',
              margin: 0,
            }}
            timeout="auto"
            unmountOnExit
          >
            {children}
          </Collapse>
        </>
      )}
      <MobileOnly>
        <Stack
          alignContent="center"
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-end"
        >
          {actions?.map((action, idx) => (
            <Fragment key={`button-${idx}`}>
              {action}
              {!!actions?.length && <HSpacer size="3" />}
            </Fragment>
          ))}
        </Stack>
      </MobileOnly>
    </Card>
  );
};
