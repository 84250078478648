import { useState } from 'react';
import { Button, VSpacer } from '@/components/DesignSystem';
import { CircularProgress, Container, Divider, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RetailerDetailsCard from './RetailerDetailsCard';
import { useNavigate, useParams } from 'react-router-dom';
import MembersCard from './Members/MemberOverviewSection';
import LocationCard from './Locations/LocationOverviewSection';
import { useGetRetailerById, useGetRetailerMembers } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import { DescriptionCard } from '@/pages/Admin/HierarchyOfRetailers/Retailer/DescriptionCard';
import { RetailerEndpoint } from '@api/endpoints';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import {
  BrandIdentityInputs,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandIdentityInputs';
import {
  RewardsProgramsCard,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramsCard';

const Loader = () => (
  <Container>
    <Stack alignItems='center'>
      <VSpacer size='14' />
      <CircularProgress />
    </Stack>
  </Container>
);

const RetailerDetailsOverviewPage = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showAddMember, setShowAddMember] = useState(false);
  const [showViewMember, setShowViewMember] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const backToAllRetailers = () => navigate(-1);

  const onAddMember = () => setShowAddMember(!showAddMember);
  const onViewMember = () => setShowViewMember(!showViewMember);
  const onAddLocation = () => setShowLocation(!showLocation);
  const { debouncedSearch, search, setSearch } = useSearch();

  const { retailerMembers, isLoading } = useGetRetailerMembers(id, debouncedSearch);

  const { retailer } = useGetRetailerById(id ?? '');

  const { mutate: updateRetailer } = useMutation(
    (updates: RetailerEndpoint.Update.Request) => (
      HierarchyOfRetailersApi.updateRetailer(id, updates)
    ),
    {
      onSuccess: async (_, updates) => {
        if (!retailer?.description && updates.description) {
          openSnackbar('Description added');
        } else if (retailer?.description && updates.description) {
          openSnackbar('Description updated');
        } else if (retailer?.description && updates.description === null) {
          openSnackbar('Description deleted');
        }

        if (updates.image) {
          openSnackbar('Logo uploaded');
        } else if (updates.image === null) {
          openSnackbar('Logo deleted');
        }

        if (!retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color added');
        } else if (retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color updated');
        } else if (retailer?.brandColor && updates.brandColor === null) {
          openSnackbar('Brand color deleted');
        }

        if (!retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template added');
        } else if (retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template updated');
        } else if (retailer?.templateType && updates.templateType === null) {
          openSnackbar('Deliverable template deleted');
        }

        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: Error) => {
        openSnackbar(error.message || 'An error has occurred');
      },
    },
  );

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, id],
    async () => {
      const result = await HierarchyOfRetailersApi.getRetailerCategories(id);
      return result.filter(({ name }) => name);
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Button
        onClick={backToAllRetailers}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{
          color: 'white',
          margin: '25px 0px',
        }}
        testID="back-to-all-retailers"
        variant="text"
      >
        Back to all retailers
      </Button>
      <Stack spacing="2">
        <RetailerDetailsCard />
        <VSpacer size="7" />
        <Divider />
        <VSpacer size="6" />
        <DescriptionCard
          description={retailer?.description}
          onChange={(description) => (
            updateRetailer({ description: description || null })
          )}
        />
        <VSpacer size="6" />
        {!!retailer && (
          <>
            <BrandIdentityInputs
              onChange={updateRetailer}
              retailer={retailer}
            />
            <VSpacer size="8" />
          </>
        )}
        <Divider />
        <VSpacer size="6" />
        <MembersCard
          members={retailerMembers}
          onAddMember={onAddMember}
          onViewMember={onViewMember}
          search={search}
          setSearch={setSearch}
          showAddMember={showAddMember}
          showViewMember={showViewMember}
        />
        <VSpacer size="8" />
        <Divider />
        <VSpacer size="8" />
        <LocationCard
          isRetailerActive={retailer?.isActive ?? false}
          onAddLocation={onAddLocation}
          retailerId={id}
          show={showLocation}
        />
        <VSpacer size="8" />
        <Divider />
        <VSpacer size="8" />
        {!!retailer && !!categories?.length && (
          <>
            <RewardsProgramsCard
              retailerId={retailer.id}
              rewardsProgram={retailer?.rewardsPrograms?.[0]}
            />
            <VSpacer size="8" />
            <Divider />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default RetailerDetailsOverviewPage;
