import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ApplicationRateUomSelect } from '@/components/shared/Select/ApplicationRateUomSelect';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { Stack } from '@mui/material';
import {
  ApplicationUom,
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  ProductUom,
  ProductUomEnums,
  parseEnum,
  parseEnums,
} from '@shared/enums';
import { ConversionUtility, localizeNumber } from '@shared/utilities';
import { useEffect, useMemo, useState } from 'react';
import { DataPoint, Dialog, NumericInput } from '..';
import { Button, ButtonProps } from '../Button/Button';
import { DialogProps } from '../Dialog/Dialog';
import { Modal, ModalProps } from '../Modal/Modal';
import { VSpacer } from '../Spacer';

interface CalculateQuantityProps {
  allowedUoms?: string[],
  onApplyAndClose: (
    quantity: number,
    uom: DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure,
  ) => void,
  onClose: () => void,
  open: boolean,
  productUom?: ProductUom,
}

export const CalculateQuantityModal = ({
  allowedUoms,
  onApplyAndClose,
  onClose,
  open,
  productUom,
}: CalculateQuantityProps) => {
  const [inputUom, setInputUom] = useState<ApplicationUom | ProductUom | undefined>(productUom);
  const [outputUom, setOutputUom] = useState<ApplicationUom | ProductUom | undefined>();
  const [acres, setAcres] = useState<number | undefined>();
  const [applicationRate, setApplicationRate] = useState<number | undefined>();
  const [acresError, setAcresError] = useState<boolean>(false);
  const [applicationRateError, setApplicationRateError] = useState<boolean>(false);

  const dryUoms: ApplicationUom[] = Object.values(DryFormulationUnitOfMeasure);
  const liquidUoms: ApplicationUom[] = Object.values(LiquidFormulationUnitOfMeasure);
  const isUomUnconvertible = (
    !dryUoms.includes(inputUom as ApplicationUom)
    && !liquidUoms.includes(inputUom as ApplicationUom)
  );

  const allowedInputOptions: ApplicationUom[] | undefined = allowedUoms?.map((allowed) => (
    parseEnums(ProductUomEnums, allowed)
  ));

  const getDisabledUoms = () => {
    const isDryUom = !!parseEnum(DryFormulationUnitOfMeasure, inputUom);
    const isLiquidUom = !!parseEnum(LiquidFormulationUnitOfMeasure, inputUom);
    if (isLiquidUom) {
      return allowedInputOptions?.filter((uom) => (
        !parseEnum(LiquidFormulationUnitOfMeasure, uom)
      ));
    }
    if (isDryUom) {
      return allowedInputOptions?.filter((uom) => (
        !parseEnum(DryFormulationUnitOfMeasure, uom)
      ));
    }
    return allowedInputOptions?.filter((uom) => (
      uom !== inputUom
    ));
  };
  const disabledUoms = getDisabledUoms();

  const quantity = useMemo(() => {
    if (!acres || !applicationRate || disabledUoms?.includes(outputUom as ApplicationUom)) {
      return 0;
    }
    let quantity = acres * applicationRate;
    if (inputUom && outputUom && inputUom !== outputUom && !isUomUnconvertible) {
      quantity = ConversionUtility.convertProductUoM(quantity, inputUom, outputUom);
    }
    return quantity;
  }, [acres, applicationRate, outputUom, inputUom]);

  const formatQuantityNeeded = (quantity: number, uom?: ApplicationUom) => {
    const quantityText = localizeNumber(quantity, 2, true);
    const uomText = uom ? FriendlyUoM[uom] : "";
    const pluralizer = uom && quantity !== 1 ? "s" : '';
    return `${quantityText} ${uomText}${pluralizer}`;
  };

  const handleInputUomChange = (newUom: ApplicationUom) => {
    const isNewUomTypeDry = dryUoms.includes(newUom);
    const isNewUomTypeLiquid = liquidUoms.includes(newUom);
    const isOutputUomTypeDry = !!outputUom && dryUoms.includes(outputUom as ApplicationUom);
    const isOutputUomTypeLiquid = !!outputUom && liquidUoms.includes(outputUom as ApplicationUom);
    const isOutputUomTypeNeither = !isOutputUomTypeLiquid && !isOutputUomTypeDry;
    const areUomsDifferentTypes = (
      (isNewUomTypeDry && isOutputUomTypeLiquid)
      || (isNewUomTypeLiquid && isOutputUomTypeDry)
      || isOutputUomTypeNeither
    );
    const isNewUomUnconvertible = (
      !dryUoms.includes(newUom as ApplicationUom)
      && !liquidUoms.includes(newUom as ApplicationUom)
    );

    if (isNewUomUnconvertible || areUomsDifferentTypes) {
      setOutputUom(newUom);
    }
    setInputUom(newUom as DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure);
  };

  useEffect(() => {
    if (inputUom && !outputUom) {
      setOutputUom(inputUom);
    }
    // Linter expects outputUom
    // Not depending on outputUom side effect to update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputUom]);

  useEffect(() => {
    if (acres) {
      setAcresError(false);
    }
    if (applicationRate) {
      setApplicationRateError(false);
    }
  }, [acres, applicationRate]);

  const resetCalculations = () => {
    setAcres(undefined);
    setApplicationRate(undefined);
    setInputUom(undefined);
    setOutputUom(undefined);
    onClose();
  };

  const isFormValid = inputUom && outputUom && acres && applicationRate;

  const modalProps: ModalProps | Omit<DialogProps, 'open'> = {
    acceptButton: (props: ButtonProps) => (
      <Button
        disabled={!isFormValid}
        onClick={() => {
          onApplyAndClose(
            quantity,
            outputUom as DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure,
          );
          resetCalculations();
        }}
        {...props}
      >
        Save
      </Button>
    ),
    cancelButton: (props: ButtonProps) => (
      <Button onClick={resetCalculations} {...props}>
        Cancel
      </Button>
    ),
    open: open,
    onClose: resetCalculations,
    showCloseButton: false,
    testID: "calculate-quantity-modal",
    title: "Calculate quantity",
  };

  return (
    <>
      <MobileOnly>
        <Dialog {...modalProps}>
          <NumericInput
            error={acresError}
            label="Acres"
            maxLength={11}
            onBlur={() => setAcresError(!acres)}
            onChangeNumber={setAcres}
            testID="calculate-quantity-modal-acres"
            value={acres}
          />
          <VSpacer size="7" />
          <NumericInput
            error={applicationRateError}
            label="Application rate/ac"
            maxLength={11}
            onBlur={() => setApplicationRateError(!applicationRate)}
            onChangeNumber={setApplicationRate}
            testID="calculate-quantity-modal-ar"
            value={applicationRate}
          />
          <VSpacer size="7" />
          <ApplicationRateUomSelect
            allowedUoms={allowedInputOptions}
            disabledUoms={[]}
            onChangeUoM={(newUom) => handleInputUomChange(newUom)}
            productUom={productUom}
            testID="calculate-quantity-modal-uom"
            value={inputUom}
          />
          <VSpacer size="7" />
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <DataPoint
              containerStyle={{ flex: 1 }}
              label="Quantity needed"
              size="large"
              spacing="fixed"
              stacked
              testID="calculate-quantity-modal-qty-needed"
            >
              {formatQuantityNeeded(quantity, outputUom as ApplicationUom)}
            </DataPoint>
            <ApplicationRateUomSelect
              allowedUoms={allowedInputOptions}
              disabled={!productUom && !inputUom}
              disabledUoms={disabledUoms}
              onChangeUoM={setOutputUom}
              productUom={productUom
                  || inputUom as DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure}
              testID="calculate-quantity-modal-uom-output"
              value={outputUom}
            />
          </Stack>
        </Dialog>
      </MobileOnly>
      <DesktopOnly>
        <Modal {...modalProps}>
          <NumericInput
            error={acresError}
            label="Acres"
            maxLength={11}
            onBlur={() => setAcresError(!acres)}
            onChangeNumber={setAcres}
            testID="calculate-quantity-modal-acres"
            value={acres}
          />
          <VSpacer size="7" />
          <NumericInput
            error={applicationRateError}
            label="Application rate/ac"
            maxLength={11}
            onBlur={() => setApplicationRateError(!applicationRate)}
            onChangeNumber={setApplicationRate}
            testID="calculate-quantity-modal-ar"
            value={applicationRate}
          />
          <VSpacer size="7" />
          <ApplicationRateUomSelect
            allowedUoms={allowedInputOptions}
            onChangeUoM={(newUom) => handleInputUomChange(newUom)}
            productUom={productUom}
            testID="calculate-quantity-modal-uom"
            value={inputUom}
          />
          <VSpacer size="7" />
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <DataPoint
              containerStyle={{ flex: 1 }}
              label="Quantity needed"
              size="large"
              stacked={true}
              testID="calculate-quantity-modal-qty-needed"
            >
              {formatQuantityNeeded(quantity, outputUom as ApplicationUom)}
            </DataPoint>
            <ApplicationRateUomSelect
              allowedUoms={allowedInputOptions}
              disabled={!productUom && !inputUom}
              disabledUoms={disabledUoms}
              onChangeUoM={setOutputUom}
              productUom={productUom
                || inputUom as DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure
              }
              testID="calculate-quantity-modal-uom-output"
              value={outputUom}
            />
          </Stack>
        </Modal>
      </DesktopOnly>
    </>
  );
};
