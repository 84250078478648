import {
  Button,
  Checkbox,
  Chip,
  HSpacer,
  IconButton,
  MenuItem,
  Radio,
  Search,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import {
  Filter,
  FilterOption,
  FilterSelection,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { Box, ButtonGroup, Divider, RadioGroup, Stack } from '@mui/material';
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";

const styles: SXStyles = {
  radio: {
    py: '4px',
  },
  checkbox: {
    p: '13px 16px 13px 0',
  },
} as const;

interface RawFilterSelectorProps {
  filter: Filter,
  onChange?: (selection: FilterSelection) => void,
  selection?: FilterSelection,
  testID: string,
}

const RawFilterSelector = ({
  filter,
  onChange,
  selection = new Set(),
  testID,
}: RawFilterSelectorProps) => {
  const [search, setSearch] = useState('');
  const [subOptionVisibility, setSubOptionVisibility] = (
    useState<{ [optionId: string]: boolean }>({})
  );

  useEffect(() => {
    const newSubOptionVisibility: { [optionId: string]: boolean } = {};
    filter.options.forEach((option) => {
      if (option.subOptions?.length && subOptionVisibility[option.id] === undefined) {
        newSubOptionVisibility[option.id] = false;
      }
    });
    if (Object.keys(newSubOptionVisibility).length) {
      setSubOptionVisibility({
        ...subOptionVisibility,
        ...newSubOptionVisibility,
      });
    }
  }, [filter]);

  const doesOptionMatchSearch = (option: FilterOption) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  };

  const getNumberOfOptions = () => {
    return filter.options.reduce((acc, option) => (
      acc + 1 + (option.subOptions?.length ?? 0)
    ), 0);
  };

  const getSubOptionsMatchingSearch = (option: FilterOption) => {
    if (!option.subOptions) {
      return [];
    }
    const parentOptionMatchesSearch = doesOptionMatchSearch(option);
    if (parentOptionMatchesSearch) {
      return option.subOptions;
    }
    return option.subOptions.filter(
      (option) => doesOptionMatchSearch(option),
    );
  };

  const handleChange = useCallback((newSelection: FilterSelection) => {
    onChange?.(newSelection);
  }, [onChange]);

  const handleParentOptionSelect = (option: FilterOption) => {
    const newSelection = new Set(selection);
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const hasSomeChecked = hasSomeButNotAllSubOptionsChecked(option);
      const isChecked = isOptionChecked(option);
      if (isChecked || hasSomeChecked) {
        option.subOptions?.forEach((option) => {
          newSelection.delete(option.id);
        });
      } else {
        option.subOptions?.forEach((option) => {
          newSelection.add(option.id);
        });
      }
    } else {
      if (selection.has(option.id)) {
        newSelection.delete(option.id);
      } else {
        newSelection.add(option.id);
      }
    }
    handleChange(newSelection);
  };

  const hasSomeButNotAllSubOptionsChecked = (option: FilterOption) => {
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const areSomeSelected = option.subOptions?.some((option) => selection?.has(option.id));
      const areAllSelected = option.subOptions?.every((option) => selection?.has(option.id));
      return (areSomeSelected && !areAllSelected) ?? false;
    }
    return false;
  };

  const isOptionChecked = (option: FilterOption) => {
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const areAllSelected = option.subOptions?.every((option) => selection?.has(option.id));
      return areAllSelected ?? false;
    } else {
      return selection?.has(option.id) ?? false;
    }
  };

  switch (filter.selectionMethod) {
    case 'boolean':
    case 'chips':
      return (
        <Stack direction="row" flexWrap="wrap" pr="24px" rowGap="16px">
          {filter.options.map((option) => (
            <Fragment key={option.id}>
              <Chip
                icon={selection.has(option.id) ? <Check color="primary" /> : undefined}
                label={
                  <Text category="label-large">
                    {option.label}
                  </Text>
                }
                onClick={() => {
                  const newSelection = new Set(selection);
                  if (newSelection.has(option.id)) {
                    newSelection.delete(option.id);
                  } else {
                    newSelection.add(option.id);
                  }
                  handleChange(newSelection);
                }}
                testID={`${testID}-${option.id}`}
                variant={selection.has(option.id) ? 'filled' : 'outlined'}
              />
              <HSpacer size="3"/>
            </Fragment>
          ))}
        </Stack>
      );
    case 'single-select':
      return (
        <Box pr="24px">
          <RadioGroup
            onChange={(event, value) => handleChange(new Set([value]))}
            value={selection.values().next().value ?? ''}
          >
            {filter.options.map((option) => (
              <Radio key={option.id} sx={styles.radio} testID={`${testID}-${option.id}`} value={option.id}>
                {option.label}
                {!!option.subline && (
                  <Text category="body-medium">{option.subline}</Text>
                )}
              </Radio>
            ))}
          </RadioGroup>
        </Box>
      );
    case 'multi-select':
      return (
        <Stack>
          {getNumberOfOptions() > 20 && (
            <>
              <Search
                onChangeText={(text) => {
                  setSearch(text);
                  // forces a rerender so menu adjusts with anchor properly
                  onChange?.(new Set(selection));
                }}
                onKeyDown={(e) => e.stopPropagation()}
                testID={`${testID}-search`}
                value={search}
              />
              <VSpacer size="5" />
            </>
          )}
          {filter.options
            .filter((option) => {
              if (doesOptionMatchSearch(option)) {
                return true;
              }
              const subOptions = getSubOptionsMatchingSearch(option);
              const hasSubOptionMatchingSearch = subOptions.length > 0;
              return hasSubOptionMatchingSearch;
            })
            .map((option) => (
              <Fragment key={option.id}>
                <Divider />
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <MenuItem
                    onClick={() => handleParentOptionSelect(option)}
                    paddingLeft={0}
                    sx={styles.checkbox}
                    testID={`${testID}-${option.id}`}
                    value={option.id}
                  >
                    <Checkbox
                      checked={isOptionChecked(option)}
                      indeterminate={hasSomeButNotAllSubOptionsChecked(option)}
                      testID={`${testID}-${option.id}`}
                    >
                      {option.label}
                      {!!option.subline && (
                        <Text category="body-medium">{option.subline}</Text>
                      )}
                    </Checkbox>
                  </MenuItem>
                  {!!(getSubOptionsMatchingSearch(option).length) &&
                    <IconButton
                      onClick={() => {
                        const currentValue = subOptionVisibility[option.id];
                        setSubOptionVisibility({
                          ...subOptionVisibility,
                          [option.id]: !currentValue,
                        });
                        // forces a rerender so menu adjusts with anchor properly
                        onChange?.(new Set(selection));
                      }}
                      testID={`${option.id}-expand-button`}
                    >
                      {subOptionVisibility[option.id] || !!search
                        ? <ExpandLess /> : <ExpandMore />
                      }
                    </IconButton>
                  }
                </Stack>
                {(subOptionVisibility[option.id] || !!search) && !!option.subOptionLabel
                  && <Text category="title-small">{option.subOptionLabel}</Text>
                }
                {(subOptionVisibility[option.id] || !!search)
                  && getSubOptionsMatchingSearch(option).map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => {
                        const newSelection = new Set(selection);
                        if (selection.has(option.id)) {
                          newSelection.delete(option.id);
                        } else {
                          newSelection.add(option.id);
                        }
                        handleChange(newSelection);
                      }}
                      paddingLeft={36}
                      testID={`${testID}-${option.id}`}
                      value={option.id}
                    >
                      <Checkbox
                        checked={selection.has(option.id)}
                        testID={`${testID}-${option.id}`}
                      >
                        {option.label}
                      </Checkbox>
                    </MenuItem>
                  ))
                }
              </Fragment>
            ))}
        </Stack>
      );
    case 'segmented-button':
      return (
        <ButtonGroup>
          {filter.options.map((option) => (
            <Button
              aria-selected={selection.has(option.id)}
              key={option.id}
              onClick={() => handleChange(new Set([option.id]))}
              testID={`${testID}-${option.id}`}
            >
              {selection.has(option.id) && (
                <>
                  <Check/>
                  <HSpacer size="2"/>
                </>
              )}
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      );
  }
};

export const FilterSelector = memo(RawFilterSelector);
