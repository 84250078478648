import { useState } from 'react';
import { Button, HSpacer, Input, Modal } from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import { isValidHexColor } from '@shared/utilities';

interface BrandColorModalProps {
  brandColor?: string | null,
  onClose: () => void,
  onSave: (brandColor: string) => void,
}

export const BrandColorModal = ({
  brandColor,
  onClose,
  onSave,
}: BrandColorModalProps) => {
  const [value, setValue] = useState<string>(brandColor ?? '#');

  const isEditing = !!brandColor;
  const isValueChanged = value !== (brandColor ?? null);
  const isValidColor = isValidHexColor(value);
  const isValid = isValueChanged && isValidColor;
  const isError = value.length === 7 && !isValidColor;

  const ColorCircle = (
    <Box
      bgcolor={isValidColor ? value : 'transparent'}
      border={isValidColor ? 'none' : '2px solid white'}
      borderRadius="50%"
      height="32px"
      width="32px"
    />
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            onSave(value);
          }}
          testID="brand-color-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="brand-color-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="brand-color-modal"
      title={`${isEditing ? 'Edit' : 'Add'} brand color`}
    >
      <Stack alignItems="center" direction="row">
        {ColorCircle}
        <HSpacer size="5" />
        <Input
          error={isError}
          helperText={isError ? 'Colors must be in 6-digit hexadecimal format.' : undefined}
          label="Brand Color"
          maxLength={7}
          onChangeText={(text) => setValue(text || '#')}
          prefix="#"
          testID="brand-color-modal-input"
          value={value}
        />
      </Stack>
    </Modal>
  );
};
