import {
  Chip,
  HSpacer,
  IconButton,
  PriceRequestProductCard,
  PriceRequestRetailerCard,
  PricingRequestCard,
} from "@/components/DesignSystem";
import { testPricingRequest } from "@/test/data/testPricingRequest";
import { PricingRequestStatus } from "@shared/enums";
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { ReactNode } from "react";
import { DemoBlock } from "../../components/DemoBlock";

export const PricingRequestCardDemo = () => {

  const productCardActions: ReactNode[] = [
    <IconButton
      key="edit-icon"
      onClick={() => { null; }}
      testID='edit-icon'>
      <EditOutlined />
    </IconButton>,
    <IconButton
      key="delete-icon"
      onClick={() => { null; }}
      testID='delete-icon'>
      <DeleteOutlined />
    </IconButton>,
  ];
  const retailerCardActions: ReactNode[] = [
    <Chip
      key="save-retailer-chip"
      label="Save Retailer"
      onClick={() => { null; }}
      testID="save-retailer"
      variant="outlined"
    />,
    <HSpacer key="spacer-1" size="1"/>,
    <IconButton
      key="edit-icon"
      onClick={() => { null; }}
      testID='edit-icon'>
      <EditOutlined />
    </IconButton>,
    <IconButton
      key="delete-icon"
      onClick={() => { null; }}
      testID='delete-icon'>
      <DeleteOutlined />
    </IconButton>,
  ];

  return (
    <Stack>

      <DemoBlock label="All Props with note">
        <PricingRequestCard
          pricingRequest={testPricingRequest}
          showNote
          showOffers
          showOverline
          showStatus
          testID="pricing-request-card-demo-all"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="All Props no note">
        <PricingRequestCard
          pricingRequest={{ ...testPricingRequest, status: PricingRequestStatus.Accepted }}
          showOffers
          showOverline
          showStatus
          testID="pricing-request-card-demo-all-no-note"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="No Subtitle">
        <PricingRequestCard
          pricingRequest={{ ...testPricingRequest, status: PricingRequestStatus.Closed }}
          showNote
          showOverline
          showStatus
          testID="pricing-request-card-demo-no-subtitle"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="No Overline">
        <PricingRequestCard
          pricingRequest={{ ...testPricingRequest, status: PricingRequestStatus.Draft }}
          showNote
          showOffers
          showStatus
          testID="pricing-request-card-demo-no-overline"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="No Overline or Subtitle">
        <PricingRequestCard
          pricingRequest={{ ...testPricingRequest, status: PricingRequestStatus.Open }}
          showNote
          showStatus
          testID="pricing-request-card-demo-no-overline-subtitle"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="No Badge">
        <PricingRequestCard
          pricingRequest={testPricingRequest}
          showNote
          showOffers
          showOverline
          testID="pricing-request-card-demo-no-badge"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="Overline and Title Only">
        <PricingRequestCard
          pricingRequest={testPricingRequest}
          showNote
          showOverline
          testID="pricing-request-card-demo-overline-title"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="Title and Subtitle Only">
        <PricingRequestCard
          pricingRequest={testPricingRequest}
          showNote
          showOffers
          testID="pricing-request-card-demo-title-subtitle"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="Title Only">
        <PricingRequestCard
          pricingRequest={testPricingRequest}
          showNote
          testID="pricing-request-card-demo-title"
        >
        </PricingRequestCard>
      </DemoBlock>

      <DemoBlock label="Product request product card">
        <PriceRequestProductCard
          actions={productCardActions}
          crop="Crop"
          name="Product name"
          packageSize="Size"
          quantityUom="1 bu"
          testID="test-id"
          type="Type"
        />
      </DemoBlock>

      <DemoBlock label="Product request retailer card">
        <PriceRequestRetailerCard
          actions={retailerCardActions}
          email="test@test.com"
          name="Retailer name"
          phone="12312313213"
          testID="test-id"
        />
      </DemoBlock>

    </Stack>
  );
};
