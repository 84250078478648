import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { StringUtility } from '@/utilities/String';

export const getQuantityWithUom = (
  product: Pick<
    OfferProduct,
    'initialQuantity' | 'initialUom' | 'quantity' | 'uom'
  >,
  showAcreLabel?: boolean,
) => {
  if (!product.quantity || !product.uom) {
    return '';
  }

  const quantityWithUom = StringUtility.formatQuantityWithUom(product.quantity, product.uom);

  return `${quantityWithUom}${showAcreLabel ? ' per acre' : ''}`;
};

export const getInitialQuantityWithUom = (
  product: Pick<
    OfferProduct,
    'initialQuantity' | 'initialUom' | 'quantity' | 'uom'
  >,
  showAcreLabel?: boolean,
) => {
  if (!product.initialQuantity || !product.initialUom) {
    return '';
  }

  const showInitial = product.quantity !== product.initialQuantity
    || product.uom !== product.initialUom;

  const quantityWithUom = StringUtility.formatQuantityWithUom(
    product.initialQuantity,
    product.initialUom,
  );

  return showInitial ? `${quantityWithUom}${showAcreLabel ? ' per acre' : ''}` : '';
};

export const getIsProductRecommended = (
  product: Pick<
    OfferProduct,
    'initialQuantity' | 'initialUom' | 'quantity' | 'uom'
  >,
) => {
  const doesRateMatchInitial = (
    product.quantity === product.initialQuantity
    && product.uom === product.initialUom
  );
  const isInitialRateAvailable = (
    product.initialQuantity !== undefined && product.initialUom !== undefined
  );
  return !doesRateMatchInitial && isInitialRateAvailable;
};
