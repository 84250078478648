import {
  baseMenu,
  landusMenu,
  MenuPropWithRoute,
} from '@/components/DesignSystem/GlobalHeader/Menu';
import { ShoppingCartDrawer } from '@/components/DesignSystem/GlobalHeader/ShoppingCartDrawer';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { UserSwitcher } from '@/components/shared/UserSwitcher';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { SessionState, useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { ArrowForward, Logout, Menu, MenuOpen, ShoppingCart } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { userIsInternal } from '@shared/utilities';
import { Fragment, useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge } from '../Badge/Badge';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { Logo } from '../Logo/Logo';
import { Search } from '../Search/Search';
import { HSpacer } from '../Spacer';
import { Text } from '../Text/Text';
import { TopAppBar } from '../TopAppBar/TopAppBar';

interface GlobalHeaderProps {
  isSignedOut?: boolean,
  isStatic?: boolean,
  onSearch?: (value: string) => void,
}

const getLogo = (onClick: () => void) => {
  const subdomain = getSubdomain();
  switch (subdomain) {
    case 'landus':
      return (
        <Logo category="landus-logo" size="tiny" />
      );
    default:
      return <Logo category="reverse-half" onClick={onClick} size="small" />;
  }
};

const MenuDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
    boxSizing: 'border-box',
    height: '100%',
    padding: '4px 8px 12px',
    width: '320px',
  },
  '& .MuiDrawer-slider': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const MenuHeader = styled(Box)(() => ({
  paddingTop: '8px',
  maxHeight: '56px !important',
}));

export const GlobalHeader = ({
  isSignedOut,
  isStatic = false,
  onSearch,
}: GlobalHeaderProps) => {
  const { logout, sessionState, user } = useAuthentication();
  const { isCartLoading, shoppingCart, setShowCart } = useShoppingCart();
  const theme = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!matchRoutes([{ path: Routes.SHOP }, { path: Routes.PRODUCT_PAGE }], location.pathname)) {
      handleSearch('');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === Routes.SHOP) {
      if (searchQuery.length >= 3) {
        navigate({
          pathname: Routes.SHOP,
          search: `?search=${searchQuery}`,
        });
      }
      if (searchParams.get('search')) {
        handleSearch(searchParams.get('search') as string);
      }
    }
  }, [location.pathname, searchParams]);

  const styles: SXStyles = {
    menuItem: {
      padding: '18px 16px 18px 16px',
    },
    mobile: {
      alignItems: 'center',
      backgroundColor: 'background.default',
      display: 'flex',
      flex: 1,
      height: 'auto',
      justifyContent: 'center',
      padding: '8px 0 8px 0',
      position: 'fixed',
      top: '64px',
      width: '100%',
      zIndex: theme.zIndex.appBar,
    },
  } as const;

  const handleNavigation = (route: Routes) => {
    navigate(route);
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    void logout();
    setIsMenuOpen(false);
    navigate(Routes.LOGIN, { replace: true });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    onSearch?.(query);
  };

  const formatVersion = (version: string) => (
    version.match(/\d+\.\d+\.\d+/) ? ('v' + version) : version
  );

  const version = (AppConfig.env.test || userIsInternal(user)) && (
    <Text>{formatVersion(AppConfig.version)}</Text>
  );

  const leftAccessory = (
    <Stack direction="row">
      <IconButton
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        size="medium"
        testID={`menu-${isMenuOpen ? 'open' : 'closed'}`}
      >
        {isMenuOpen ? <MenuOpen fontSize="inherit" /> : <Menu fontSize="inherit" />}
      </IconButton>
      <HSpacer size="2" />
      {getLogo(() => navigate(Routes.HOMEPAGE))}
    </Stack>
  );

  const rightAccessory = (
    <Stack alignItems="center" direction="row" position="relative">
      {!!version && (
        <>
          {version}
          <HSpacer size="3" />
        </>
      )}
      {(sessionState !== SessionState.LOGGED_IN || isSignedOut) && (
        <Button
          onClick={() => {
            const route = subdomain ? Routes.STOREFRONT_LOGIN : Routes.LOGIN;
            handleNavigation(route);
          }}
          size="small"
          square
          testID="sign-in-button"
        >
          Sign in
        </Button>
      )}
      <IconButton
        onClick={() => setShowCart(true)}
        size="medium"
        sx={{ margin: 0 }}
        testID="shopping-cart"
      >
        <Badge
          content={!isCartLoading ? shoppingCart?.length : undefined}
          testID="shopping-cart-badge"
        >
          <ShoppingCart fontSize="inherit" />
        </Badge>
      </IconButton>
    </Stack>
  );

  const DesktopGlobalHeader = (
    <TopAppBar
      isStatic={isStatic}
      leftAccessory={leftAccessory}
      rightAccessory={rightAccessory}
      testID="desktop-global-header"
    >
      <Stack
        direction="row"
        flex={1}
        justifyContent="center"
      >
        <Search
          height={48}
          onChangeText={handleSearch}
          placeholder="Search Products"
          sx={{ marginLeft: '20px', marginRight: '20px' }}
          testID="global-header-search"
          value={searchQuery}
          width={420}
        />
      </Stack>
    </TopAppBar>
  );

  const MobileGlobalHeader = (
    <>
      <TopAppBar
        isStatic={isStatic}
        leftAccessory={leftAccessory}
        rightAccessory={rightAccessory}
        testID="mobile-global-header"
      />
      <Box sx={styles.mobile}>
        <Search
          height={48}
          onChangeText={handleSearch}
          placeholder="Search Products"
          testID="global-header-search"
          value={searchQuery}
          width="90vw"
        />
      </Box>
    </>
  );

  const subdomain = getSubdomain();
  let menuItems = subdomain === 'landus' ? landusMenu : baseMenu;
  if (!user) {
    menuItems = menuItems.filter((item) => !item.requiresAuthentication);
  }

  const renderMenuItems = () => menuItems.map((item, idx) => (
    <Fragment key={item.name}>
      {item.headerName && (
        <Box sx={styles.menuItem}>
          <Text>
            {item.headerName}
          </Text>
        </Box>
      )}
      <ListItem
        data-testid={`menu-item-${idx}`}
        disablePadding
        secondaryAction={item.showArrow && (
          <IconButton
            edge="end"
            onClick={() => {
              if (item.route) {
                navigate(item?.route);
              }
              setIsMenuOpen(!isMenuOpen);
            }}
            size="small"
            testID={`menu-item-arrow-${idx}`}
          >
            <ArrowForward fontSize="inherit" />
          </IconButton>
        )}
      >
        <ListItemButton
          onClick={() => {
            if (item.externalLink) {
              window.location.replace(item.externalLink);
            } else {
              navigate((item as MenuPropWithRoute).route);
            }
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          {!!item.icon && (
            <ListItemIcon>
              {item.icon({ fontSize: 'small' })}
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.name}
          />
        </ListItemButton>
      </ListItem>
      {item.dividerAfter ? <Divider /> : undefined}
    </Fragment>
  ));

  const signOutItem = (
    <ListItem
      data-testid={`menu-item-logout`}
      disablePadding
    >
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="inherit" />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItemButton>
    </ListItem>
  );

  const signInItem = (
    <Stack alignItems="center" justifyContent="center">
      <Button
        onClick={() => {
          const route = subdomain ? Routes.STOREFRONT_LOGIN : Routes.LOGIN;
          navigate(route, { replace: true });
        }}
        testID="sign-in-button"
      >
        Sign In
      </Button>
    </Stack>
  );

  return (
    <>
      <DesktopOnly>{DesktopGlobalHeader}</DesktopOnly>
      <MobileOnly>{MobileGlobalHeader}</MobileOnly>
      <MenuDrawer
        anchor="left"
        onClose={() => setIsMenuOpen(false)}
        open={isMenuOpen}
      >
        <MenuHeader>
          <IconButton
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            size="medium"
            testID="menu-open"
          >
            <MenuOpen fontSize="inherit" />
          </IconButton>
        </MenuHeader>
        <Stack
          sx={{
            alignContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <List
            sx={{ flex: 1 }}
          >
            {renderMenuItems()}
          </List>
          <Stack alignSelf="flex-start">
            <UserSwitcher />
          </Stack>
          {user && !getSubdomain() && signOutItem}
          {!user && signInItem}
        </Stack>
      </MenuDrawer>
      <ShoppingCartDrawer />
    </>
  );
};
