import { Text, VSpacer } from '@/components/DesignSystem';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ApiOfferProduct, ApiPricingRequestProduct, RecommendationProduct } from '@api/interfaces';
import { ApiBundleProduct } from '@api/interfaces/ApiBundleProduct';
import { Stack } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import React from 'react';

interface DeliverableProductCardProps {
  product: ApiOfferProduct | ApiBundleProduct | RecommendationProduct,
  requestProducts?: ApiPricingRequestProduct[],
}

export const DeliverableProductCard = ({
  product,
  requestProducts,
}: DeliverableProductCardProps) => {
  let productName = '';
  let totalCost;
  if ('productDetail' in product) {
    if ('name' in product) {
      productName = product.name;
    } else if (product.productDetail) {
      productName = product.productDetail.name;
    } else {
      const found = requestProducts?.find(
        (requestProduct) => requestProduct.id === product.pricingRequestProductId,
      );
      if (found) {
        productName = found.name;
      }
    }
    if ('recommendationId' in product) {
      totalCost = (product.price ?? 0) * (product.quantity ?? 0);
    } else {
      totalCost = product.price ?? 0;
    }
  } else {
    const bundleProduct = product as ApiBundleProduct;
    productName = bundleProduct.name;
    totalCost = bundleProduct.pricePerUnit * bundleProduct.quantity;
  }

  let packageType;
  if ('packageType' in product && product.packageType) {
    packageType = product.packageType;
  } else if ('package' in product && product.package) {
    packageType = product.package;
  }
  return (
    <>
      <Stack bgcolor="#f7f7f7" borderRadius="2px" p="16px">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Text category="title-small">{productName}</Text>
            <Text category="body-medium">
              {product.quantity} {FriendlyUoM[product.uom!]}
              {packageType ? `  •  ${packageType}` : ''}
            </Text>
          </Stack>
          <Stack alignItems="flex-end">
            <Text category="title-small">
              {formatCurrency(totalCost)}
            </Text>
            <Text category="body-small">
              {formatCurrency(totalCost / (product.quantity ?? 0))}/{FriendlyUoM[product.uom!]}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <VSpacer size="4" />
    </>
  );
};
