import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { MenuItem } from '@mui/material';
import {
  ApplicationUom,
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  parseEnum,
  ProductUom,
} from '@shared/enums';
import { Select } from '../../DesignSystem';
import { SelectProps } from '../../DesignSystem/Select/Select';

interface ApplicationRateUomSelectProps extends SelectProps {
  allowedUoms?: ApplicationUom[],
  disabledUoms?: string[],
  label?: string,
  onChangeUoM: (uom: DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure) => void,
  productUom?: ProductUom,
  showAllUomsIfNotDryOrLiquid?: boolean,
  value?: ApplicationUom | ProductUom,
}

export const ApplicationRateUomSelect = ({
  allowedUoms,
  disabledUoms = [],
  label = "UoM",
  onChangeUoM,
  productUom,
  showAllUomsIfNotDryOrLiquid = true,
  ...rest
}: ApplicationRateUomSelectProps) => {
  const isLiquidProduct = !!parseEnum(LiquidFormulationUnitOfMeasure, productUom);
  const isDryProduct = !!parseEnum(DryFormulationUnitOfMeasure, productUom);

  const dryProductUoMs = Object.values(DryFormulationUnitOfMeasure);
  const liquidProductUoMs = Object.values(LiquidFormulationUnitOfMeasure);

  let uoms: ApplicationUom[] = [];
  if (allowedUoms?.length) {
    uoms = allowedUoms;
  } else if (isLiquidProduct) {
    uoms = liquidProductUoMs;
  } else if (isDryProduct) {
    uoms = dryProductUoMs;
  } else {
    uoms = showAllUomsIfNotDryOrLiquid
      ? [...dryProductUoMs, ...liquidProductUoMs]
      : [productUom] as ApplicationUom[];
  }

  return (
    <Select
      label={label}
      onChangeValue={(uom) => onChangeUoM(
        uom as DryFormulationUnitOfMeasure | LiquidFormulationUnitOfMeasure,
      )}
      {...rest}
    >
      {uoms.map((uom) => {
        return (
          <MenuItem
            disabled={disabledUoms?.includes(uom)}
            key={uom}
            value={uom}
          >
            {FriendlyUoM[uom]}
          </MenuItem>
        );
      })}
    </Select>
  );
};
