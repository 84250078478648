import { FullPageLayout } from '@/components/layouts/FullPageLayout';
import { StorefrontCreateAccountForm } from '@/pages/Auth/StorefrontCreateAccountForm';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { FarmerCreateAccountForm } from './FarmerCreateAccountForm';
import { LoginHeader } from './LoginHeader';
import { ContactFooter } from '@/components/shared/ContactFooter';

export const CreateAccountPage = () => {
  const subdomain = getSubdomain();
  return (
    <FullPageLayout footer={<ContactFooter />} header={<LoginHeader />}>
      {subdomain ? <StorefrontCreateAccountForm /> : <FarmerCreateAccountForm />}
    </FullPageLayout>
  );
};
