import { ChipProps } from "@/components/DesignSystem";
import {
  FriendlyPricingRequestStatus,
  FriendlyPricingRequestStatusNew,
} from "@/constants/FriendlyPricingRequestStatus";
import { Product } from '@/pages/CreateSendPriceRequest/interfaces';
import { Color } from '@/themes/MUITheme/palette';
import { ApiPricingRequestPublic } from '@api/interfaces';
import {
  OfferStatusForSalesperson,
  PricingRequestStatus,
  PricingRequestStatusNew,
  ProductUom,
} from "@shared/enums";
import { ConversionUtility } from "@shared/utilities";
import { DateTime } from "luxon";

export const calculateCompanionProductQuantity = (
  rate: number,
  quantity: number,
  productUom: ProductUom,
  companionUom: ProductUom,
): (number | undefined) => {

  if (productUom === companionUom) {
    return rate * quantity;
  }

  if (ConversionUtility.isSameCategory(productUom, companionUom)) {
    return rate * ConversionUtility.convertProductUoM(quantity, productUom, companionUom);
  }

  return undefined;
};

export const formatLicense = (
  licenseNumber?: string | null,
  expirationDate?: Date | null,
) => {
  if (!licenseNumber) {
    return "Not provided -- please coordinate with the farmer directly";
  } else if (!expirationDate) {
    return licenseNumber;
  }
  const formattedDate = DateTime.fromJSDate(expirationDate).toFormat("MM/dd/yyyy");
  return `${licenseNumber} -- expires ${formattedDate}`;
};

export const getChipStatusForOffers = (
  pricingRequest: ApiPricingRequestPublic,
): ChipProps => {
  let color: Color = 'success';
  let label: string = '';

  switch(pricingRequest.retailerOfferStatus) {
    case OfferStatusForSalesperson.AwaitingOffer:
      color = 'warning';
      label = OfferStatusForSalesperson.AwaitingOffer;
      break;
    case OfferStatusForSalesperson.PaymentDue:
      color = 'error';
      label = OfferStatusForSalesperson.PaymentDue;
      break;
    case OfferStatusForSalesperson.OnAccountRequested:
      color = 'info';
      label = OfferStatusForSalesperson.OnAccountRequested;
      break;
    case OfferStatusForSalesperson.FinancingRequested:
      color = 'info';
      label = OfferStatusForSalesperson.FinancingRequested;
      break;
    case OfferStatusForSalesperson.AwaitingFulfillment:
      color = 'info';
      label = OfferStatusForSalesperson.AwaitingFulfillment;
      break;
    case OfferStatusForSalesperson.PickupReady:
      color = 'info';
      label = OfferStatusForSalesperson.PickupReady;
      break;
    case OfferStatusForSalesperson.AwaitingDelivery:
      color = 'info';
      label = OfferStatusForSalesperson.AwaitingDelivery;
      break;
    case OfferStatusForSalesperson.OfferSent:
      color = 'info';
      label = OfferStatusForSalesperson.OfferSent;
      break;
    case OfferStatusForSalesperson.OfferClosed:
      label = OfferStatusForSalesperson.OfferClosed;
      break;
    case OfferStatusForSalesperson.OfferExpired:
      label = OfferStatusForSalesperson.OfferExpired;
      break;
  }

  return {
    color,
    label,
    testID: 'view-price-request-status-badge',
  };
};

export const getStatusChipPropsFromPRStatusNew = (
  farmerStatus: PricingRequestStatusNew,
): ChipProps => {
  const label = FriendlyPricingRequestStatusNew[farmerStatus];
  let color: Color = 'info';
  const testID = 'pricing-request-chip-status';
  switch (farmerStatus) {
    case PricingRequestStatusNew.AwaitingFulfillment:
    case PricingRequestStatusNew.FinancingRequested:
    case PricingRequestStatusNew.OnAccountRequested:
    case PricingRequestStatusNew.OrderShipped:
    case PricingRequestStatusNew.AwaitingPickup:
    case PricingRequestStatusNew.ReviewOffer:
      color = 'info';
      break;
    case PricingRequestStatusNew.PaymentDue:
      color = 'error';
      break;
    case PricingRequestStatusNew.OfferClosed:
    case PricingRequestStatusNew.OfferExpired:
      break;
    case PricingRequestStatusNew.PendingApproval:
      color = 'warning';
      break;
    default:
      break;
  }
  return { color, label, testID };
};

export const getStatusChipPropsFromPRStatus = (status: PricingRequestStatus): ChipProps => {
  const message = FriendlyPricingRequestStatus[status];

  let color: Color | undefined = 'info';
  const testID="pricing-request-badge-status";

  switch (status) {
    case PricingRequestStatus.Accepted:
    case PricingRequestStatus.Open:
      color = 'success';
      break;
    case PricingRequestStatus.Draft:
    case PricingRequestStatus.AwaitingApproval:
      color = 'warning';
      break;
    case PricingRequestStatus.Review:
      color = 'info';
      break;
    case PricingRequestStatus.Closed:
      color = undefined;
      break;
  }

  const badgeProps = {
    color,
    label: message,
    testID,
  };

  return badgeProps;
};

export const getTotalProductCount = (products: Product[]) => {
  return products?.reduce(
    (sum, product) => sum + 1 + (product.companionProducts?.length ?? 0), 0,
  ) ?? 0;
};

export const scrubQtyAndUom = (product: Product) => {
  const isMissingUomOrQty = (product.quantity && !product.uom)
  || (product.uom && !product.quantity);
  let payload = { ...product };
  if (isMissingUomOrQty && !!product.recommendedRateNote) {
    payload = { ...payload, uom: null, quantity: null };
  }
  return payload;
};
