import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { isWebView } from '@/utilities/BrowserUtils';
import { ApiPricingRequest, ApiProductRecommendation } from '@api/interfaces';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { alpha, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface DeliverableBottomBarProps {
  pricingRequest?: ApiPricingRequest,
  recommendation?: ApiProductRecommendation,
}

export const DeliverableBottomBar = ({
  pricingRequest,
  recommendation,
}: DeliverableBottomBarProps) => {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();

  const farmer = pricingRequest?.user ?? recommendation?.recommendedFarmer?.[0]?.user;
  let overlineText = '';
  let buttonText = 'Place your order & earn rewards';
  if (recommendation) {
    if (farmer?.isProfileConfirmed) {
      overlineText = 'Lock it in! Contact your seller or open GROWERS';
    } else {
      overlineText = 'Lock it in! Contact your seller or download GROWERS';
    }
  }
  if (pricingRequest) {
    buttonText = 'View orders, rewards & more in GROWERS';
  }
  const domain = window.location.href.split("#")[0];
  const getAppUrl = `${domain}#${Routes.GET_APP}`;
  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      bottom="0px"
      boxShadow={`
        0px -12px 48px 16px rgba(0, 0, 0, 0.08),
        0px -9px 28px 0px rgba(0, 0, 0, 0.10),
        0px -6px 16px -8px rgba(0, 0, 0, 0.16);
      `}
      height="96px"
      justifyContent="center"
      maxWidth="599px"
      p="12px 16px 20px 16px"
      position="fixed"
      width="100%"
    >
      {!!overlineText && (
        <>
          <Text category="body-small">
            Lock it in! Contact your seller or download GROWERS
          </Text>
          <VSpacer size="3" />
        </>
      )}
      <Button
        endIcon={<ArrowForward />}
        fullWidth
        onClick={() => {
          if (isWebView()) {
            if (recommendation) {
              window.open(`${domain}#/Recommendation/${recommendation.id}`);
            } else if (pricingRequest) {
              window.open(location.href = `${domain}#/Request/${pricingRequest.id}`);
            }
            return;
          }
          if (isMobile) {
            navigate(Routes.GET_APP, { state: { redirect: location.href } });
          } else {
            window.open(getAppUrl, '_blank');
          }
        }}
        sx={{
          bgcolor: '#141414',
          color: '#FEFEFE',
          "&:hover": {
            backgroundColor: alpha('#141414', .9),
          },
        }}
        testID="place-order-button"
      >
        {buttonText}
      </Button>
    </Stack>
  );
};
