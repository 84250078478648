import { Color } from '@/themes/MUITheme/palette';
import { Category } from '@/themes/variant-interfaces/Category';
import { Stack, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { TextProps, Text } from '../Text/Text';
import { VSpacer } from '../Spacer';

type TextElementCategory = '12' | '14' | '16';

const truncateStyle: SxProps<Theme> = {
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4,
  wordBreak: 'break-word',
};


interface LevelStyleTextProps extends TextProps {
  category: Category,
  color?: Color,
  sx?: SxProps<Theme>,
  testID: string,
}

const getTextElementLevel = (
  category: TextElementCategory,
  emphasis: boolean,
  testID: string,
  truncateLines?: number,
) => {
  const baseSx: SxProps<Theme> = { wordBreak: 'break-word', hyphens: 'auto' };
  const sx: SxProps<Theme> = truncateLines !== undefined
    ? {
      ...baseSx,
      ...truncateStyle,
      WebkitLineClamp: truncateLines,
    }
    : baseSx;
  const titleStyles: LevelStyleTextProps = {
    category: 'body-small',
    sx,
    testID: `${testID}-title`,
  };
  const overlineStyles: LevelStyleTextProps = {
    category: 'body-small',
    sx,
    testID: `${testID}-overline`,
  };
  const sublineStyles: LevelStyleTextProps = {
    category: 'body-medium',
    sx: {
      ...sx,
      wordBreak: 'break-word',
    },
    testID: `${testID}-subline`,
  };
  switch (category) {
    case '14':
      titleStyles.category = emphasis ? 'label-large' : 'body-medium';
      overlineStyles.category = 'body-small';
      sublineStyles.category = 'body-small';
      break;
    case '16':
      titleStyles.category = 'title-medium';
      overlineStyles.category = 'body-small';
      sublineStyles.category = 'body-medium';
      break;
  }
  return { overlineStyles, sublineStyles, titleStyles };
};

export interface TextElementProps {
  alignment?: 'flex-start' | 'center' | 'flex-end',
  breakWord?: boolean,
  category?: TextElementCategory,
  emphasis?: boolean,
  lineThrough?: boolean,
  multiline?: boolean,
  nowrap?: boolean,
  overline?: string,
  subline?: string,
  sublineAccessory?: ReactNode,
  testID: string,
  title?: string,
  truncateLines?: number,
}

export const TextElement = ({
  alignment = 'flex-start',
  breakWord,
  category = '12',
  emphasis = false,
  lineThrough = false,
  multiline,
  nowrap = false,
  overline,
  subline,
  sublineAccessory,
  testID,
  truncateLines,
  title,
}: TextElementProps) => {
  const {
    overlineStyles,
    sublineStyles,
    titleStyles,
  } = getTextElementLevel(category, emphasis, testID, truncateLines);
  const renderOverlineSpacer = !!overline && !!title && category === '12';
  const renderSublineSpacer = !!subline && !!title;

  return (
    <Stack
      alignItems={alignment}
      data-testid={testID}
      sx={{
        ...(nowrap && { whiteSpace: 'nowrap' }),
      }}
    >
      {!!overline && <Text lineThrough={lineThrough} {...overlineStyles}>{overline}</Text>}
      {renderOverlineSpacer && <VSpacer size="2"/>}
      {!!title && (
        <Text
          breakWord={breakWord}
          lineThrough={lineThrough}
          multiline={multiline}
          {...titleStyles}
        >
          {title}
        </Text>
      )}
      {renderSublineSpacer &&  (
        <>
          <VSpacer size="2"/>
          <VSpacer size="1"/>
        </>
      )}
      <Stack alignItems="center" direction="row" spacing={2}>
        {!!subline && <Text lineThrough={lineThrough} {...sublineStyles}>{subline}</Text>}
        {sublineAccessory}
      </Stack>
    </Stack>
  );
};
