import { productRequestConstants } from '@/constants/ProductRequest';
import { QueryKeys } from '@/constants/QueryKeys';
import { ToastMessages } from '@/constants/constant';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { useMutation, useQueryClient } from 'react-query';

export const useRemoveSalespersonById = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: deleteRetailer } = useMutation(
    ({
      pricingRequestId,
      salespersonId,
    }: {
      pricingRequestId: string;
      salespersonId: string;
    }) =>
      PricingRequestsApi.deleteRetailerForAdmin(pricingRequestId, salespersonId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST]);
        openSnackbar(ToastMessages.deleteSalesPerson);
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || productRequestConstants.deleteRetailerConfirmation,
        );
      },
    },
  );

  return { deleteRetailer };
};

export const useActiveSalespersonLastLook = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { mutate: activeSalespersonLastLook } = useMutation(
    ({ id, lastLook }: { id: string; lastLook: boolean }) =>
      PricingRequestsApi.updateLastLook(id, { lastLook: lastLook }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST]);
        openSnackbar(productRequestConstants.confirmationLastLook);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message || productRequestConstants.errorLastLook);
      },
    },
  );

  return { activeSalespersonLastLook };
};
