import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { productRequestConstants } from '@/constants/ProductRequest';
import { Card, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMutation, useQueryClient } from 'react-query';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { useState } from 'react';
import ConfirmModal from '@/components/shared/ConfirmModal';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ExtendedModal from './ExtendedModal';
import { ApiPricingRequest } from '@api/interfaces';
import { QueryKeys } from '@/constants/QueryKeys';

const ExpirationPanel = ({
  pricingListRequestData,
}: {
  pricingListRequestData: ApiPricingRequest | undefined;
}) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const calculateDifferenceFromNow = (targetDateStr: string | Date ) => {
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const differenceMilliseconds = targetDate.getTime() - currentDate.getTime();
    const differenceSeconds = Math.floor(differenceMilliseconds / 1000);
    const days = Math.floor(differenceSeconds / (24 * 3600));
    const hoursDiff = Math.floor((differenceSeconds % (24 * 3600)) / 3600);
    const differenceStr = `${days}d ${hoursDiff}h`;
    return differenceStr;
  };
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [showAllowConfirmation, setShowAllowConfirmation] = useState(false);
  const [showExtendedModal, setShowExtendedModal] = useState(false);

  const expiredAt = pricingListRequestData?.expiration;
  const reviewExpiration = pricingListRequestData?.reviewExpiration;

  const { mutate: rejectPricingRequest } = useMutation(
    (requestId: string) => PricingRequestsApi.rejectPricingRequest(requestId),
    {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || productRequestConstants.errorOccurred);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUEST);
        openSnackbar(productRequestConstants.expirationUpdated);
        setShowRejectConfirmation(false);
      },
    },
  );

  const { mutate: approvePricingRequest } = useMutation(
    (requestId: string) => PricingRequestsApi.approvePricingRequest(requestId),
    {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || productRequestConstants.errorOccurred);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUEST);
        openSnackbar(productRequestConstants.successfully);
        setShowAllowConfirmation(false);
      },
    },
  );

  return (
    <>
      <VSpacer size='5' />
      {
      (pricingListRequestData?.status === 'Open' ||
        pricingListRequestData?.status === 'Review') ? (
        <Card
          sx={{
            background: pricingListRequestData?.status === 'Review' ? 'info.main' : 'warning.main',
          }}
        >
          <Stack
            alignItems='flex-start'
            direction='row'
            height='inherit'
            justifyContent='space-between'
          >
            <Stack
              alignSelf='center'
              direction='row'
              marginLeft='16px'
              py='12px'
            >
              {pricingListRequestData?.status === 'Review' ? (
                <>
                  <InfoOutlinedIcon />
                  <HSpacer size='3' />
                  <Stack>
                    <Text
                      category='title-medium'
                      testID={`-message`}
                    >
                      {`${productRequestConstants.closedOffer} 
                        ${DateTime.fromJSDate(expiredAt!).toFormat('MM/dd/yyyy')
                      } | ${
                        productRequestConstants.farmerReview
                      } ${calculateDifferenceFromNow(
                        pricingListRequestData?.reviewExpiration ?? '',
                      )} on ${DateTime.fromJSDate(reviewExpiration!).toFormat(
                        'MM/dd/yyyy',
                      )}`}
                    </Text>
                  </Stack>
                </>
              ) : (
                <>
                  <TimerOutlinedIcon />
                  <HSpacer size='3' />
                  <Stack>
                    <Text
                      category='title-medium'
                      testID={`-message`}
                    >
                      {`${
                        productRequestConstants.expiresIn
                      } ${calculateDifferenceFromNow(
                        pricingListRequestData?.expiration ?? '',
                      )} |
                            ${DateTime.fromJSDate(expiredAt!).toFormat(
                        'MM/dd/yyyy',
                      )}. ${productRequestConstants.expirationMsg}`}
                    </Text>
                  </Stack>
                </>
              )}
            </Stack>
            <Stack alignItems='center' direction='row' m='4px'>
              <Button
                onClick={() => setShowExtendedModal(true)}
                testID='extended-window'
                variant='text'
              >
                {productRequestConstants.extendedWindow}
              </Button>
            </Stack>
          </Stack>
        </Card>
        ) : (
        <Card sx={{ padding: '20px' }}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Text category='title-medium' testID={`Expiration`}>
              {pricingListRequestData?.expiration !== null
                ? `${productRequestConstants?.expirationDate} ${DateTime.fromJSDate(
                  expiredAt!,
                ).toFormat('LLL, d, yyyy')}`
                : 'No expiration date'}
            </Text>
            {!pricingListRequestData?.deletedAt && (
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
              >
                <Text category='title-medium' testID={`-amount-due`}>
                  <Button
                    onClick={() => {
                      setShowRejectConfirmation(true);
                    }}
                    testID='button-demo-enabled'
                    variant='text'
                  >
                    {' '}
                    {productRequestConstants.productRejectRequest}
                  </Button>
                </Text>
                <>
                  <HSpacer size='6' />
                  <Button
                    onClick={() => setShowAllowConfirmation(true)}
                    testID='button-demo-enable'
                  >
                    {productRequestConstants.allowRequest}
                  </Button>
                  {showAllowConfirmation && (
                    <ConfirmModal
                      confirmButtonText={productRequestConstants.allowRequest}
                      message={productRequestConstants.confirmRequest}
                      onCancel={() => setShowAllowConfirmation(false)}
                      onConfirm={() =>
                        approvePricingRequest(pricingListRequestData?.id ?? '')
                      }
                      open
                      testID='confirm-delete-modal'
                      title={productRequestConstants.productRequestAllow}
                    />
                  )}
                  {showRejectConfirmation && (
                    <ConfirmModal
                      confirmButtonText={productRequestConstants.productRejectRequest}
                      message={productRequestConstants.confirmationRejectRequest}
                      onCancel={() => setShowRejectConfirmation(false)}
                      onConfirm={() =>
                        rejectPricingRequest(pricingListRequestData?.id ?? '')
                      }
                      open
                      testID='confirm-delete-modal'
                      title={productRequestConstants.productRejectRequest}
                    />
                  )}
                </>
              </Stack>
            )}
          </Stack>
        </Card>
        )}
      {showExtendedModal && pricingListRequestData && pricingListRequestData.expiration && (
        <ExtendedModal
          onClose={() => {
            setShowExtendedModal(false);
          }}
          pricingRequest={{
            isExpired: pricingListRequestData.isExpired,
            reviewExpiration: pricingListRequestData.reviewExpiration,
            expiration: pricingListRequestData.expiration ,
            id: pricingListRequestData.id,
          }}
        />
      )}
    </>
  );
};

export default ExpirationPanel;
