import { QueryKeys } from '@/constants/QueryKeys';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ShoppingCartContext, ShoppingCartContextType } from '@/providers/ShoppingCartProvider';
import { ShoppingCartApi } from '@/utilities/api/ShoppingCartApi';
import { ShoppingCartUtils } from '@/utilities/ShoppingCartUtils';
import { PricingRequestCartEndpoint } from '@api/endpoints';
import { ApiExtendedProduct, ApiPricingRequestCart, ApiUser } from '@api/interfaces';
import { asyncMap } from '@shared/utilities';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface ShoppingCartUpdateProps {
  shoppingCartId: string,
  updateRequest: PricingRequestCartEndpoint.UpdatePricingRequestCart.Request,
}

export const useShoppingCart = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const {
    isRequestView,
    setIsRequestView,
    setShowCart,
    showCart,
  } = useContext(ShoppingCartContext) as ShoppingCartContextType;
  const [guestShoppingCart, setGuestShoppingCart] = useState<ApiPricingRequestCart[]>([]);
  const { user } = useAuthentication();

  const { data: userShoppingCart, isFetching } = useQuery(
    [QueryKeys.GET_SHOPPING_CART],
    () => ShoppingCartApi.list({ sort: 'createdAt' }),
    { enabled: !!user },
  );

  useEffect(() => {
    const updateCart = () => {
      const cart = ShoppingCartUtils.getShoppingCart();
      setGuestShoppingCart(cart);
    };

    updateCart();
    window.addEventListener(ShoppingCartUtils.CartEventKey, updateCart);

    return () => {
      window.removeEventListener(ShoppingCartUtils.CartEventKey, updateCart);
    };
  }, []);

  const returnOptions = (action: 'bulkCreating' | 'creating' | 'deleting' | 'updating') => ({
    onError: (error: { message: string }) => {
      openSnackbar(
        error.message || `Error ${action} shopping cart`,
      );
    },
    onSuccess: async () => {
      if (user) {
        await queryClient.invalidateQueries(QueryKeys.GET_SHOPPING_CART);
      }
    },
  });

  const { mutateAsync: createShoppingCart, isLoading: isCreating } = useMutation(
    async ({
      createRequest,
      image,
      user,
    }: {
      createRequest: PricingRequestCartEndpoint.Create.Request,
      image?: string | null,
      user: ApiUser | undefined,
    }) => {
      if (user) {
        return ShoppingCartApi.create(createRequest);
      } else {
        return ShoppingCartUtils.addShoppingCartItem({
          ...createRequest,
          productDetails: { image } as ApiExtendedProduct,
        } as ApiPricingRequestCart);
      }
    }, returnOptions('creating'));

  const { mutateAsync: bulkCreateShoppingCart, isLoading: isBulkCreating } = useMutation(
    async ({
      createRequests,
      image,
      user,
    }: {
      createRequests: PricingRequestCartEndpoint.Create.Request[],
      image?: string | null,
      user: ApiUser | undefined,
    }) => {
      await asyncMap(createRequests, async (request) => {
        if (user) {
          return ShoppingCartApi.create(request);
        } else {
          return ShoppingCartUtils.addShoppingCartItem({
            ...request,
            productDetails: { image } as ApiExtendedProduct,
          } as ApiPricingRequestCart);
        }
      });
    }, returnOptions('bulkCreating'));

  const { mutateAsync: deleteShoppingCart, isLoading: isUpdating } = useMutation(
    async ({ id, user } : { id: string, user: ApiUser | undefined }) => {
      if (user) {
        await ShoppingCartApi.delete(id);
      } else {
        ShoppingCartUtils.removeShoppingCartItem(id);
      }
    }, returnOptions('deleting'));

  const { mutateAsync: updateShoppingCart, isLoading: isDeleting } = useMutation(
    async (updateData: ShoppingCartUpdateProps) => {
      if (user) {
        await ShoppingCartApi.update(updateData.shoppingCartId, updateData.updateRequest);
      } else {
        ShoppingCartUtils.updateCartItem(updateData.shoppingCartId, updateData.updateRequest);
      }
    }, returnOptions('updating'));

  const clearShoppingCart = async (user: ApiUser | undefined, invalidateCache: boolean = true) => {
    if (user) {
      const userShoppingCart = await ShoppingCartApi.list({});
      if (!userShoppingCart.total) {
        return;
      }
      await Promise.all(userShoppingCart.data.map(
        ({ id }) => deleteShoppingCart({ id, user }),
      ));
      if (invalidateCache) {
        await queryClient.invalidateQueries(QueryKeys.GET_SHOPPING_CART);
      }
    } else {
      ShoppingCartUtils.clearCart();
      setGuestShoppingCart([]);
    }
  };

  const saveGuestShoppingCart = async (user: ApiUser, guestCart: ApiPricingRequestCart[]) => {
    if (guestCart.length === 0) {
      return;
    }
    await clearShoppingCart(user, false);
    await bulkCreateShoppingCart({ createRequests: guestCart, user });
    ShoppingCartUtils.clearCart();
  };

  const isCartLoading = isBulkCreating|| isCreating || isDeleting || isFetching  || isUpdating;

  return {
    clearShoppingCart,
    createShoppingCart,
    deleteShoppingCart,
    guestShoppingCart,
    isCartLoading,
    isRequestView,
    saveGuestShoppingCart,
    setIsRequestView,
    setShowCart,
    showCart,
    shoppingCart: userShoppingCart?.data ?? guestShoppingCart,
    updateShoppingCart,
  };
};
