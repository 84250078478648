import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import { ReactNode } from 'react';
import { IconButton, VSpacer } from '..';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import Close from '@mui/icons-material/Close';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

export interface BottomSheetProps extends SwipeableDrawerProps {
  children?: ReactNode,
  desktop?: boolean, // For testing only!!!
  footer?: ReactNode,
  showCloseButton?: boolean,
  showPuller? : boolean,
  testID: string,
}

const Puller = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  borderRadius: 3,
  height: 4,
  left: 'calc(50% - 15px)',
  marginBottom: 16,
  opacity: .4,
  position: 'absolute',
  top: 8,
  width: 32,
}));

const styles: SXStyles = {
  closeButton: { position: 'absolute', top: '16px', right: '16px' },
};

export const BottomSheet = ({
  children,
  desktop = false,
  footer,
  onClose,
  onOpen,
  open,
  showCloseButton,
  showPuller = true,
  testID,
}: BottomSheetProps) => {
  const Only = desktop ? DesktopOnly : MobileOnly;

  return (
    <Only>
      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            maxHeight: 'calc(100% - 72px)',
          },
        }}
        anchor="bottom"
        data-testid={testID}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        sx={{ zIndex: 1300 }}
      >
        <Stack overflow="hidden">
          {showCloseButton && (
            <IconButton
              onClick={onClose}
              sx={styles.closeButton}
              testID={`${testID}-close-button`}
            >
              <Close />
            </IconButton>
          )}
          {showPuller && (
            <Box height={34}>
              <Puller onClick={onClose} onDrag={onClose} />
            </Box>
          )}
          {children}
          {footer || <VSpacer size="8" />}
        </Stack>
      </SwipeableDrawer>
    </Only>
  );
};
