import { AppConfig } from '@/constants/AppConfig';
import { OauthConfig } from '@/constants/OauthConfig';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { QueryUtil } from '@/utilities/Query';
import { getPortalRedirectUrl } from '@/utilities/StorefrontUtilities';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { LocalStorageUtility } from '@shared/utilities';
import { useNavigate } from 'react-router-dom';

export const AccountIdQueryKey = 'AccountID';
export const AccountNameQueryKey = 'AccountName';

export const PortalLogin = () => {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const subdomain = getSubdomain();

  if (!subdomain) {
    navigate(Routes.HOMEPAGE, { replace: true });
    return null;
  }

  const searchParams = QueryUtil.getSearchParams(location);
  let accountId = searchParams.get(AccountIdQueryKey);
  let accountName = searchParams.get(AccountNameQueryKey);
  if (AppConfig.env.local && !accountId) {
    accountId = '111';
    accountName = 'Test Farm Account';
  }
  if (!accountId || !accountName) {
    window.location.href = getPortalRedirectUrl(subdomain);
    return null;
  }
  LocalStorageUtility.set(AccountIdQueryKey, accountId);
  LocalStorageUtility.set(AccountNameQueryKey, accountName);
  const config = OauthConfig[subdomain];
  const redirectUri = encodeURIComponent(`${window.location.origin}/#/oauth/verify`);
  const oauthUrl = `${config.authEndpoint}?client_id=${config.clientId}&scope=openid%20profile%20email%20phone&response_type=code&redirect_uri=${redirectUri}`;

  if (!user || (user && user.externalId !== accountId)) {
    window.location.href = oauthUrl;
  } else {
    window.location.replace('/');
  }

  return null;

  // This will be used later for guest experience with a sign in button
  // return (
  //   <OAuth2Login
  //     authorizationUrl={config.authEndpoint}
  //     clientId={config.clientId}
  //     isCrossOrigin
  //     onFailure={() => openSnackbar('Authentication failed')}
  //     onSuccess={async ({ code }) => {
  //       try {
  //         await OauthApi.verifyCode(code);
  //         await refreshUser();
  //         openSnackbar('Successfully logged in');
  //         window.location.replace('/');
  //       } catch (e) {
  //         openSnackbar('Authentication failed');
  //       }
  //     }}
  //     redirectUri={redirectUri}
  //     render={(props) => (
  //       <Button { ...props} testID="oauth-signin-button">{props.buttonText}</Button>
  //     )}
  //     responseType="code"
  //     scope="openid"
  //   />
  // );
};
