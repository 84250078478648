import React, { Fragment, useState } from 'react';
import { Button, Search, Text, VSpacer } from '@/components/DesignSystem';
import { Container, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSearch } from '@/hooks/useSearch';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import ManufacturerCard from './ManufacturerCard';
import { ApiManufacturer } from '@api/interfaces';
import { SaveManufacturerModal } from './SaveEditManufacturer';
const ManufacturerList = () => {
  const [isAddManufacturerDialog, setIsAddManufacturerDialog] = useState(false);
  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: manufacturersList = [] } = useQuery(
    [QueryKeys.GET_MANUFACTURERS, debouncedSearch],
    () => ManufacturerApi.getManufacturerName(debouncedSearch.trim()),
  );

  const getConstant = () => {
    if (manufacturersList?.length <=1 ) {
      return `${manufacturersList?.length} manufacturer`;
    }
    return `${manufacturersList?.length} manufacturers`;
  };

  return (
    <Container maxWidth='lg'>
      <Stack>
        <VSpacer size='8' />
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Text category='h3'>Manufacturers</Text>
          <Button
            onClick={() => setIsAddManufacturerDialog(true)}
            size='medium'
            startIcon={<AddIcon />}
            testID='manage-partner-retailers-add-button'
          >
            Add Manufacturer
          </Button>
        </Stack>
        <VSpacer size='5' />
        <Search
          onChangeText={setSearch}
          testID='counties-modal-search'
          value={search}
          width={289}
        />
        {manufacturersList?.length > 0 && (
          <>
            <VSpacer size='8' />
            <Text>{getConstant()}</Text>
          </>
        )}
        <VSpacer size='8' />
        {manufacturersList?.length === 0 && <Text>No results found</Text>}
        {manufacturersList?.map((manufacturer: ApiManufacturer) => (
          <Fragment key={manufacturer.id}>
            <ManufacturerCard
              isRedirectable
              key={manufacturer.id}
              manufacturer={manufacturer}
            />
            <VSpacer size='3' />
          </Fragment>
        ))}
        <VSpacer size='14' />
      </Stack>
      {isAddManufacturerDialog && (
        <SaveManufacturerModal
          close={() => setIsAddManufacturerDialog(false)}
        />
      )}
    </Container>
  );
};

export default ManufacturerList;
