import { AppUrls } from '@/constants/AppUrls';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { Box, Link } from '@mui/material';

const styles: SXStyles = {
  androidDownloadButton: {
    borderRadius: '13px',
    height: '80px',
  },
} as const;

interface AndroidAppDownloadButtonProps {
  href: string,
}

export const AndroidAppDownloadButton = ({ href }: AndroidAppDownloadButtonProps) => (
  <Link
    href={href}
    sx={styles.androidDownloadButton}
  >
    <Box
      alt="Get it on Google Play"
      component="img"
      src={AppUrls.androidDownloadBadge}
      sx={styles.androidDownloadButton}
    />
  </Link>
);
