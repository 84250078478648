import { Button, FarmerCard, Modal } from '@/components/DesignSystem';
import { ApiProductRecommendation } from '@api/interfaces';
import { Stack } from '@mui/material';

interface ProductRecommendationFarmersModalProps {
  onClose: () => void,
  recommendation: ApiProductRecommendation,
}

export const ProductRecommendationFarmersModal = ({
  onClose,
  recommendation,
}: ProductRecommendationFarmersModalProps) => {

  function getIsViewed (farmerId: string): boolean {
    return !!recommendation.recommendedFarmer?.find(({ userId }) => userId === farmerId)?.viewedAt;
  }

  return (
    <Modal
      cancelButton={() => (
        <Button onClick={onClose} testID="prfm-cancel-button" variant="text">
          Close
        </Button>
      )}
      onClose={onClose}
      open
      testID="product-recommendation-farmers-modal"
      title="Shared with farmers"
    >
      <Stack spacing={1}>
        {recommendation.recommendationFarmer?.map(farmer => (
          <FarmerCard
            farmer={farmer}
            isViewed={getIsViewed(farmer.id)}
            key={farmer.id}
            showTelephone="inline"
            testID={`${farmer.id}-farmer-card`}
          />
        ))}
      </Stack>
    </Modal>
  );
};
