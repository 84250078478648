import { DataPoint, Heading, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { getResponsiveValue, useMediaQuery } from '@/hooks/useMediaQuery';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { StringUtility } from '@/utilities/String';
import { ApiPricingRequest } from '@api/interfaces';
import Mail from '@mui/icons-material/Mail';
import { Stack } from '@mui/material';
import { FulfillmentMethod, PricingRequestStatus } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities/StringUtils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

const styles: SXStyles = {
  dataPoint: {
    marginBottom: getResponsiveValue("0px", "10px"),
  },
  note: {
    border: '1px solid',
    borderRadius: 2,
    padding: '20px 20px 20px 24px',
  },
};

interface PriceRequestDetailsProps {
  priceRequest: ApiPricingRequest,
}

export const PriceRequestDetails = ({
  priceRequest,
}: PriceRequestDetailsProps) => {

  const { isMobile } = useMediaQuery();
  const isInOpenStatus = priceRequest.status === PricingRequestStatus.Open;

  const deliveryAddress = useMemo(() => StringUtility.formatAddress(
    priceRequest.deliveryAddress1,
    priceRequest.deliveryAddress2,
    priceRequest.deliveryCity,
    priceRequest.deliveryState,
    priceRequest.deliveryPostalCode,
  ), [priceRequest]);

  const DataPoints = (
    <>
      {isInOpenStatus ? (
        <>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Offer window closes"
            spacing={isMobile ? "fixed" : "none"}
            stacked={!isMobile}
            testID="pricerequest-details-offer-window-closes"
          >
            {priceRequest.expiration
              ? DateTime.fromJSDate(priceRequest.expiration).toFormat(
                "MMMM dd, yyyy 'at' hh:mm a",
              )
              : ""
            }
          </DataPoint>
        </>
      ) : (
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Expiration date"
          spacing={isMobile ? "fixed" : "none"}
          stacked={!isMobile}
          testID="pricerequest-details-expiration-date"
        >
          {priceRequest.reviewExpiration
            ? DateTime.fromJSDate(priceRequest.reviewExpiration).toFormat(
              "MMMM dd, yyyy 'at' hh:mm a",
            )
            : '-'
          }
        </DataPoint>
      )}
      <DataPoint
        containerStyle={styles.dataPoint}
        label="Business name"
        spacing={isMobile ? "fixed" : "none"}
        stacked={!isMobile}
        testID="pricerequest-details-business-name"
      >
        {priceRequest.user?.businessName ? priceRequest.user.businessName : '-'}
      </DataPoint>
      <DataPoint
        containerStyle={styles.dataPoint}
        label="Fulfillment"
        spacing={isMobile ? "fixed" : "none"}
        stacked={!isMobile}
        testID="pricerequest-details-fulfillment"
      >
        {FriendlyFulfillmentMethod[priceRequest.fulfillmentMethod]}
      </DataPoint>
      {priceRequest?.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
        <DataPoint
          containerStyle={styles.dataPointContainer}
          label="Delivery address"
          multiline
          spacing="none"
          stacked
          testID={`${priceRequest.id}-delivery-address`}
        >
          {deliveryAddress || '-'}
        </DataPoint>
      )}
      <DataPoint
        containerStyle={styles.dataPoint}
        label="Contact phone"
        spacing={isMobile ? "fixed" : "none"}
        stacked={!isMobile}
        testID="pricerequest-details-contact-phone"
      >
        {priceRequest.user?.telephone ? (
          <TextLink
            category="body-medium"
            href={`tel:${priceRequest.user?.telephone}`}
            testID="pricerequest-details-contact-phone-link"
          >
            {formatPhoneNumber(priceRequest.user.telephone)}
          </TextLink>
        ) : '-'}
      </DataPoint>
      <DataPoint
        containerStyle={styles.dataPoint}
        label="Contact email"
        spacing={isMobile ? "fixed" : "none"}
        stacked={!isMobile}
        testID="pricerequest-details-contact-email"
      >
        {priceRequest.user?.email ? (
          <TextLink
            category="body-medium"
            href={`mailto:${priceRequest.user?.email}`}
            testID="pricerequest-details-contact-email-link"
          >
            {priceRequest.user.email}
          </TextLink>
        ) : '-'}
      </DataPoint>
    </>
  );

  const Note = (
    <>
      <VSpacer size="2" />
      <Stack sx={styles.note}>
        <Heading
          leftAccessory={<Mail fontSize="small" />}
          level="4"
          testID="pricerequest-details-note-header"
          title="NOTE"
        />
        <VSpacer size="4" />
        <Text multiline testID="pricerequest-details-note">
          {priceRequest.note}
        </Text>
      </Stack>
    </>
  );

  return (
    <>
      {DataPoints}
      {!!priceRequest.note && (
        <>
          <DesktopOnly>
            <VSpacer size="3" />
          </DesktopOnly>
          {Note}
        </>
      )}
    </>
  );
};
