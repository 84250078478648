import { TextElement, Text, TextLink } from '@/components/DesignSystem';
import { TextElementProps } from '@/components/DesignSystem/TextElement/TextElement';
import { Add } from '@mui/icons-material';
import { DemoBlock } from '../../components/DemoBlock';
import { Stack } from '@mui/material';

export const TextElementDemo = () => {
  const categories: TextElementProps['category'][] = ['16', '14', '12'];
  return (
    <Stack spacing={2}>
      <DemoBlock label="Title only">
        <Stack direction="row" spacing={4}>
          {categories.map((category) => (
            <Stack key={category} spacing={2}>
              <Text category="body-large">Size: {category}</Text>
              <TextElement
                category={category}
                testID="test"
                title="Title"
              />
            </Stack>
          ))}
        </Stack>
      </DemoBlock>
      <DemoBlock label="Subline and Title only">
        <Stack direction="row" spacing={4}>
          {categories.map((category) => (
            <Stack key={category} spacing={2}>
              <Text category="body-large">Size: {category}</Text>
              <TextElement
                category={category}
                subline="Subline"
                testID="test"
                title="Title"
              />
            </Stack>
          ))}
        </Stack>
      </DemoBlock>
      <DemoBlock label="Overline and Title only">
        <Stack direction="row" spacing={4}>
          {categories.map((category) => (
            <Stack key={category} spacing={2}>
              <Text category="body-large">Size: {category}</Text>
              <TextElement
                category={category}
                overline="Overline"
                testID="test"
                title="Title"
              />
            </Stack>
          ))}
        </Stack>
      </DemoBlock>
      <DemoBlock label="Overline, Title, and Subline">
        <Stack direction="row" spacing={4}>
          {categories.map((category) => (
            <Stack key={category}>
              <Text category="body-large">Size: {category}</Text>
              <TextElement
                category={category}
                overline="Overline"
                subline="Subline"
                testID="test"
                title="Title"
              />
            </Stack>
          ))}
        </Stack>
      </DemoBlock>
      <DemoBlock label="Overline, Title Subline and ">
        <Stack direction="row" spacing={4}>
          {categories.map((category) => (
            <Stack key={category}>
              <Text category="body-large">Size: {category}</Text>
              <TextElement
                category={category}
                overline="Overline"
                subline="Subline"
                sublineAccessory={(
                  <TextLink
                    accessoryLeft={(
                      <Add fontSize="inherit" />
                    )}
                    accessoryLeftSpacing="2"
                    category="body-medium"
                    onClick={console.log}
                    testID={`product-card-more-text-link`}
                  >
                    More
                  </TextLink>
                )}
                testID="test"
                title="Title"
              />
            </Stack>
          ))}
        </Stack>
      </DemoBlock>
    </Stack>
  );
};
