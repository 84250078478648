import { AppConfig } from "@/constants/AppConfig";
import {
  Breakpoint,
  SxProps,
  Theme,
  useMediaQuery as useMediaQueryMui,
} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { useEffect, useState } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

type CssVariableType = string | number | null;
type CssVariables = { [key: string]: CssVariableType };

export const getResponsiveValues = (
  mobile: CssVariables,
  desktop: CssVariables,
): SxProps<Theme> => {
  const styles: any = {};
  Object.keys(mobile).map((key) => {
    styles[key] = {
      [AppConfig.mobileBreakpoint]: mobile[key],
      [AppConfig.desktopBreakpoint]: desktop[key],
    };
  });

  Object.keys(desktop).map((key) => {
    if (!styles[key]) {
      styles[key] = {
        [AppConfig.mobileBreakpoint]: undefined,
        [AppConfig.desktopBreakpoint]: desktop[key],
      };
    }
  });

  return styles;
};

export const getResponsiveValue = <M, D>(mobile: M, desktop: D): ResponsiveStyleValue<M | D> => {
  return {
    [AppConfig.mobileBreakpoint]: mobile,
    [AppConfig.desktopBreakpoint]: desktop,
  };
};

export const useMediaQuery = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = useMediaQueryMui((theme: Theme) => theme.breakpoints?.down(
    AppConfig.desktopBreakpoint as Breakpoint,
  )) ?? false;
  const isTablet = useMediaQueryMui((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  const getValueFromResponsiveValue = <T,>(value: ResponsiveStyleValue<T>) => {
    if (typeof value === 'object') {
      return (value as any)[isMobile ? AppConfig.mobileBreakpoint : AppConfig.desktopBreakpoint];
    }

    return value;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    getValueFromResponsiveValue,
    isMobile,
    isTablet,
    windowHeight: windowDimensions.height,
    windowWidth: windowDimensions.width,
  };
};