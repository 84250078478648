import { TextElement } from '@/components/DesignSystem';
import { isEmptyValue } from '@shared/utilities';
import { useMemo } from 'react';

type Item = number | string | null | undefined;

interface InlineTextListProps {
  items: Item[],
  testID: string,
  title?: string,
}

export const InlineTextList = ({ items, testID, title }: InlineTextListProps) => {
  const text = useMemo(() => (
    items
      .filter((item) => !isEmptyValue(item))
      .join(' • ')
  ), [items]);

  return (!title && text === '') ? null : (
    <TextElement
      breakWord
      category="16"
      overline={!title ? text : undefined}
      subline={!title ? undefined : text}
      testID={testID}
      title={title}
    />
  );
};
