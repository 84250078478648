import { ConfirmDialog } from '@/components/shared/ConfirmDialog';

interface DeleteBenefitConfirmDialogProps {
  onCancel: () => void,
  onConfirm: () => void,
  open: boolean,
}

export const DeleteBenefitConfirmDialog = ({
  onCancel,
  onConfirm,
  open,
}: DeleteBenefitConfirmDialogProps) => {
  return (
  <ConfirmDialog
    confirmText="Yes, delete benefit"
    onCancel={onCancel}
    onConfirm={onConfirm}
    open={open}
    showCloseButton={false}
    testID="delete-benefit-confirm-dialog"
    title="Delete Custom Benefit?"
  />
  );
};
