import { Client } from '@/utilities/api/Client';
import { ProductRecommendationEndpoint } from '@api/endpoints';
import { QueryUtil } from '../Query';
import { ApiProductRecommendation } from '@api/interfaces';

export class ProductRecommendationApi {
  static list (
    filter: ProductRecommendationEndpoint.ListProductRecommendation.Query,
  ): Promise<ProductRecommendationEndpoint.ListProductRecommendation.Response> {
    const query = QueryUtil.stringify(filter);
    if (query) {
      return Client(`recommendations?${query}`);
    }
    return Client(`recommendations`);
  }

  static getById (
    id: string,
  ): Promise<ApiProductRecommendation> {
    return Client(`recommendations/${id}`);
  }

  static getByIdForDeliverable (
    id: string,
    deliverableToken: string,
  ): Promise<ProductRecommendationEndpoint.RecommendedProductsDetail.Response> {
    const query = QueryUtil.stringify({ deliverableToken });
    return Client(`recommendation/${id}/deliverable?${query}`);
  }

  static getByIdForFarmer (
    id: string,
  ): Promise<ProductRecommendationEndpoint.RecommendedProductsDetail.Response> {
    return Client(`recommendation/${id}/farmer`);
  }
}
