import { HSpacer, TextElement, VSpacer } from "@/components/DesignSystem";
import { AcceptedMessage } from "@/components/shared/SharedCardComponents/AcceptedMessage";
import { ProductOfferRetailer, ProductOffers } from '@/pages/CompareAcceptOffers/interfaces';
import { Box, ButtonBase, Stack, StackProps } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { OfferProduct } from "../CreateSendPriceRequest/interfaces";
import { getQuantityWithUom } from "@/components/shared/SharedCardComponents/helpers";
import { formatCurrency, formatPhoneNumber, pluralize } from "@shared/utilities";
import { FriendlyUoM } from "@/constants/FriendlyUoM";

interface ContainerProps {
  children: ReactNode,
  onClick?: () => void,
}

const Container = ({ children, onClick }: ContainerProps) => {
  const props: StackProps = {
    direction: 'row',
    spacing: 1,
    sx: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  };

  return !onClick ? (
    <Stack {...props}>
      {children}
    </Stack>
  ) : (
    <ButtonBase component={Stack} disableRipple onClick={onClick} {...props}>
      {children}
    </ButtonBase>
  );
};

export interface RetailerCardProps {
  acceptedProducts?: ProductOffers[],
  bottomLeftAccessory?: ReactNode,
  bottomRightAccessory?: ReactNode,
  isAccepted?: boolean,
  isInOpenStatus?: boolean,
  lineThrough?: boolean,
  onClick?: () => void,
  product?: OfferProduct,
  productOfferRetailer: ProductOfferRetailer,
  rightAccessory?: ReactNode,
  showAcreLabel?: boolean,
  testID: string,
}

export const RetailerCard = ({
  acceptedProducts,
  bottomLeftAccessory,
  bottomRightAccessory,
  isAccepted = false,
  isInOpenStatus,
  lineThrough = false,
  onClick,
  product,
  productOfferRetailer,
  rightAccessory,
  showAcreLabel = false,
  testID,
}: RetailerCardProps) => {
  const { offerProduct, retailer } = productOfferRetailer;

  const quantityWithUom = useMemo(() => {
    if (!offerProduct || !product) {
      return '';
    }

    return getQuantityWithUom({
      initialQuantity: product.quantity,
      initialUom: product.uom,
      quantity: offerProduct?.quantity,
      uom: offerProduct?.uom,
    }, showAcreLabel);
  }, [offerProduct, product, showAcreLabel]);

  return (
    <Stack data-testid={`${testID}-retailer-card`} flex={1}>
      <Container data-testid={`${testID}-retailer-card-clickable-container`} onClick={onClick}>
        <Stack width="100%">
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Box>
              <TextElement
                category="16"
                lineThrough={!isInOpenStatus && lineThrough}
                subline={isInOpenStatus
                  ? `${formatPhoneNumber(retailer?.retailerSalesperson?.telephone ?? '')} • ${retailer?.retailerSalesperson?.email}`
                  :  quantityWithUom}
                testID={`${testID}-retailer-name-uom`}
                title={retailer?.name}
              />
              {bottomLeftAccessory}
              {(acceptedProducts?.length || 0) > 0 && (
                <>
                  <VSpacer size="4" />
                  <AcceptedMessage
                    testID="retailer-list-accepted-message"
                    title={`${pluralize(acceptedProducts?.length || 0, 'product')} in order`}
                  />
                </>
              )}
            </Box>
            <HSpacer size="7"/>
            <Stack direction="row" >
              {(!!offerProduct?.price && !!offerProduct.uom && !! offerProduct.quantity) && (
                <TextElement
                  alignment="flex-end"
                  category="16"
                  nowrap
                  subline={`Total: ${formatCurrency(offerProduct.price)}`}
                  testID={`${testID}-retailer-price-element`}
                  title={`${formatCurrency(offerProduct.price / offerProduct.quantity)} /${FriendlyUoM[offerProduct.uom]}`}
                />
              )}
              <HSpacer size="5" />
              {rightAccessory}
            </Stack>
          </Stack>
          {isAccepted && (
            <>
              <VSpacer size="4" />
              <AcceptedMessage
                testID={`${testID}-retailer-card`}
              />
            </>
          )}
        </Stack>
      </Container>
      {!!bottomRightAccessory && (
        <>
          <VSpacer size="4"/>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            {bottomRightAccessory}
          </Stack>
        </>
      )}
      <VSpacer size="2"/>
    </Stack>
  );
};
