import React from 'react';
import { Button, HSpacer, Text } from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import AddMember from './MemberFormModal';
import ViewMember from './ViewMember';
import { RetailerEndpoint } from '@api/endpoints';
import AddIcon from '@mui/icons-material/Add';
import { stringifyCount } from "@/constants/HierarchyOfRetailers";

type MemberOverviewSectionProps = {
  isLocation?: boolean;
  members: RetailerEndpoint.MemberList.Response | undefined;
  onAddMember: () => void;
  onViewMember: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  showAddMember: boolean;
  showViewMember: boolean;
};

const MemberOverviewSection = ({
  isLocation = false,
  members,
  onAddMember,
  onViewMember,
  search,
  setSearch,
  showAddMember,
  showViewMember,
}: MemberOverviewSectionProps) => {

  return (
    <Stack
      alignItems={'center'}
      direction='row'
      justifyContent='space-between'
      sx={{
        margin: '2rem 0px',
      }}
    >
      <Stack spacing={2}>
        <Text category='h6'>Members</Text>
        <Text>{stringifyCount(members?.total, 'member', 'members')}</Text>
      </Stack>
      <Box>
        <Button
          color="inherit"
          disabled={!members?.total}
          onClick={onViewMember}
          testID='view-members'
          variant="outlined"
        >
          View Members
        </Button>
        <HSpacer size='4' />
        <Button
          onClick={onAddMember}
          startIcon={<AddIcon />}
          testID='view-member'
          variant="outlined"
        >
          Add Member
        </Button>
      </Box>
      {showAddMember && (
        <AddMember
          isLocation={isLocation}
          onClose={onAddMember}
          show={showAddMember}
        />
      )}
      {showViewMember && (
        <ViewMember
          isLocation={isLocation}
          members={members}
          onClose={onViewMember}
          search={search}
          setSearch={setSearch}
          show={showViewMember}
        />
      )}
    </Stack>
  );
};

export default MemberOverviewSection;
