import {
  BottomSheet,
  Button,
  HSpacer,
  IconButton,
  Modal,
  Text,
} from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { ReactNode, isValidElement } from 'react';
import { Close } from "@mui/icons-material/";
import { MobileOnly } from '../MobileOnly';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import {
  getResponsiveValue,
} from '@/hooks/useMediaQuery';
import { SpacerSize } from '@/themes/variant-interfaces/Size';

interface ModalBottomSheetProps {
  acceptButton?: (props: ButtonProps) => ReactNode,
  children: ReactNode,
  closeButton?: ReactNode,
  closeButtonLabel?: string,
  containerSpacing?: number,
  containerStyle?: SxProps<Theme>,
  footerSpacerSize?: SpacerSize,
  headerStyle?: SxProps<Theme>,
  hideBottomCloseButton?: boolean,
  hideScrollbarOnDesktop?: boolean,
  mobileTitle?: string,
  onClose: () => void,
  onOpen?: () => void,
  open?: boolean,
  showPuller?: boolean,
  showTopButton?: boolean,
  testID: string,
  title: string,
  titleAccessory?: ReactNode,
  topButtonAction?: ReactNode,
  width?: number,
}

export const ModalBottomSheet = ({
  acceptButton,
  children,
  closeButton,
  closeButtonLabel = "Close",
  containerSpacing,
  containerStyle,
  footerSpacerSize = "7",
  headerStyle,
  hideBottomCloseButton,
  hideScrollbarOnDesktop,
  mobileTitle,
  onClose,
  onOpen,
  open = false,
  showPuller = false,
  showTopButton = true,
  testID,
  title,
  titleAccessory,
  topButtonAction,
  width,
}: ModalBottomSheetProps) => {

  const styles: SXStyles = {
    closeButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: 48,
      height: 48,
    },
  };

  const TopLeftCloseButton = () => (
    <IconButton
      onClick={onClose}
      sx={{
        marginLeft: "-8px",
        marginRight: "-2px",
      }}
      testID={`${testID}-close`}
    >
      <Close sx={{
        width: 24,
        height: 24,
      }} />
    </IconButton>
  );

  const CloseButton = () => (
    <Box
      data-testid={`${testID}-close`}
      onClick={onClose}
      sx={{
        ...styles.closeButton,
        right: getResponsiveValue(10, 13),
        top: getResponsiveValue(10, 13),
      }}
    >
      <Close sx={{
        width: getResponsiveValue(24, 22.6),
        height: getResponsiveValue(24, 22.6),
      }} />
    </Box>
  );

  return (
    <>
      <MobileOnly>
        <BottomSheet
          onClose={onClose}
          onOpen={onOpen ? onOpen : () => null}
          open={open}
          showPuller={showPuller}
          testID={`${testID}-mobile-view`}
        >
          <Box sx={{
            padding: showPuller ? "0 0 22px 3px" : "20px 9px 18px 16px",
            ...headerStyle,
          }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                sx={{ maxWidth: "calc(100% - 50px)" }}
              >
                {!!topButtonAction && (
                  <>
                    {closeButton && isValidElement(closeButton)
                      ? closeButton
                      : <TopLeftCloseButton />
                    }
                    <HSpacer size='4' />
                  </>
                )}
                {!!titleAccessory && (
                  <>
                    {titleAccessory}
                    <HSpacer size="4" />
                  </>
                )}
                <Text
                  breakWord
                  category="title-large"
                  testID={`${testID}-mobile-heading`}
                >
                  {mobileTitle ?? title}
                </Text>
              </Box>
              {!topButtonAction ? (
                <CloseButton />
              ) : (
                <Box>
                  {topButtonAction}
                  <HSpacer size='3' />
                </Box>
              )}
            </Stack>
          </Box>
          <Stack
            spacing={containerSpacing}
            sx={{ overflowX: 'hidden', overflowY: 'auto', ...containerStyle }}
          >
            {children}
          </Stack>
        </BottomSheet>
      </MobileOnly>
      <DesktopOnly>
        <Modal
          acceptButton={(props) => acceptButton?.(props)}
          cancelButton={hideBottomCloseButton ? undefined : () => (
            <Button
              onClick={onClose}
              testID={`${testID}-close-button`}
              variant="text"
            >
              {closeButtonLabel}
            </Button>
          )}
          footerSpacerSize={footerSpacerSize}
          headerAccessoryLeft={
            titleAccessory ?? (
              (showTopButton && !!topButtonAction) && (
                <TopLeftCloseButton />
              )
            )}
          headerAccessoryRight={showTopButton && (
            topButtonAction ? topButtonAction : (
              <CloseButton />
            )
          )}
          onClose={onClose}
          open={open}
          padding={3}
          testID={`${testID}-desktop-view`}
          title={title}
          titleSpacerSize="6"
          width={width}
        >
          <Stack
            {...(hideScrollbarOnDesktop && { className: 'scrollable-container' })}
            spacing={containerSpacing}
            sx={{
              maxHeight: "490px",
              overflowX: "hidden",
              overflowY: "auto",
              ...containerStyle,
            }}
          >
            {children}
          </Stack>
        </Modal>
      </DesktopOnly>
    </>
  );
};
