import { Stack, SxProps, Theme } from '@mui/material';
import { useState, useEffect, ReactNode, isValidElement, cloneElement } from 'react';
import { Text, TextProps } from '../Text/Text';
import { HSpacer } from '@/components/DesignSystem';

export type DataPointFontSize = 'medium' | 'large' | 'title';
export type DataPointSpacing = 'fixed' | 'flushed' | 'none';
type DataPointFont = 'body-large' | 'body-medium' | 'title-medium';

const sizeToCategory: Record<DataPointFontSize, DataPointFont> = {
  'large': 'body-large',
  'medium': 'body-medium',
  'title': 'title-medium',
};

export interface DataPointProps extends TextProps {
  children?: ReactNode,
  childrenStyle?: SxProps<Theme>,
  containerStyle?: SxProps<Theme>,
  fullWidth?: boolean,
  label: string | ReactNode,
  labelAccessory?: ReactNode,
  labelStyle?: SxProps<Theme>,
  multiline?: boolean,
  py?: number,
  size?: DataPointFontSize,
  spacing?: DataPointSpacing,
  stacked?: boolean,
  testID: string,
  noWrapText?: boolean,
}

export const DataPoint = ({
  children,
  childrenStyle,
  containerStyle,
  fullWidth,
  label,
  labelAccessory,
  labelStyle,
  multiline = false,
  py = 1,
  size = 'medium',
  spacing = 'flushed',
  stacked = false,
  testID,
  noWrapText = false,
}: DataPointProps) => {
  const [category, setCategory] = useState<DataPointFont>('body-medium');

  useEffect(() => {
    setCategory(sizeToCategory[size]);
  }, [size]);

  let spacingValue;
  if (spacing === 'fixed') {
    spacingValue = 2;
  } else if (spacing === 'flushed') {
    spacingValue = 1;
  } else {
    spacingValue = 0;
  }

  return (
    <Stack
      data-testid={testID}
      direction={stacked ? 'column' : 'row'}
      spacing={spacingValue}
      sx={{
        maxWidth: fullWidth ? undefined : 375,
        py,
        ...containerStyle,
      }}
    >
      <Text
        category={category}
        flexShrink={0}
        sx={{
          ...labelStyle,
          ...(spacing === 'fixed' && { width: 128 }),
        }}
        testID={`${testID}-label`}
      >
        {label}
        {!!labelAccessory && (
          <>
            <HSpacer size="3" />
            {labelAccessory}
          </>
        )}
      </Text>
      {!isValidElement(children) ? (
        <Text
          breakWord={!noWrapText}
          category={category}
          multiline={multiline}
          sx={childrenStyle}
          testID={`${testID}-value`}
        >
          {children}
        </Text>
      ) : cloneElement(children, { ...children.props, 'data-testid': `${testID}-value` })}
    </Stack>
  );
};
