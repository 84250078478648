import { Button, Text, VSpacer, Input } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { EmailType, NavigationState } from '@/constants/EmailType';
import { AuthApi } from '@/utilities/api/AuthApi';
import { Alert, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';
import { validateEmail } from '@shared/utilities';

const FarmerForgotPasswordForm = () => {

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const { mutate: requestPasswordReset, isLoading } = useMutation(
    () => AuthApi.requestPasswordReset({ email }),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
      onSuccess: () => {
        const state: NavigationState = {
          email,
          emailType: EmailType.FORGOT_PASSWORD,
        };
        navigate(Routes.CHECK_EMAIL, {
          state,
        });
      },
    },
  );

  const submit = () => {
    if (validateEmail(email)) {
      requestPasswordReset();
    }
  };

  return (
    <Stack direction="column">
      <Text category="h3">Recover password</Text>
      <VSpacer size="3" />
      <Text category="p2">
        Enter your email and we’ll send you a link to reset your password.
      </Text>
      <VSpacer size="10" />
      { !!errorMessage && (
        <>
          <Alert color="error" icon={false}>{errorMessage}</Alert>
          <VSpacer mobileSize="5" size="8" />
        </>
      )}
      <Input
        label="Email"
        onChangeText={setEmail}
        onEnterPressed={submit}
        testID="farmer-forgot-password-form"
        value={email}
      />
      <VSpacer mobileSize="9" size="11" />
      <Stack alignItems="center">
        <Button
          disabled={!validateEmail(email)}
          loading={isLoading}
          onClick={submit}
          size="giant"
          testID="farmer-forgot-password-form-submit-button"
        >
          Email recovery link
        </Button>
      </Stack>
    </Stack>
  );
};

export default FarmerForgotPasswordForm;
