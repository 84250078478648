import { forwardRef, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Input, InputProps } from '../Input/Input';
import { DateTime } from 'luxon';

export type DatePickerInputProps = InputProps & {
  value?: Date,
  onChangeDate?: (value?: Date) => void,
};

interface DatePickerMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void,
  name: string,
}

const DatePickerMaskInput = forwardRef<HTMLElement, DatePickerMaskInputProps>(
  function TextMaskCustom (props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        definitions={{ '#': /[0-9]/ }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputRef={ref as any}
        mask="##/##/####"
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // To prevent the warning from showing up in the console
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        overwrite
      />
    );
  },
);

const DateFormat = 'MM/dd/yyyy';

export const DatePickerInput = ({
  onChangeDate,
  onChangeText,
  value,
  ...rest
}: DatePickerInputProps) => {

  const [internalValue, setInternalValue] = useState("");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setInternalValue(formatDate(value));
  }, []);

  useEffect(() => {
    if (!focused) {
      setInternalValue(formatDate(value));
    }
  }, [value]);

  useEffect(() => {
    if (!focused) { 
      setInternalValue(formatDate(value)); 
    }
  }, [focused]);

  const parseDate = (text: string) => {
    const date = DateTime.fromFormat(text, DateFormat);

    if (date.isValid) {
      return date.toJSDate();
    }
    return undefined;
  };

  const handleChangeText = (text: string) => {
    onChangeText?.(text);
    setInternalValue(text);
  };

  const fireOnChangeDate = (text: string) => {
    const parsedDate = !text ? undefined : parseDate(text);
    onChangeDate?.(parsedDate);
  };

  const formatDate = (date?: Date) => {
    if (!date) {
      return "";
    }
    return DateTime.fromJSDate(date).toFormat(DateFormat);
  };
   
  return (
    <Input
      inputComponent={DatePickerMaskInput}
      inputMode="tel"
      onBlur={() => {
        setFocused(false);
        fireOnChangeDate(internalValue as string);
      }}
      onChangeText={handleChangeText}
      onFocus={() => {
        setFocused(true);
      }}
      placeholder="mm/dd/yyyy"
      value={internalValue}
      variant="outlined"
      {...rest}
    />
  );
};
