import { Checkbox, VSpacer } from '@/components/DesignSystem';
import Comment from '@mui/icons-material/Comment';
import {
  IconButton,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Fragment } from 'react';

export const ListDemo = () => {
  return (
    <Stack>
      {[0, 1, 2, 3].map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <Fragment key={value}>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton aria-label="comments" edge="end">
                  <Comment />
                </IconButton>
              }
            >
              <ListItemButton>
                <ListItemIcon>
                  <Checkbox testID="list-demo-item-icon-checkbox" />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`List item... ${value + 1}`}
                  secondary="Supporting text..." />
              </ListItemButton>
            </ListItem>
            <VSpacer size="5" />
          </Fragment>
        );
      })}
    </Stack>
  );
};
