import GraphicHandshake from '@/assets/icons/graphic_handshake.svg';
import GraphicStar from '@/assets/icons/graphic_star.svg';
import GrowersLogo from '@/assets/logos/black/growers_logo.svg';
import { HSpacer, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { DeliverableBottomBar } from '@/pages/WebDeliverable/DeliverableBottomBar';
import { DeliverableBundleCard } from '@/pages/WebDeliverable/DeliverableBundleCard';
import { DeliverableProductCard } from '@/pages/WebDeliverable/DeliverableProductCard';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { ProductRecommendationApi } from '@/utilities/api/ProductRecommendationApi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import Store from '@mui/icons-material/Store';
import { Avatar, Box, darken, Divider, Stack } from '@mui/material';
import { DeliverableTemplateType, GrowersContactInfo, LedgerType } from '@shared/enums';
import { formatCurrency, formatPhoneNumber } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { Fragment, ReactNode, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EarnRewardsChip } from './EarnRewardsChip';

export const WebDeliverable = () => {
  const [searchParams] = useSearchParams();
  const pricingRequestId = searchParams.get('pricingRequestId');
  const recommendationId = searchParams.get('recommendationId');
  const deliverableToken = searchParams.get('deliverableToken');
  const [showPointsApplied, setShowPointsApplied] = useState(false);
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const handleOnError = () => {
    navigate(Routes.HOMEPAGE);
    openSnackbar('Could not retrieve deliverable');
  };

  const { data: pricingRequest } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST, pricingRequestId],
    () => PricingRequestsApi.getPricingRequestForDeliverable(pricingRequestId!, deliverableToken!),
    {
      enabled: !!pricingRequestId && !!deliverableToken,
      onError: handleOnError,
    },
  );

  const { data: recommendation } = useQuery(
    [QueryKeys.GET_RECOMMENDATION_BY_ID, recommendationId],
    () => ProductRecommendationApi.getByIdForDeliverable(recommendationId!, deliverableToken!),
    {
      enabled: !!recommendationId && !!deliverableToken,
      onError: handleOnError,
    },
  );

  const isRecommendation = !!recommendationId;
  const salesperson = pricingRequest?.salespersons?.[0]?.retailerSalesperson
    ?? recommendation?.salespersonDetails;
  const retailer = salesperson?.userRetailer;
  const brandColor = retailer?.brandColor ?? '#2a2a2a';
  const templateType = retailer?.templateType ?? DeliverableTemplateType.WhiteText;
  const textColor = templateType === DeliverableTemplateType.WhiteText ? 'white' : 'black';

  const CostSummary = () => {
    if (!pricingRequest || !pricingRequest.offers || !pricingRequest.calculations) {
      return <VSpacer size="7" />;
    }
    const { calculations } = pricingRequest;
    const { rewardRedemptions } = calculations;
    return (
      <Stack py="20px">
        <Text category="title-large">Cost Summary</Text>
        <VSpacer size="4" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="body-large">Item subtotal</Text>
          <Text category="body-large">{formatCurrency(calculations.subtotal)}</Text>
        </Stack>
        <VSpacer size="3" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="body-large">Shipping fee</Text>
          <Text category="body-large">{formatCurrency(calculations.shippingCost)}</Text>
        </Stack>
        <VSpacer size="3" />
        {!!Object.keys(rewardRedemptions).length && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <TextLink
                  onClick={() => setShowPointsApplied(!showPointsApplied)}
                  sx={{ color: 'black' }}
                  testID="points-applied-link"
                >
                  <Text category="title-medium">Points applied</Text>
                  <HSpacer size="2" />
                  {showPointsApplied ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </TextLink>
              </Stack>
              <Text category="body-large" sx={{ color: '#00875A' }}>
                -{formatCurrency(calculations.pointsRedeemed)}
              </Text>
            </Stack>
            {showPointsApplied && <VSpacer size="3" />}
            {showPointsApplied && getPointRedemptions().map((redemption) => (
              <Fragment key={redemption.ledgerName}>
                <Stack direction="row" justifyContent="space-between">
                  <Text
                    category="body-large"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {redemption.ledgerName}
                  </Text>
                  <Text category="body-large" sx={{ color: '#00875A' }}>
                    -{formatCurrency(redemption.points)}
                  </Text>
                </Stack>
                <VSpacer size="3" />
              </Fragment>
            ))}
            {!showPointsApplied && <VSpacer size="3" />}
          </>
        )}
        <GreyDivider />
        <VSpacer size="3" />
        <Stack direction="row" justifyContent="space-between" py="2px">
          <Text category="title-medium">Order Total Cost</Text>
          <Text category="title-medium">
            {formatCurrency(calculations.total)}
          </Text>
        </Stack>
      </Stack>
    );
  };

  const ElevatedCard = ({ children }: { children: ReactNode }) => (
    <Stack
      bgcolor="white"
      borderRadius="12px"
      boxShadow="0px 13px 27px -5px rgba(0, 0, 0, 0.15), 0px 8px 16px -8px rgba(0, 0, 0, 0.05)"
      color="black"
      p="16px"
      width="100%"
    >
      {children}
    </Stack>
  );

  const getPointRedemptions = (): { ledgerName: string, points: number }[]  => {
    if (!pricingRequest
      || !pricingRequest.calculations
      || !pricingRequest.offers) {
      return [];
    }
    let pointRedemptions = [];
    const offerId = pricingRequest.offers[0].id;
    const { offerBreakdownMap, rewardRedemptions } = pricingRequest.calculations;
    const retailerName = offerBreakdownMap[offerId].retailerName;
    const hasRetailerRedemption = rewardRedemptions[retailerName]
      && rewardRedemptions[retailerName].ledgerType === LedgerType.Retailer;
    if (hasRetailerRedemption) {
      pointRedemptions.push({
        ledgerName: retailerName,
        points: rewardRedemptions[retailerName].pointsRedeemed,
      });
    }
    const retailerManufacturerLedgerNames = Object.keys(rewardRedemptions).filter((key) => (
      rewardRedemptions[key].ledgerType === LedgerType.RetailerManufacturer
      && rewardRedemptions[key].offerId === offerId
    ));
    if (retailerManufacturerLedgerNames.length) {
      const retailerManufacturerLedgers = retailerManufacturerLedgerNames.map((name) => {
        const ledger = rewardRedemptions[name];
        return {
          ledgerName: name,
          points: ledger.pointsRedeemed,
        };
      });
      retailerManufacturerLedgers.sort((a, b) => a.ledgerName.localeCompare(b.ledgerName));
      pointRedemptions = [
        ...pointRedemptions,
        ...retailerManufacturerLedgers,
      ];
    }
    const hasGrowersRedemption = rewardRedemptions['Growers']
      && rewardRedemptions['Growers'].ledgerType === LedgerType.Growers
      && rewardRedemptions['Growers'].offerId === offerId;
    if (hasGrowersRedemption) {
      pointRedemptions.push({
        ledgerName: 'Growers',
        points: rewardRedemptions['Growers'].pointsRedeemed,
      });
    }
    return pointRedemptions;
  };

  const getProductCount = () => {
    if (pricingRequest) {
      return (pricingRequest.offers?.[0]?.products?.length ?? 0)
        + (pricingRequest.bundles?.length ?? 0);
    } else if (recommendation) {
      return (recommendation.productRecommendationData?.length ?? 0)
        + (recommendation.bundles?.length ?? 0);
    }
    return 0;
  };

  const getTotalCost = () => {
    if (pricingRequest) {
      return pricingRequest.calculations?.total ?? 0;
    } else if (recommendation) {
      const productCost = recommendation.productRecommendationData?.reduce((acc, product) => {
        return acc + (product.price ?? 0 * product.quantity);
      }, 0) ?? 0;
      return productCost + (recommendation.shipmentCost ?? 0);
    }
    return 0;
  };

  const GreyDivider = () => <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.10)' }} />;

  const Header = () => {
    if (!retailer || !salesperson) {
      return null;
    }
    return (
      <Stack
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
      >
        <Stack
          alignItems="center"
          bgcolor={brandColor}
          color={textColor}
          px="16px"
          textAlign="center"
        >
          <VSpacer size="9" />
          <VSpacer size="6" />
          <Avatar
            alt={`${retailer.name} logo`}
            src={retailer.image ? `${AppConfig.staticImageHost}/${retailer.image}` : undefined}
            sx={{
              bgcolor: brandColor,
              border: `1px solid ${textColor}`,
              height: '56px',
              width: '56px',
            }}
          >
            {retailer?.image ? null : <Store sx={{ color: textColor }} />}
          </Avatar>
          <VSpacer size="3" />
          <Text category="title-large">
            {retailer.name}
          </Text>
          <VSpacer size="1" />
          <Text category="body-small">
            {salesperson.userLocation?.name}
          </Text>
          <VSpacer size="5" />
          <Text category="overline">
            SELLER
          </Text>
          <Text category="title-medium">
            {salesperson.businessName}
          </Text>
          <VSpacer size="7" />
        </Stack>
        <Stack alignItems="center">
          <VSpacer size="5" />
          <Text category="title-medium">
            {isRecommendation ? 'PRODUCT RECOMMENDATION' : 'DIRECT ORDER'}
          </Text>
          <VSpacer size="5" />
        </Stack>
      </Stack>
    );
  };

  const OrderDetails = () => {
    const formattedOrderDate = pricingRequest ? DateTime.fromJSDate(
      pricingRequest.createdAt,
    ).toFormat('MMM dd, y') : '';
    const formattedExpiresDate = recommendation ? DateTime.fromJSDate(
      recommendation.expiration,
    ).toFormat('MMM dd, y') : '';
    return (
      <>
        <VSpacer size="3" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="body-medium">{isRecommendation ? 'Recommendation' : 'Order'} #</Text>
          <Text category="body-medium">
            {!!recommendation && recommendation.publicId}
            {!!pricingRequest && pricingRequest.publicId}
          </Text>
        </Stack>
        <VSpacer size="3" />
        <GreyDivider />
        <VSpacer size="3" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="body-medium">{isRecommendation ? 'Expires on' : 'Order date'}</Text>
          <Text category="body-medium">
            {isRecommendation ? formattedExpiresDate : formattedOrderDate}
          </Text>
        </Stack>
        <VSpacer size="3" />
        <GreyDivider />
        <VSpacer size="3" />
        {!isRecommendation && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Text category="title-small">Total cost</Text>
              <Text category="title-small">{formatCurrency(getTotalCost())}</Text>
            </Stack>
            <VSpacer size="3" />
            <GreyDivider />
          </>
        )}
        <VSpacer size="3" />
      </>
    );
  };

  return (
    <Stack bgcolor="white" color="black" margin="0 auto 96px auto" maxWidth="599px">
      <Header />
      <VSpacer size="3" />
      <Stack px="16px">
        <OrderDetails />
        <VSpacer size="8" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="title-large">
            Products ({getProductCount()})
          </Text>
          {deliverableToken && (
            <EarnRewardsChip
              pricingRequest={pricingRequest}
              recommendation={recommendation}
            />
          )}
        </Stack>
        <VSpacer size="6" />
        {pricingRequest && (
          <>
            {pricingRequest.offers?.[0]?.products?.map(
              (product) => (
                <DeliverableProductCard
                  key={product.id}
                  product={product}
                  requestProducts={pricingRequest?.products}
                />
              ))
            }
            {pricingRequest.bundles?.filter((bundle) => bundle.isAccepted).map((bundle) => (
              <DeliverableBundleCard bundle={bundle} key={bundle.id} />
            ))}
          </>
        )}
        {recommendation && (
          <>
            {recommendation.productRecommendationData?.map(
              (product) => <DeliverableProductCard key={product.id} product={product} />)
            }
            {recommendation.bundles?.filter((bundle) => bundle.isAccepted).map((bundle) => (
              <DeliverableBundleCard bundle={bundle} key={bundle.id} />
            ))}
          </>
        )}
        <CostSummary />
      </Stack>
      <Stack alignItems="center">
        <Text category="label-medium">
          THIS {isRecommendation ? 'RECOMMENDATION' : 'DIRECT ORDER'} IS
        </Text>
        {isRecommendation ? (
          <Text category="title-medium">
            Tailored for you  •  Ready to order
          </Text>
        ) : (
          <Text category="title-medium">
            Created for you  •  Fulfillment underway
          </Text>
        )}
      </Stack>
      <VSpacer size="8" />
      <Stack
        alignItems="center"
        bgcolor={darken(brandColor, .2)}
        color={textColor}
        px="16px"
        textAlign="center"
      >
        <VSpacer size="7" />
        {!!retailer && (
          <>
            <Text category="label-medium" upperCase>
              {retailer.name} knows farmers
            </Text>
            <VSpacer size="7" />
          </>
        )}
      </Stack>
      <Stack alignItems="center" bgcolor={brandColor} color={textColor} px="16px">
        <VSpacer size="6" />
        <Text category="display-medium" fontWeight={300}>
          What we offer
        </Text>
        <VSpacer size="7" />
        <ElevatedCard>
          <Text category="title-large">
            Earn Points, Get Rewarded
          </Text>
          <VSpacer size="3" />
          <Stack direction="row">
            <Box alignContent="center" height="60px" textAlign="center" width="60px">
              <img src={GraphicStar} />
            </Box>
            <HSpacer size="5" />
            <Text category="body-large">
              Points translate into dollars off your next order.
            </Text>
          </Stack>
        </ElevatedCard>
        <VSpacer size="7" />
        <ElevatedCard>
          <Text category="title-large">
            Work with trusted sellers
          </Text>
          <VSpacer size="3" />
          <Stack direction="row">
            <img height="60px" src={GraphicHandshake} width="60px" />
            <HSpacer size="5" />
            <Text category="body-large">
              Get the products you need, from the sellers you trust.
            </Text>
          </Stack>
        </ElevatedCard>
        {!!retailer?.description && (
          <>
            <VSpacer size="7" />
            <ElevatedCard>
              <Stack alignItems="center">
                <Avatar
                  alt={`${retailer.name} logo`}
                  src={retailer.image ? `${AppConfig.staticImageHost}/${retailer.image}` : undefined}
                  sx={{ bgcolor: brandColor, border: '1px solid black', color: textColor }}
                >
                  {retailer.image ? null : <Store />}
                </Avatar>
                <VSpacer size="3" />
                <Text category="title-large" textAlign="center">Why {retailer.name}?</Text>
                <VSpacer size="4" />
                <Text category="body-large">
                  {retailer.description}
                </Text>
              </Stack>
            </ElevatedCard>
          </>
        )}
        <VSpacer size="10" />
      </Stack>
      <Stack alignItems="center" p="48px 16px">
        <img src={GrowersLogo} />
        <VSpacer size="4" />
        <Text category="title-small">
          Need Help?  Contact GROWERS support.
        </Text>
        <VSpacer size="3" />
        <Stack alignItems="center" direction={isMobile ? 'column' : 'row'}>
          <TextLink
            category="title-small"
            href={`tel:${GrowersContactInfo.supportPhone}`}
            p="10px 16px 10px 12px"
            sx={{ color: 'black' }}
            testID="growers-phone-text-link"
          >
            <Phone />
            <HSpacer size="3" />
            <Text sx={{ textDecoration: 'underline' }}>
              {formatPhoneNumber(GrowersContactInfo.supportPhone, 'paren')}
            </Text>
          </TextLink>
          {!isMobile && (
            <HSpacer size="3" />
          )}
          <TextLink
            category="title-small"
            href={`mailto:${GrowersContactInfo.supportEmail}`}
            p="10px 16px 10px 12px"
            sx={{ color: 'black' }}
            testID="growers-email-text-link"
          >
            <Email />
            <HSpacer size="3" />
            <Text sx={{ textDecoration: 'underline' }}>
              {GrowersContactInfo.supportEmail}
            </Text>
          </TextLink>
        </Stack>
      </Stack>
      <DeliverableBottomBar
        pricingRequest={pricingRequest}
        recommendation={recommendation}
      />
    </Stack>
  );
};
