import { Button, HSpacer, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { Stack } from "@mui/material";
import { State } from '@shared/enums';
import { Nullable } from '@shared/utilities';

interface ContactInfo {
  address1?: Nullable<string>,
  address2?: Nullable<string>,
  city?: Nullable<string>,
  county?: Nullable<string>,
  postal?: Nullable<string>,
  state?: Nullable<State>,
}

interface ContactInformationModalProps {
  contactInfo: ContactInfo,
  isVisible: boolean,
  onClose: () => void,
}

const ContactInformationModal = ({
  contactInfo,
  isVisible,
  onClose,
}: ContactInformationModalProps) => {

  return (
    <Modal
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Close
        </Button>
      )}
      hideHeaderOnMobile
      onClose={onClose}
      open={isVisible}
      testID="contact-information-modal"
      title="Contact Information">
      <Stack>
        <Stack direction="row">
          <Text category="p1">Address 1:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.address1}</Text>
        </Stack>
        <VSpacer size="2" />
        <Stack direction="row">
          <Text category="p1">Address 2:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.address2}</Text>
        </Stack>
        <VSpacer size="2" />
        <Stack direction="row">
          <Text category="p1">City:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.city}</Text>
        </Stack>
        <VSpacer size="2" />
        <Stack direction="row">
          <Text category="p1">County:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.county}</Text>
        </Stack>
        <VSpacer size="2" />
        <Stack direction="row">
          <Text category="p1">State:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.state}</Text>
        </Stack>
        <VSpacer size="2" />
        <Stack direction="row">
          <Text category="p1">Zip:</Text>
          <HSpacer size="2" />
          <Text category="p1">{contactInfo.postal}</Text>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ContactInformationModal;
