import defaultProductImage from '@/assets/images/default-product-img-growers.png';
import defaultLandusProductImage from '@/assets/images/default-product-img-landus.png';
import { AppConfig } from '@/constants/AppConfig';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { ApiProduct, ApiPromotion } from '@api/interfaces';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly } from '@shared/utilities';

export const getProductImageUrl = (image?: string | null) => {
  const subdomain = getSubdomain();
  const defaultImageUrl = subdomain === 'landus'
    ? defaultLandusProductImage
    : defaultProductImage;
  const productImageUrl = image && `${AppConfig.staticImageHost}/${image}`;
  return productImageUrl || defaultImageUrl;
};

export const getFarmerPromotions = (
  promotions: ApiPromotion[],
  product: ApiProduct,
): ApiPromotion[]  => {
  const currentDate = formatDateOnly(new Date());
  return promotions.filter(
    (promotion) => (
      promotion.targetUserType === PromotionTargetUserType.Farmer
      && promotion.productIds.includes(product.id)
      && promotion.startDate <= currentDate && promotion.endDate >= currentDate
    ),
  ) ?? [];
};

export const getRetailerPromotions = (
  promotions: ApiPromotion[],
  product: ApiProduct,
): ApiPromotion[] => {
  const currentDate = formatDateOnly(new Date());
  return promotions.filter(
    (promotion) => (
      promotion.targetUserType === PromotionTargetUserType.Retailer
      && promotion.productIds.includes(product.id)
      && promotion.startDate <= currentDate && promotion.endDate >= currentDate
    ),
  ) ?? [];
};
