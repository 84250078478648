import { BottomBar, Button, HSpacer, VSpacer } from "@/components/DesignSystem";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { QueryKeys } from "@/constants/QueryKeys";
import { Routes } from "@/constants/Routes";
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { OffersApi } from "@/utilities/api/OffersApi";
import { PricingRequestRetailersApi } from "@/utilities/api/PricingRequestRetailersApi";
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";
import { OfferEndpoint, PricingRequestRetailerEndpoint } from "@api/endpoints";
import { ApiOfferProduct, ApiPricingRequestPublic } from "@api/interfaces";
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Alert, CircularProgress, Container, Stack } from "@mui/material";
import { FulfillmentMethod, PricingRequestStatus } from "@shared/enums";
import { pick, userIsRetailer } from '@shared/utilities';
import { isNil, omitBy } from "lodash";
import { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { defaultOffer, Offer, PricingRequestRetailer } from "./interfaces";
import AddNoteToOfferModal from "./Modals/AddNoteToOfferModal";
import { OfferOverviewDesktop } from "./OfferOverview/OfferOverviewDesktop";
import { OfferOverviewMobile } from "./OfferOverview/OfferOverviewMobile";
import RetailerUpdateInfoForm from "./RetailerUpdateInfoForm";

enum PlaceOfferMode {
  Overview,
  Review,
  RetailerUpdateInfo,
}

export const PlaceOffer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useAuthentication();
  const { openSnackbar } = useSnackbar();

  const [offer, setOffer] = useState<Offer>({ ...defaultOffer });
  const [
    pricingRequestRetailer,
    setPricingRequestRetailer,
  ] = useState<PricingRequestRetailer>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [readonly, setReadonly] = useState(false);
  const [showAddNoteToOfferModal, setShowAddNoteToOfferModal] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  const steps = [
    {
      mode: PlaceOfferMode.Overview,
      buttonLabel: "Create Offer",
      isValid: () => true,
    },
    {
      mode: PlaceOfferMode.Review,
      buttonLabel: "Submit Offer",
      isValid: () => isFulfillmentMethodValid(),
    },
    {
      mode: PlaceOfferMode.RetailerUpdateInfo,
      buttonLabel: "Confirm and Submit Offer",
      isValid: () => isRetailerValid(),
    },
  ];

  const isFulfillmentMethodValid = useCallback(() => {
    return offer.fulfillmentMethod !== FulfillmentMethod.NO_PREFERENCE;
  }, [offer.fulfillmentMethod]);

  const isRetailerValid = useCallback(() => {
    return true;
  }, [pricingRequestRetailer]);

  const inviteToken = searchParams.get('token') ?? undefined;

  const mapOfferToCreateRequest = (offer: Offer): OfferEndpoint.Create.Request => {
    return {
      ...pick(
        offer,
        ['fulfillmentMethod', 'note'],
      ),
      products: (offer.products ?? []).map((product) => ({
        ...pick(product, ['price', 'quantity', 'substituteProduct', 'uom']),
        pricingRequestProductId: product.pricingRequestProductId!,
      }) as unknown as OfferEndpoint.Create.Product),
    };
  };

  const { mutate: createOffer, isLoading: isCreatingOffer } = useMutation(
    () => OffersApi.createOffer(id || "", mapOfferToCreateRequest(offer), inviteToken),
    {
      onSuccess: (offer) => {
        navigate(
          Routes.PLACE_OFFER_SUCCESS,
          {
            replace: true,
            state: {
              offerPublicId: offer.publicId,
              priceRequestExpiration: pricingRequest?.expiration,
            },
          },
        );
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || "An error has occurred",
        );
      },
    },
  );

  const { mutate: updateRetailer, isLoading: isUpdatingRetailer } = useMutation(
    () => PricingRequestRetailersApi.update (
      pricingRequest?.id ?? "",
      pricingRequestRetailer as PricingRequestRetailerEndpoint.Update.Request,
      inviteToken,
    ),
    {
      onSuccess: () => {
        createOffer();
      }, onError: (error: { message: string }) => {
        openSnackbar(
          error.message || "An error has occurred",
        );
      },
    },
  );

  const isLastLookEnabled = (pricingRequest: ApiPricingRequestPublic | undefined) => {
    return (
      !!pricingRequest?.status
      && pricingRequest.status === PricingRequestStatus.Open
      && pricingRequest.salesperson?.preferred
    );
  };

  const { data: pricingRequest } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST_PUBLIC, id, user?.id],
    () => PricingRequestsApi.getPricingRequestPublic(id as string, inviteToken),
    {
      enabled: !!id && (userIsRetailer(user) || !!inviteToken),
      onSuccess: (data) => {
        const isExpiredForRetailer = data.isExpired && !isLastLookEnabled(data);
        setReadonly(!!data.offer || isExpiredForRetailer);
        setIsExpired(isExpiredForRetailer);
        if (!data.offer) {
          setOffer({
            ...offer,
            products: data.products?.map((p) => ({
              allowSubstitutions: p.allowSubstitutions,
              name: p.name,
              package: p.package,
              pricingRequestProductId: p.id,
              quantity: p.quantity,
              uom: p.uom,
            })),
          });
        }
        if (data.salesperson) {
          setPricingRequestRetailer({
            ...pricingRequestRetailer,
            ...omitBy(data.salesperson, isNil),
          });
        }
      },
      onError: () => {
        setHasAccess(false);
      },
      retry: false,
    },
  );

  const { data: placedOffer } = useQuery(
    [QueryKeys.GET_OFFER, pricingRequest?.offer?.id],
    () => OffersApi.getOffer(pricingRequest?.offer?.id ?? "", inviteToken),
    {
      enabled: !!pricingRequest?.offer?.id,
      onSuccess: (placedOffer) => {
        setOffer({
          ...placedOffer as Offer,
          products: placedOffer.products?.map((p: ApiOfferProduct) => {
            const pricingRequestProduct = pricingRequest?.products?.find(
              (brp) => brp.id === p.pricingRequestProductId,
            );
            return {
              price: p.price,
              substituteProduct: p.substituteProduct,
              pricingRequestProductId: p.pricingRequestProductId,
              isAccepted: p.isAccepted as boolean,
              name: pricingRequestProduct!.name ?? p.pricingRequestProduct?.name,
              package: pricingRequestProduct!.package ?? p.pricingRequestProduct?.package,
              quantity: pricingRequestProduct!.quantity
                ?? p.pricingRequestProduct?.quantity
                ?? null,
              uom: pricingRequestProduct!.uom ?? p.pricingRequestProduct?.uom ?? null,
              allowSubstitutions: !!pricingRequestProduct?.allowSubstitutions
                ?? p.pricingRequestProduct?.allowSubstitutions,
            };
          }),
        });
      },
      onError: () => {
        setHasAccess(false);
      },
      retry: false,
    },
  );

  const { data: lastLookData } = useQuery(
    [QueryKeys.GET_LAST_LOOK, pricingRequest?.id],
    () => PricingRequestsApi.getLastLook(pricingRequest!.id, inviteToken),
    { enabled: isLastLookEnabled(pricingRequest) },
  );

  const handleNext = async () => {
    scrollTo(0, 0);
    if (currentStep === steps.length - 1) {
      updateRetailer();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = async () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
    scrollTo(0, 0);
  };

  const CloseButton = () => (
    <Button
      onClick={() => navigate(Routes.VIEW_OFFERS)} size="giant"
      testID="place-offer-view"
    >
      Close
    </Button>
  );

  const CancelButton = () => (
    <Button
      onClick={() => navigate(Routes.VIEW_OFFERS)}
      size="giant"
      testID="place-offer-cancel-button"
      variant="outlined"
    >
      Cancel
    </Button>
  );

  const BackButton = () => (
    <Button
      onClick={handleBack}
      size="giant"
      startIcon={<ArrowBack />}
      testID="place-offer-back-button"
      variant="outlined"
    >
      Back
    </Button>
  );

  const NextButton = () => (
    <Button
      disabled={!steps[currentStep].isValid()}
      loading={isCreatingOffer || isUpdatingRetailer}
      onClick={handleNext}
      size="giant"
      testID="place-offer-next-button"
    >
      {steps[currentStep].buttonLabel}
    </Button>
  );

  return hasAccess ? (
    <>
      {(pricingRequest && (!pricingRequest.offer || placedOffer)) ? (
        <>
          <Container maxWidth="md">
            { steps[currentStep].mode === PlaceOfferMode.RetailerUpdateInfo ? (
              <RetailerUpdateInfoForm
                onChange={setPricingRequestRetailer}
                pricingRequestRetailer={pricingRequestRetailer} />
            ) : (
              <>
                <DesktopOnly>
                  <OfferOverviewDesktop
                    expired={isExpired}
                    lastLookData={lastLookData}
                    offer={offer}
                    onChangeOffer={(offer: Offer) => setOffer({ ...offer })}
                    onEditNote={() => setShowAddNoteToOfferModal(true)}
                    pricingRequest={pricingRequest}
                    readonly={readonly}
                    reviewMode={readonly || steps[currentStep].mode === PlaceOfferMode.Review} />
                </DesktopOnly>
                <MobileOnly>
                  <OfferOverviewMobile
                    expired={isExpired}
                    lastLookData={lastLookData}
                    offer={offer}
                    onChangeOffer={(offer: Offer) => setOffer({ ...offer })}
                    onEditNote={() => setShowAddNoteToOfferModal(true)}
                    pricingRequest={pricingRequest}
                    readonly={readonly}
                    reviewMode={readonly || steps[currentStep].mode === PlaceOfferMode.Review} />
                </MobileOnly>
              </>
            )}
          </Container>
          <DesktopOnly>
            <BottomBar justifyContent={readonly ? "flex-end" : "space-between"}>
              <Stack direction="row">
                {
                  currentStep > 0 && !readonly && (
                    <>
                      <BackButton />
                      <HSpacer size="7" />
                    </>
                  )
                }
                {userIsRetailer(user) && (
                  <>
                    {readonly ? <CloseButton /> : <CancelButton />}
                  </>
                )}
              </Stack>
              {!readonly && (
                <NextButton />
              )}
            </BottomBar>
          </DesktopOnly>
          <MobileOnly>
            <>
              {readonly ? (
                <>
                  {userIsRetailer(user) &&
                    <Stack direction="row" justifyContent="center">
                      <CloseButton />
                    </Stack>
                  }
                </>
              ) : (
                <>
                  <VSpacer size="11" />
                  <Stack direction="row" justifyContent="center">
                    {
                      currentStep > 0 && (
                        <>
                          <BackButton />
                          <HSpacer size="7"/>
                        </>
                      )
                    }
                    <NextButton />
                  </Stack>
                </>
              )}
              <VSpacer size="11" />
            </>
          </MobileOnly>
        </>
      ) : (
        <Container>
          <Stack alignItems="center">
            <VSpacer size="14" />
            <CircularProgress />
          </Stack>
        </Container>
      )}
      <AddNoteToOfferModal
        isVisible={showAddNoteToOfferModal}
        note={offer.note}
        onApplyNote={(note) => {
          setOffer({ ...offer, note });
          setShowAddNoteToOfferModal(false);
        }}
        onClose={() => setShowAddNoteToOfferModal(false)}
      />
    </>
  ) : (
    <Container maxWidth="md">
      <VSpacer size="8" />
      <Alert color="error" icon={false}>
        The link is invalid or it has expired. Please verify it and try again.
      </Alert>
    </Container>
  );
};
