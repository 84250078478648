import { HSpacer, IconButton } from "@/components/DesignSystem";
import { SpaceBetweenRow } from "@/components/layouts/SpaceBetweenRow";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { HelpLinks } from "@/components/shared/HelpLinks";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { StorefrontSwitcher } from '@/components/shared/StorefrontSwitcher';
import { UserSwitcher } from "@/components/shared/UserSwitcher";
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";

export const LoginHeader = () => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  return (
    <SpaceBetweenRow
      alignItems='center'
      paddingX={isMobile ? '8px' : '24px'}
      paddingY={isMobile ? '4px' : '8px'}
    >
      <Stack direction="row">
        <MobileOnly>
          <IconButton
            onClick={() => navigate(Routes.LANDING)}
            sx={{ height: 48, width: 48 }}
            testID='mobile-back-icon'
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </MobileOnly>
        <DesktopOnly>
          <HSpacer size="1" />
        </DesktopOnly>
        <UserSwitcher />
        <HSpacer size="2" />
        <StorefrontSwitcher />
      </Stack>
      <HelpLinks />
    </SpaceBetweenRow>
  );
};
