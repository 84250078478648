import { Text, VSpacer, Card, Button } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Stack } from "@mui/material";
import { Offer } from '../interfaces';

interface OfferNoteProps {
  offer: Offer,
  onEditNote: () => void,
  readonly: boolean,
}

const OfferNote = ({
  offer,
  onEditNote,
  readonly = false,
}: OfferNoteProps) => {

  const { isMobile } = useMediaQuery();

  return (
    <>
      <Stack direction="row" justifyContent={isMobile ? "center" : "space-between"}>
        <Text category="h3">Note</Text>
        <DesktopOnly>
          {
            !readonly && (
              <Button
                onClick={onEditNote}
                startIcon={<EditOutlined />}
                testID="offer-note-edit"
                variant="text"
              >
              </Button>
            )
          }
        </DesktopOnly>
      </Stack>
      <VSpacer size="7" />
      <Card testID="offer-note">
        <Text category="p1">
          {offer.note}
        </Text>
      </Card>
    </>
  );
};

export default OfferNote;


