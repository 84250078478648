import { SideSheet, Text, VSpacer } from '@/components/DesignSystem';
import { useProductRecommendationsById } from '@/hooks/useProductRecommendations';
import ProductRecommendationDetails
  from '@/pages/Admin/ProductRecommendation/ProductRecommendationDetails';
import { ApiRecommendationFarmerUser } from '@api/interfaces';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import ChatCard from './ChatCard';

type ProductRecommendationDetailsSlideSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  productRecommendationId: string | undefined;
};

const ProductRecommendationDetailsSlideSheet = ({
  isOpen,
  onClose,
  onOpen,
  productRecommendationId,
}: ProductRecommendationDetailsSlideSheetProps) => {
  const { productRecommendationById, isLoading } =
    useProductRecommendationsById(productRecommendationId ?? '');

  const Body = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    return (
      <Box px='24px'>
        <ProductRecommendationDetails
          recommendation={productRecommendationById}
          showDescription={true}
        />
        <VSpacer size='10' />
        <Text category="title-medium">
          Chats ({productRecommendationById?.recommendationFarmer?.length})
        </Text>
        <VSpacer size='5' />
        {productRecommendationById?.recommendationFarmer?.length ? (
          productRecommendationById?.recommendationFarmer?.map(
            (farmer: ApiRecommendationFarmerUser) => (
              <ChatCard
                farmer={farmer}
                key={farmer?.id}
                publicId={productRecommendationById?.publicId}
                retailerId={productRecommendationById?.salespersonDetails?.id}
              />
            ),
          )
        ) : (
          <Text category='p2'>
            No chats available
          </Text>
        )}
      </Box>
    );
  };

  return (
    <SideSheet
      hideScrollbar
      isBorderless
      onClose={onClose}
      onOpen={onOpen}
      open={isOpen}
      testID='product-recommendation-details'
      title='Details'
      width='425px'
    >
      <Body />
    </SideSheet>
  );
};

export default ProductRecommendationDetailsSlideSheet;
