import { Box } from '@mui/material';

export const IconBadge = ({ content }: { content: string | number }) => (
  <Box
    component="span"
    sx={{
      backgroundColor: 'background.default',
      width: '24px',
      color: 'text.primary',
      borderRadius: '12px',
    }}
  >
    {content}
  </Box>
);