import { AppUrls } from '@/constants/AppUrls';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { Box, Link } from '@mui/material';

const styles: SXStyles = {
  iosDownloadButton: {
    borderRadius: '13px',
    height: '60px',
  },
} as const;

interface IosAppDownloadButtonProps {
  href: string,
}

export const IosAppDownloadButton = ({ href }: IosAppDownloadButtonProps) => (
  <Link
    href={href}
  >
    <Box
      alt="Download on the App Store"
      component="img"
      src={AppUrls.iosDownloadBadge}
      sx={styles.iosDownloadButton}
    />
  </Link>
);
